import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Card, Stack, Container } from "react-bootstrap";
import { useKit } from "./CustomerKit";
import { useNavigate, useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import Loader from "../includes/Loader";
import axios from "../api/axios";

const CustomerKitProject = () => {
  const { key } = useParams();
  const init = useRef(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [projectList, setProjectList] = useState([]);
  const [thumbsSwiper, setThumbsSwiper] = useState({});
  const { hostUrl, hostUrlType, company_id } = useKit();

  // GET FUNCTION ----------------------------

  const getProjectListing = async () => {
    try {
      const response = await axios.get("ws/ws_rea_sales_kit.php", {
        params: {
          task: "SalesKit_project-list",
          key: key,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        data.record.forEach((record, idx) => {
          record.project_id = record.project_name.replaceAll(" ", "").toLowerCase().trim();
          setThumbsSwiper((prevstate) => ({ ...prevstate, [idx]: null }));
        });

        setProjectList(data.record);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // SET FUNCTION ----------------------------

  const setProjectImage = (image) => {
    if (image) {
      return `${hostUrl}/${hostUrlType}/assets/rea_booking/${company_id}/project/${image}`;
    } else {
      return `${hostUrl}/${hostUrlType}/assets/rea_booking/generic.jpg`;
    }
  };

  // OPEN FUNCTION ---------------------------

  const openProject = (record) => {
    navigate(`/rea-sales-kit/${key}/${record.project_id}`);
  };

  // USEEFFECT FUNCTION ----------------------

  useEffect(() => {
    const initData = async () => {
      if (!init.current) {
        try {
          await getProjectListing();
          setLoading(false);
          init.current = true;
        } catch (error) {
          setLoading(false);
        }
      }
    };
    initData();
  }, []);

  return (
    <Container fluid className="m-0 p-0 internal-project">
      {loading ? (
        <Loader />
      ) : (
        <div className="p-4">
          <Row className="op-swiper">
            {projectList.length > 0 &&
              projectList.map((record, idx) => (
                <Col key={idx} xxl={12} className="mb-3" style={{ marginRight: 100 }}>
                  <Card className="shadow border-0 h-100">
                    <Row>
                      <Col xxl={7} className="cursor-pointer" onClick={() => openProject(record)}>
                        <Swiper spaceBetween={0} navigation={false} thumbs={{ swiper: thumbsSwiper[idx] }} modules={[FreeMode, Navigation, Thumbs]} className="mySwiper2">
                          {record.project_image.map((data, i) => (
                            <SwiperSlide key={i}>
                              <img src={setProjectImage(data)} alt="" />
                            </SwiperSlide>
                          ))}
                        </Swiper>
                      </Col>
                      <Col xxl={5} className="py-4" style={{ paddingRight: "2.5rem" }}>
                        <Stack direction="vertical" className="h-100">
                          <h5 className="cursor-pointer" onClick={() => openProject(record)}>
                            {record.project_name}
                          </h5>
                          <p className="mb-0 text-muted">{record.project_location}</p>
                          <p className="mb-0">{record.project_desc}</p>
                          <div className="mt-auto">
                            <Swiper onSwiper={(swiper) => setThumbsSwiper((prevSwipers) => ({ ...prevSwipers, [idx]: swiper }))} spaceBetween={5} slidesPerView={4} freeMode={true} watchSlidesProgress={true} className="mySwiper" modules={[FreeMode, Navigation, Thumbs]}>
                              {record.project_image.map((data, i) => (
                                <SwiperSlide key={i}>
                                  <img src={setProjectImage(data)} alt="" />
                                </SwiperSlide>
                              ))}
                            </Swiper>
                          </div>
                        </Stack>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              ))}
          </Row>
        </div>
      )}
    </Container>
  );
};

export default CustomerKitProject;
