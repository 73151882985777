import { faBuildings, faCircleDollar, faUser, faCalendar, faPhone, faUsers, faListCheck, faFlag, faEnvelopeCircleCheck, faPhoneArrowDownLeft, faPhoneArrowUpRight, faLink, faLocationCheck, faCircleMinus, faHouseCircleCheck, faGrid } from "@fortawesome/pro-light-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ListGroup from "react-bootstrap/ListGroup";
import { Nav } from "react-bootstrap";
import { debounce } from "lodash";
import axios from "../api/axios";
import { useAuth } from "../auth/AuthContext";
import { useState, useEffect, useRef } from "react";
import { setCurrency } from "../lib/js/Function";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const SearchDropdown = ({ options, setOptions, category, setCategory, searchBackup, setSearchBackup, onSearch, onLoading, handleSearchClose }) => {
  const { session } = useAuth();
  const searchRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [recent, setRecent] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    if (searchBackup === onSearch) {
      onLoading(false);
      setLoading(false);
      return;
    }

    setOptions([]);
    onLoading(true);
    setLoading(true);

    if (!onSearch) {
      onLoading(false);
      setLoading(false);
      setOptions([]);
    }

    const fetchData = async () => {
      setSearchBackup(onSearch);
      try {
        const response = await axios.get("ws/ws_global_search.php", {
          params: {
            task: 1,
            search: onSearch,
            utoken: session.user_ac_token,
            ctoken: session.company_token,
          },
        });

        const data = response.data;
        if (data.status === 0) {
          const deal = data.deal.map((item) => {
            const search_desc_parts = [];

            if (item.deal_value) {
              search_desc_parts.push(setCurrency(item.deal_value));
            }

            if (item.prospect_name) {
              search_desc_parts.push(item.prospect_name);
            }

            if (item.deal_status) {
              search_desc_parts.push(setDealStatus(item.deal_status));
            }

            const search_desc = search_desc_parts.join("&nbsp;&nbsp;·&nbsp;&nbsp;");
            return { ...item, search_title: item.deal_title, search_desc, search_desc2: "", search_icon: faCircleDollar };
          });

          const prospect = data.prospect.map((item) => {
            const search_desc_parts = [];
            if (item.contact1) {
              search_desc_parts.push(item.contact1);
            }

            if (item.email1) {
              search_desc_parts.push(item.email1);
            }
            const search_desc = search_desc_parts.join("&nbsp;&nbsp;·&nbsp;&nbsp;");
            return { ...item, search_title: item.prospect_name, search_desc, search_desc2: "", search_icon: faUser };
          });

          const organization = data.organization.map((item) => {
            const search_desc_parts = [];
            if (item.organization_contact) {
              search_desc_parts.push(item.organization_contact);
            }

            const search_desc = search_desc_parts.join("&nbsp;&nbsp;·&nbsp;&nbsp;");
            return { ...item, search_title: item.organization_title, search_desc, search_desc2: "", search_icon: faBuildings };
          });

          const booking = data.booking.map((item) => {
            const search_desc_parts = [];
            const search_desc_parts2 = [];

            if (item.buyer_id_number) {
              search_desc_parts.push(item.buyer_id_number);
            }

            if (item.booking_status) {
              search_desc_parts.push(setBookingStatus(item.booking_status));
            }

            if (item.nett_price) {
              search_desc_parts.push(setCurrency(item.nett_price));
            }

            if (item.unit_number) {
              search_desc_parts2.push(item.unit_number);
            }

            if (item.phase_name) {
              search_desc_parts2.push(item.phase_name);
            }

            if (item.project_name) {
              search_desc_parts2.push(item.project_name);
            }

            const search_desc = search_desc_parts.join("&nbsp;&nbsp;·&nbsp;&nbsp;");
            const search_desc2 = search_desc_parts2.join("&nbsp;&nbsp;·&nbsp;&nbsp;");
            return { ...item, search_title: item.buyer_name, search_desc, search_desc2, search_icon: faHouseCircleCheck };
          });

          const activity = data.activity.map((item) => {
            const search_desc_parts = [];

            if (item.dat_title) {
              search_desc_parts.push(item.dat_title);
            }

            if (item.activity_dated !== "0000-00-00") {
              search_desc_parts.push(moment(item.activity_dated + " " + item.activity_timed).format("lll"));
            }

            if (item.deal_title) {
              search_desc_parts.push(item.deal_title);
            }

            const search_desc = search_desc_parts.join("&nbsp;&nbsp;·&nbsp;&nbsp;");
            return { ...item, search_title: item.activity_title, search_desc, search_desc2: "", search_icon: setActivityIcon(item.dat_id) };
          });

          const search_merge = [...deal, ...prospect, ...organization, ...booking, ...activity];
          const search_record = search_merge.sort((a, b) => a.search_title.localeCompare(b.search_title));

          setOptions(search_record);
          onLoading(false);
          setLoading(false);
        } else {
          setOptions([]);
          onLoading(false);
          setLoading(false);
        }
      } catch {
        setOptions([]);
        onLoading(false);
        setLoading(false);
      }
    };

    const debouncedLoadOptions = onSearch ? debounce(fetchData, 1000) : null;

    if (debouncedLoadOptions) {
      debouncedLoadOptions();
    }

    return () => {
      if (debouncedLoadOptions) {
        debouncedLoadOptions.cancel();
      }
    };
  }, [onSearch, session.user_ac_token, session.company_token]);

  useEffect(() => {
    const recent = sessionStorage.getItem("recentViewed") || "[]";
    setRecent(JSON.parse(recent));
  }, []);

  useEffect(() => {
    if (searchRef.current) {
      searchRef.current.scrollTop = 0;
    }
  }, [searchRef.current, category]);

  const openOptions = (record) => {
    const recent = sessionStorage.getItem("recentViewed") || "[]";
    const options = JSON.parse(recent);
    const isRecordExists = options.some((data) => data.deal_id === record.deal_id);

    if (!isRecordExists) {
      options.push(record);
      const optionsEncode = JSON.stringify(options);
      sessionStorage.setItem("recentViewed", optionsEncode);
    }

    if (record.search_type === "deal") {
      navigate(`/deal/${record.deal_id}`, { replace: true });
    } else if (record.search_type === "prospect") {
      navigate(`/prospect/${record.prospect_id}`, { replace: true });
    } else if (record.search_type === "organization") {
      navigate(`/organizations/${record.organization_id}`, { replace: true });
    } else if (record.search_type === "activity") {
      navigate(`/deal/${record.deal_id}`, { replace: true });
    } else if (record.search_type === "booking") {
      navigate(`/booking-details/${record.rea_booking_id}/${record.deal_id}`, { replace: true });
    }

    handleSearchClose(false);
  };

  const setActivityIcon = (datId) => {
    if (Number(datId) === 1) {
      return faPhone;
    } else if (Number(datId) === 2) {
      return faUsers;
    } else if (Number(datId) === 3) {
      return faListCheck;
    } else if (Number(datId) === 4) {
      return <FontAwesomeIcon icon={faFlag} />;
    } else if (Number(datId) === 5) {
      return faEnvelopeCircleCheck;
    } else if (Number(datId) === 6) {
      return faPhoneArrowDownLeft;
    } else if (Number(datId) === 7) {
      return faPhoneArrowUpRight;
    } else if (Number(datId) === 8) {
      return faWhatsapp;
    } else if (Number(datId) === 9) {
      return faLink;
    } else if (Number(datId) === 10) {
      return faLocationCheck;
    } else if (Number(datId) === 11) {
      return faCircleMinus;
    }
  };

  const setDealStatus = (input) => {
    if (input === "new") {
      return "Contact Lead";
    } else if (input === "active") {
      return "Progressing";
    } else if (input === "inactive") {
      return "Follow Up";
    }
  };

  const setBookingStatus = (input) => {
    if (input === "booked") {
      return "Reserved";
    } else if (input === "booking_confirmed") {
      return "Booking Confirmed";
    } else if (input === "spa_stamped") {
      return "SPA Stamped";
    } else if (input === "spa_signed") {
      return "SPA Signed";
    } else if (input === "spa_sold") {
      return "SPA Sold";
    }
  };

  const setHighlightText = (text) => {
    return text.replace(new RegExp(`(${onSearch})`, "gi"), '<span className="highlight" style="background-color: yellow;">$1</span>');
  };

  return (
    <div className={`op-search-dropdown shadow-sm ${onSearch ? "active" : ""}`}>
      {onSearch && (
        <div className={`left-panel ${onSearch ? "open" : "close"}`}>
          <div className="d-flex flex-column flex-shrink-0 p-2">
            <Nav className="nav-pills flex-column mb-auto" activeKey={category} onSelect={(selectedKey) => setCategory(selectedKey)}>
              <Nav.Item>
                <Nav.Link eventKey="all" className={`search-filter op-text-medium`}>
                  <div className="nav-icon">
                    <FontAwesomeIcon icon={faGrid} size="lg" />
                  </div>
                  <div className="search-filter-text">All categories</div>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="deal" className="search-filter op-text-medium">
                  <div className="nav-icon">
                    <div className="nav-icon-wrapper">
                      <FontAwesomeIcon icon={faCircleDollar} size="lg" />
                    </div>
                  </div>
                  <div className="search-filter-text">Deals</div>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="prospect" className="search-filter op-text-medium">
                  <div className="nav-icon">
                    <div className="nav-icon-wrapper">
                      <FontAwesomeIcon icon={faUser} size="lg" />
                    </div>
                  </div>
                  <div className="search-filter-text">People</div>
                </Nav.Link>
              </Nav.Item>

              {Number(session.company_type_id) !== 2 && (
                <Nav.Item>
                  <Nav.Link eventKey="organization" className="search-filter op-text-medium">
                    <div className="nav-icon">
                      <div className="nav-icon-wrapper">
                        <FontAwesomeIcon icon={faBuildings} size="lg" />
                      </div>
                    </div>
                    <div className="search-filter-text">Organizations</div>
                  </Nav.Link>
                </Nav.Item>
              )}

              {Number(session.booking_module) === 1 && (
                <Nav.Item>
                  <Nav.Link eventKey="booking" className="search-filter op-text-medium">
                    <div className="nav-icon">
                      <div className="nav-icon-wrapper">
                        <FontAwesomeIcon icon={faHouseCircleCheck} size="lg" />
                      </div>
                    </div>
                    <div className="search-filter-text">Bookings</div>
                  </Nav.Link>
                </Nav.Item>
              )}

              <Nav.Item>
                <Nav.Link eventKey="activity" className="search-filter op-text-medium">
                  <div className="nav-icon">
                    <div className="nav-icon-wrapper">
                      <FontAwesomeIcon icon={faCalendar} size="lg" />
                    </div>
                  </div>
                  <div className="search-filter-text">Activities</div>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
        </div>
      )}

      <div ref={searchRef} className="right-panel overflow-y-auto">
        {onSearch ? (
          <>
            <h6 className="op-text-medium">All search results</h6>
            <ListGroup>
              {options.filter((record) => (category === "all" ? true : record.search_type === category)).length > 0 ? (
                options
                  .filter((record) => (category === "all" ? true : record.search_type === category))
                  .map((record, index) => (
                    <ListGroup.Item action className="op-text-medium list-item" key={index} onClick={() => openOptions(record)}>
                      <div className="list-icon">
                        <div className="list-icon-wrapper">
                          <FontAwesomeIcon icon={record.search_icon} size="2x" />
                        </div>
                      </div>
                      <div className="list-content">
                        <div className="list-title" dangerouslySetInnerHTML={{ __html: setHighlightText(record.search_title) }} />
                        {record.search_desc && <div className="list-desc" dangerouslySetInnerHTML={{ __html: setHighlightText(record.search_desc) }} />}
                        {record.search_desc2 && <div className="list-desc" dangerouslySetInnerHTML={{ __html: setHighlightText(record.search_desc2) }} />}
                        {record.search_type !== "activity" && record.fields_count > 0 && (
                          <>
                            {record.fields.map((field, i) => (
                              <div className="list-desc" key={i} dangerouslySetInnerHTML={{ __html: setHighlightText(`${field.cf_label}: ${field.fd_value}`) }} />
                            ))}
                          </>
                        )}
                      </div>
                    </ListGroup.Item>
                  ))
              ) : (
                <div>{loading ? <div className="text-center op-text-medium">Please wait...</div> : <div className="text-center op-text-medium">No record found</div>}</div>
              )}
            </ListGroup>
          </>
        ) : (
          <>
            <h6 className="op-text-medium">Recently viewed</h6>
            {recent.length > 0 ? (
              <ListGroup>
                {recent.map((record, index) => (
                  <ListGroup.Item
                    action
                    className="op-text-medium list-item"
                    key={index}
                    onClick={() => {
                      if (record.search_type === "deal") {
                        navigate(`/deal/${record.deal_id}`, { replace: true });
                      } else if (record.search_type === "prospect") {
                        navigate(`/prospect/${record.prospect_id}`, { replace: true });
                      } else if (record.search_type === "organization") {
                        navigate(`/organizations/${record.organization_id}`, { replace: true });
                      } else if (record.search_type === "activity") {
                        navigate(`/deal/${record.deal_id}`, { replace: true });
                      } else if (record.search_type === "booking") {
                        navigate(`/booking-details/${record.rea_booking_id}/${record.deal_id}`, { replace: true });
                      }

                      handleSearchClose(false);
                    }}
                  >
                    <div className="list-icon">
                      <div className="list-icon-wrapper">
                        <FontAwesomeIcon icon={record.search_icon} size="2x" />
                      </div>
                    </div>
                    <div className="list-content">
                      <div className="list-title" dangerouslySetInnerHTML={{ __html: record.search_title }} />
                      {record.search_desc && <div className="list-desc" dangerouslySetInnerHTML={{ __html: record.search_desc }} />}
                      {record.search_desc2 && <div className="list-desc" dangerouslySetInnerHTML={{ __html: record.search_desc2 }} />}
                      {record.search_type !== "activity" && record.fields_count > 0 && (
                        <>
                          {record.fields.map((field, i) => (
                            <div className="list-desc" key={i} dangerouslySetInnerHTML={{ __html: `${field.cf_label}: ${field.fd_value}` }} />
                          ))}
                        </>
                      )}
                    </div>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            ) : (
              <div className="op-text-medium text-center">No recently viewed items at the moment.</div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default SearchDropdown;
