import React, { useEffect, forwardRef, useRef, useState, useCallback } from "react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Col, Container, Form, Image, Row, Stack, Modal, ListGroup, Offcanvas, Dropdown, Accordion, Badge, Nav } from "react-bootstrap";
import { FormSelect } from "../includes/FormCustom";
import Loader from "../includes/Loader";
import axios from "../api/axios";
import moment from "moment";
import { setCurrency } from "../lib/js/Function";
import { GoogleMap, useJsApiLoader, Marker, DirectionsRenderer, DirectionsService } from "@react-google-maps/api";
import { faBagShopping, faGraduationCap, faHouseMedical, faTrain } from "@fortawesome/free-solid-svg-icons";
import {
  faMap,
  faHouseLock,
  faPersonBooth,
  faChevronRight,
  faBedFront,
  faBath,
  faEllipsisVertical,
  faHouse,
  faHouseBuilding,
  faMapLocationDot,
  faKeySkeleton,
  faDoorOpen,
  faX,
  faFileCertificate,
  faBuildings,
  faWaterLadder,
  faFile,
  faSliders,
  faPhone,
  faImage,
  faPlay,
} from "@fortawesome/pro-light-svg-icons";
import EducationMarker from "../assets/images/marker/education_marker.png";
import EntertainmentMarker from "../assets/images/marker/entertainment_marker.png";
import HospitalMarker from "../assets/images/marker/hospital_marker.png";
import PublicTransportMarker from "../assets/images/marker/public_transport_marker.png";
import ShoppingMarker from "../assets/images/marker/shopping_marker.png";
import { triggerBase64Download } from "react-base64-downloader";
import { Swiper, SwiperSlide } from "swiper/react";
import { Zoom, FreeMode, Navigation, Thumbs, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "../lib/scss/phaseDetails.scss";
import { faCameraMovie, faPlayCircle } from "@fortawesome/pro-solid-svg-icons";
import * as formik from "formik";
import { setModulesValues } from "./booking/BookingFormat";
import { debounce } from "lodash";
import iziToast from "izitoast";
import { useKit } from "./CustomerKit";
const FloorPlanSwiper = ({ images, hostUrl, hostUrlType, company_id, phaseData, type }) => {
  const [mainSwiper, setMainSwiper] = useState(null);
  const [modalSwiper, setModalSwiper] = useState(null);
  const [show, setShow] = useState(false);
  const [mainSwiperIndex, setMainSwiperIndex] = useState(0);
  const [modalSwiperIndex, setModalSwiperIndex] = useState(0);

  const setPhaseImage = (image) => {
    if (image !== "generic.png") {
      return `${hostUrl}/${hostUrlType}/assets/rea_booking/${company_id}/project/${image}`;
    } else {
      return `${hostUrl}/${hostUrlType}/assets/rea_booking/generic.png`;
    }
  };

  const setSwiperLabel = (index, images) => {
    const cleanLabel = (filename) => {
      let decodedFilename = decodeURIComponent(filename);
      decodedFilename = decodedFilename.replace(/\.[^/.]+$/, "");
      decodedFilename = decodedFilename.replace(/_/g, " ");
      return decodedFilename;
    };

    if (index < images.length) {
      return cleanLabel(images[index]);
    }

    return "Unnamed";
  };

  const RenderImageUnavailable = () => (
    <div className="position-relative w-100 d-flex align-items-center justify-content-center" style={{ height: 300 }}>
      <FontAwesomeIcon icon={faHouse} size="10x" style={{ opacity: 0.05 }} />
      <h6 style={{ position: "absolute" }}>Image Not Available</h6>
    </div>
  );

  const sendDownload = async (filename, filepath) => {
    try {
      const response = await axios.get(`${hostUrl}/${hostUrlType}/php/api_get_file_content.php`, {
        params: {
          filepath: filepath,
        },
      });

      const data = await response.data;
      const base64 = `data:${data.mime_type};base64,${data.content}`;
      triggerBase64Download(base64, filename);
    } catch (error) {
      console.error(error);
    }
  };

  const RenderShareToggle2 = forwardRef(({ children, onClick }, ref) => (
    <Button
      variant="link"
      className="m-0 text-light text-decoration-none"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </Button>
  ));

  return (
    <div className="d-flex justify-content-center align-items-center">
      {images.length > 0 ? (
        <Swiper
          spaceBetween={10}
          navigation={true}
          thumbs={{ swiper: mainSwiper }}
          modules={[FreeMode, Navigation, Thumbs]}
          className="swiperpd3"
          style={{
            "--swiper-navigation-color": "transparent",
            "--swiper-pagination-color": "transparent",
          }}
        >
          {images.map((data, i) => (
            <SwiperSlide key={i}>
              <img
                className="cursor-pointer"
                src={setPhaseImage(data)}
                alt=""
                onClick={() => {
                  setMainSwiperIndex(i);
                  setModalSwiper(null);
                  setShow(true);
                }}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <RenderImageUnavailable />
      )}

      <Modal show={show} onHide={setShow} fullscreen={true} dialogClassName="op-modal-dialog-fullscreen">
        <Modal.Header className="position-relative border-0 py-1" style={{ background: "#505050" }}>
          <div className="d-flex flex-row-reverse w-100">
            <Button
              variant="link"
              className="m-0 text-light text-decoration-none"
              onClick={() => {
                setShow(false);
                setMainSwiper(mainSwiper);
              }}
            >
              <FontAwesomeIcon icon={faX} size="xl" style={{ color: "#fff" }} />
            </Button>
            <div className="me-2">
              <Dropdown>
                <Dropdown.Toggle as={RenderShareToggle2}>
                  <FontAwesomeIcon className="cursor-pointer" icon={faEllipsisVertical} size="xl" />
                </Dropdown.Toggle>
                <Dropdown.Menu align={"end"}>
                  <Dropdown.Item
                    onClick={() => {
                      if (modalSwiper) {
                        const activeSlide = modalSwiper.slides.find((slide) => slide.classList.contains("swiper-slide-active"));
                        if (activeSlide) {
                          const activeSlideIndex = Array.from(modalSwiper.slides).indexOf(activeSlide);
                          const currentImage = images.length === 0 ? `${hostUrl}/${hostUrlType}/assets/rea_booking/generic.jpg` : `${hostUrl}/${hostUrlType}/assets/rea_booking/${company_id}/project/${images[activeSlideIndex]}`;
                          const text = `Please find the file of floor plans ${type.unit_title} ${type.unit_type} listed below for your reference: \n${currentImage}`;
                          const whatsapp = `whatsapp://send?text=${encodeURIComponent(text)}`;
                          window.open(whatsapp, "_blank");
                        }
                      }
                    }}
                  >
                    Share via Whatsapp
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      if (modalSwiper) {
                        const activeSlide = modalSwiper.slides.find((slide) => slide.classList.contains("swiper-slide-active"));
                        if (activeSlide) {
                          const activeSlideIndex = Array.from(modalSwiper.slides).indexOf(activeSlide);
                          const currentImage = images.length === 0 ? `../assets/rea_booking/generic.jpg` : `../assets/rea_booking/${company_id}/project/${images[activeSlideIndex]}`;
                          sendDownload(`${phaseData.phase_name}`, currentImage);
                        }
                      }
                    }}
                  >
                    Download
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body style={{ background: "#505050" }}>
          <Swiper
            initialSlide={mainSwiperIndex}
            onSlideChange={(swiper) => setModalSwiperIndex(swiper.activeIndex)}
            spaceBetween={10}
            navigation={true}
            zoom={true}
            thumbs={{ swiper: modalSwiper }}
            modules={[Zoom, FreeMode, Navigation, Thumbs, Pagination]}
            className="mySwiper2"
            style={{
              "--swiper-navigation-color": "#fff",
              "--swiper-pagination-color": "#fff",
              zIndex: 0,
            }}
          >
            {images.map((data, i) => (
              <SwiperSlide key={i} className="d-flex justify-content-center w-100">
                <div className="swiper-zoom-container">
                  <img className="cursor-pointer" src={setPhaseImage(data)} style={{ width: "auto", height: "65vh", objectFit: "cover" }} alt="mediaimage" />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>

          <Row className="g-0">
            <Col md={{ span: 6, offset: 3 }}>
              <label className="my-2 text-light fw-bold">{setSwiperLabel(modalSwiperIndex, images)}</label>
              <Swiper onSwiper={setModalSwiper} spaceBetween={10} slidesPerView={8} freeMode={true} watchSlidesProgress={true} modules={[FreeMode, Navigation, Thumbs]}>
                {images.map((data, i) => (
                  <SwiperSlide key={i} style={{ cursor: "pointer" }}>
                    <img src={setPhaseImage(data)} style={{ width: "100%", height: 55, objectFit: "cover", borderRadius: 5 }} alt="mediaimage" />
                  </SwiperSlide>
                ))}
              </Swiper>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </div>
  );
};

const CustomerKitPhaseDetails = () => {
  const init = useRef();
  const modulesValues = setModulesValues({});
  const { hostUrl, hostUrlType, company_id } = useKit();
  const { Formik } = formik;
  const [loading, setLoading] = useState(true);
  const [modules, setModules] = useState(modulesValues);
  const [phaseData, setPhaseData] = useState({});
  const [typeList, setTypeList] = useState([]);
  const navigate = useNavigate();
  const [location, setLocation] = useState(null);
  const [isUnit, setIsUnit] = useState(false);
  const [files, setFiles] = useState([]);
  const [isFile, setIsFile] = useState(false);
  const [bedroomList, setBedroomList] = useState([]);
  const [isDocument, setIsDocument] = useState(false);
  const [documentData, setDocumentData] = useState({});
  const { isLoaded } = useJsApiLoader({ id: "google-map-script", googleMapsApiKey: "AIzaSyACc3wiPr3UpBXlQuZ4i1BOGEIvpAUY1TY" });
  const [unitList, setUnitList] = useState([]);
  const [show, setShow] = useState(false);
  const [travelResponse, setTravelResponse] = useState(null);
  const [destinationResponse, setDestinationResponse] = useState(false);
  const [destination, setDestination] = useState(null);
  const [isProjectDetails, setIsProjectDetails] = useState(false);
  const [isUnitFilter, setIsUnitFilter] = useState(false);
  const [unitFilter, setUnitFilter] = useState([]);
  const [unitSettings, setUnitSettings] = useState({ unit: "", unit_status: "", unit_type: "", unit_bedroom: "" });
  const [unitTypeList, setUnitTypeList] = useState([]);
  const [unitBedroomList, setUnitBedroomList] = useState([]);
  const [unitFilterLoading, setUnitFilterLoading] = useState(false);
  const [isFloorPlan, setIsFloorPlan] = useState(false);
  const [isFloorPlanFilter, setIsFloorPlanFilter] = useState(false);
  const [floorPlanFilter, setFloorPlanFilter] = useState([]);
  const [floorPlanSettings, setFloorPlanSettings] = useState({ unit_type: "", unit_bedroom: "" });
  const [floorPlanFilterLoading, setFloorPlanFilterLoading] = useState(false);
  const [unitFilterCount, setUnitFilterCount] = useState(0);
  const [floorPlanFilterCount, setFloorPlanFilterCount] = useState(0);
  const [isContact, setIsContact] = useState(false);
  const [mainSwiperIndex, setMainSwiperIndex] = useState(0);
  const [modalSwiperIndex, setModalSwiperIndex] = useState(0);
  const [mainSwiper, setMainSwiper] = useState(null);
  const [mainSwiper2, setMainSwiper2] = useState(null);
  const [modalSwiper, setModalSwiper] = useState(null);
  const [modalSwiper2, setModalSwiper2] = useState(null);
  const [amenities, setAmenities] = useState({ education: [], healthcare: [], shopping: [], entertainment: [], public_transport: [] });
  const [isAmenities, setIsAmenities] = useState(0);
  const [virtualTour, setVirtualTour] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [facilities, setFacilities] = useState([]);
  const videoRefs = useRef([]);
  const [isVideo, setIsVideo] = useState(false);
  const { project_id, phase_id, key} = useParams();
  const [unitStatusList] = useState([
    { label: "All Status", value: "" },
    { label: "Available", value: "available" },
    { label: "Reserved", value: "reserve" },
    { label: "Booked", value: "booked" },
    { label: "Sold", value: "sold" },
    { label: "Unavailable", value: "unavailable" },
  ]);

  // GET FUNCTION ---------------------------------

  const getViewPhase = async () => {
    try {
      const response = await axios.get("ws/ws_rea_sales_kit.php", {
        params: {
          task: "SalesKit_phase-details",
          phase_id: phase_id,
          key: key,
        }, 
      });

      const data = response.data;

      if (data.status === 0) {
        
        if (data.record.type_count > 0) {
          data.record.type_record.forEach((record) => {
            var unitRecord = data.record.unit_count > 0 ? data.record.unit_record.filter((unit) => unit.rea_type_id === record.rea_type_id) : [];
            var unitRecordAvailable = data.record.unit_count > 0 ? data.record.unit_record.filter((unit) => unit.rea_type_id === record.rea_type_id && unit.unit_status === "available") : [];
            record.unit = unitRecord.length > 0 ? unitRecord : [];
            record.unit_count = unitRecord.length > 0 ? unitRecord.length : 0;
            record.unit_available = unitRecordAvailable.length > 0 ? unitRecordAvailable : [];
            record.unit_available_count = unitRecordAvailable.length > 0 ? unitRecordAvailable.length : 0;

            if (unitRecord.length > 0) {
              var lowest_unit_price = unitRecord.reduce((minPrice, unit) => Math.min(minPrice, unit.unit_selling_price), unitRecord[0].unit_selling_price);
              var highest_unit_price = unitRecord.reduce((maxPrice, unit) => Math.max(maxPrice, unit.unit_selling_price), unitRecord[0].unit_selling_price);
              record.price = lowest_unit_price === highest_unit_price ? setCurrency(lowest_unit_price) : setCurrency(lowest_unit_price) + " - " + setCurrency(highest_unit_price);
            } else {
              record.price = setCurrency(0);
            }
          });

          var unitTypeOpts = data.record.type_record.map((record) => ({ label: `${record.unit_title} ${record.unit_type}`, value: record.rea_type_id }));
          unitTypeOpts.unshift({ label: "All Unit Type", value: "" });
          setUnitTypeList(unitTypeOpts);
        }

        if (data.record.unit_count > 0) {
          var maxUnitBedroom = data.record.unit_record.reduce((bedroom, unit) => Math.max(bedroom, Number(unit.unit_total_bedroom)), data.record.unit_record[0].unit_total_bedroom);
          var bedroomOpts = setBedroomArray(maxUnitBedroom);
          bedroomOpts.unshift({ label: "All Bedroom", value: "" });
          setUnitBedroomList(bedroomOpts);
        }

        if (data.files.length > 0) {
          const groupedFiles = data.files.reduce((acc, file) => {
            const groupName = file.file_group_name || "Others";
            const group = acc.find((g) => g.group === groupName);

            if (group) {
              group.files.push(file);
            } else {
              acc.push({
                group: groupName,
                files: [file],
              });
            }

            return acc;
          }, []);

          setFiles(groupedFiles);
        }

        if (data.amenities) {
          setAmenities({
            education: data.amenities.education && data.amenities.education.length > 0 ? data.amenities.education : [],
            healthcare: data.amenities.healthcare && data.amenities.healthcare.length > 0 ? data.amenities.healthcare : [],
            shopping: data.amenities.shopping && data.amenities.shopping.length > 0 ? data.amenities.shopping : [],
            entertainment: data.amenities.entertainment && data.amenities.entertainment.length > 0 ? data.amenities.entertainment : [],
            public_transport: data.amenities.public_transport && data.amenities.public_transport.length > 0 ? data.amenities.public_transport : [],
          });
        }

        setPhaseData(data.record);
        setTypeList(data.record.type_count > 0 ? data.record.type_record : []);
        setUnitList(data.record.unit_count > 0 ? data.record.unit_record : []);
        setLocation({ latitude: Number(data.record.phase_lat), longitude: Number(data.record.phase_lon) });
        setModules(data.modules);

        setFacilities(data.facilities);
        setVirtualTour(data.virtual_tour);
        setContacts(data.contacts);
        setBedroomList(data.bedroom);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getDirectionCallback = useCallback((res) => {
    if (res !== null) {
      setTravelResponse(res);
      setDestinationResponse(false);
    }
  }, []);


  // ONCHANGE FUNCTION ----------------------------

  const onchangeDestination = (record) => {
    setDestination({ latitude: record.amenities_lat, longitude: record.amenities_lon });
    setDestinationResponse(true);
  };

  const onchangeUnitFilter = (values) => {
    setUnitSettings(values);
    setUnitFilterLoading(true);

    let unitRecord = unitList;

    if (values.unit !== "") {
      unitRecord = unitRecord.filter((record) => record.unit_number.toLowerCase().includes(values.unit.toLowerCase()));
    }

    if (values.unit_status !== "") {
      unitRecord = unitRecord.filter((record) => record.unit_status === values.unit_status);
    }

    if (values.unit_type !== "") {
      unitRecord = unitRecord.filter((record) => record.rea_type_id === values.unit_type);
    }

    if (values.unit_bedroom !== "") {
      unitRecord = unitRecord.filter((record) => Number(record.unit_total_bedroom) === Number(values.unit_bedroom));
    }

    setUnitFilterCount(unitRecord.length);
    const unitRecordGrouped = unitRecord.reduce((acc, obj) => {
      const unitType = typeList.find((record) => Number(record.rea_type_id) === Number(obj.rea_type_id));
      const sectionTitle = phaseData.unit_summary_type === "bedroom" ? `Bedroom ${obj.unit_total_bedroom}` : `${unitType.unit_title} ${unitType.unit_type}`;
      const existingSection = acc.find((section) => section.section === sectionTitle);

      if (existingSection) {
        existingSection.unit.push(obj);
      } else {
        acc.push({
          section: sectionTitle,
          unit: [obj],
        });
      }

      return acc;
    }, []);

    setUnitFilter(unitRecordGrouped);
    setIsUnitFilter(false);

    setTimeout(() => {
      setUnitFilterLoading(false);
    }, 1);
  };

  const onchangeFloorPlanFilter = (values) => {
    setFloorPlanSettings(values);
    setFloorPlanFilterLoading(true);

    let typRecord = typeList;

    if (values.unit_type !== "") {
      typRecord = typRecord.filter((record) => record.rea_type_id === values.unit_type);
    }

    if (values.unit_bedroom !== "") {
      typRecord = typRecord.filter((record) => Number(record.total_bedroom) === Number(values.unit_bedroom));
    }

    setFloorPlanFilterCount(typRecord.length);
    const unitRecordGrouped = typRecord.reduce((acc, obj) => {
      const sectionTitle = `Bedroom ${obj.total_bedroom}`;
      const existingSection = acc.find((section) => section.section === sectionTitle);

      if (existingSection) {
        existingSection.unit_type.push(obj);
      } else {
        acc.push({
          section: sectionTitle,
          unit_type: [obj],
        });
      }

      return acc;
    }, []);

    setFloorPlanFilter(unitRecordGrouped);
    setIsFloorPlanFilter(false);

    setTimeout(() => {
      setFloorPlanFilterLoading(false);
    }, 1);
  };

  // OPEN FUNCTION --------------------------------

  const openSalesChart = () => {
    navigate(`/rea-sales-kit/${key}/${project_id}/${phase_id}/saleschart`);
  };

  const openUnit = async (unit) => {
    const navigateToUnitDetails = () => {
      // navigate(`/sales-chart/unit-details/${deal_id}/${prospect_id}/${unit.rea_unit_id}`);
    };

    const showWarning = (message) => {
      iziToast.warning({
        title: "Caution",
        message,
        timeout: 2000,
      });
    };

    if (unit.unit_status === "available") {
      navigateToUnitDetails();
    } else {
      navigateToUnitDetails();
    }
  };

  const openVirtualTour = (record) => {
    window.open(record, "_blank");
  };

  const openFiles = (record) => {
    if (record.mode === "new_tab") {
      window.open(record.file_url, "_blank");
    } else {
      const document = record.file_name.split(".");
      const documenttype = document[document.length - 1];

      const data = {
        title: record.file_title,
        url: `${hostUrl}/${hostUrlType}/assets/rea_booking/pdfjs/web/viewer.html?${company_id}/files/${record.file_name}`,
        filepath: record.file_url.replace(`${hostUrl}/${hostUrlType}`, "../"),
        mode: documenttype === "pdf" ? "pdf" : "image",
      };
      setDocumentData(data);
      setIsDocument(true);
    }
  };

  const openAmenities = (event) => {
    if (event) {
      setDestinationResponse(false);
      setTravelResponse(null);
      setDestination(null);
      setIsAmenities(event);
    }
  };

  const openUnitQuickLink = () => {
    if (phaseData.unit_summary_type === "bedroom") {
      var unitRecord = unitList.reduce((acc, obj) => {
        const sectionTitle = `Bedroom ${obj.unit_total_bedroom}`;
        const existingSection = acc.find((section) => section.section === sectionTitle);

        if (existingSection) {
          existingSection.unit.push(obj);
        } else {
          acc.push({
            section: sectionTitle,
            unit: [obj],
          });
        }

        return acc;
      }, []);

      setUnitFilter(unitRecord);
    } else {
      var unitRecord2 = unitList.reduce((acc, obj) => {
        const unitType = typeList.find((record) => Number(record.rea_type_id) === Number(obj.rea_type_id));
        const sectionTitle = `${unitType.unit_title} ${unitType.unit_type}`;
        const existingSection = acc.find((section) => section.section === sectionTitle);

        if (existingSection) {
          existingSection.unit.push(obj);
        } else {
          acc.push({
            section: sectionTitle,
            unit: [obj],
          });
        }

        return acc;
      }, []);
      setUnitFilter(unitRecord2);
    }

    setUnitFilterCount(unitList.length);
    setUnitSettings({ unit: "", unit_status: "", unit_type: "", unit_bedroom: "" });
    setIsUnit(true);
  };

  const openUnitUnitType = (data) => {
    let unitRecord = unitList;
    unitRecord = unitRecord.filter((record) => record.unit_status === "available");
    unitRecord = unitRecord.filter((record) => record.rea_type_id === data.rea_type_id);

    var unitRecord2 = unitRecord.reduce((acc, obj) => {
      const unitType = typeList.find((record) => Number(record.rea_type_id) === Number(obj.rea_type_id));
      const sectionTitle = `${unitType.unit_title} ${unitType.unit_type}`;
      const existingSection = acc.find((section) => section.section === sectionTitle);

      if (existingSection) {
        existingSection.unit.push(obj);
      } else {
        acc.push({
          section: sectionTitle,
          unit: [obj],
        });
      }

      return acc;
    }, []);

    setUnitFilterCount(unitRecord.length);
    setUnitFilter(unitRecord2);
    setUnitSettings({ unit: "", unit_status: "available", unit_type: data.rea_type_id, unit_bedroom: "" });
    setIsUnit(true);
  };

  const openUnitBedroom = (data) => {
    let unitRecord = unitList;
    unitRecord = unitRecord.filter((record) => record.unit_status === "available");
    unitRecord = unitRecord.filter((record) => Number(record.unit_total_bedroom) === Number(data.bedroom));

    var unitRecord2 = unitRecord.reduce((acc, obj) => {
      const sectionTitle = `Bedroom ${obj.unit_total_bedroom}`;
      const existingSection = acc.find((section) => section.section === sectionTitle);

      if (existingSection) {
        existingSection.unit.push(obj);
      } else {
        acc.push({
          section: sectionTitle,
          unit: [obj],
        });
      }

      return acc;
    }, []);

    setUnitFilterCount(unitRecord.length);
    setUnitFilter(unitRecord2);
    setUnitSettings({ unit: "", unit_status: "available", unit_type: "", unit_bedroom: Number(data.bedroom) });
    setIsUnit(true);
  };

  const openUnitFloorPlan = (data) => {
    let unitRecord = unitList;
    if (phaseData.unit_summary_type === "bedroom") {
      unitRecord = unitRecord.filter((record) => record.unit_status === "available");
      unitRecord = unitRecord.filter((record) => record.rea_type_id === data.rea_type_id);
      var unitRecord2 = unitRecord.reduce((acc, obj) => {
        const sectionTitle = `Bedroom ${obj.unit_total_bedroom}`;
        const existingSection = acc.find((section) => section.section === sectionTitle);

        if (existingSection) {
          existingSection.unit.push(obj);
        } else {
          acc.push({
            section: sectionTitle,
            unit: [obj],
          });
        }

        return acc;
      }, []);

      setUnitFilterCount(unitRecord.length);
      setUnitFilter(unitRecord2);
    } else {
      unitRecord = unitRecord.filter((record) => record.unit_status === "available");
      unitRecord = unitRecord.filter((record) => record.rea_type_id === data.rea_type_id);

      var unitRecord3 = unitRecord.reduce((acc, obj) => {
        const unitType = typeList.find((record) => Number(record.rea_type_id) === Number(obj.rea_type_id));
        const sectionTitle = `${unitType.unit_title} ${unitType.unit_type}`;
        const existingSection = acc.find((section) => section.section === sectionTitle);

        if (existingSection) {
          existingSection.unit.push(obj);
        } else {
          acc.push({
            section: sectionTitle,
            unit: [obj],
          });
        }

        return acc;
      }, []);
      setUnitFilterCount(unitRecord.length);
      setUnitFilter(unitRecord3);
    }

    setUnitSettings({ unit: "", unit_status: "available", unit_type: data.rea_type_id, unit_bedroom: "" });
    setIsUnit(true);
  };

  const openFloorPlanQuickLink = () => {
    var unitRecord = typeList.reduce((acc, obj) => {
      const sectionTitle = `Bedroom ${obj.total_bedroom}`;
      const existingSection = acc.find((section) => section.section === sectionTitle);

      if (existingSection) {
        existingSection.unit_type.push(obj);
      } else {
        acc.push({
          section: sectionTitle,
          unit_type: [obj],
        });
      }

      return acc;
    }, []);

    setFloorPlanFilterCount(typeList.length);
    setFloorPlanFilter(unitRecord);
    setFloorPlanSettings({ unit_type: "", unit_bedroom: "" });
    setIsFloorPlan(true);
  };

  const openFloorPlanBedroom = (data) => {
    let typRecord = typeList;
    typRecord = typRecord.filter((record) => Number(record.total_bedroom) === Number(data.bedroom));

    var unitRecord = typRecord.reduce((acc, obj) => {
      const sectionTitle = `Bedroom ${obj.total_bedroom}`;
      const existingSection = acc.find((section) => section.section === sectionTitle);

      if (existingSection) {
        existingSection.unit_type.push(obj);
      } else {
        acc.push({
          section: sectionTitle,
          unit_type: [obj],
        });
      }

      return acc;
    }, []);

    setFloorPlanFilterCount(typRecord.length);
    setFloorPlanFilter(unitRecord);
    setFloorPlanSettings({ unit_type: "", unit_bedroom: Number(data.bedroom) });
    setIsFloorPlan(true);
  };

  // SEND FUNCTION --------------------------------

  const sendShareWhatsapp = (record) => {
    const text = `Please find the file of project ${phaseData.phase_name} listed below for your reference ${record.file_title}: \n${record.file_url}`;
    const whatsapp = `whatsapp://send?text=${encodeURIComponent(text)}`;
    window.open(whatsapp, "_blank");
  };

  const sendDownload = async (filename, filepath) => {
    try {
      const response = await axios.get(`${hostUrl}/${hostUrlType}/php/api_get_file_content.php`, {
        params: {
          filepath: filepath,
        },
      });

      const data = await response.data;
      const base64 = `data:${data.mime_type};base64,${data.content}`;
      triggerBase64Download(base64, filename);
    } catch (error) {
      console.error(error);
    }
  };

  // SET FUNCTION ---------------------------------

  const setPhaseImage = (image) => {
    if (image !== "generic.png") {
      return `${hostUrl}/${hostUrlType}/assets/rea_booking/${company_id}/project/${image}`;
    } else {
      return `${hostUrl}/${hostUrlType}/assets/rea_booking/generic.png`;
    }
  };

  const setArea = (input) => {
    if (input) {
      return (
        input
          .toLowerCase()
          .replace(/[^0-9.]/g, "")
          .trim() + " sqft"
      );
    }

    return "0 sqft";
  };

  const setCalculateDistance = (currentLat, currentLon, amenityLat, amenityLon) => {
    const R = 6371;
    const dLat = (amenityLat - currentLat) * (Math.PI / 180);
    const dLon = (amenityLon - currentLon) * (Math.PI / 180);
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(currentLat * (Math.PI / 180)) * Math.cos(amenityLat * (Math.PI / 180)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c * 1000;
    return distance;
  };

  const setFormatDistance = (distance) => {
    if (distance < 1000) {
      return `${distance.toFixed()}m`;
    } else {
      return `${(distance / 1000).toFixed(2)}km`;
    }
  };

  const setBedroomArray = (num) => {
    let array = [];
    for (let i = 1; i <= num; i++) {
      array.push({ label: `Bedroom ${i}`, value: i });
    }
    return array;
  };

  const setFilterCount = (values) => {
    let count = 0;

    if (values.unit) {
      count = count + 1;
    }

    if (values.unit_status) {
      count = count + 1;
    }

    if (values.unit_type) {
      count = count + 1;
    }

    if (values.unit_bedroom) {
      count = count + 1;
    }

    return count;
  };

  const setSwiperLabel = (index, images, videos) => {
    const cleanLabel = (filename) => {
      let decodedFilename = decodeURIComponent(filename);
      decodedFilename = decodedFilename.replace(/\.[^/.]+$/, "");
      decodedFilename = decodedFilename.replace(/_/g, " ");
      return decodedFilename;
    };

    if (index < images.length) {
      return cleanLabel(images[index]);
    } else if (index - images.length < videos.length) {
      return cleanLabel(videos[index - images.length]);
    }

    return "Unnamed";
  };

  const setStatusColor = (status) => {
    if (status === "available") {
      return "success";
    } else if (status === "booked") {
      return "danger";
    } else if (status === "reserved") {
      return "danger";
    } else if (status === "sold") {
      return "danger";
    } else if (status === "unavailable") {
      return "danger";
    } else if (status === "on_hold") {
      return "danger";
    } else {
      return "";
    }
  };

  const setSlideTo = (key) => {
    if (key === "image") {
      mainSwiper.slideTo(0, 0);
      mainSwiper2.slideTo(0, 0);
    } else {
      mainSwiper.slideTo(phaseData.phase_image.length, 0);
      mainSwiper2.slideTo(phaseData.phase_image.length, 0);
    }
  };

  const setSlideToModal = (key) => {
    if (key === "image") {
      modalSwiper.slideTo(0, 0);
      modalSwiper2.slideTo(0, 0);
    } else {
      modalSwiper.slideTo(phaseData.phase_image.length, 0);
      modalSwiper2.slideTo(phaseData.phase_image.length, 0);
    }
  };

  // RENDER FUNCTION ------------------------------

  const RenderShareToggle = forwardRef(({ children, onClick }, ref) => (
    <Button
      variant="link"
      className="text-dark text-decoration-none"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </Button>
  ));

  const RenderShareToggle2 = forwardRef(({ children, onClick }, ref) => (
    <Button
      variant="link"
      className="m-0 text-light text-decoration-none"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </Button>
  ));

  const RenderUnitType = ({ record, index, label }) => {
    return (
      <Col xxl={4} key={index} className="mb-3">
        <Card className="border-0 shadow-sm h-100">
          <Card.Body>
            <h6 className="op-text-medium fw-bold">
              {record.unit_title} {record.unit_type}
            </h6>
            <Stack direction="vertical" gap={2}>
              {label.is_unit_available && (
                <div className="d-flex justify-content-between">
                  <div className="d-flex align-items-center text-muted">
                    <div className="text-center" style={{ width: 30 }}>
                      <FontAwesomeIcon icon={faHouseLock} size="lg" className="me-2" />
                    </div>
                    Unit Available
                  </div>
                  <Button variant="link" className="text-dark text-decoration-none p-0 m-0 cursor-pointer" onClick={() => openUnitUnitType(record)}>
                    <span>
                      {record.unit_available_count}/{record.unit_count}
                    </span>
                    <FontAwesomeIcon icon={faChevronRight} className="ms-1" size="sm" />
                  </Button>
                </div>
              )}

              {label.is_bedroom && (
                <div className="d-flex justify-content-between">
                  <div className="d-flex align-items-center text-muted">
                    <div className="text-center" style={{ width: 30 }}>
                      <FontAwesomeIcon icon={faBedFront} size="lg" className="me-2" />
                    </div>
                    Bedroom
                  </div>
                  <span>{record.total_bedroom}</span>
                </div>
              )}

              {label.is_bathroom && (
                <div className="d-flex justify-content-between">
                  <div className="d-flex align-items-center text-muted">
                    <div className="text-center" style={{ width: 30 }}>
                      <FontAwesomeIcon icon={faBath} size="lg" className="me-2" />
                    </div>
                    Bathroom
                  </div>
                  <span>{record.total_bathroom}</span>
                </div>
              )}

              {label.is_builtup_area && (
                <div className="d-flex justify-content-between">
                  <div className="d-flex align-items-center text-muted">
                    <div className="text-center" style={{ width: 30 }}>
                      <svg xmlns="http://www.w3.org/2000/svg" height="22" width="22" viewBox="0 0 410 512" className="me-2">
                        <path
                          fill="rgba(33, 37, 41, 0.75)"
                          d="M395.1 229.4l-43.4-33.6v-38.5c0-2.5-2-4.9-4.9-4.9h-13.5c-2.5 0-4.9 2-4.9 4.9v20.5L257 122.9c-4.1-3.3-10.2-3.3-14.3 0L105.1 229.4c-2.5 2-4.1 4.5-4.5 7.8s.4 6.1 2.5 8.6s4.5 4.1 7.8 4.5s6.1-.4 8.6-2.5l11.5-9V369c0 6.1 4.9 11.1 11.1 11.1h216.3c6.1 0 11.1-4.9 11.1-11.1V238.8l11.5 9c2.5 1.6 5.3 2.5 8.6 2s6.1-2 7.8-4.5c2-2.5 2.9-5.3 2.5-8.6c-.4-2.9-2-5.3-4.5-7.4zM278.3 358H221.8V268.3h56.5V358zm68.8 0H300.4V257.2c0-6.1-4.9-11.1-11.1-11.1H210.7c-6.1 0-11.1 4.9-11.1 11.1V358H153V221.6l.4-.4L250.1 147l.8 .4 96.3 74.1V358zM140.3 116.3c2 2 4.9 3.3 7.8 3.3s5.7-1.2 7.8-3.3c4.5-4.5 4.5-11.5 0-15.6l-15.2-15.2h217.9l-14.3 15.2c-4.5 4.5-4.5 11.5 0 15.6c2 2 4.9 3.3 7.8 3.3s5.7-1.2 7.8-3.3l34-34c4.5-4.5 4.5-11.5 0-15.6l-34-34c-4.5-4.5-11.5-4.5-15.6 0c-4.5 4.5-4.5 11.5 0 15.6l15.2 15.2H141.1l15.2-15.2c4.5-4.5 4.5-11.5 0-15.6c-4.5-4.5-11.5-4.5-15.6 0l-34 34c-4.5 4.5-4.5 11.5 0 15.6l33.6 34zM81.7 319.9l-15.2 15.2V165.9L81.7 181c2 2 4.9 3.3 7.8 3.3s5.7-1.2 7.8-3.3c4.5-4.5 4.5-11.5 0-15.6l-34-34c-4.5-4.5-11.5-4.5-15.6 0l-34 34c-4.5 4.5-4.5 11.5 0 15.6c4.5 4.5 11.5 4.5 15.6 0l15.2-15.2v168.8l-15.2-15.2c-4.5-4.5-11.5-4.5-15.6 0c-4.5 4.5-4.5 11.5 0 15.6l34 34c2 2 4.9 3.3 7.8 3.3s5.7-1.2 7.8-3.3l34-34c4.5-4.5 4.5-11.5 0-15.6s-11.5-4.1-15.6 .4z"
                        />
                      </svg>
                    </div>
                    Builtup Area
                  </div>
                  <span>{setArea(record.builtup_area)}</span>
                </div>
              )}

              {label.is_land_area && (
                <div className="d-flex justify-content-between">
                  <div className="d-flex align-items-center text-muted">
                    <div className="text-center" style={{ width: 30 }}>
                      <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 410 512" className="me-2">
                        <path
                          fill="rgba(33, 37, 41, 0.75)"
                          d="M395.9 340.4l-37.7-37.7c-4.9-4.9-12.7-4.9-17.6 0s-4.9 12.7 0 17.6l16.4 16.4H73.1V53.2l16.4 16.4c2.5 2.5 5.7 3.7 9 3.7s6.6-1.2 9-3.7c4.9-4.9 4.9-12.7 0-17.6L69.8 14.3c-4.9-4.9-12.7-4.9-17.6 0L14.5 52c-4.9 4.9-4.9 12.7 0 17.6s12.7 4.9 17.6 0l16.4-16.4v296.1c0 7 5.7 12.7 12.7 12.7H356.6l-16.4 16.4c-4.9 4.9-4.9 12.7 0 17.6c2.5 2.5 5.7 3.7 9 3.7s6.6-1.2 9-3.7l37.7-37.7c4.9-5.3 4.9-13.1 0-18zM152.2 50h12.7c7 0 12.7-5.7 12.7-12.7s-5.7-12.7-12.7-12.7h-12.7c-7 0-12.7 5.7-12.7 12.7S145.2 50 152.2 50zm136 0h20.5c7 0 12.7-5.7 12.7-12.7s-5.7-12.7-12.7-12.7h-20.5c-7 0-12.7 5.7-12.7 12.7S281.2 50 288.1 50zm-72.1 0h20.5c7 0 12.7-5.7 12.7-12.7s-5.7-12.7-12.7-12.7h-20.5c-7 0-12.7 5.7-12.7 12.7S209.1 50 216.1 50zm143.8 0c0 7 5.7 12.7 12.7 12.7s12.7-5.7 12.7-12.7V37.3c0-7-5.7-12.7-12.7-12.7h-12.7c-7 0-12.7 5.7-12.7 12.7S353.3 50 359.8 50zm25.4 123.3c0-7-5.7-12.7-12.7-12.7s-12.7 5.7-12.7 12.7v20.5c0 7 5.7 12.7 12.7 12.7s12.7-5.7 12.7-12.7V173.3zm0-72.1c0-7-5.7-12.7-12.7-12.7s-12.7 5.7-12.7 12.7v20.5c0 7 5.7 12.7 12.7 12.7s12.7-5.7 12.7-12.7V101.2zM372.5 232.7c-7 0-12.7 5.7-12.7 12.7V258c0 7 5.7 12.7 12.7 12.7s12.7-5.7 12.7-12.7v-12.7c0-7.4-5.7-12.7-12.7-12.7z"
                        />
                      </svg>
                    </div>
                    Land Area
                  </div>
                  <span> {setArea(record.land_area)} </span>
                </div>
              )}

              {label.is_price && (
                <div className="d-flex justify-content-between">
                  <div className="d-flex align-items-center text-muted">
                    <div className="text-center" style={{ width: 30 }}>
                      <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" className="me-2" viewBox="0 0 640 512">
                        <path
                          fill="rgba(33, 37, 41, 0.75)"
                          d="M4 266.6C-1.9 260-1.2 249.8 5.4 244L277.4 4c6.1-5.3 15.2-5.3 21.2 0L512.5 192.8c-5.4-.5-11-.8-16.5-.8c-10.2 0-20.1 .9-29.8 2.5L288 37.3 96 206.7 96 432c0 26.5 21.5 48 48 48l64 0 0-160c0-17.7 14.3-32 32-32l96 0c1.1 0 2.1 .1 3.1 .1c-5.1 10.1-9.4 20.7-12.5 31.9L240 320l0 160 96 0 0-38.6c13.1 28.4 33.5 52.8 58.8 70.6L144 512c-44.2 0-80-35.8-80-80l0-197L26.6 268C20 273.9 9.9 273.2 4 266.6zM352 368c0-79.5 64.5-144 144-144s144 64.5 144 144s-64.5 144-144 144s-144-64.5-144-144zm32 0c0 61.9 50.1 112 112 112s112-50.1 112-112s-50.1-112-112-112s-112 50.1-112 112zm56.1-27c-1.2-18.8 10-30.9 21.8-37.2c5.7-3.1 12-5.1 18-6.3l0-9.5c0-8.8 7.2-16 16-16s16 7.2 16 16l0 9.4c6.3 .9 12.3 2.3 17.9 3.7c8.6 2.1 13.8 10.8 11.7 19.4s-10.8 13.8-19.4 11.7c-9.3-2.3-18.2-4-26.4-4.1c-6.2-.1-13.6 1.3-18.7 4c-2.4 1.3-3.6 2.5-4.2 3.4c-.4 .7-.9 1.6-.8 3.6c0 .5 0 1.9 4.8 4.5c5.6 3 13.5 5.2 23.9 8.2l.2 0c9.3 2.6 21.1 6 30.5 11.5c10.2 6 20 16.1 20.5 32.3c.5 18.2-9.7 30.4-21.7 36.9c-5.8 3.1-12.1 5.1-18.3 6.2l0 10.8c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-11.4c-8.6-1.7-16.7-4.3-23.7-6.6c-1.7-.6-3.4-1.1-5-1.6c-8.4-2.6-13.1-11.6-10.5-20s11.6-13.1 20-10.5c2 .6 3.9 1.2 5.8 1.8c11.5 3.6 20.4 6.5 29.9 6.8c6.7 .2 13.8-1.1 18.5-3.6c2.2-1.2 3.4-2.4 4-3.3c.5-.8 1.1-2.1 1-4.5c0-1.4-.2-3-4.7-5.6c-5.3-3.1-12.9-5.4-23.2-8.3c-.6-.2-1.2-.3-1.8-.5c-8.9-2.5-19.8-5.6-28.6-10.3c-9.9-5.3-20.6-14.7-21.6-30.7z"
                        />
                      </svg>
                    </div>
                    Price
                  </div>
                  <span>{record.price}</span>
                </div>
              )}

              {label.is_reservation_fee && (
                <div className="d-flex justify-content-between">
                  <div className="d-flex align-items-center text-muted">
                    <div className="text-center" style={{ width: 30 }}>
                      <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" className="me-2" viewBox="0 0 640 512">
                        <path
                          fill="rgba(33, 37, 41, 0.75)"
                          d="M0 80C0 53.5 21.5 32 48 32.1l149.4 0c17 0 33.3 6.7 45.3 18.7L407.6 215.7c-9.4 5.5-18.2 11.8-26.4 18.8L220.1 73.4c-6-6-14.1-9.4-22.6-9.4L48 64c-8.8 0-16 7.2-16 16l0 149.5c-.1 8.5 3.3 16.6 9.3 22.6c-7.5 7.5-15.1 15.1-22.6 22.6C6.7 262.8 0 246.5 0 229.5L0 80zM18.7 274.8c7.5-7.6 15.1-15.1 22.6-22.6l176 176c12.5 12.5 32.8 12.5 45.3 0L320 370.7c.2 13.8 2 27.2 5.2 40l-40 40c-25 25-65.5 25-90.5 0c-58.7-58.7-117.3-117.3-176-176zM88 144c0-13.3 10.7-24 24-24s24 10.7 24 24s-10.7 24-24 24s-24-10.7-24-24zM352 368c0-79.5 64.5-144 144-144s144 64.5 144 144s-64.5 144-144 144s-144-64.5-144-144zm32 0c0 61.9 50.1 112 112 112s112-50.1 112-112s-50.1-112-112-112s-112 50.1-112 112zm56.1-27c-1.2-18.8 10-30.9 21.8-37.2c5.7-3.1 12-5.1 18-6.3l0-9.5c0-8.8 7.2-16 16-16s16 7.2 16 16l0 9.4c6.3 .9 12.3 2.3 17.9 3.7c8.6 2.1 13.8 10.8 11.7 19.4s-10.8 13.8-19.4 11.7c-9.3-2.3-18.2-4-26.4-4.1c-6.2-.1-13.6 1.3-18.7 4c-2.4 1.3-3.6 2.5-4.2 3.4c-.4 .7-.9 1.6-.8 3.6c0 .5 0 1.9 4.8 4.5c5.6 3 13.5 5.2 23.9 8.2l.2 0c9.3 2.6 21.1 6 30.5 11.5c10.2 6 20 16.1 20.5 32.3c.5 18.2-9.7 30.4-21.7 36.9c-5.8 3.1-12.1 5.1-18.3 6.2l0 10.8c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-11.4c-8.6-1.7-16.7-4.3-23.7-6.6c-1.7-.6-3.4-1.1-5-1.6c-8.4-2.6-13.1-11.6-10.5-20s11.6-13.1 20-10.5c2 .6 3.9 1.2 5.8 1.8c11.5 3.6 20.4 6.5 29.9 6.8c6.7 .2 13.8-1.1 18.5-3.6c2.2-1.2 3.4-2.4 4-3.3c.5-.8 1.1-2.1 1-4.5c0-1.4-.2-3-4.7-5.6c-5.3-3.1-12.9-5.4-23.2-8.3c-.6-.2-1.2-.3-1.8-.5c-8.9-2.5-19.8-5.6-28.6-10.3c-9.9-5.3-20.6-14.7-21.6-30.7z"
                        />
                      </svg>
                    </div>
                    Reservation Fee
                  </div>
                  <span>{setCurrency(record.reservation_fee)}</span>
                </div>
              )}
            </Stack>
          </Card.Body>
        </Card>
      </Col>
    );
  };

  const RenderBedroom = ({ record, index, label }) => {
    return (
      <Col xxl={4} key={index} className="mb-3">
        <Card className="border-0 shadow-sm h-100">
          <Card.Body>
            <h6 className="op-text-medium fw-bold">{record.bedroom} BEDROOM</h6>
            <Stack direction="vertical" gap={2}>
              {label.is_unit_available && (
                <div className="d-flex justify-content-between">
                  <div className="d-flex align-items-center text-muted">
                    <div className="text-center" style={{ width: 30 }}>
                      <FontAwesomeIcon icon={faHouseLock} size="lg" className="me-2" />
                    </div>
                    Unit Available
                  </div>
                  <Button variant="link" className="text-dark text-decoration-none p-0 m-0 cursor-pointer" onClick={() => openUnitBedroom(record)}>
                    <span>
                      {record.total_unit}/{record.total_unit + record.total_unit_sold}
                    </span>
                    <FontAwesomeIcon icon={faChevronRight} className="ms-1" size="sm" />
                  </Button>
                </div>
              )}

              {label.is_builtup_area && (
                <div className="d-flex justify-content-between">
                  <div className="d-flex align-items-center text-muted">
                    <div className="text-center" style={{ width: 30 }}>
                      <svg xmlns="http://www.w3.org/2000/svg" height="22" width="22" viewBox="0 0 410 512" className="me-2">
                        <path
                          fill="rgba(33, 37, 41, 0.75)"
                          d="M395.1 229.4l-43.4-33.6v-38.5c0-2.5-2-4.9-4.9-4.9h-13.5c-2.5 0-4.9 2-4.9 4.9v20.5L257 122.9c-4.1-3.3-10.2-3.3-14.3 0L105.1 229.4c-2.5 2-4.1 4.5-4.5 7.8s.4 6.1 2.5 8.6s4.5 4.1 7.8 4.5s6.1-.4 8.6-2.5l11.5-9V369c0 6.1 4.9 11.1 11.1 11.1h216.3c6.1 0 11.1-4.9 11.1-11.1V238.8l11.5 9c2.5 1.6 5.3 2.5 8.6 2s6.1-2 7.8-4.5c2-2.5 2.9-5.3 2.5-8.6c-.4-2.9-2-5.3-4.5-7.4zM278.3 358H221.8V268.3h56.5V358zm68.8 0H300.4V257.2c0-6.1-4.9-11.1-11.1-11.1H210.7c-6.1 0-11.1 4.9-11.1 11.1V358H153V221.6l.4-.4L250.1 147l.8 .4 96.3 74.1V358zM140.3 116.3c2 2 4.9 3.3 7.8 3.3s5.7-1.2 7.8-3.3c4.5-4.5 4.5-11.5 0-15.6l-15.2-15.2h217.9l-14.3 15.2c-4.5 4.5-4.5 11.5 0 15.6c2 2 4.9 3.3 7.8 3.3s5.7-1.2 7.8-3.3l34-34c4.5-4.5 4.5-11.5 0-15.6l-34-34c-4.5-4.5-11.5-4.5-15.6 0c-4.5 4.5-4.5 11.5 0 15.6l15.2 15.2H141.1l15.2-15.2c4.5-4.5 4.5-11.5 0-15.6c-4.5-4.5-11.5-4.5-15.6 0l-34 34c-4.5 4.5-4.5 11.5 0 15.6l33.6 34zM81.7 319.9l-15.2 15.2V165.9L81.7 181c2 2 4.9 3.3 7.8 3.3s5.7-1.2 7.8-3.3c4.5-4.5 4.5-11.5 0-15.6l-34-34c-4.5-4.5-11.5-4.5-15.6 0l-34 34c-4.5 4.5-4.5 11.5 0 15.6c4.5 4.5 11.5 4.5 15.6 0l15.2-15.2v168.8l-15.2-15.2c-4.5-4.5-11.5-4.5-15.6 0c-4.5 4.5-4.5 11.5 0 15.6l34 34c2 2 4.9 3.3 7.8 3.3s5.7-1.2 7.8-3.3l34-34c4.5-4.5 4.5-11.5 0-15.6s-11.5-4.1-15.6 .4z"
                        />
                      </svg>
                    </div>
                    Builtup Area
                  </div>
                  <span> {record.min_builtup_area === 0 ? "-" : record.min_builtup_area === record.max_builtup_area ? `${record.max_builtup_area} sqft` : `${record.min_builtup_area} sqft - ${record.max_builtup_area} sqft`}</span>
                </div>
              )}

              {label.is_land_area && (
                <div className="d-flex justify-content-between">
                  <div className="d-flex align-items-center text-muted">
                    <div className="text-center" style={{ width: 30 }}>
                      <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 410 512" className="me-2">
                        <path
                          fill="rgba(33, 37, 41, 0.75)"
                          d="M395.9 340.4l-37.7-37.7c-4.9-4.9-12.7-4.9-17.6 0s-4.9 12.7 0 17.6l16.4 16.4H73.1V53.2l16.4 16.4c2.5 2.5 5.7 3.7 9 3.7s6.6-1.2 9-3.7c4.9-4.9 4.9-12.7 0-17.6L69.8 14.3c-4.9-4.9-12.7-4.9-17.6 0L14.5 52c-4.9 4.9-4.9 12.7 0 17.6s12.7 4.9 17.6 0l16.4-16.4v296.1c0 7 5.7 12.7 12.7 12.7H356.6l-16.4 16.4c-4.9 4.9-4.9 12.7 0 17.6c2.5 2.5 5.7 3.7 9 3.7s6.6-1.2 9-3.7l37.7-37.7c4.9-5.3 4.9-13.1 0-18zM152.2 50h12.7c7 0 12.7-5.7 12.7-12.7s-5.7-12.7-12.7-12.7h-12.7c-7 0-12.7 5.7-12.7 12.7S145.2 50 152.2 50zm136 0h20.5c7 0 12.7-5.7 12.7-12.7s-5.7-12.7-12.7-12.7h-20.5c-7 0-12.7 5.7-12.7 12.7S281.2 50 288.1 50zm-72.1 0h20.5c7 0 12.7-5.7 12.7-12.7s-5.7-12.7-12.7-12.7h-20.5c-7 0-12.7 5.7-12.7 12.7S209.1 50 216.1 50zm143.8 0c0 7 5.7 12.7 12.7 12.7s12.7-5.7 12.7-12.7V37.3c0-7-5.7-12.7-12.7-12.7h-12.7c-7 0-12.7 5.7-12.7 12.7S353.3 50 359.8 50zm25.4 123.3c0-7-5.7-12.7-12.7-12.7s-12.7 5.7-12.7 12.7v20.5c0 7 5.7 12.7 12.7 12.7s12.7-5.7 12.7-12.7V173.3zm0-72.1c0-7-5.7-12.7-12.7-12.7s-12.7 5.7-12.7 12.7v20.5c0 7 5.7 12.7 12.7 12.7s12.7-5.7 12.7-12.7V101.2zM372.5 232.7c-7 0-12.7 5.7-12.7 12.7V258c0 7 5.7 12.7 12.7 12.7s12.7-5.7 12.7-12.7v-12.7c0-7.4-5.7-12.7-12.7-12.7z"
                        />
                      </svg>
                    </div>
                    Land Area
                  </div>
                  <span> {record.min_land_area === 0 ? "-" : record.min_land_area === record.max_land_area ? `${record.max_land_area} sqft` : `${record.min_land_area} sqft - ${record.max_land_area} sqft`}</span>
                </div>
              )}

              {label.is_price && (
                <div className="d-flex justify-content-between">
                  <div className="d-flex align-items-center text-muted">
                    <div className="text-center" style={{ width: 30 }}>
                      <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" className="me-2" viewBox="0 0 640 512">
                        <path
                          fill="rgba(33, 37, 41, 0.75)"
                          d="M4 266.6C-1.9 260-1.2 249.8 5.4 244L277.4 4c6.1-5.3 15.2-5.3 21.2 0L512.5 192.8c-5.4-.5-11-.8-16.5-.8c-10.2 0-20.1 .9-29.8 2.5L288 37.3 96 206.7 96 432c0 26.5 21.5 48 48 48l64 0 0-160c0-17.7 14.3-32 32-32l96 0c1.1 0 2.1 .1 3.1 .1c-5.1 10.1-9.4 20.7-12.5 31.9L240 320l0 160 96 0 0-38.6c13.1 28.4 33.5 52.8 58.8 70.6L144 512c-44.2 0-80-35.8-80-80l0-197L26.6 268C20 273.9 9.9 273.2 4 266.6zM352 368c0-79.5 64.5-144 144-144s144 64.5 144 144s-64.5 144-144 144s-144-64.5-144-144zm32 0c0 61.9 50.1 112 112 112s112-50.1 112-112s-50.1-112-112-112s-112 50.1-112 112zm56.1-27c-1.2-18.8 10-30.9 21.8-37.2c5.7-3.1 12-5.1 18-6.3l0-9.5c0-8.8 7.2-16 16-16s16 7.2 16 16l0 9.4c6.3 .9 12.3 2.3 17.9 3.7c8.6 2.1 13.8 10.8 11.7 19.4s-10.8 13.8-19.4 11.7c-9.3-2.3-18.2-4-26.4-4.1c-6.2-.1-13.6 1.3-18.7 4c-2.4 1.3-3.6 2.5-4.2 3.4c-.4 .7-.9 1.6-.8 3.6c0 .5 0 1.9 4.8 4.5c5.6 3 13.5 5.2 23.9 8.2l.2 0c9.3 2.6 21.1 6 30.5 11.5c10.2 6 20 16.1 20.5 32.3c.5 18.2-9.7 30.4-21.7 36.9c-5.8 3.1-12.1 5.1-18.3 6.2l0 10.8c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-11.4c-8.6-1.7-16.7-4.3-23.7-6.6c-1.7-.6-3.4-1.1-5-1.6c-8.4-2.6-13.1-11.6-10.5-20s11.6-13.1 20-10.5c2 .6 3.9 1.2 5.8 1.8c11.5 3.6 20.4 6.5 29.9 6.8c6.7 .2 13.8-1.1 18.5-3.6c2.2-1.2 3.4-2.4 4-3.3c.5-.8 1.1-2.1 1-4.5c0-1.4-.2-3-4.7-5.6c-5.3-3.1-12.9-5.4-23.2-8.3c-.6-.2-1.2-.3-1.8-.5c-8.9-2.5-19.8-5.6-28.6-10.3c-9.9-5.3-20.6-14.7-21.6-30.7z"
                        />
                      </svg>
                    </div>
                    Price
                  </div>
                  <span> {record.min_selling_price === record.max_selling_price ? setCurrency(record.max_selling_price) : `${setCurrency(record.min_selling_price)} - ${setCurrency(record.max_selling_price)}`}</span>
                </div>
              )}

              {label.is_floor_plan && (
                <div className="d-flex justify-content-between">
                  <div className="d-flex align-items-center text-muted">
                    <div className="text-center" style={{ width: 30 }}>
                      <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" className="me-2" viewBox="0 0 410 512">
                        <path
                          fill="rgba(33, 37, 41, 0.75)"
                          d="M379.4 25.6H33.3c-3.4 0-6.1 2.8-6.1 6.1v299.7 45.9c0 .1 0 .2 0 .3s0 .2 0 .3c0 3.4 2.8 6.1 6.1 6.1h346.1c3.4 0 6.1-2.8 6.1-6.1V230.6 100.7 31.7c0-3.4-2.8-6.1-6.1-6.1zM334.9 371.7v-2c0-3.4-2.8-6.1-6.1-6.1s-6.1 2.8-6.1 6.1v2H220.9V134.5c0-3.4-2.8-6.1-6.1-6.1s-6.1 2.8-6.1 6.1v190.8h-25.2c-3.4 0-6.1 2.8-6.1 6.1s2.8 6.1 6.1 6.1h25.2v34.1H39.5v-34.1h96.1c3.4 0 6.1-2.8 6.1-6.1s-2.8-6.1-6.1-6.1H39.5V37.9H208.6V82c0 3.4 2.8 6.1 6.1 6.1s6.1-2.8 6.1-6.1V37.9h152.3v56.7h-12.6c-3.4 0-6.1 2.8-6.1 6.1s2.8 6.1 6.1 6.1h12.6v117.6H284.2V106.8h27.9c3.4 0 6.1-2.8 6.1-6.1s-2.8-6.1-6.1-6.1h-34c-3.4 0-6.1 2.8-6.1 6.1v129.9c0 3.4 2.8 6.1 6.1 6.1h44.5v2c0 3.4 2.8 6.1 6.1 6.1s6.1-2.8 6.1-6.1v-2h38.4v135H334.9zm-6.1-37.3c-3.4 0-6.1 2.8-6.1 6.1v14.5c0 3.4 2.8 6.1 6.1 6.1s6.1-2.8 6.1-6.1v-14.5c0-3.4-2.8-6.1-6.1-6.1zm0-29.1c-3.4 0-6.1 2.8-6.1 6.1V326c0 3.4 2.8 6.1 6.1 6.1s6.1-2.8 6.1-6.1v-14.5c0-3.4-2.8-6.1-6.1-6.1zm0-29.1c-3.4 0-6.1 2.8-6.1 6.1V297c0 3.4 2.8 6.1 6.1 6.1s6.1-2.8 6.1-6.1v-14.5c0-3.4-2.8-6.1-6.1-6.1zm0-29.1c-3.4 0-6.1 2.8-6.1 6.1v14.5c0 3.4 2.8 6.1 6.1 6.1s6.1-2.8 6.1-6.1v-14.5c0-3.4-2.8-6.1-6.1-6.1z"
                        />
                      </svg>
                    </div>
                    Floor Plan
                  </div>
                  <Button variant="link" className="text-dark text-decoration-none p-0 m-0 cursor-pointer" onClick={() => openFloorPlanBedroom(record)}>
                    <span>{record.floor_plan}</span>
                    <FontAwesomeIcon icon={faChevronRight} className="ms-1" size="sm" />
                  </Button>
                </div>
              )}
            </Stack>
          </Card.Body>
        </Card>
      </Col>
    );
  };

  const RenderVirtualTour = forwardRef(({ children, onClick }, ref) => (
    <div
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </div>
  ));

  const setFormat = useCallback((format) => {
    return Number(format) === 1;
  }, []);

  // USEEFFECT FUNCTION ---------------------------

  useEffect(() => {
    const initData = async () => {
      if (!init.current) {
        try {
          await getViewPhase();
          setLoading(false);
          init.current = true;
        } catch (error) {
          setLoading(false);
        }
      }
    };
    initData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const resetAndPlayVideo = debounce((images, index) => {
    videoRefs.current.forEach((videoRef) => {
      if (videoRef && !videoRef.paused) {
        videoRef.pause();
        videoRef.currentTime = 0;
      }
    });

    if (videoRefs.current[images - index]) {
      videoRefs.current[images - index].play();
    } else if (isVideo && videoRefs.current[images - index]) {
      videoRefs.current[images - index].play();
      setIsVideo(false);
    }
  }, 1000);

  useEffect(() => {
    if (init.current) {
      resetAndPlayVideo(phaseData.phase_image.length, modalSwiperIndex);
    }
  }, [modalSwiperIndex, videoRefs, isVideo, resetAndPlayVideo]);

  return (
    <Container fluid className="m-0 p-0 internal-project">
      {loading ? (
        <Loader />
      ) : (
        <div className="page-phase">
          <Stack direction="horizontal" className="p-4 mb-1">
            <div className="op-title h5">{phaseData.phase_name}</div>
          </Stack>

          <section className="w-100 position-relative">
            <Swiper
              onSwiper={setMainSwiper2}
              spaceBetween={10}
              navigation={true}
              thumbs={{ swiper: mainSwiper }}
              modules={[FreeMode, Navigation, Thumbs]}
              className="swiperpd2"
              style={{
                "--swiper-navigation-color": "transparent",
                "--swiper-pagination-color": "transparent",
              }}
            >
              {phaseData.phase_image.length > 0 &&
                phaseData.phase_image.map((data, i) => (
                  <SwiperSlide key={i}>
                    <img
                      className="cursor-pointer"
                      src={setPhaseImage(data)}
                      alt=""
                      onClick={() => {
                        setMainSwiperIndex(i);
                        setModalSwiper(null);
                        setShow(true);
                      }}
                    />
                  </SwiperSlide>
                ))}

              {phaseData.video_url.length > 0 &&
                phaseData.video_url.map((data, i) => (
                  <SwiperSlide key={i}>
                    <div
                      className="video cursor-pointer"
                      onClick={() => {
                        setModalSwiperIndex(phaseData.phase_image.length + i);
                        setMainSwiperIndex(phaseData.phase_image.length + i);
                        setModalSwiper(null);
                        setShow(true);
                        setIsVideo(true);
                      }}
                    >
                      <FontAwesomeIcon icon={faPlayCircle} size="3x" />
                    </div>
                  </SwiperSlide>
                ))}
            </Swiper>
            <div className="w-100 px-5" style={{ position: "absolute", bottom: "-100px", zIndex: 1 }}>
              <div className="bg-light shadow px-4 py-3 rounded">
                <Nav variant="underline" defaultActiveKey="image" className="mb-4" onSelect={setSlideTo}>
                  <Nav.Item>
                    <Nav.Link eventKey="image" className="px-2" style={{ fontSize: 13 }}>
                      <FontAwesomeIcon icon={faImage} className="me-1" /> {phaseData.phase_image.length} Images
                    </Nav.Link>
                  </Nav.Item>
                  {phaseData.video_url.length > 0 && (
                    <Nav.Item>
                      <Nav.Link eventKey="video" className="px-2" style={{ fontSize: 13 }}>
                        <FontAwesomeIcon icon={faPlay} className="me-1" /> {phaseData.video_url.length} Videos
                      </Nav.Link>
                    </Nav.Item>
                  )}
                </Nav>
                <Swiper onSwiper={setMainSwiper} spaceBetween={10} slidesPerView={6} freeMode={true} watchSlidesProgress={true} modules={[FreeMode, Navigation, Thumbs]} className="swiperpd">
                  {phaseData.phase_image.map((data, i) => (
                    <SwiperSlide key={i}>
                      <img src={setPhaseImage(data)} alt="" />
                    </SwiperSlide>
                  ))}

                  {phaseData.video_url.length > 0 &&
                    phaseData.video_url.map((data, i) => (
                      <SwiperSlide key={i}>
                        <div className="video">
                          <FontAwesomeIcon icon={faPlayCircle} size="xl" />
                        </div>
                      </SwiperSlide>
                    ))}
                </Swiper>
              </div>
            </div>
          </section>

          <section className="p-5 w-100" style={{ marginTop: 120 }}>
            <Row>
              <Col xxl={12} className="mb-5">
                <Row>
                  <Col xxl={6} className="d-flex align-items-center">
                    <div>
                      <div className="op-title h5">Development Overview</div>
                      <a className="text-dark text-decoration-none cursor-pointer" onClick={setIsProjectDetails}>
                        More Phase Details <FontAwesomeIcon icon={faChevronRight} size="sm" />
                      </a>
                    </div>
                  </Col>
                  <Col xxl={6}>
                    <Row className="g-2">
                      <Col className="h-100" xxl={4}>
                        <Card className="p-3 border-0 shadow-sm d-flex justify-content-center align-items-center" style={{ height: "10rem" }}>
                          <div>
                            <div className="d-flex justify-content-center">
                              <FontAwesomeIcon icon={faHouseBuilding} size="4x" className="text-center" />
                            </div>
                            <p className="mb-0 text-center mt-3">Type</p>
                            <h6 className="text-center">{phaseData.phase_type}</h6>
                          </div>
                        </Card>
                      </Col>
                      <Col className="h-100" xxl={4}>
                        <Card className="p-3 border-0 shadow-sm d-flex justify-content-center align-items-center" style={{ height: "10rem" }}>
                          <div>
                            <div className="d-flex justify-content-center">
                              <FontAwesomeIcon icon={faMapLocationDot} size="4x" className="text-center" />
                            </div>
                            <p className="mb-0 text-center mt-3">Location</p>
                            <h6 className="text-center">{phaseData.phase_location}</h6>
                          </div>
                        </Card>
                      </Col>
                      <Col className="h-100" xxl={4}>
                        <Card className="p-3 border-0 shadow-sm d-flex justify-content-center align-items-center" style={{ height: "10rem" }}>
                          <div>
                            <div className="d-flex justify-content-center">
                              <FontAwesomeIcon icon={faKeySkeleton} size="4x" className="text-center" />
                            </div>
                            <p className="mb-0 text-center mt-3">Ready By</p>
                            <h6 className="text-center">{phaseData.completion_date !== "0000-00-00 00:00:00" ? moment(new Date(phaseData.completion_date)).format("MMMM YYYY") : "-"}</h6>
                          </div>
                        </Card>
                      </Col>
                      <Col className="h-100" xxl={4}>
                        <Card className="p-3 border-0 shadow-sm d-flex justify-content-center align-items-center" style={{ height: "10rem" }}>
                          <div>
                            <div className="d-flex justify-content-center">
                              <FontAwesomeIcon icon={faFileCertificate} size="4x" className="text-center" />
                            </div>
                            <p className="mb-0 text-center mt-3">Tenure</p>
                            <h6 className="text-center">{phaseData.tenure || "-"}</h6>
                          </div>
                        </Card>
                      </Col>
                      <Col className="h-100" xxl={4}>
                        <Card className="p-3 border-0 shadow-sm d-flex justify-content-center align-items-center" style={{ height: "10rem" }}>
                          <div>
                            <div className="d-flex justify-content-center">
                              <FontAwesomeIcon icon={faDoorOpen} size="4x" className="text-center" />
                            </div>
                            <p className="mb-0 text-center mt-3">Total Units</p>
                            <h6 className="text-center">{unitList.length}</h6>
                          </div>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>

              {(setFormat(modules.is_ql_saleschart_customer) || setFormat(modules.is_ql_virtual_tour_customer) || setFormat(modules.is_ql_unit_customer) || setFormat(modules.is_ql_floor_plan_customer) || setFormat(modules.is_ql_files_customer) || setFormat(modules.is_ql_contacts_customer)) && (
                <Col xxl={12} className="mb-5">
                  <div className="op-title h5 mb-4">Quick Links</div>
                  <Row>
                    {setFormat(modules.is_ql_saleschart_customer) && (
                      <Col xxl={3} className="mb-3">
                        <Card className="shadow-sm h-100 cursor-pointer" onClick={() => openSalesChart()}>
                          <Card.Body className="d-flex justify-content-between align-items-center">
                            <div className="d-flex align-items-center">
                              <FontAwesomeIcon icon={faMap} className="me-2" size="lg" />
                              <p className="op-text-bigger mb-0">Sales Chart</p>
                            </div>
                            <FontAwesomeIcon icon={faChevronRight} size="lg" />
                          </Card.Body>
                        </Card>
                      </Col>
                    )}

                    {setFormat(modules.is_ql_virtual_tour_customer) && (
                      <Col xxl={3} className="mb-3">
                        <Dropdown>
                          <Dropdown.Toggle as={RenderVirtualTour} style={{ zIndex: 1 }}>
                            <Card className="shadow-sm h-100 cursor-pointer">
                              <Card.Body className="d-flex justify-content-between align-items-center">
                                <div className="d-flex align-items-center">
                                  <FontAwesomeIcon icon={faPersonBooth} className="me-2" size="lg" />
                                  <p className="op-text-bigger mb-0">Virtual Tour</p>
                                </div>
                                <FontAwesomeIcon icon={faChevronRight} size="lg" />
                              </Card.Body>
                            </Card>
                          </Dropdown.Toggle>
                          <Dropdown.Menu align={"end"}>
                            {virtualTour.length > 0 ? (
                              <>
                                {virtualTour.map((record, index) => (
                                  <Dropdown.Item key={index} onClick={() => openVirtualTour(record.virtual_tour_url)}>
                                    {record.virtual_tour_title}
                                  </Dropdown.Item>
                                ))}
                              </>
                            ) : phaseData["3d_walkthrough"] ? (
                              <>
                                {phaseData["3d_walkthrough"].map((record, index) => (
                                  <Dropdown.Item key={index} onClick={() => openVirtualTour(record)}>
                                    Virtual Tour {index + 1}
                                  </Dropdown.Item>
                                ))}
                              </>
                            ) : (
                              <Dropdown.Item>No Virtual Tour</Dropdown.Item>
                            )}
                          </Dropdown.Menu>
                        </Dropdown>
                      </Col>
                    )}

                    {setFormat(modules.is_ql_unit_customer) && (
                      <Col xxl={3} className="mb-3">
                        <Card className="shadow-sm h-100 cursor-pointer" onClick={openUnitQuickLink}>
                          <Card.Body className="d-flex justify-content-between align-items-center">
                            <div className="d-flex align-items-center">
                              <FontAwesomeIcon icon={faDoorOpen} className="me-2" size="lg" />
                              <p className="op-text-bigger mb-0">Units</p>
                            </div>
                            <FontAwesomeIcon icon={faChevronRight} size="lg" />
                          </Card.Body>
                        </Card>
                      </Col>
                    )}

                    {setFormat(modules.is_ql_floor_plan_customer) && (
                      <Col xxl={3} className="mb-3">
                        <Card className="shadow-sm h-100 cursor-pointer" onClick={openFloorPlanQuickLink}>
                          <Card.Body className="d-flex justify-content-between align-items-center">
                            <div className="d-flex align-items-center">
                              <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" className="me-2" viewBox="0 0 410 512">
                                <path
                                  fill="#000"
                                  d="M379.4 25.6H33.3c-3.4 0-6.1 2.8-6.1 6.1v299.7 45.9c0 .1 0 .2 0 .3s0 .2 0 .3c0 3.4 2.8 6.1 6.1 6.1h346.1c3.4 0 6.1-2.8 6.1-6.1V230.6 100.7 31.7c0-3.4-2.8-6.1-6.1-6.1zM334.9 371.7v-2c0-3.4-2.8-6.1-6.1-6.1s-6.1 2.8-6.1 6.1v2H220.9V134.5c0-3.4-2.8-6.1-6.1-6.1s-6.1 2.8-6.1 6.1v190.8h-25.2c-3.4 0-6.1 2.8-6.1 6.1s2.8 6.1 6.1 6.1h25.2v34.1H39.5v-34.1h96.1c3.4 0 6.1-2.8 6.1-6.1s-2.8-6.1-6.1-6.1H39.5V37.9H208.6V82c0 3.4 2.8 6.1 6.1 6.1s6.1-2.8 6.1-6.1V37.9h152.3v56.7h-12.6c-3.4 0-6.1 2.8-6.1 6.1s2.8 6.1 6.1 6.1h12.6v117.6H284.2V106.8h27.9c3.4 0 6.1-2.8 6.1-6.1s-2.8-6.1-6.1-6.1h-34c-3.4 0-6.1 2.8-6.1 6.1v129.9c0 3.4 2.8 6.1 6.1 6.1h44.5v2c0 3.4 2.8 6.1 6.1 6.1s6.1-2.8 6.1-6.1v-2h38.4v135H334.9zm-6.1-37.3c-3.4 0-6.1 2.8-6.1 6.1v14.5c0 3.4 2.8 6.1 6.1 6.1s6.1-2.8 6.1-6.1v-14.5c0-3.4-2.8-6.1-6.1-6.1zm0-29.1c-3.4 0-6.1 2.8-6.1 6.1V326c0 3.4 2.8 6.1 6.1 6.1s6.1-2.8 6.1-6.1v-14.5c0-3.4-2.8-6.1-6.1-6.1zm0-29.1c-3.4 0-6.1 2.8-6.1 6.1V297c0 3.4 2.8 6.1 6.1 6.1s6.1-2.8 6.1-6.1v-14.5c0-3.4-2.8-6.1-6.1-6.1zm0-29.1c-3.4 0-6.1 2.8-6.1 6.1v14.5c0 3.4 2.8 6.1 6.1 6.1s6.1-2.8 6.1-6.1v-14.5c0-3.4-2.8-6.1-6.1-6.1z"
                                />
                              </svg>
                              <p className="op-text-bigger mb-0">Floor Plans</p>
                            </div>
                            <FontAwesomeIcon icon={faChevronRight} size="lg" />
                          </Card.Body>
                        </Card>
                      </Col>
                    )}

                    {setFormat(modules.is_ql_files_customer) && (
                      <Col xxl={3} className="mb-3">
                        <Card className="shadow-sm h-100 cursor-pointer" onClick={setIsFile}>
                          <Card.Body className="d-flex justify-content-between align-items-center">
                            <div className="d-flex align-items-center">
                              <FontAwesomeIcon icon={faFile} className="me-2" size="lg" />
                              <p className="op-text-bigger mb-0">Files</p>
                            </div>
                            <FontAwesomeIcon icon={faChevronRight} size="lg" />
                          </Card.Body>
                        </Card>
                      </Col>
                    )}

                    {setFormat(modules.is_ql_contacts_customer) && (
                      <Col xxl={3} className="mb-3">
                        <Card className="shadow-sm h-100 cursor-pointer" onClick={setIsContact}>
                          <Card.Body className="d-flex justify-content-between align-items-center">
                            <div className="d-flex align-items-center">
                              <FontAwesomeIcon icon={faPhone} className="me-2" size="lg" />
                              <p className="op-text-bigger mb-0">Contacts</p>
                            </div>
                            <FontAwesomeIcon icon={faChevronRight} size="lg" />
                          </Card.Body>
                        </Card>
                      </Col>
                    )}
                  </Row>
                </Col>
              )}

              {setFormat(modules.is_location_customer) && (
                <Col xxl={12} className="mb-5">
                  <div className="op-title h5 mb-4">
                    Location <br />
                    <span className="op-text-medium text-muted">{phaseData.phase_location}</span>
                  </div>
                  <div className="w-100 d-flex justify-content-center">
                    <div style={{ width: "60%" }}>
                      {location && isLoaded && (
                        <GoogleMap center={{ lat: location.latitude, lng: location.longitude }} zoom={15} mapContainerStyle={{ width: "100%", height: "400px" }}>
                          <Marker position={{ lat: location.latitude, lng: location.longitude }} />

                          {Number(isAmenities) === 0 &&
                            amenities.education.map((record, index) => (
                              <Marker
                                key={index}
                                position={{ lat: Number(record.amenities_lat), lng: Number(record.amenities_lon) }}
                                options={{
                                  icon: {
                                    url: EducationMarker,
                                    scaledSize: new window.google.maps.Size(40, 40),
                                  },
                                }}
                              />
                            ))}

                          {Number(isAmenities) === 1 &&
                            amenities.healthcare.map((record, index) => (
                              <Marker
                                key={index}
                                position={{ lat: Number(record.amenities_lat), lng: Number(record.amenities_lon) }}
                                options={{
                                  icon: {
                                    url: HospitalMarker,
                                    scaledSize: new window.google.maps.Size(40, 40),
                                  },
                                }}
                              />
                            ))}

                          {Number(isAmenities) === 2 &&
                            amenities.shopping.map((record, index) => (
                              <Marker
                                key={index}
                                position={{ lat: Number(record.amenities_lat), lng: Number(record.amenities_lon) }}
                                options={{
                                  icon: {
                                    url: ShoppingMarker,
                                    scaledSize: new window.google.maps.Size(40, 40),
                                  },
                                }}
                              />
                            ))}

                          {Number(isAmenities) === 3 &&
                            amenities.entertainment.map((record, index) => (
                              <Marker
                                key={index}
                                position={{ lat: Number(record.amenities_lat), lng: Number(record.amenities_lon) }}
                                options={{
                                  icon: {
                                    url: EntertainmentMarker,
                                    scaledSize: new window.google.maps.Size(40, 40),
                                  },
                                }}
                              />
                            ))}

                          {Number(isAmenities) === 4 &&
                            amenities.public_transport.map((record, index) => (
                              <Marker
                                key={index}
                                position={{ lat: Number(record.amenities_lat), lng: Number(record.amenities_lon) }}
                                options={{
                                  icon: {
                                    url: PublicTransportMarker,
                                    scaledSize: new window.google.maps.Size(40, 40),
                                  },
                                }}
                              />
                            ))}

                          {travelResponse && <DirectionsRenderer options={{ directions: travelResponse }} />}
                          {destination && destinationResponse && (
                            <DirectionsService
                              options={{
                                destination: { lat: Number(destination.latitude), lng: Number(destination.longitude) },
                                origin: { lat: location.latitude, lng: location.longitude },
                                travelMode: "DRIVING",
                              }}
                              callback={getDirectionCallback}
                            />
                          )}
                        </GoogleMap>
                      )}
                    </div>

                    <div className="amenities-list overflow-auto">
                      <div className="header">Nearby Amenities</div>
                      <Accordion defaultActiveKey="0" flush onSelect={(event) => openAmenities(event)}>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>
                            <div style={{ width: 40 }}>
                              <FontAwesomeIcon icon={faGraduationCap} size="xl" className="me-2" />
                            </div>
                            Education
                          </Accordion.Header>
                          <Accordion.Body className="py-0">
                            <ListGroup>
                              {amenities.education.map((record, index) => (
                                <ListGroup.Item className="d-flex justify-content-between cursor-pointer" key={index} onClick={() => onchangeDestination(record)}>
                                  <span>{record.amenities_name}</span>
                                  <span>{setFormatDistance(setCalculateDistance(location.latitude, location.longitude, record.amenities_lat, record.amenities_lon))}</span>
                                </ListGroup.Item>
                              ))}
                            </ListGroup>
                          </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="1">
                          <Accordion.Header>
                            <div style={{ width: 40 }}>
                              <FontAwesomeIcon icon={faHouseMedical} size="xl" className="me-2" />
                            </div>
                            HealthCare
                          </Accordion.Header>
                          <Accordion.Body>
                            <ListGroup>
                              {amenities.healthcare.map((record, index) => (
                                <ListGroup.Item className="d-flex justify-content-between cursor-pointer" key={index} onClick={() => onchangeDestination(record)}>
                                  <span>{record.amenities_name}</span>
                                  <span>{setFormatDistance(setCalculateDistance(location.latitude, location.longitude, record.amenities_lat, record.amenities_lon))}</span>
                                </ListGroup.Item>
                              ))}
                            </ListGroup>
                          </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="2">
                          <Accordion.Header>
                            <div style={{ width: 40 }}>
                              <FontAwesomeIcon icon={faBagShopping} size="xl" className="me-2" />
                            </div>
                            Shopping
                          </Accordion.Header>
                          <Accordion.Body>
                            <ListGroup>
                              {amenities.shopping.map((record, index) => (
                                <ListGroup.Item className="d-flex justify-content-between cursor-pointer" key={index} onClick={() => onchangeDestination(record)}>
                                  <span>{record.amenities_name}</span>
                                  <span>{setFormatDistance(setCalculateDistance(location.latitude, location.longitude, record.amenities_lat, record.amenities_lon))}</span>
                                </ListGroup.Item>
                              ))}
                            </ListGroup>
                          </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="3">
                          <Accordion.Header>
                            <div style={{ width: 40 }}>
                              <FontAwesomeIcon icon={faCameraMovie} size="xl" className="me-2" />
                            </div>
                            Entertainment
                          </Accordion.Header>
                          <Accordion.Body>
                            <ListGroup>
                              {amenities.entertainment.map((record, index) => (
                                <ListGroup.Item className="d-flex justify-content-between cursor-pointer" key={index} onClick={() => onchangeDestination(record)}>
                                  <span>{record.amenities_name}</span>
                                  <span>{setFormatDistance(setCalculateDistance(location.latitude, location.longitude, record.amenities_lat, record.amenities_lon))}</span>
                                </ListGroup.Item>
                              ))}
                            </ListGroup>
                          </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="4">
                          <Accordion.Header>
                            <div style={{ width: 40 }}>
                              <FontAwesomeIcon icon={faTrain} size="xl" className="me-2" />
                            </div>
                            Public Transport
                          </Accordion.Header>
                          <Accordion.Body>
                            <ListGroup>
                              {amenities.public_transport.map((record, index) => (
                                <ListGroup.Item className="d-flex justify-content-between cursor-pointer" key={index} onClick={() => onchangeDestination(record)}>
                                  <span>{record.amenities_name}</span>
                                  <span>{setFormatDistance(setCalculateDistance(location.latitude, location.longitude, record.amenities_lat, record.amenities_lon))}</span>
                                </ListGroup.Item>
                              ))}
                            </ListGroup>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  </div>
                </Col>
              )}

              {setFormat(modules.is_site_plan_customer) && (
                <Col xxl={12} className="mb-5">
                  <div className="op-title h5 mb-4">Site Plan</div>
                  {phaseData.site_plan_image ? (
                    <Card className="shadow-sm border-0 cursor-pointer" onClick={() => openSalesChart()}>
                      <Card.Body>
                        <div className="d-flex justify-content-center">
                          <div className="w-75">
                            <Image src={`${hostUrl}/${hostUrlType}/assets/rea_booking/${company_id}/project/${phaseData.site_plan_image}`} fluid className="cursor-pointer" />
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  ) : (
                    "No site plan available"
                  )}
                </Col>
              )}

              {setFormat(modules.is_unit_summary_customer) && (
                <Col xxl={12} className="mb-5">
                  <div className="op-title h5 mb-4">Unit Summary</div>
                  {phaseData.unit_summary_type === "unit_type" ? (
                    <Row>
                      {typeList.map((record, index) => (
                        <RenderUnitType key={index} record={record} index={index} label={modules} />
                      ))}
                    </Row>
                  ) : (
                    <Row>
                      {bedroomList.map((record, index) => (
                        <RenderBedroom key={index} record={record} index={index} label={modules} />
                      ))}
                    </Row>
                  )}
                </Col>
              )}
            </Row>
          </section>

          <Modal show={show} onHide={setShow} fullscreen={true} dialogClassName="op-modal-dialog-fullscreen">
            <Modal.Header className="position-relative border-0 py-1" style={{ background: "#505050" }}>
              <div className="d-flex flex-row-reverse w-100">
                <Button
                  variant="link"
                  className="m-0 text-light text-decoration-none"
                  onClick={() => {
                    setShow(false);
                    setMainSwiper(mainSwiper);
                  }}
                >
                  <FontAwesomeIcon icon={faX} size="xl" style={{ color: "#fff" }} />
                </Button>
                <div className="me-2">
                  <Dropdown>
                    <Dropdown.Toggle as={RenderShareToggle2}>
                      <FontAwesomeIcon className="cursor-pointer" icon={faEllipsisVertical} size="xl" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu align={"end"}>
                      <Dropdown.Item
                        onClick={() => {
                          if (modalSwiper) {
                            const activeSlide = modalSwiper.slides.find((slide) => slide.classList.contains("swiper-slide-active"));
                            if (activeSlide) {
                              const activeSlideIndex = Array.from(modalSwiper.slides).indexOf(activeSlide);
                              const images = phaseData.phase_image;
                              const currentImage = images.length > 0 ? `${hostUrl}/${hostUrlType}/assets/rea_booking/generic.jpg` : `${hostUrl}/${hostUrlType}/assets/rea_booking/${company_id}/project/${images[activeSlideIndex]}`;
                              const text = `Please find the file of project ${phaseData.phase_name} listed below for your reference: \n${currentImage}`;
                              const whatsapp = `whatsapp://send?text=${encodeURIComponent(text)}`;
                              window.open(whatsapp, "_blank");
                            }
                          }
                        }}
                      >
                        Share via Whatsapp
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          if (modalSwiper) {
                            const activeSlide = modalSwiper.slides.find((slide) => slide.classList.contains("swiper-slide-active"));
                            if (activeSlide) {
                              const activeSlideIndex = Array.from(modalSwiper.slides).indexOf(activeSlide);
                              const images = phaseData.phase_image;
                              const currentImage = images.length > 0 ? `../assets/rea_booking/generic.jpg` : `../assets/rea_booking/${company_id}/project/${images[activeSlideIndex]}`;
                              sendDownload(`${phaseData.phase_name}`, currentImage);
                            }
                          }
                        }}
                      >
                        Download
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </Modal.Header>
            <Modal.Body style={{ background: "#505050" }}>
              <Swiper
                onSwiper={setModalSwiper2}
                initialSlide={mainSwiperIndex}
                onSlideChange={(swiper) => setModalSwiperIndex(swiper.activeIndex)}
                spaceBetween={10}
                navigation={true}
                zoom={true}
                thumbs={{ swiper: modalSwiper }}
                modules={[Zoom, FreeMode, Navigation, Thumbs, Pagination]}
                className="mySwiper2"
                style={{
                  "--swiper-navigation-color": "#fff",
                  "--swiper-pagination-color": "#fff",
                  zIndex: 0,
                }}
              >
                {phaseData.phase_image.map((data, i) => (
                  <SwiperSlide key={i} className="d-flex justify-content-center w-100">
                    <div className="swiper-zoom-container">
                      <img className="cursor-pointer" src={setPhaseImage(data)} style={{ width: "auto", height: "65vh", objectFit: "cover" }} alt="mediaimage" />
                    </div>
                  </SwiperSlide>
                ))}

                {phaseData.video_url &&
                  phaseData.video_url.map((data, i) => (
                    <SwiperSlide key={i}>
                      <video
                        autoPlay
                        ref={(ref) => {
                          videoRefs.current[i] = ref;
                        }}
                        // height="480"
                        controls
                        loop
                        style={{ width: "100%", height: "65vh" }}
                      >
                        <source src={setPhaseImage(data)} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </SwiperSlide>
                  ))}
              </Swiper>

              <Row className="g-0">
                <Col md={{ span: 6, offset: 3 }}>
                  <label className="my-2 text-light fw-bold">{setSwiperLabel(modalSwiperIndex, phaseData.phase_image, phaseData.phase_video)}</label>
                  <Nav variant="underline" defaultActiveKey="image" className="mb-4" onSelect={setSlideToModal} style={{ borderBottom: "0.5px solid #999" }}>
                    <Nav.Item>
                      <Nav.Link eventKey="image" style={{ fontSize: 13, color: "#ddd" }}>
                        <FontAwesomeIcon icon={faImage} className="me-1" /> {phaseData.phase_image.length} Images
                      </Nav.Link>
                    </Nav.Item>
                    {phaseData.video_url && (
                      <Nav.Item>
                        <Nav.Link eventKey="video" style={{ fontSize: 13, color: "#ddd" }}>
                          <FontAwesomeIcon icon={faPlay} className="me-1" /> {phaseData.video_url.length} Videos
                        </Nav.Link>
                      </Nav.Item>
                    )}
                  </Nav>
                  <Swiper onSwiper={setModalSwiper} spaceBetween={10} slidesPerView={8} freeMode={true} watchSlidesProgress={true} modules={[FreeMode, Navigation, Thumbs]}>
                    {phaseData.phase_image.map((data, i) => (
                      <SwiperSlide key={i} style={{ cursor: "pointer" }}>
                        <img src={setPhaseImage(data)} style={{ width: "100%", height: 55, objectFit: "cover", borderRadius: 5 }} alt="mediaimage" />
                      </SwiperSlide>
                    ))}

                    {phaseData.video_url &&
                      phaseData.video_url.map((data, i) => (
                        <SwiperSlide key={i}>
                          {({ isActive }) => (
                            <div style={{ width: "100%", height: 55, borderRadius: 5, objectFit: "cover", backgroundColor: "#000", color: "#fff", display: "flex", alignItems: "center", justifyContent: "center", opacity: isActive ? 0.5 : 1 }}>
                              <FontAwesomeIcon icon={faPlayCircle} size="xl" />
                            </div>
                          )}
                        </SwiperSlide>
                      ))}
                  </Swiper>
                </Col>
              </Row>
            </Modal.Body>
          </Modal>

          <Modal show={isUnitFilter} onHide={setIsUnitFilter} size="md">
            <Formik onSubmit={onchangeUnitFilter} initialValues={unitSettings}>
              {({ handleSubmit, handleChange, setFieldValue, values }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Modal.Header closeButton>
                    <Modal.Title as={"h6"}>Unit Filters</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form.Group className="mb-3">
                      <Form.Label>Unit Number</Form.Label>
                      <Form.Control value={values.unit} onChange={handleChange("unit")} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Status</Form.Label>
                      <FormSelect options={unitStatusList} valueDefault={unitStatusList.find((record) => record.value === values.unit_status)} onChange={(e) => setFieldValue("unit_status", e.value)} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Unit Type</Form.Label>
                      <FormSelect options={unitTypeList} valueDefault={unitTypeList.find((record) => record.value === values.unit_type)} onChange={(e) => setFieldValue("unit_type", e.value)} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Bedrooms</Form.Label>
                      <FormSelect options={unitBedroomList} valueDefault={unitBedroomList.find((record) => record.value === values.unit_bedroom)} onChange={(e) => setFieldValue("unit_bedroom", e.value)} />
                    </Form.Group>
                  </Modal.Body>
                  <Modal.Footer>
                    <button type="submit" className="btn op-button op-primary-color text-light shadow">
                      Apply
                    </button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal>

          <Modal show={isFloorPlanFilter} onHide={setIsFloorPlanFilter} size="md">
            <Formik onSubmit={onchangeFloorPlanFilter} initialValues={floorPlanSettings}>
              {({ handleSubmit, handleChange, setFieldValue, values }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Modal.Header closeButton>
                    <Modal.Title as={"h6"}>Floor Plans Filters</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form.Group className="mb-3">
                      <Form.Label>Unit Type</Form.Label>
                      <FormSelect options={unitTypeList} valueDefault={unitTypeList.find((record) => record.value === values.unit_type)} onChange={(e) => setFieldValue("unit_type", e.value)} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Bedrooms</Form.Label>
                      <FormSelect options={unitBedroomList} valueDefault={unitBedroomList.find((record) => record.value === values.unit_bedroom)} onChange={(e) => setFieldValue("unit_bedroom", e.value)} />
                    </Form.Group>
                  </Modal.Body>
                  <Modal.Footer>
                    <button type="submit" className="btn op-button op-primary-color text-light shadow">
                      Apply
                    </button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal>

          <Offcanvas show={isProjectDetails} onHide={setIsProjectDetails} placement="bottom" style={{ height: "100vh" }}>
            <Offcanvas.Header closeButton style={{ paddingLeft: 50, paddingRight: 50 }}>
              <Offcanvas.Title>Phase Details</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body style={{ paddingLeft: 50, paddingRight: 50, paddingTop: 30 }}>
              <div className="w-75">
                <section>
                  <h6 className="fw-bold">
                    <FontAwesomeIcon icon={faBuildings} size="sm" className="me-2" />
                    Phase Information
                  </h6>

                  <Stack direction="vertical" gap={1} className="mt-3">
                    <Stack direction="horizontal">
                      <div style={{ width: "50%", marginBottom: 10, display: "flex" }}>
                        <label style={{ color: "#999", width: 80, display: "inline-block" }}>Type</label>
                        <span style={{ width: 260, marginLeft: 20, display: "inline-block" }}>{phaseData.phase_type || "-"}</span>
                      </div>
                      <div className="ms-auto" style={{ width: "50%", marginBottom: 10, display: "flex" }}>
                        <label style={{ color: "#999", width: 80, display: "inline-block" }}>Tenure</label>
                        <span style={{ width: 260, marginLeft: 20, display: "inline-block" }}>{phaseData.tenure || "-"}</span>
                      </div>
                    </Stack>

                    <Stack direction="horizontal">
                      <div style={{ width: "50%", marginBottom: 10, display: "flex" }}>
                        <label style={{ color: "#999", width: 80, display: "inline-block" }}>Total Units</label>
                        <span style={{ width: 260, marginLeft: 20, display: "inline-block" }}>{unitList.length}</span>
                      </div>
                      <div className="ms-auto" style={{ width: "50%", marginBottom: 10, display: "flex" }}>
                        <label style={{ color: "#999", width: 80, display: "inline-block" }}>Developer</label>
                        <span style={{ width: 260, marginLeft: 20, display: "inline-block" }}>{phaseData.developer || "-"}</span>
                      </div>
                    </Stack>

                    <Stack direction="horizontal">
                      <div className="ms-auto" style={{ width: "50%", marginBottom: 10, display: "flex" }}>
                        <label style={{ color: "#999", width: 80, display: "inline-block" }}>Ready by</label>
                        <span style={{ width: 260, marginLeft: 20, display: "inline-block" }}>{phaseData.completion_date !== "0000-00-00 00:00:00" ? moment(new Date(phaseData.completion_date)).format("MMMM YYYY") : "-"}</span>
                      </div>
                      <div className="ms-auto" style={{ width: "50%", marginBottom: 10, display: "flex" }}>
                        <label style={{ color: "#999", width: 80, display: "inline-block" }}>Address</label>
                        <span style={{ width: 260, marginLeft: 20, display: "inline-block" }}>{phaseData.phase_location || "-"}</span>
                      </div>
                    </Stack>
                  </Stack>
                </section>

                <section className="mt-3">
                  <label className="fw-bold op-text-bigger">Key Points</label>
                  {phaseData.key_points.length > 0 ? (
                    <ul className="mt-2">
                      {phaseData.key_points.map((record, index) => (
                        <li key={index}>{record}</li>
                      ))}
                    </ul>
                  ) : (
                    <div className="mt-2">No key points</div>
                  )}
                </section>

                <section className="mt-3">
                  <label className="fw-bold op-text-bigger">Description</label>
                  {phaseData.phase_desc ? <p className="mt-2">{phaseData.phase_desc}</p> : <div className="mt-2">No description</div>}
                </section>

                <section className="mt-4">
                  <h6 className="fw-bold">
                    <FontAwesomeIcon icon={faWaterLadder} size="sm" className="me-2" />
                    Facilities
                  </h6>
                  {facilities.length > 0 ? (
                    facilities.map((record, sectionIndex) => {
                      let startNumber = 1;
                      for (let i = 0; i < sectionIndex; i++) {
                        startNumber += facilities[i].facilities.length;
                      }

                      return (
                        <div key={sectionIndex} style={{ margin: "20px 0" }}>
                          <label style={{ fontWeight: "bold" }}>{record.facilities_title}</label>
                          {record.facilities.map((info, i) => (
                            <div key={i}>
                              {String(startNumber + i).padStart(2, "0")}. {info}
                            </div>
                          ))}
                        </div>
                      );
                    })
                  ) : phaseData.phase_facilities ? (
                    <div style={{ margin: "20px 0" }}>
                      {phaseData.phase_facilities.map((record, i) => (
                        <div key={i}>
                          {String(i + 1).padStart(2, "0")}. {record}
                        </div>
                      ))}
                    </div>
                  ) : (
                    "No facilities"
                  )}
                </section>
              </div>
            </Offcanvas.Body>
          </Offcanvas>

          <Offcanvas show={isContact} onHide={setIsContact} placement="bottom" style={{ height: "100vh" }}>
            <Offcanvas.Header closeButton style={{ paddingLeft: 50, paddingRight: 50 }}>
              <Offcanvas.Title>Contacts</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body style={{ paddingLeft: 50, paddingRight: 50, paddingTop: 30 }}>
              <div className="w-75">
                <section>
                  <label className="h6 fw-bold">{phaseData.phase_name} Contact Details</label>
                  <Stack direction="vertical" gap={1} className="mt-2">
                    {contacts.length > 0 ? (
                      <Stack direction="vertical" gap={2}>
                        {contacts.map((record, index) => (
                          <div key={index} style={{ width: "50%", display: "flex" }}>
                            <label style={{ color: "#999", width: 150, display: "inline-block" }}>{record.contact_title}</label>
                            <span style={{ width: 260, marginLeft: 20, display: "inline-block" }}>{record.contact_value || "-"}</span>
                          </div>
                        ))}
                      </Stack>
                    ) : (
                      "No contact available"
                    )}
                  </Stack>
                </section>
              </div>
            </Offcanvas.Body>
          </Offcanvas>

          <Offcanvas show={isUnit} onHide={setIsUnit} placement="bottom" style={{ height: "100vh" }}>
            <Offcanvas.Header closeButton style={{ paddingLeft: 50, paddingRight: 50 }}>
              <Offcanvas.Title>Units</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body style={{ paddingLeft: 50, paddingRight: 50, paddingTop: 30 }}>
              <div className="w-75">
                <Stack direction="horizontal" gap={2} className="w-100">
                  <Button variant="light" className="border shadow-sm" onClick={setIsUnitFilter}>
                    <FontAwesomeIcon icon={faSliders} className="me-1" /> Filter {setFilterCount(unitSettings) > 0 && <Badge bg="secondary">{setFilterCount(unitSettings)}</Badge>}
                  </Button>
                  <p className="m-0">{unitFilterCount > 1 ? `${unitFilterCount} records found` : unitFilterCount === 1 ? "1 record found" : "No record found"}</p>
                </Stack>

                <Row className="mt-3">
                  <Col xxl={10}>
                    <div className="w-100">
                      {unitFilterLoading ? (
                        <Loader />
                      ) : (
                        <ListGroup>
                          {unitFilter.length > 0
                            ? unitFilter.map((record, index) => (
                                <div key={index}>
                                  <label className="h6 fw-bold my-3">{record.section}</label>
                                  {record.unit.map((info, i) => (
                                    <ListGroup.Item key={i} className="mb-3 shadow-sm border cursor-pointer" onClick={() => openUnit(info)}>
                                      <Stack direction="vertical" gap={2}>
                                        <Stack direction="horizontal">
                                          <h6 className="fw-bold">{info.unit_number}</h6>
                                          <div className="d-flex ms-auto">
                                            {Number(info.bumi_reserved) !== 2 && (
                                              <div className="py-1 px-3 rounded text-capitalize me-2" style={{ backgroundColor: "#eee", color: "#000" }}>
                                                {Number(info.bumi_reserved) === 1 ? "BUMI" : "NON BUMI"}
                                              </div>
                                            )}
                                            <Button variant={setStatusColor(info.unit_status)} className="py-1 px-3 text-capitalize">
                                              {info.unit_status !== "available" ? "Unavailable" : info.unit_status}
                                            </Button>
                                            {/* <div className="py-1 px-3 bg-success text-light rounded text-capitalize">{info.unit_status}</div> */}
                                          </div>
                                        </Stack>
                                        <Stack direction="horizontal">
                                          <div>
                                            <span className="op-text-bigger">{info.unit_title}</span> <br />
                                            <span className="op-text-medium text-muted">{info.unit_type}</span>
                                          </div>
                                          {info.unit_status === "available" && <div className="ms-auto">{setCurrency(info.unit_selling_price)}</div>}
                                        </Stack>
                                      </Stack>
                                    </ListGroup.Item>
                                  ))}
                                </div>
                              ))
                            : "None at the moment"}
                        </ListGroup>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            </Offcanvas.Body>
          </Offcanvas>

          <Offcanvas show={isFloorPlan} onHide={setIsFloorPlan} placement="bottom" style={{ height: "100vh" }}>
            <Offcanvas.Header closeButton style={{ paddingLeft: 50, paddingRight: 50 }}>
              <Offcanvas.Title>Floor Plans</Offcanvas.Title>
            </Offcanvas.Header>

            <Offcanvas.Body style={{ paddingLeft: 50, paddingRight: 50, paddingTop: 30 }}>
              <div className="w-100">
                <Stack direction="horizontal" gap={2} className="w-100 align-items-center">
                  <Button variant="light" className="border shadow-sm" onClick={setIsFloorPlanFilter}>
                    <FontAwesomeIcon icon={faSliders} className="me-1" /> Filter {setFilterCount(floorPlanSettings) > 0 && <Badge bg="secondary">{setFilterCount(floorPlanSettings)}</Badge>}
                  </Button>
                  <p className="m-0">{floorPlanFilterCount > 1 ? `${floorPlanFilterCount} records found` : floorPlanFilterCount === 1 ? "1 record found" : "No record found"}</p>
                </Stack>

                {floorPlanFilterLoading ? (
                  <Loader />
                ) : (
                  <>
                    {floorPlanFilter.length > 0 ? (
                      floorPlanFilter.map((record, index) => (
                        <div key={index}>
                          <label className="h6 fw-bold my-3">{record.section}</label>
                          <Row>
                            {record.unit_type.map((info, i) => (
                              <Col key={i} xxl={4} className="mb-4">
                                <Card className="w-100 h-100 shadow-sm position-relative">
                                  <FloorPlanSwiper images={info.floor_plan} hostUrl={hostUrl} hostUrlType={hostUrlType} company_id={company_id} phaseData={phaseData} type={info} />
                                  <Card.Footer>
                                    <Stack className="vertical" gap={2}>
                                      <label className="fw-bold" style={{ fontSize: 15 }}>
                                        {info.unit_title} {info.unit_type} {info.builtup_area ? `(${setArea(info.builtup_area)})` : ""}
                                      </label>
                                      <div>
                                        <label className="text-muted">Price Range</label> <br />
                                        <label>{info.price}</label>
                                      </div>
                                      <a className="text-dark text-decoration-none p-0 m-0 cursor-pointer fw-bold mt-1" onClick={() => openUnitFloorPlan(info)}>
                                        <span>
                                          {info.unit_available_count} of {info.unit_count} available
                                        </span>
                                        <FontAwesomeIcon icon={faChevronRight} className="ms-1" size="sm" />
                                      </a>
                                    </Stack>
                                  </Card.Footer>
                                </Card>
                              </Col>
                            ))}
                          </Row>
                        </div>
                      ))
                    ) : (
                      <Col xxl={4}>None at the moment</Col>
                    )}
                  </>
                )}
              </div>
            </Offcanvas.Body>
          </Offcanvas>

          <Offcanvas show={isDocument} onHide={setIsDocument} placement="top" style={{ height: "100vh" }}>
            <Offcanvas.Header className="position-relative" closeButton>
              <Offcanvas.Title>{documentData.title}</Offcanvas.Title>
              <div style={{ position: "absolute", right: 50, cursor: "pointer" }}>
                <Dropdown>
                  <Dropdown.Toggle as={RenderShareToggle} style={{ zIndex: 1 }}>
                    <FontAwesomeIcon className="cursor-pointer" icon={faEllipsisVertical} size="lg" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu align={"end"}>
                    <Dropdown.Item
                      onClick={() => {
                        const text = `Please find the file of project ${phaseData.phase_name} listed below for your reference type ${documentData.title} floor plan: \n${documentData.url}`;
                        const whatsapp = `whatsapp://send?text=${encodeURIComponent(text)}`;
                        window.open(whatsapp, "_blank");
                      }}
                    >
                      Share via Whatsapp
                    </Dropdown.Item>
                    {documentData.mode === "image" && <Dropdown.Item onClick={() => sendDownload(`${documentData.title}_floor_plan`, documentData.filepath)}>Download</Dropdown.Item>}
                  </Dropdown.Menu>
                </Dropdown>
                {/* <FontAwesomeIcon icon={faShare} size="xl" /> */}
              </div>
            </Offcanvas.Header>
            {documentData.mode === "image" && (
              <Offcanvas.Body>
                <div className="d-flex justify-content-center align-items-center w-100">
                  <Image src={documentData.url} style={{ height: "85vh", width: "auto" }} />
                </div>
              </Offcanvas.Body>
            )}

            {documentData.mode === "pdf" && (
              <Offcanvas.Body className="p-0 overflow-hidden">
                <iframe title="Document" src={documentData.url} style={{ width: "100%", height: "100%" }}></iframe>
              </Offcanvas.Body>
            )}
          </Offcanvas>

          <Offcanvas show={isFile} onHide={setIsFile} placement="bottom" style={{ height: "100vh" }}>
            <Offcanvas.Header closeButton style={{ paddingLeft: 50, paddingRight: 50 }}>
              <Offcanvas.Title>Brochures & Files</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body style={{ paddingLeft: 50, paddingRight: 50, paddingTop: 30 }}>
              <div className="w-75">
                {files.length > 0 ? (
                  <>
                    {files.map((record, index) => (
                      <div key={index}>
                        <label className="fw-bold">
                          {record.group} ({record.files.length})
                        </label>
                        <Row className="mt-3">
                          {record.files.map((info, i) => (
                            <Col xxl={6} key={i} className="mb-3">
                              <div className="border rounded shadow-sm w-100 p-3 bg-light d-flex align-items-center justify-content-between">
                                <span className="w-75" onClick={() => openFiles(info)}>
                                  {info.file_title}
                                </span>
                                <Dropdown>
                                  <Dropdown.Toggle as={RenderShareToggle} style={{ zIndex: 1 }}>
                                    <FontAwesomeIcon className="cursor-pointer" icon={faEllipsisVertical} size="lg" />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu align={"end"}>
                                    <Dropdown.Item onClick={() => sendShareWhatsapp(info)}>Share via Whatsapp</Dropdown.Item>
                                    {info.download && <Dropdown.Item>Download</Dropdown.Item>}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </Col>
                          ))}
                        </Row>
                      </div>
                    ))}
                  </>
                ) : (
                  "No file available"
                )}
              </div>
            </Offcanvas.Body>
          </Offcanvas>
        </div>
      )}
    </Container>
  );
};

export default CustomerKitPhaseDetails;
