import { useState, useRef, useEffect, useMemo } from "react";
import { InputGroup, Form, Stack, ListGroup, Card, Offcanvas, Row, Col, Spinner, Button } from "react-bootstrap";
import axios from "../../api/axios";
import { useAuth } from "../../auth/AuthContext";
import { AgGridReact } from "ag-grid-react";
import "../../lib/scss/op-step.scss";
import FormSelect from "../../includes/FormSelect";
import Swal from "sweetalert2/dist/sweetalert2";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginPdfPreview from "filepond-plugin-pdf-preview";
import "filepond-plugin-pdf-preview/dist/filepond-plugin-pdf-preview.min.css";
import WSBackground from "../../assets/ws_background.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faImage as faImageSolid } from "@fortawesome/pro-solid-svg-icons";
import moment from "moment";
import { faClipboardListCheck, faMagnifyingGlass, faMessage, faPhone, faUser } from "@fortawesome/pro-light-svg-icons";

const AddWSCampaignV2 = ({ show, onHide, onSuccess }) => {
  const { session } = useAuth();
  const gridRef = useRef();

  registerPlugin(FilePondPluginImagePreview);
  registerPlugin(FilePondPluginPdfPreview);
  registerPlugin(FilePondPluginFileValidateType);

  const [isCur, setIsCur] = useState(true);
  const [isTemplate, setIsTemplate] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);

  const [curationList, setCurationList] = useState([]);
  const [curationData, setCurationData] = useState("");
  const [curationSearch, setCurationSearch] = useState("");

  const [templateList, setTemplateList] = useState([]);
  const [templateData, setTemplateData] = useState("");
  const [templateSearch, setTemplateSearch] = useState("");

  const [wsNumberList, setWsNumberList] = useState([]);
  const [wsNumberData, setWsNumberData] = useState("");

  const [isGrid, setIsGrid] = useState(false);
  const [recordFound, setRecordFound] = useState(0);
  const [rowData, setRowData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loadingSection, setLoadingSection] = useState(false);

  const [paramsArr, setParamsArr] = useState([]);
  const [changeMsg, setChangeMsg] = useState("");
  const [headerImg, setHeaderImg] = useState("");
  const [headerImgUrl, setHeaderImgUrl] = useState("");
  const [headerFiles, setHeaderFiles] = useState([]);
  const [campaignTitle, setCampaignTitle] = useState("");
  const [testCampaign, setTestCampaign] = useState("");
  const [whatsappMessagePreview, setWhatsappMessagePreview] = useState("");

  const containerStyle = useMemo(() => ({ width: "100%", height: "70vh" }), [isGrid]);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const [headerType, setHeaderType] = useState({ value: "image", label: "Upload an image" });

  const reportColDef = useMemo(() => {
    return {
      sortable: false,
      filter: false,
    };
  }, []);

  const [reportColumn] = useState([
    {
      headerName: "Name",
      field: "prospect_name",
      flex: 1,
      minWidth: 200,
    },
    {
      headerName: "Contact",
      field: "contact_num",
      flex: 1,
      minWidth: 200,
    },
    {
      headerName: "Email",
      field: "email_title",
      flex: 1,
      minWidth: 200,
    },
  ]);

  const getAllCuration = async () => {
    try {
      const response = await axios.get("ws/ws_curation.php", {
        params: {
          task: "listCuration",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (Number(data.status) === 0) {
        var dataList = data.record.map((data) => ({ label: data.curation_title, value: data.curation_id }));
        setCurationList(dataList);
      } else {
        setCurationList([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getAllWSTemplate = async (chatInstanceId) => {
    try {
      const response = await axios.get("ws/ws_waba_interactive.php", {
        params: {
          task: "GetWsCampaignTemplateList",
          chat_instance_id: chatInstanceId,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;

      if (Number(data.status) === 0) {
        const dataList = data.record
          .filter((record) => record.waba_status === "approved")
          .map((record) => {
            const paramsArray = Array.from({ length: Number(record.whatsapp_template_params) }, () => "");
            return {
              ...record,
              label: record.whatsapp_template_name,
              value: record.whatsapp_template_op_id,
              wsTempOpID: record.whatsapp_template_op_id,
              params: paramsArray,
              header: record.whatsapp_template_header_image,
            };
          });

        setTemplateList(dataList);
      } else {
        setTemplateList([]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading2(false);
    }
  };

  const getAllWSNumber = async () => {
    try {
      const response = await axios.get("ws/ws_ai_campaign.php", {
        params: {
          task: "getAudienceOptions",
          type: "chat_instance",
          company_id: session.company_id,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (Number(data.status) === 0) {
        var dataList = data.record.map((record) => ({
          ...record,
          label: `${record.instance_title} - ${record.user_number}`,
          value: record.chat_instance_id,
        }));

        setWsNumberList(dataList);
      } else {
        setWsNumberList([]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (show) {
      setLoading(true);
      Promise.all([getAllCuration(), getAllWSNumber()]);
    } else {
      setLoading(true);
    }
  }, [show]); // eslint-disable-line react-hooks/exhaustive-deps

  const getCurGrid = async (e) => {
    if (!e) {
      setLoadingSection(false);
      setRowData([]);
      setRecordFound(0);
      setIsGrid(false);
      return;
    }

    try {
      const response = await axios.get("ws/ws_curation.php", {
        params: {
          task: "listCurationContacts",
          curation: e.value,
          startRow: 0,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;

      if (Number(data.status) === 0) {
        setRowData(data.record);
        setRecordFound(data.record.length);
        setIsGrid(true);
      } else {
        setRowData([]);
        setRecordFound(0);
        setIsGrid(false);

        Swal.fire({
          icon: "error",
          title: "Failed",
          text: "No prospect for the selected curation",
        });
      }
    } catch (error) {
      console.error(error);
      Swal.fire({ icon: "error", title: error.message });
      setRowData([]);
      setRecordFound(0);
      setIsGrid(false);
    } finally {
      setLoadingSection(false);
    }
  };

  const handleToCur = () => {
    setIsCur(true);
    setIsTemplate(false);
    setIsConfirm(false);
  };

  const handleToTemplate = () => {
    if (curationData && rowData.length > 0) {
      setIsCur(false);
      setIsTemplate(true);
      setIsConfirm(false);
    } else {
      Swal.fire({
        icon: "error",
        title: "Failed",
        text: "Please choose curation or make sure there are prospect in the curation",
      });
    }
  };

  const handleToConfirm = () => {
    var allowNext = 0;
    if (templateData && wsNumberData) {
      if (templateData.params.length > 0 && Number(templateData.header) === 1) {
        if (!paramsArr.includes("") && headerImgUrl) {
          allowNext = 1;
        } else {
          allowNext = 0;
          Swal.fire({
            icon: "error",
            title: "Failed",
            text: "Please fill in all params or upload header image",
          });
        }
      } else if (templateData.params.length > 0) {
        if (!paramsArr.includes("")) {
          allowNext = 1;
        } else {
          allowNext = 0;
          Swal.fire({
            icon: "error",
            title: "Failed",
            text: "Please fill in all params",
          });
        }
      } else if (Number(templateData.header) === 1) {
        if (headerImgUrl) {
          allowNext = 1;
        } else {
          allowNext = 0;
          Swal.fire({
            icon: "error",
            title: "Failed",
            text: "Please upload header image",
          });
        }
      } else {
        allowNext = 1;
      }

      if (allowNext === 1) {
        setIsCur(false);
        setIsTemplate(false);
        setIsConfirm(true);
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Failed",
        text: "Please choose whatsapp template and whatsapp number",
      });
    }
  };

  const handleStartCampaign = async () => {
    var prospectID = rowData.map((data) => {
      return data.prospect_id;
    });

    // console.log(templateData.label);
    // console.log(paramsArr.toString().replaceAll(',', '|'));
    // console.log(changeMsg);
    // console.log(curationData.value);
    // console.log(wsNumberData.value);
    // console.log(prospectID.toString());
    // console.log(campaignTitle);
    // console.log(headerImgUrl);

    const params = new FormData();

    params.append("task", "sendBulkWACampaign");
    params.append("template_name", templateData.label);
    params.append("template_params", paramsArr.toString().replaceAll(",", "|"));
    params.append("message", whatsappMessagePreview);
    params.append("curation", curationData.value);
    params.append("instance", wsNumberData.value);
    params.append("prospect", prospectID.toString());
    params.append("campaign_title", campaignTitle);
    params.append("whatsapp_template_header_image", headerImgUrl);
    params.append("utoken", session.user_ac_token);
    params.append("ctoken", session.company_token);

    if (campaignTitle) {
      try {
        const res = await axios.post("ws/ws_whatsapp_campaign.php", params);

        let data = res.data;

        if (data) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Successfully create whatsapp campaign",
            timer: 1500,
          }).then(() => {
            onSuccess();
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Failed",
            text: "Please try again or contact your IT Support",
          });
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Failed",
        text: "Please insert campaign title",
      });
    }
  };

  const handleTestCampaign = async () => {
    if (testCampaign === "") {
      return Swal.fire({
        icon: "error",
        title: "Failed",
        text: "Please insert contact number to send test",
      });
    }

    var media_mime_type = "";
    var media_op_file_name = "";

    if (headerImgUrl) {
      const getMimeTypeFromUrl = (url) => {
        const extension = url.split("/").pop().split(".")[1];
        const mimeTypes = {
          jpg: "image/jpeg",
          jpeg: "image/jpeg",
          png: "image/png",
        };
        return mimeTypes[extension] || "application/octet-stream";
      };

      media_mime_type = getMimeTypeFromUrl(headerImgUrl);
      media_op_file_name = headerImgUrl.split("/").pop().split(".")[0];
    }

    try {
      const response = await axios.get("ws/ws_whatsapp_campaign.php", {
        params: {
          task: "testWACampaign",
          chat_instance_id: wsNumberData.chat_instance_id,
          whatsapp_template_name: templateData.label,
          whatsapp_template_params: paramsArr.toString().replaceAll(",", "|"),
          media_mime_type: media_mime_type,
          media_op_file_name: media_op_file_name,
          whatsapp_template_header_image: headerImgUrl,
          api_token: "",
          msg: whatsappMessagePreview,
          contact_num: testCampaign,
          chat_contact_id: wsNumberData.instance_id,
          prospect_id: rowData.map((data) => data.prospect_id).toString(),
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (Number(data.status) === 0) {
        Swal.fire({
          icon: "success",
          text: "Test is send Successfully",
        });
      } else if (Number(data.status) === 1) {
        Swal.fire({
          icon: "error",
          text: data.error,
        });
      } else {
        Swal.fire({
          icon: "error",
          text: "Please try again or contact your IT Support",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const setFormatMessages2 = (input) => {
    const regBold = /\*(.*?)\*/g;
    const regItalic = /_(.*?)_/g;
    const regStrikethrough = /~(.*?)~/g;
    const regCode = /```(.*?)```/g;
    // const regBreakLine = /\n/g;

    input = input.replace(/</g, "&lt;").replace(/>/g, "&gt;");

    let formattedText = input.replace(regBold, "<strong>$1</strong>").replace(regItalic, "<em>$1</em>").replace(regStrikethrough, "<s>$1</s>").replace(regCode, "<code>$1</code>");
    return formattedText;
  };

  return (
    <Offcanvas show={show} onHide={onHide} placement="bottom" style={{ left: 57, height: "100vh" }} backdrop="static">
      <Offcanvas.Header closeButton style={{ height: 50 }}>
        <Offcanvas.Title>Create Whatsapp Campaign</Offcanvas.Title>
      </Offcanvas.Header>

      <Offcanvas.Body className="p-0" style={{ overflow: "hidden" }}>
        <div className="mt-3 px-5 border-bottom" style={{ height: 70 }}>
          <div className="op steps w-100 mb-3">
            <div className="link step active py-2" style={{ pointerEvents: "none" }}>
              <div className="content">
                <div className="title text-center">Curation</div>
                <div className="description d-flex text-center text-light" style={{ fontSize: 11 }}>
                  Choose Curation
                </div>
              </div>
            </div>
            <div className={`link step ${!isConfirm && isTemplate ? "active" : isConfirm && !isTemplate ? "active" : ""} py-2`} style={{ pointerEvents: "none" }}>
              <div className="content">
                <div className="title text-center">Whatsapp Template</div>
                <div className={`description d-flex text-center ${!isConfirm && isTemplate ? "text-light" : isConfirm && !isTemplate ? "text-light" : "text-muted"}`} style={{ fontSize: 11 }}>
                  Choose Whatsapp Template
                </div>
              </div>
            </div>
            <div className={`link step ${isConfirm ? "active" : ""} py-2`} style={{ pointerEvents: "none" }}>
              <div className="content">
                <div className="title text-center">Confirmation</div>
                <div className={`description d-flex text-center ${isConfirm ? "text-light" : "text-muted"}`} style={{ fontSize: 11 }}>
                  Summary of the info
                </div>
              </div>
            </div>
          </div>
        </div>

        {loading ? (
          <div className="d-flex justify-content-center align-items-center w-100 mt-5" style={{ zIndex: 2 }}>
            <div className="rounded-circle shadow d-flex justify-content-center align-items-center" style={{ height: 30, width: 30 }}>
              <Spinner animation="border" role="status" size="sm">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          </div>
        ) : (
          <div className="d-flex h-100">
            <div className="border-end" style={{ width: "20%" }}>
              {isCur && (
                <div>
                  <div className="px-3 d-flex align-items-center justify-content-center shadow-sm" style={{ height: 50, backgroundColor: "#eeeeee" }}>
                    <InputGroup className="border rounded shadow-sm">
                      <InputGroup.Text style={{ backgroundColor: "#fff", paddingRight: 2, border: "none" }}>
                        <FontAwesomeIcon icon={faMagnifyingGlass} rotation={90} />
                      </InputGroup.Text>
                      <Form.Control placeholder="Search curation" value={curationSearch} onChange={(e) => setCurationSearch(e.target.value)} style={{ borderLeft: "none", outline: "none", paddingRight: 0, boxShadow: "none", border: "none" }} onFocus={(e) => (e.target.style.outline = "none")} />
                    </InputGroup>
                  </div>
                  <div className="w-100 overflow-auto" style={{ height: "calc(100vh - 180px)" }}>
                    <ListGroup className="mt-2 px-3">
                      {curationList.filter((opt) => opt.label.toLowerCase().includes(curationSearch.toLowerCase())).length ? (
                        <>
                          {curationList
                            .filter((opt) => opt.label.toLowerCase().includes(curationSearch.toLowerCase()))
                            .map((opt) => (
                              <ListGroup.Item
                                key={opt.value}
                                action
                                active={curationData.value === opt.value}
                                className="cursor-pointer shadow-sm mb-2 border rounded py-3 d-flex align-items-center justify-content-between"
                                onClick={async () => {
                                  if (opt.value === curationData.value) return;
                                  setLoadingSection(true);
                                  setCurationData(opt);
                                  await getCurGrid(opt);
                                }}
                              >
                                <span>{opt.label}</span>
                                <FontAwesomeIcon icon={faChevronRight} />
                              </ListGroup.Item>
                            ))}
                        </>
                      ) : (
                        <div className="text-center mt-3">No curation found</div>
                      )}
                    </ListGroup>
                  </div>
                </div>
              )}

              {isTemplate && (
                <div>
                  <div className="px-3 d-flex align-items-center justify-content-center shadow-sm" style={{ height: 100, backgroundColor: "#eeeeee" }}>
                    <div className="w-100">
                      <Form.Group className="mb-2">
                        <FormSelect
                          placeholder="Choose Whatsapp Number"
                          options={wsNumberList}
                          valueDefault={wsNumberList.filter((opt) => opt.value === wsNumberData.value)}
                          isClearable={true}
                          isSearchable={true}
                          shadow={true}
                          border={false}
                          onChange={async (e) => {
                            setLoading2(true);
                            setWsNumberData(e ? e : "");
                            await getAllWSTemplate(e ? e.chat_instance_id : "");
                          }}
                        />
                      </Form.Group>

                      <InputGroup className="border rounded shadow-sm">
                        <InputGroup.Text style={{ backgroundColor: wsNumberData ? "#fff" : "#e9ecef", paddingRight: 2, border: "none" }}>
                          <FontAwesomeIcon icon={faMagnifyingGlass} rotation={90} />
                        </InputGroup.Text>
                        <Form.Control
                          placeholder="Search template"
                          value={templateSearch}
                          onChange={(e) => setTemplateSearch(e.target.value)}
                          disabled={!wsNumberData}
                          style={{ borderLeft: "none", outline: "none", paddingRight: 0, boxShadow: "none", border: "none" }}
                          onFocus={(e) => (e.target.style.outline = "none")}
                        />
                      </InputGroup>
                    </div>
                  </div>

                  {loading2 ? (
                    <div className="d-flex justify-content-center w-100 mt-3" style={{ zIndex: 2 }}>
                      <div className="rounded-circle shadow d-flex justify-content-center align-items-center" style={{ height: 30, width: 30 }}>
                        <Spinner animation="border" role="status" size="sm">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      </div>
                    </div>
                  ) : wsNumberData ? (
                    <div className="w-100 overflow-auto" style={{ height: "calc(100vh - 240px)" }}>
                      <ListGroup className="mt-2 px-3">
                        {templateList.filter((opt) => opt.label.toLowerCase().includes(templateSearch.toLowerCase())).length ? (
                          <>
                            {templateList
                              .filter((opt) => opt.label.toLowerCase().includes(templateSearch.toLowerCase()))
                              .map((opt) => (
                                <ListGroup.Item
                                  key={opt.value}
                                  action
                                  active={templateData.value === opt.value}
                                  className="cursor-pointer shadow-sm mb-2 border rounded py-3 d-flex align-items-center justify-content-between"
                                  onClick={async () => {
                                    if (opt.value === templateData.value) return;
                                    setTemplateData(opt ? opt : "");
                                    setParamsArr(opt ? opt.params : []);
                                    setWhatsappMessagePreview(opt ? opt.message : "");
                                    setHeaderImgUrl("");
                                  }}
                                >
                                  <span>{opt.label}</span>
                                  <FontAwesomeIcon icon={faChevronRight} />
                                </ListGroup.Item>
                              ))}
                          </>
                        ) : (
                          <div className="text-center mt-3">No template found</div>
                        )}
                      </ListGroup>
                    </div>
                  ) : (
                    <div className="text-center mt-3">Please choose Whatsapp number first</div>
                  )}
                </div>
              )}

              {isConfirm && (
                <div className="p-3">
                  <Form.Group className="mb-3">
                    <Form.Label>Campaign Title</Form.Label>
                    <Form.Control
                      type="text"
                      value={campaignTitle}
                      onChange={(e) => {
                        setCampaignTitle(e.target.value);
                      }}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Test this campaign</Form.Label>
                    <Form.Control type="text" placeholder="contact number eg. 012556221" value={testCampaign} onChange={(e) => setTestCampaign(e.target.value)} />
                  </Form.Group>
                  <div>
                    <button
                      className="btn op-button op-primary-color text-light"
                      onClick={() => {
                        handleTestCampaign();
                      }}
                    >
                      Send Test
                    </button>
                  </div>
                </div>
              )}
            </div>

            {loadingSection ? (
              <div className="d-flex justify-content-center w-100 mt-5" style={{ zIndex: 2 }}>
                <div className="rounded-circle shadow d-flex justify-content-center align-items-center" style={{ height: 30, width: 30 }}>
                  <Spinner animation="border" role="status" size="sm">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
              </div>
            ) : (
              <div className="h-100" style={{ backgroundColor: "#eeeeee", width: "80%" }}>
                {isCur && (
                  <div className="p-3">
                    {curationData ? (
                      <div style={containerStyle}>
                        <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                          <AgGridReact ref={gridRef} columnDefs={reportColumn} rowData={rowData} defaultColDef={reportColDef} rowHeight={50} pagination={false} />
                        </div>
                      </div>
                    ) : (
                      <div className="text-center w-100 h-100 mt-5">You haven't choose any curation yet. Please select a curation on the left side</div>
                    )}
                  </div>
                )}

                {isTemplate && (
                  <div className="p-3">
                    {templateData ? (
                      <Card className="shadow-sm overflow-y-auto" style={{ height: "540px" }}>
                        <Card.Body>
                          <Row>
                            <Col sm={6}>
                              <h6 className="mb-3 fw-bold" style={{ fontSize: 15 }}>
                                Possible Values
                              </h6>
                              {Number(templateData.header) === 0 && templateData.params.length === 0 && <div className="h-100 mt-5 text-center">This template haven't used any variable placeholders or image header in your text</div>}
                              {Number(templateData.header) === 1 && (
                                <Form.Group className="mb-3">
                                  <div className="d-flex align-items-center mb-2">
                                    <Button variant="" className="op-primary-color text-light me-2" style={{ pointerEvents: "none" }}>
                                      Header
                                    </Button>

                                    <FormSelect
                                      valueDefault={headerType}
                                      width="200px"
                                      options={[
                                        { value: "image", label: "Upload an image" },
                                        { value: "link", label: "Insert a Link" },
                                      ]}
                                      onChange={(e) => {
                                        setHeaderType(e ? e : { value: "image", label: "Upload an image" });
                                        setHeaderFiles([]);
                                        setHeaderImgUrl("");
                                      }}
                                    />
                                  </div>

                                  {headerType.value === "image" ? (
                                    <FilePond
                                      allowMultiple={false}
                                      acceptedFileTypes={["image/*"]}
                                      files={headerFiles}
                                      maxFiles={1}
                                      credits={false}
                                      instantUpload={false}
                                      onremovefile={() => {
                                        setHeaderFiles([]);
                                        setHeaderImgUrl("");
                                      }}
                                      onupdatefiles={(fileItems) => {
                                        setHeaderFiles(fileItems.map((fileItem) => fileItem.file));
                                      }}
                                      server={{
                                        process: (fieldName, file, metadata, load, error, progress, abort) => {
                                          const formData = new FormData();
                                          const reader = new FileReader();

                                          reader.onload = (e) => {
                                            formData.append("fileToUpload", e.target.result);
                                            formData.append("utoken", session.user_ac_token);
                                            formData.append("ctoken", session.company_token);
                                            formData.append("task", "getMediaURL");
                                            formData.append("mime_type", file.type);
                                            formData.append("file_name", file.name);
                                            axios.post("ws/ws_conversation.php", formData).then((response) => {
                                              const data = response.data;
                                              if (Number(data.status) === 0) {
                                                data.media_url = data.media_url.replaceAll("cloud_dev", session.hostUrlType);
                                                setHeaderImgUrl(data.media_url.replaceAll("cloud_dev", session.hostUrlType));
                                                load(data);
                                              }
                                            });
                                          };
                                          reader.readAsDataURL(file);
                                        },
                                      }}
                                    />
                                  ) : (
                                    <Form.Control type="text" placeholder="Insert image link" onChange={(e) => setHeaderImgUrl(e.target.value)} />
                                  )}
                                </Form.Group>
                              )}

                              {templateData.params.length > 0 && (
                                <>
                                  {paramsArr.map((record, index) => (
                                    <Form.Group className="mb-3" key={index}>
                                      <div className="d-flex align-items-center mb-2">
                                        <Button variant="" className="op-primary-color text-light me-2" style={{ pointerEvents: "none" }}>
                                          Body
                                        </Button>
                                        Variable: {`{{${index + 1}}}`}
                                      </div>
                                      <Form.Control
                                        value={record}
                                        onChange={(e) => {
                                          const params_value = [...paramsArr];
                                          params_value[index] = e.target.value;

                                          let message_content = templateData.message;
                                          params_value.forEach((message, idx) => {
                                            if (message) {
                                              message_content = message_content.replace(`{{${idx + 1}}}`, message);
                                            }
                                          });

                                          setParamsArr(params_value);
                                          setWhatsappMessagePreview(message_content);
                                        }}
                                      />
                                    </Form.Group>
                                  ))}
                                </>
                              )}
                            </Col>
                            <Col>
                              <div className="border w-100 px-4 py-4">
                                <h6>Preview</h6>
                                <div className="rounded position-relative p-2" style={{ background: `url(${WSBackground}) left top repeat rgb(236, 229, 221)`, minHeight: 400 }}>
                                  <div className="ws-bubble">
                                    {Number(templateData.header) === 1 && (
                                      <>
                                        {headerImgUrl === "" ? (
                                          <div className="ws-bubble-header">
                                            <FontAwesomeIcon icon={faImageSolid} color="#fff" size="10x" />
                                          </div>
                                        ) : headerImgUrl !== "" ? (
                                          <img src={headerImgUrl} alt="header" className="w-100" />
                                        ) : null}
                                      </>
                                    )}

                                    <p className="ws-bubble-message" dangerouslySetInnerHTML={{ __html: setFormatMessages2(whatsappMessagePreview) }} />
                                    <div className="ws-bubble-time">{moment().format("HH:mm A")}</div>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    ) : (
                      // <Card className="rounded-1 shadow-sm">
                      //   <Card.Header className="text-center">
                      //     <Card.Title as={"h6"} className="mt-2">
                      //       {templateData ? templateData.label : "No template selected"}
                      //     </Card.Title>
                      //   </Card.Header>
                      //   {templateData && (
                      //     <Card.Body className="p-0" style={{ height: "400px", overflowX: "hidden", overflowY: "auto" }}>
                      //       <Row sm={12} className="p-0" style={{ height: "100%" }}>
                      //         <Col sm={6} className="border-end p-4">
                      //           <Form.Group>
                      //             <Form.Label>Message</Form.Label>
                      //           </Form.Group>
                      //           <div style={{ textAlign: "justify" }}>{templateData.value}</div>
                      //         </Col>
                      //         <Col sm={6} className="p-4">
                      //           <div>
                      //             {templateData.params.map((value, index) => (
                      //               <Form.Group key={index} className="mb-3">
                      //                 <Form.Control
                      //                   type="text"
                      //                   placeholder="params"
                      //                   value={paramsArr[index]}
                      //                   onChange={(e) => {
                      //                     paramsOnchange(index, e.target.value);
                      //                   }}
                      //                 />
                      //               </Form.Group>
                      //             ))}
                      //           </div>
                      //           {Number(templateData.header) === 1 && (
                      //             <div>
                      //               <Form.Group>
                      //                 <Form.Label>Header Image</Form.Label>
                      //               </Form.Group>
                      //               {!submitImg && (
                      //                 <FilePond
                      //                   allowMultiple={false}
                      //                   maxFiles={1}
                      //                   name="file"
                      //                   files={headerImg}
                      //                   credits={false}
                      //                   instantUpload={false}
                      //                   acceptedFileTypes={["image/jpg", "image/png", "image/jpeg"]}
                      //                   onupdatefiles={(fileItems) => {
                      //                     if (fileItems && fileItems.length) {
                      //                       if (fileItems[0].file.type === "image/jpg" || fileItems[0].file.type === "image/jpeg" || fileItems[0].file.type === "image/png") {
                      //                         var imgItem = fileItems.map((fileItem) => fileItem.file);
                      //                         setHeaderImg(imgItem);
                      //                       }
                      //                     }
                      //                   }}
                      //                   onremovefile={() => {
                      //                     setHeaderImg("");
                      //                     setHeaderImgUrl("");
                      //                   }}
                      //                   server={{
                      //                     process: (fieldName, file, metadata, load, error, progress, abort) => {
                      //                       const formData = new FormData();
                      //                       const reader = new FileReader();

                      //                       reader.onload = (e) => {
                      //                         formData.append("fileToUpload", e.target.result);
                      //                         axios
                      //                           .post("ws/ws_conversation.php", formData, {
                      //                             params: {
                      //                               task: "getMediaURL",
                      //                               mime_type: headerImg[0].type,
                      //                               file_name: headerImg[0].name,
                      //                               utoken: session.user_ac_token,
                      //                               ctoken: session.company_token,
                      //                             },
                      //                           })
                      //                           .then((response) => {
                      //                             const data = response.data;
                      //                             if (Number(data.status) === 0) {
                      //                               data.media_url = data.media_url.replaceAll("cloud_dev", session.hostUrlType);
                      //                               load(data);
                      //                               setHeaderImgUrl(data.media_url.replaceAll("cloud_dev", session.hostUrlType));
                      //                               setSubmitImg(true);
                      //                             } else {
                      //                               setSubmitImg(false);
                      //                             }
                      //                           });
                      //                       };
                      //                       reader.readAsDataURL(file);
                      //                     },
                      //                   }}
                      //                 />
                      //               )}
                      //               {submitImg && (
                      //                 <div>
                      //                   <div className="border rounded d-flex justify-content-center align-items-center" style={{ width: "100%", height: "200px" }}>
                      //                     <Image src={headerImgUrl} rounded width={"100%"} />
                      //                   </div>
                      //                   <div className="mt-2">
                      //                     <button
                      //                       type="button"
                      //                       className="btn btn-light border-1 shadow-sm float-end"
                      //                       onClick={() => {
                      //                         setSubmitImg(false);
                      //                         setHeaderImg("");
                      //                         setHeaderImgUrl("");
                      //                       }}
                      //                     >
                      //                       Change Image
                      //                     </button>
                      //                   </div>
                      //                 </div>
                      //               )}
                      //             </div>
                      //           )}
                      //         </Col>
                      //       </Row>
                      //     </Card.Body>
                      //   )}
                      // </Card>
                      <div className="text-center w-100 h-100 mt-5">You haven't choose any Whatsapp template yet. Please select a Whatsapp template on the left side</div>
                    )}
                  </div>
                )}

                {isConfirm && (
                  <div className="px-5 py-3 overflow-y-auto" style={{ height: "calc(100vh - 180px)" }}>
                    <Row>
                      <Col sm={7}>
                        <Card className="border mb-2">
                          <Card.Body className="d-flex justify-content-between align-items-center p-3">
                            <div>
                              <Form.Label className=" w-100" style={{ fontSize: 17 }}>
                                Curation Title
                              </Form.Label>
                              <div className="text-muted" style={{ fontSize: 15 }}>
                                {curationData.label}
                              </div>
                            </div>
                            <FontAwesomeIcon icon={faClipboardListCheck} size="2x" />
                          </Card.Body>
                        </Card>

                        <Card className="border mb-2">
                          <Card.Body className="d-flex justify-content-between align-items-center p-3">
                            <div>
                              <Form.Label className="w-100" style={{ fontSize: 17 }}>
                                Total Contact
                              </Form.Label>
                              <div className="text-muted" style={{ fontSize: 15 }}>
                                {rowData.length} records
                              </div>
                            </div>
                            <FontAwesomeIcon icon={faUser} size="2x" />
                          </Card.Body>
                        </Card>

                        <Card className="border mb-2">
                          <Card.Body className="p-3">
                            <Form.Label className="w-100" style={{ fontSize: 17 }}>
                              List of contact
                            </Form.Label>
                            <ListGroup className="mt-3">
                              {rowData.length > 0 &&
                                rowData.map((data, index) => (
                                  <ListGroup.Item key={index} className="mb-2 rounded border">
                                    <Form.Label className="mb-0">{data.prospect_name && data.contact_num ? `${data.prospect_name} - ${data.contact_num}` : data.prospect_name ? data.prospect_name : ``}</Form.Label>
                                  </ListGroup.Item>
                                ))}
                            </ListGroup>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col sm={5}>
                        <Card className="border mb-2">
                          <Card.Body className="d-flex justify-content-between align-items-center p-3">
                            <div>
                              <Form.Label className="w-100" style={{ fontSize: 17 }}>
                                Whatsapp Template Name
                              </Form.Label>
                              <div className="text-muted" style={{ fontSize: 15 }}>
                                {templateData.label}
                              </div>
                            </div>
                            <FontAwesomeIcon icon={faMessage} size="2x" />
                          </Card.Body>
                        </Card>

                        <Card className="border mb-2">
                          <Card.Body className="d-flex justify-content-between align-items-center p-3">
                            <div>
                              <Form.Label className="w-100" style={{ fontSize: 17 }}>
                                Whatsapp Number
                              </Form.Label>
                              <div className="text-muted" style={{ fontSize: 15 }}>
                                {wsNumberData.label}
                              </div>
                            </div>
                            <FontAwesomeIcon icon={faPhone} size="2x" />
                          </Card.Body>
                        </Card>

                        <Card className="border mb-2">
                          <Card.Body className="p-3">
                            <Form.Label className="fw-bold w-100" style={{ fontSize: 17 }}>
                              Whatsapp Message Preview
                            </Form.Label>
                            <div className="rounded position-relative p-2" style={{ background: `url(${WSBackground}) left top repeat rgb(236, 229, 221)`, minHeight: 400 }}>
                              <div className="ws-bubble">
                                {Number(templateData.header) === 1 && (
                                  <>
                                    {headerImgUrl === "" ? (
                                      <div className="ws-bubble-header">
                                        <FontAwesomeIcon icon={faImageSolid} color="#fff" size="10x" />
                                      </div>
                                    ) : headerImgUrl !== "" ? (
                                      <img src={headerImgUrl} alt="header" className="w-100" />
                                    ) : null}
                                  </>
                                )}

                                <p className="ws-bubble-message" dangerouslySetInnerHTML={{ __html: setFormatMessages2(whatsappMessagePreview) }} />
                                <div className="ws-bubble-time">{moment().format("HH:mm A")}</div>
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </Offcanvas.Body>

      <div className="offcanvas-footer" style={{ height: 60, zIndex: 100 }}>
        {isCur && (
          <Stack direction="horizontal" gap={2}>
            <button className="btn op-button op-primary-color text-light ms-auto" onClick={handleToTemplate}>
              Next
            </button>
          </Stack>
        )}
        {isTemplate && (
          <Stack direction="horizontal" gap={2}>
            <button className="btn btn-dark" onClick={handleToCur}>
              Previous
            </button>
            <button className="btn op-button op-primary-color text-light ms-auto" onClick={handleToConfirm}>
              Next
            </button>
          </Stack>
        )}
        {isConfirm && (
          <Stack direction="horizontal" gap={2}>
            <button className="btn btn-dark" onClick={handleToTemplate}>
              Previous
            </button>
            <button className="btn op-button op-primary-color text-light ms-auto" onClick={handleStartCampaign}>
              Start Campaign
            </button>
          </Stack>
        )}
      </div>
    </Offcanvas>
  );
};

export default AddWSCampaignV2;
