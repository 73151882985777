import { useState, useRef, useEffect, useCallback, forwardRef, useMemo } from "react";
// import {FormSelect, FormSelectCreatable, FormCustomField} from "../includes/FormCustom";
import FormSelect from "../includes/FormSelect";
import FormCustomField from "../includes/FormCustomField";
import FormSelectCreatable from "../includes/FormSelectCreatable";
import { useParams } from "react-router-dom";
import { Container, Row, Col, Stack, Dropdown, Card, Nav, Accordion, useAccordionButton, CloseButton, Form, ToggleButton, ButtonGroup, Modal, Spinner, Button } from "react-bootstrap";
import "../lib/scss/op-deal-details.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faEllipsis, faPlus, faChevronDown, faCircleXmark } from "@fortawesome/pro-solid-svg-icons";
import { faCircle as faCircleLight } from "@fortawesome/pro-light-svg-icons";
import {
  faChartLineDown,
  faChartLineUp,
  faEnvelopeOpenText,
  faFlag,
  faPenToSquare,
  faPhoneVolume,
  faStopwatch,
  faUser,
  faUsers,
  faSave,
  faCalendarClock,
  faCircleUser,
  faNoteSticky,
  faEnvelope,
  faCalendarDay,
  faBuilding,
  faBallot,
  faUserTie,
  faPhone,
  faListCheck,
  faEnvelopeCircleCheck,
  faPhoneArrowDownLeft,
  faPhoneArrowUpRight,
  faLink,
  faLocationCheck,
  faCircleMinus,
  faPeopleArrows,
  faCircleArrowRight,
  faBoxArchive,
  faLockKeyholeOpen,
  faEnvelopeOpen,
  faFile,
  faFileImage,
  faEnvelopesBulk,
  faFax,
  faShieldCheck,
  faDollarSign,
} from "@fortawesome/pro-duotone-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import axios from "../api/axios";
import { useAuth } from "../auth/AuthContext";
import { setCurrency, setDateTimeStr } from "../lib/js/Function";
import * as formik from "formik";
import * as yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import ReactHtmlParser from "html-react-parser";
import { setLatestTime } from "../lib/js/Function";
import moment from "moment";
import { AgGridReact } from "ag-grid-react";
import NoRecord from "../includes/NoRecord";
import { useNavigate } from "react-router-dom";
import Loader from "../includes/Loader";
import { useTranslation } from "react-i18next";
import NoteEditor from "../includes/NoteEditor";

const ProspectDetails = () => {
  const { prospect_id } = useParams();
  const init = useRef(false);
  const { session, logoutUser } = useAuth();
  const { Formik } = formik;
  const editorRef = useRef(null);
  const prosDealGridRef = useRef();
  const edmGridRef = useRef();
  const auHisGridRef = useRef();
  const { t } = useTranslation();

  const openDealGridRef = useRef();
  const wonDealGridRef = useRef();
  const inbLdGridRef = useRef();
  const lostDealGridRef = useRef();

  const [isLoading, setIsLoading] = useState(true);
  const [isAddDeal, setIsAddDeal] = useState(false);
  const [isEditProspect, setIsEditProspect] = useState(false);
  const [isAddContr, setIsAddContr] = useState(false);
  const [isFindDesignation, setIsFindDesignation] = useState(false);
  const [isActiveTab, setIsActiveTab] = useState(false);
  const [activeTab, setActiveTab] = useState("notes");
  const [defaultCsId, setDefaultCsId] = useState("");

  const [prosDetData, setProsDetData] = useState([]);
  const [analyticData, setAnalyticData] = useState({});
  const [initial, setInitial] = useState("");
  const [userList, setUserList] = useState([]);
  const [selectedContr, setSelectedContr] = useState([]);
  const [initialVal, setInitialVal] = useState({});
  const [editProsCf, setEditProsCf] = useState([]);

  const [editNoteInit, setEditNoteInit] = useState({});
  const [isEditNote, setIsEditNote] = useState(false);
  const [editActivityInit, setEditActivityInit] = useState({});
  const [isEditActivity, setIsEditActivity] = useState(false);

  const [countryData, setCountryData] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [pipeData, setPipeData] = useState([]);
  const [dsData, setDsData] = useState([]);
  const [addDealCf, setAddDealCf] = useState([]);
  const [timelineData, setTimelineData] = useState([]);
  const [callAnalytics, setCallAnalytics] = useState([]);
  const [isGenerateCall, setIsGenerateCall] = useState(false);

  const [isMain, setIsMain] = useState("");
  const [isProsTab, setIsProsTab] = useState("contact");

  const [contactPrefData, setContactPrefData] = useState({});
  const [isEditContactPref, setIsEditContactPref] = useState(false);

  const [prosDealRowData, setProsDealRowData] = useState([]);
  const [isProsDeal, setIsProsDeal] = useState(false);

  const [edmRowData, setEdmRowData] = useState([]);
  const [isEdm, setIsEdm] = useState(false);

  const [auHisRowData, setAuHisRowData] = useState([]);
  const [isAuHis, setIsAuHis] = useState(false);

  const [analyticOpen, setAnalyticOpen] = useState(false);
  const [isOpenDeal, setIsOpenDeal] = useState(false);
  const [openDealRowData, setOpenDealRowData] = useState([]);

  const [analyticWon, setAnalyticWon] = useState(false);
  const [isWonDeal, setIsWonDeal] = useState(false);
  const [wonDealRowData, setWonDealRowData] = useState([]);

  const [analyticInbLd, setAnalyticInbLd] = useState(false);
  const [isInbLd, setIsInbLd] = useState(false);
  const [inbLdRowData, setInbLdRowData] = useState([]);

  const [analyticLost, setAnalyticLost] = useState(false);
  const [isLostDeal, setIsLostDeal] = useState(false);
  const [lostDealRowData, setLostDealRowData] = useState([]);

  var prefix = [
    { label: "60", value: "60" },
    { label: "61", value: "61" },
    { label: "65", value: "65" },
  ];

  var contactPrefList = [
    { label: t("ProspectDetail_contactPrefList_label_1"), value: "1" },
    { label: t("ProspectDetail_contactPrefList_label_2"), value: "0" },
  ];

  const getprospectDet = () => {
    setIsLoading(true);

    axios
      .get("ws/ws_prospect_view.php", {
        params: {
          task: "7",
          prospect: prospect_id,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      })
      .then((res) => {
        let data = res.data;

        if (data.status === 0) {
          setProsDetData(data.record);
          setInitial(data.record.prospect_name.split("")[0]);
          var contriID = [];

          if (data.record.contributor_count > 0) {
            data.record.contributor.map((data) => {
              contriID.push(data.user_id);
            });
            setSelectedContr(contriID);
          } else {
            setSelectedContr([]);
          }
          setIsLoading(false);
          getEdm(data.record);
        } else {
          setProsDetData([]);
          setInitial("");
          setSelectedContr([]);
          setIsLoading(false);
          getEdm("");
          logoutUser();
        }
      })
      .catch((error) => {
        logoutUser();
      });
  };

  const getEditProspect = async () => {
    try {
      const res = await axios.get("ws/ws_prospect.php", {
        params: {
          task: "getProspectDetails",
          prospect: prospect_id,
          pipe: "",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      let data = res.data;

      if (data.status === 0) {
        setInitialVal({
          prosID: data.record.prospect_id,
          prosName: data.record.prospect_name,
          designationIDEdit: data.record.designation_id,
          designationTitleEdit: data.record.designation_title,
          prosOwner: data.record.prospectOwner,
          prosContact1Pref: data.record.contact1_prefix,
          prosContact1: data.record.contact1 !== "" ? data.record.contact1 : data.record.contact_count > 0 && data.record.contacts[0] ? data.record.contacts[0].contact_num : "",
          prosContact2Pref: data.record.contact2_prefix,
          prosContact2: data.record.contact2 !== "" ? data.record.contact2 : data.record.contact_count > 0 && data.record.contacts[1] ? data.record.contacts[1].contact_num : "",
          prosContact3Pref: data.record.contact3_prefix,
          prosContact3: data.record.contact3 !== "" ? data.record.contact3 : data.record.contact_count > 0 && data.record.contacts[2] ? data.record.contacts[2].contact_num : "",
          prosContact4Pref: data.record.contact4_prefix,
          prosContact4: data.record.contact4 !== "" ? data.record.contact4 : data.record.contact_count > 0 && data.record.contacts[3] ? data.record.contacts[3].contact_num : "",
          prosEmail1: data.record.email1 !== "" ? data.record.email1 : data.record.email_count > 0 && data.record.emails[0] ? data.record.emails[0].email_title : "",
          prosEmail2: data.record.email2 !== "" ? data.record.email2 : data.record.email_count > 0 && data.record.emails[1] ? data.record.emails[1].email_title : "",
          prosEmail3: data.record.email3 !== "" ? data.record.email3 : data.record.email_count > 0 && data.record.emails[2] ? data.record.emails[2].email_title : "",
          prosAddress1: data.record.address_line1 !== "" ? data.record.address_line1 : data.record.prospect_address,
          prosAddress2: data.record.address_line2,
          prosAddress3: data.record.address_line3,
          countryID: data.record.country_id,
          stateID: data.record.state_id,
          cityID: data.record.city_id,
          postcode: data.record.postal_code,
          cf_person:
            data.record.custom_field > 0
              ? data.record.custom_field_data.map((record) => ({
                  cf_id: record.cf_id,
                  name: record.cf_id,
                  fd_values: record.fd_value,
                }))
              : "",
        });

        if (data.record.custom_field > 0) {
          setEditProsCf(data.record.custom_field_data);
        } else {
          setEditProsCf([]);
        }

        setIsEditProspect(true);
      } else {
        setInitialVal({
          prosID: "",
          prosName: "",
          designationIDEdit: "",
          designationTitleEdit: "",
          prosOwner: "",
          prosContact1Pref: "",
          prosContact1: "",
          prosContact2Pref: "",
          prosContact2: "",
          prosContact3Pref: "",
          prosContact3: "",
          prosContact4Pref: "",
          prosContact4: "",
          prosEmail1: "",
          prosEmail2: "",
          prosEmail3: "",
          prosAddress1: "",
          prosAddress2: "",
          prosAddress3: "",
          countryID: "",
          stateID: "",
          cityID: "",
          postcode: "",
          cf_person: "",
        });

        setEditProsCf([]);
        setIsEditProspect(true);
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: error.message,
      });
    }
  };

  const durationList = [
    { label: "5 Mins", value: "5min" },
    { label: "10 Mins", value: "10min" },
    { label: "15 Mins", value: "15min" },
    { label: "20 Mins", value: "20min" },
    { label: "30 Mins", value: "30min" },
    { label: "35 Mins", value: "35min" },
    { label: "40 Mins", value: "40min" },
    { label: "45 Mins", value: "45min" },
    { label: "50 Mins", value: "50min" },
    { label: "55 Mins", value: "55min" },
    { label: "1 Hour", value: "60min" },
    { label: "1 Hour 30 Mins", value: "90min" },
    { label: "2 Hours", value: "120min" },
    { label: "2 Hours 30 Mins", value: "150min" },
    { label: "3 Hours", value: "180min" },
    { label: "3 Hours 30 Mins", value: "210min" },
    { label: "4 Hours", value: "240min" },
  ];

  const timings = [
    { label: "08:00", value: "08:00" },
    { label: "08:15", value: "08:15" },
    { label: "08:30", value: "08:30" },
    { label: "08:45", value: "08:45" },
    { label: "09:00", value: "09:00" },
    { label: "09:15", value: "09:15" },
    { label: "09:30", value: "09:30" },
    { label: "09:45", value: "09:45" },
    { label: "10:00", value: "10:00" },
    { label: "10:15", value: "10:15" },
    { label: "10:30", value: "10:30" },
    { label: "10:45", value: "10:45" },
    { label: "11:00", value: "11:00" },
    { label: "11:15", value: "11:15" },
    { label: "11:30", value: "11:30" },
    { label: "11:45", value: "11:45" },
    { label: "12:00", value: "12:00" },
    { label: "12:15", value: "12:15" },
    { label: "12:30", value: "12:30" },
    { label: "12:45", value: "12:45" },
    { label: "13:00", value: "13:00" },
    { label: "13:15", value: "13:15" },
    { label: "13:30", value: "13:30" },
    { label: "13:45", value: "13:45" },
    { label: "14:00", value: "14:00" },
    { label: "14:15", value: "14:15" },
    { label: "14:30", value: "14:30" },
    { label: "14:45", value: "14:45" },
    { label: "15:00", value: "15:00" },
    { label: "15:15", value: "15:15" },
    { label: "15:30", value: "15:30" },
    { label: "15:45", value: "15:45" },
    { label: "16:00", value: "16:00" },
    { label: "16:15", value: "16:15" },
    { label: "16:30", value: "16:30" },
    { label: "16:45", value: "16:45" },
    { label: "17:00", value: "17:00" },
    { label: "17:15", value: "17:15" },
    { label: "17:30", value: "17:30" },
    { label: "17:45", value: "17:45" },
    { label: "18:00", value: "18:00" },
    { label: "18:15", value: "18:15" },
    { label: "18:30", value: "18:30" },
    { label: "18:45", value: "18:45" },
    { label: "19:00", value: "19:00" },
    { label: "19:15", value: "19:15" },
    { label: "19:30", value: "19:30" },
    { label: "19:45", value: "19:45" },
    { label: "20:00", value: "20:00" },
    { label: "20:15", value: "20:15" },
    { label: "20:30", value: "20:30" },
    { label: "20:45", value: "20:45" },
    { label: "21:00", value: "21:00" },
    { label: "21:15", value: "21:15" },
    { label: "21:30", value: "21:30" },
    { label: "21:45", value: "21:45" },
    { label: "22:00", value: "22:00" },
  ];

  const noteSchema = yup.object().shape({
    prosNotes: yup.string().required(t("ProspectDetail_required_field")),
  });

  const addActivitySchema = yup.object().shape({
    activity_title: yup.string().required(t("ProspectDetail_required_field")),
    activity_type: yup.string().required(t("ProspectDetail_required_field")),
    activity_user: yup.string().required(t("ProspectDetail_required_field")),
  });

  const editNoteSchema = yup.object().shape({
    activity_note: yup.string().required(t("ProspectDetail_required_field")),
  });

  const editActivitySchema = yup.object().shape({
    activity_title: yup.string().required(t("ProspectDetail_required_field")),
    activity_type: yup.string().required(t("ProspectDetail_required_field")),
    activity_user: yup.string().required(t("ProspectDetail_required_field")),
  });

  const onchangeActionTabs = (key) => {
    setActiveTab(key);
    setIsActiveTab(false);
  };

  const RenderActionTab = useCallback(
    ({ tab, active, userListNotes, activityUser }) => {
      switch (tab) {
        case "notes":
          if (active) {
            return (
              <Formik
                validationSchema={noteSchema}
                onSubmit={sendProsNote}
                initialValues={{
                  prosNotes: "",
                  user: session.user_id,
                }}
              >
                {({ handleSubmit, handleChange, values, errors, touched }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <Card.Body className="note-notes">
                      <Form.Group className="mb-3">
                        <NoteEditor
                          value={values.prosNotes}
                          onChangeValue={(value) => {
                            handleChange("prosNotes")(value);
                          }}
                        />
                        {errors.prosNotes && touched.prosNotes && <div className="op-error-message">{errors.prosNotes}</div>}
                      </Form.Group>
                    </Card.Body>
                    <Card.Footer className="d-flex flex-row-reverse">
                      <button type="submit" className="btn op-button op-primary-color text-light">
                        {t("ProspectDetail_cardFooter_btn_submit")}
                      </button>
                    </Card.Footer>
                  </Form>
                )}
              </Formik>
            );
          } else {
            return (
              <Card.Body id="notes" onClick={setIsActiveTab} style={{ cursor: "pointer" }}>
                {t("ProspectDetail_click_take_note")}
              </Card.Body>
            );
          }
        case "activity":
          if (active) {
            return (
              <>
                <Formik
                  validationSchema={addActivitySchema}
                  onSubmit={sendAddActivity}
                  initialValues={{
                    activity_title: "",
                    activity_type: "",
                    activity_date: moment(new Date()).format("YYYY-MM-DD"),
                    activity_time: setLatestTime(timings).value,
                    activity_dur: "",
                    activity_user: session.user_id,
                    activity_notes: "",
                    activity_mad: "no",
                  }}
                >
                  {({ handleSubmit, handleChange, values, errors, touched }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                      <Card.Body id="notes_collapse">
                        <div className="d-flex mb-3">
                          <div style={{ width: "5%" }}></div>
                          <div style={{ width: "95%" }}>
                            <Form.Group>
                              <Form.Control type="text" placeholder={t("ProspectDetail_activity_activity_title_placeholder")} isInvalid={errors.activity_title && touched.activity_title} onChange={handleChange("activity_title")} />
                              {errors.activity_title && touched.activity_title && <div className="op-error-message">{errors.activity_title}</div>}
                            </Form.Group>
                          </div>
                        </div>

                        <div className="d-flex mb-3">
                          <div style={{ width: "5%" }}></div>
                          <div style={{ width: "95%" }}>
                            <Form.Group>
                              <ButtonGroup className="activity-tab" style={{ width: "100%" }}>
                                <ToggleButton variant="" type="radio" name="activity_type" id="activity_type_1" value="1" checked={values.activity_type === "1"} onChange={() => handleChange("activity_type")("1")}>
                                  <FontAwesomeIcon icon={faPhoneVolume} className="me-1" />
                                  {t("ProspectDetail_activity_toggleButton_1")}
                                </ToggleButton>
                                <ToggleButton variant="" type="radio" name="activity_type" id="activity_type_2" value="2" checked={values.activity_type === "2"} onChange={() => handleChange("activity_type")("2")}>
                                  <FontAwesomeIcon icon={faUsers} className="me-1" />
                                  {t("ProspectDetail_activity_toggleButton_2")}
                                </ToggleButton>
                                <ToggleButton variant="" type="radio" name="activity_type" id="activity_type_3" value="3" checked={values.activity_type === "3"} onChange={() => handleChange("activity_type")("3")}>
                                  <FontAwesomeIcon icon={faStopwatch} className="me-1" />
                                  {t("ProspectDetail_activity_toggleButton_3")}
                                </ToggleButton>
                                <ToggleButton variant="" type="radio" name="activity_type" id="activity_type_4" value="4" checked={values.activity_type === "4"} onChange={() => handleChange("activity_type")("4")}>
                                  <FontAwesomeIcon icon={faFlag} className="me-1" />
                                  {t("ProspectDetail_activity_toggleButton_4")}
                                </ToggleButton>
                                <ToggleButton variant="" type="radio" name="activity_type" id="activity_type_5" value="5" checked={values.activity_type === "5"} onChange={() => handleChange("activity_type")("5")}>
                                  <FontAwesomeIcon icon={faEnvelopeOpenText} className="me-1" />
                                  {t("ProspectDetail_activity_toggleButton_5")}
                                </ToggleButton>
                              </ButtonGroup>
                              {errors.activity_type && touched.activity_type && <div className="op-error-message">{errors.activity_type}</div>}
                            </Form.Group>
                          </div>
                        </div>

                        <div className="d-flex mb-3">
                          <div className="d-flex justify-content-center align-items-center" style={{ width: "5%" }}>
                            <FontAwesomeIcon icon={faCalendarClock} size="lg" />
                          </div>
                          <div style={{ width: "95%" }}>
                            <Stack direction="horizontal" gap={1}>
                              <Row style={{ width: "100%" }}>
                                <Col sm={4}>
                                  <Form.Group>
                                    <Form.Control type="date" value={values.activity_date} onChange={handleChange("activity_date")} />
                                  </Form.Group>
                                </Col>
                                <Col sm={4}>
                                  <Form.Group>
                                    <FormSelect placeholder={t("ProspectDetail_activity_time_placeholder")} options={timings} valueDefault={timings.filter((option) => option.value === values.activity_time)} onChange={(e) => handleChange("activity_time")(e.value)} />
                                  </Form.Group>
                                </Col>
                                <Col sm={4}>
                                  <Form.Group>
                                    <FormSelect placeholder={t("ProspectDetail_activity_duration_placeholder")} options={durationList} onChange={(e) => handleChange("activity_dur")(e.value)} />
                                  </Form.Group>
                                </Col>
                              </Row>
                            </Stack>
                          </div>
                        </div>

                        <div className="d-flex mb-3">
                          <div className="d-flex justify-content-center align-items-center" style={{ width: "5%" }}>
                            <FontAwesomeIcon icon={faCircleUser} size="lg" />
                          </div>

                          <div style={{ width: "95%" }}>
                            <Form.Group>
                              <FormSelect
                                placeholder={t("ProspectDetail_activity_user_placeholder")}
                                options={activityUser}
                                valueDefault={activityUser.filter((option) => option.value === values.activity_user)}
                                isInvalid={errors.activity_user && touched.activity_user}
                                isClearable={true}
                                isSearchable={true}
                                onChange={(e) => {
                                  handleChange("activity_user")(e ? e.value : "");
                                }}
                              />
                              {errors.activity_user && touched.activity_user && <div className="op-error-message">{errors.activity_user}</div>}
                            </Form.Group>
                          </div>
                        </div>

                        <div className="d-flex mb-3">
                          <div className="d-flex justify-content-center" style={{ width: "5%" }}>
                            <FontAwesomeIcon icon={faNoteSticky} size="lg" className="mt-2" />
                          </div>
                          <div style={{ width: "95%" }}>
                            <Form.Group className="activity-notes">
                              <NoteEditor
                                value={values.activity_notes}
                                onChangeValue={(value) => {
                                  handleChange("activity_notes")(value);
                                }}
                              />
                            </Form.Group>
                          </div>
                        </div>
                      </Card.Body>
                      <Card.Footer className="d-flex flex-row-reverse align-items-center">
                        <button type="submit" className="btn op-button op-primary-color text-light">
                          {t("ProspectDetail_cardFooter_btn_submit")}
                        </button>
                        <Form.Check
                          type="switch"
                          label={t("ProspectDetail_activity_mad_label")}
                          className="d-flex justify-content-center align-items-center me-3"
                          onChange={(e) => {
                            if (e.target.checked === true) {
                              handleChange("activity_mad")("yes");
                            } else {
                              handleChange("activity_mad")("no");
                            }
                          }}
                        />
                      </Card.Footer>
                    </Form>
                  )}
                </Formik>
              </>
            );
          } else {
            return (
              <Card.Body id="notes" onClick={setIsActiveTab} style={{ cursor: "pointer" }}>
                {t("ProspectDetail_click_take_note")}
              </Card.Body>
            );
          }
        default:
          return null;
      }
    },
    [editorRef.current, isActiveTab]
  );

  const sendProsNote = async (values) => {
    const noteData = new FormData();

    noteData.append("task", "addProspectOrgNotes");
    noteData.append("user", values.user);
    noteData.append("content", values.prosNotes);
    noteData.append("prospectid", prospect_id);
    noteData.append("type", "prospect");
    noteData.append("utoken", session.user_ac_token);
    noteData.append("ctoken", session.company_token);

    try {
      const res = await axios.post("ws/ws_post_note.php", noteData);

      let data = res.data;

      if (data) {
        Swal.fire({
          icon: "success",
          title: t("ProspectDetail_alert_success_title"),
          text: t("ProspectDetail_alert_success_add_note_text"),
          timer: 1500,
        }).then((result) => {
          setIsActiveTab(false);
          getProsTimeline();
        });
      } else {
        Swal.fire({
          icon: "error",
          title: t("ProspectDetail_alert_failed_title"),
          text: t("ProspectDetail_alert_failed_text"),
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: error.message,
      });
    }
  };

  const sendAddActivity = (values) => {
    axios
      .get("ws/ws_deal.php", {
        params: {
          task: 2,
          create_user: session.user_id,
          dated: values.activity_date,
          timed: values.activity_time,
          duration: values.activity_dur,
          title: values.activity_title,
          content: values.activity_notes,
          mad: values.activity_mad,
          assign_user: values.activity_user,
          dat: values.activity_type,
          deal: "",
          organization: "",
          people: prospect_id,
        },
      })
      .then((res) => {
        let data = res.data;

        if (data.status === 0) {
          Swal.fire({
            icon: "success",
            title: t("ProspectDetail_alert_success_title"),
            text: t("ProspectDetail_alert_success_add_activity_text"),
            timer: 1500,
          }).then((result) => {
            setIsActiveTab(false);
            getProsTimeline();
          });
        } else {
          Swal.fire({
            icon: "error",
            title: t("ProspectDetail_alert_failed_title"),
            text: t("ProspectDetail_alert_failed_text"),
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: error.message,
        });
      });
  };

  const sendMarkAsDone = (record) => {
    if (record.record_activity_type_id === 6 || record.record_activity_type_id === 7) {
      Swal.fire({
        icon: "error",
        title: t("ProspectDetail_alert_error_mad_title"),
        text: t("ProspectDetail_alert_error_mad_text"),
      });
    } else {
      var mark = record.record_activity_mad === "yes" ? "no" : "yes";

      axios
        .get("ws/ws_deal.php", {
          params: {
            task: "16",
            activity: record.record_activity_id,
            status: mark,
            user: session.user_id,
          },
        })
        .then((res) => {
          let data = res.data;

          if (data.status === 0) {
            setTimelineData((prev) => {
              return prev.map((info) => {
                if (info.record_activity_id === record.record_activity_id) {
                  return {
                    ...info,
                    record_activity_mad: mark,
                  };
                }
                return info;
              });
            });
          } else {
            Swal.fire({
              icon: "error",
              title: t("ProspectDetail_alert_failed_title"),
              text: t("ProspectDetail_alert_failed_title"),
            });
          }
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: error.message,
          });
        });
    }
  };

  const RenderTimelineAction = ({ record }) => {
    const customToggle = forwardRef(({ onClick }, ref) => {
      const openDropdown = (e) => {
        e.preventDefault();
        onClick(e);
      };

      return (
        <Button ref={ref} variant="light" onClick={openDropdown}>
          <FontAwesomeIcon icon={faEllipsis} size="lg" />
        </Button>
      );
    });

    return (
      <Dropdown size={100}>
        <Dropdown.Toggle as={customToggle} />
        <Dropdown.Menu className="shadow-sm border-none animate slideIn" align="end" size={100} style={{ right: 0, top: 30 }}>
          {(record.record_type === "Activity" || record.record_type === "CustomActivity") && record.record_activity_type !== "whatsapp" && (
            <Dropdown.Item
              onClick={() => {
                editActivityModal(record);
              }}
            >
              {t("ProspectDetail_accordian_timeline_action_edit_activity")}
            </Dropdown.Item>
          )}
          {(record.record_type === "Activity" || record.record_type === "CustomActivity") && record.record_activity_type !== "whatsapp" && (
            <Dropdown.Item
              onClick={() => {
                sendDeleteActivity(record);
              }}
            >
              {t("ProspectDetail_accordian_timeline_action_delete_activity")}
            </Dropdown.Item>
          )}
          {(record.record_type === "Note_Prospect" || record.record_type === "Note") && (
            <Dropdown.Item
              onClick={() => {
                editNoteModal(record);
              }}
            >
              {t("ProspectDetail_accordian_timeline_action_edit_note")}
            </Dropdown.Item>
          )}
          {(record.record_type === "Note_Prospect" || record.record_type === "Note") && Number(session.readwrite_permission) === 0 && (
            <Dropdown.Item
              onClick={() => {
                sendDeleteNote(record);
              }}
            >
              {t("ProspectDetail_accordian_timeline_action_delete_note")}
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const editActivityModal = (rec) => {
    axios
      .get("ws/ws_deal.php", {
        params: {
          task: 18,
          activity: rec.record_activity_id,
        },
      })
      .then((res) => {
        let data = res.data;

        if (data.status === 0) {
          var timeOri = data.record.activity_timed.split(":");
          var timeNew = `${timeOri[0]}:${timeOri[1]}`;

          const editData = {
            activity_dealid: data.record.deal_id,
            activity_id: data.record.activity_id,
            activity_title: data.record.activity_title,
            activity_type: data.record.dat_id,
            activity_date: data.record.activity_dated,
            activity_time: timeNew,
            activity_dur: data.record.activity_duration,
            activity_user: data.record.assign_user_id,
            activity_note: data.record.activity_content,
            activity_mad: data.record.activity_mark_as_done,
          };

          setEditActivityInit(editData);
          setIsEditActivity(true);
        } else {
          Swal.fire({
            icon: "error",
            title: t("ProspectDetail_alert_failed_title"),
            text: t("ProspectDetail_alert_failed_text"),
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: error.message,
        });
      });
  };

  const sendEditActivity = async (values) => {
    try {
      const res = await axios.get("ws/ws_deal.php", {
        params: {
          task: 11,
          user: session.user_id,
          dt: values.activity_date,
        },
      });

      let data = res.data;

      if (data.status === 0) {
        axios
          .get("ws/ws_deal.php", {
            params: {
              task: 12,
              create_user: session.user_id,
              dated: values.activity_date,
              timed: values.activity_time,
              duration: values.activity_dur,
              title: values.activity_title,
              content: values.activity_note,
              mad: values.activity_mad,
              dat: values.activity_type,
              ca_id: 0,
              assign_user: values.activity_user,
              aid: values.activity_id,
            },
          })
          .then((response) => {
            let rec = response.data;

            if (rec.status === 0) {
              Swal.fire({
                icon: "success",
                title: t("ProspectDetail_alert_success_title"),
                text: t("ProspectDetail_alert_success_edit_activity_text"),
                timer: 1500,
              }).then((result) => {
                setIsEditActivity(false);
                getProsTimeline();
              });
            } else {
              Swal.fire({
                icon: "error",
                title: t("ProspectDetail_alert_failed_title"),
                text: t("ProspectDetail_alert_failed_text"),
              });
            }
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: error.message,
            });
          });
      } else {
        Swal.fire({
          icon: "error",
          title: t("ProspectDetail_alert_failed_title"),
          text: t("ProspectDetail_alert_failed_text"),
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: error.message,
      });
    }
  };

  const editNoteModal = (rec) => {
    var type;
    var dealid;
    if (rec.record_type === "Note") {
      type = "deal";
      dealid = rec.deal_id;
    } else {
      type = "prospect";
      dealid = "";
    }

    axios
      .get("ws/ws_deal.php", {
        params: {
          task: 19,
          note: rec.record_activity_id,
          type: type,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      })
      .then((res) => {
        let data = res.data;

        if (data.status === 0) {
          const editData = {
            activity_dealid: dealid,
            activity_type: type,
            activity_id: rec.record_activity_id,
            activity_note: data.record.note_content,
          };

          setEditNoteInit(editData);
          setIsEditNote(true);
        } else {
          Swal.fire({
            icon: "error",
            title: t("ProspectDetail_alert_failed_title"),
            text: t("ProspectDetail_alert_failed_text"),
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: error.message,
        });
      });
  };

  const sendEditNote = async (values) => {
    var editNoteData = new FormData();

    if (values.activity_type === "prospect") {
      editNoteData.append("task", "editProspectOrgNotes");
      editNoteData.append("source", values.activity_type);
      editNoteData.append("user", session.user_id);
      editNoteData.append("content", values.activity_note);
      editNoteData.append("note", values.activity_id);
      editNoteData.append("utoken", session.user_ac_token);
      editNoteData.append("ctoken", session.company_token);
    } else {
      editNoteData.append("task", "editNotes");
      editNoteData.append("note", values.activity_id);
      editNoteData.append("deal", values.activity_dealid);
      editNoteData.append("content", values.activity_note);
      editNoteData.append("utoken", session.user_ac_token);
      editNoteData.append("ctoken", session.company_token);
    }

    try {
      const res = await axios.post("ws/ws_post_note.php", editNoteData);

      let data = res.data;

      if (data.status === 0) {
        Swal.fire({
          icon: "success",
          title: t("ProspectDetail_alert_success_title"),
          text: t("ProspectDetail_alert_success_edit_note_text"),
          timer: 1500,
        }).then((result) => {
          setIsEditNote(false);
          getProsTimeline();
        });
      } else {
        Swal.fire({
          icon: "error",
          title: t("ProspectDetail_alert_failed_title"),
          text: t("ProspectDetail_alert_failed_text"),
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: error.message,
      });
    }
  };

  const sendDeleteActivity = (rec) => {
    Swal.fire({
      icon: "warning",
      title: t("ProspectDetail_alert_warning_title"),
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("ProspectDetail_alert_warning_confirm_1"),
      cancelButtonText: t("ProspectDetail_alert_warning_cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .get("ws/ws_deal.php", {
            params: {
              task: 23,
              activity: rec.record_activity_id,
            },
          })
          .then((res) => {
            let data = res.data;

            if (data.status === 0) {
              Swal.fire({
                icon: "success",
                title: t("ProspectDetail_alert_success_title"),
                text: t("ProspectDetail_alert_success_delete_activity_text"),
                timer: 1500,
              }).then((result) => {
                getProsTimeline();
              });
            } else {
              Swal.fire({
                icon: "error",
                title: t("ProspectDetail_alert_failed_title"),
                text: t("ProspectDetail_alert_failed_text"),
              });
            }
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: error.message,
            });
          });
      }
    });
  };

  const sendDeleteNote = (rec) => {
    var paramsData;

    if (rec.record_type === "Note") {
      paramsData = {
        task: 24,
        note: rec.record_activity_id,
        prospectNote: 0,
        utoken: session.user_ac_token,
        ctoken: session.company_token,
      };
    } else if (rec.record_type === "Note_Prospect") {
      paramsData = {
        task: 24,
        note: rec.record_activity_id,
        type: "prospect",
        utoken: session.user_ac_token,
        ctoken: session.company_token,
      };
    }

    Swal.fire({
      icon: "warning",
      title: t("ProspectDetail_alert_warning_title"),
      text: t("ProspectDetail_alert_warning_text_1"),
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("ProspectDetail_alert_warning_confirm_1"),
      cancelButtonText: t("ProspectDetail_alert_warning_cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .get("ws/ws_deal.php", {
            params: paramsData,
          })
          .then((res) => {
            let data = res.data;

            if (data.status === 0) {
              Swal.fire({
                icon: "success",
                title: t("ProspectDetail_alert_success_title"),
                text: t("ProspectDetail_alert_success_delete_note_text"),
                timer: 1500,
              }).then((result) => {
                getProsTimeline();
              });
            } else {
              Swal.fire({
                icon: "error",
                title: t("ProspectDetail_alert_failed_title"),
                text: t("ProspectDetail_alert_failed_text"),
              });
            }
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: error.message,
            });
          });
      }
    });
  };

  const setFilename = (x) => {
    var str = x.toString();
    var strSplit = str.split("/");
    var index = strSplit.length - 1;
    var output = strSplit[index];
    return output;
  };

  const setTimelineDateTime = (record) => {
    var icon = "";
    var content = "";

    if (record.record_type === "Note_Prospect" || record.record_type === "Note") {
      icon = faUser;
      content = record.CREATE_USER;
    } else if (record.record_type === "Activity" || record.record_type === "CustomActivity") {
      icon = faUser;
      content = record.ASSIGN_USER;
    } else if (record.record_type === "Activity" || record.record_type === "CustomActivity") {
      icon = faBuilding;
      content = "";
    } else if (record.record_type === "Email" && record.record_comment !== "") {
      icon = faEnvelopeOpen;
      content = record.record_comment;
    } else if (record.record_type === "Deal") {
      icon = faDollarSign;
      content = (
        <div
          onClick={() => {
            window.open(`${session.origin}/deal/${record.deal_id}`, "_blank");
          }}
          style={{ cursor: "pointer" }}
        >
          {record.deal_title}
        </div>
      );
    }

    return (
      <Stack direction="horizontal" gap={3}>
        <div>{setDateTimeStr(record.record_date_time)}</div>
        <div className="d-flex">
          {icon ? <FontAwesomeIcon icon={icon} size="lg" className="me-2" /> : ""}
          {content}
        </div>
      </Stack>
    );
  };

  const RenderTimelineIcon = ({ record }) => {
    if (record.record_type === "Activity") {
      if (Number(record.record_activity_type_id) === 1) {
        return <FontAwesomeIcon icon={faPhone} />;
      } else if (Number(record.record_activity_type_id) === 2) {
        return <FontAwesomeIcon icon={faUsers} />;
      } else if (Number(record.record_activity_type_id) === 3) {
        return <FontAwesomeIcon icon={faListCheck} />;
      } else if (Number(record.record_activity_type_id) === 4) {
        return <FontAwesomeIcon icon={faFlag} />;
      } else if (Number(record.record_activity_type_id) === 5) {
        return <FontAwesomeIcon icon={faEnvelopeCircleCheck} />;
      } else if (Number(record.record_activity_type_id) === 6) {
        return <FontAwesomeIcon icon={faPhoneArrowDownLeft} />;
      } else if (Number(record.record_activity_type_id) === 7) {
        return <FontAwesomeIcon icon={faPhoneArrowUpRight} />;
      } else if (Number(record.record_activity_type_id) === 8) {
        return <FontAwesomeIcon icon={faWhatsapp} />;
      } else if (Number(record.record_activity_type_id) === 9) {
        return <FontAwesomeIcon icon={faLink} />;
      } else if (Number(record.record_activity_type_id) === 10) {
        return <FontAwesomeIcon icon={faLocationCheck} />;
      } else if (Number(record.record_activity_type_id) === 11) {
        return <FontAwesomeIcon icon={faCircleMinus} />;
      }
    } else if (record.record_type === "CustomActivity") {
      return <FontAwesomeIcon icon={faListCheck} />;
    } else if (record.record_type === "NoEditActivity" || record.record_type === "Activity1") {
      if (Number(record.record_activity_type_id) === 1) {
        return <FontAwesomeIcon icon={faPhone} />;
      } else if (Number(record.record_activity_type_id) === 2) {
        return <FontAwesomeIcon icon={faUsers} />;
      } else if (Number(record.record_activity_type_id) === 3) {
        return <FontAwesomeIcon icon={faListCheck} />;
      } else if (Number(record.record_activity_type_id) === 4) {
        return <FontAwesomeIcon icon={faFlag} />;
      } else if (Number(record.record_activity_type_id) === 5) {
        return <FontAwesomeIcon icon={faEnvelopeCircleCheck} />;
      } else if (Number(record.record_activity_type_id) === 6) {
        return <FontAwesomeIcon icon={faPhoneArrowDownLeft} />;
      } else if (Number(record.record_activity_type_id) === 7) {
        return <FontAwesomeIcon icon={faPhoneArrowUpRight} />;
      } else if (Number(record.record_activity_type_id) === 8) {
        return <FontAwesomeIcon icon={faWhatsapp} />;
      } else if (Number(record.record_activity_type_id) === 9) {
        return <FontAwesomeIcon icon={faLink} />;
      } else if (Number(record.record_activity_type_id) === 10) {
        return <FontAwesomeIcon icon={faLocationCheck} />;
      } else if (Number(record.record_activity_type_id) === 11) {
        return <FontAwesomeIcon icon={faCircleMinus} />;
      }
    } else if (record.record_type === "File") {
      return <FontAwesomeIcon icon={faFile} />;
    } else if (record.record_type === "Ownership") {
      return <FontAwesomeIcon icon={faPeopleArrows} />;
    } else if (record.record_type === "Status") {
      return <FontAwesomeIcon icon={faCircleArrowRight} />;
    } else if (record.record_type === "Archieve") {
      return <FontAwesomeIcon icon={faBoxArchive} />;
    } else if (record.record_type === "Reopen") {
      return <FontAwesomeIcon icon={faLockKeyholeOpen} />;
    } else if (record.record_type === "Email") {
      if (record.record_comment) {
        return <FontAwesomeIcon icon={faEnvelope} />;
      } else {
        return <FontAwesomeIcon icon={faEnvelopeOpen} />;
      }
    } else if (record.record_type === "Note_Prospect") {
      return <FontAwesomeIcon icon={faNoteSticky} />;
    } else if (record.record_type === "Note") {
      return <FontAwesomeIcon icon={faNoteSticky} />;
    } else if (record.record_type === "Deal") {
      return <FontAwesomeIcon icon={faSave} />;
    } else {
      return "";
    }
  };

  const RenderTimelineHeader = ({ record }) => {
    let element = "";
    let location = "";

    switch (record.record_type) {
      case "Ownership":
        element = `${t("ProspectDetail_accordian_timeline_header_ownership")} ${record.ASSIGN_USER}`;
        break;
      case "Status":
      case "Archieve":
      case "Reopen":
        element = record.record_description;
        break;
      case "File":
        return <RenderFile url={`${session.hostUrlApi}/${session.hostUrlApiType}/${record.record_file_url}`} title={`${record.record_title}.${record.record_file_ext}`} />;
      case "Activity":
      case "CustomActivity":
      case "NoEditActivity":
        element = `${record.record_type === "NoEditActivity" ? "" : record.record_activity_type + " - "}${record.record_title}`;
        if (record.record_comment !== "," && record.record_comment) {
          location = `<a href="http://www.google.com/maps/place/${record.record_comment}" target="_blank" class="btn btn-light shadow-sm"><FontAwesomeIcon icon={faMapLocation} size="lg" /></a>`;
        }
        break;
      case "Note_Prospect":
        element = "Note";
        element = ReactHtmlParser(element);
        return (
          <div style={{ overflow: "auto", width: 700 }}>
            <div dangerouslySetInnerHTML={{ __html: element }}></div>
          </div>
        );
        break;
      case "Note":
        element = "Note";
        element = ReactHtmlParser(element);
        return (
          <div style={{ overflow: "auto", width: 700 }}>
            <div dangerouslySetInnerHTML={{ __html: element }}></div>
          </div>
        );
        break;
      case "Deal":
        element = `${record.deal_title} - ${t("ProspectDetail_accordian_timeline_header_deal")}`;
        element = ReactHtmlParser(element);
        return (
          <div style={{ overflow: "auto", width: 700 }}>
            <div dangerouslySetInnerHTML={{ __html: element }}></div>
          </div>
        );
        break;
      case "Email":
        element = record.record_title;
        break;
      default:
        return null;
    }

    return <div dangerouslySetInnerHTML={{ __html: element + location }} />;
  };

  const RenderTimelineDescription = ({ record }) => {
    const { record_type, record_description, record_comment, record_activity_type, record_file_url, record_activity_id } = record;

    if (record_type === "Activity" || record_type === "CustomActivity" || record_type === "NoEditActivity" || record_type === "Activity1") {
      if (record_activity_type === "Call" && record_file_url) {
        var isLabel = callAnalytics.find((record) => record.record_activity_id === record_activity_id);
        return (
          <div style={{ padding: "10px 0" }}>
            <RenderAudio url={record_file_url} />

            {!isLabel ? (
              <Button variant="link" style={{ textDecoration: "none" }} onClick={() => sendCallAnalytics(record_file_url, record_activity_id)}>
                {isGenerateCall ? (
                  <div>
                    <Spinner animation="border" size="sm" className="me-1" /> Generating Call Analytics...
                  </div>
                ) : (
                  <div>
                    <FontAwesomeIcon icon={faChartLineUp} size="lg" className="me-1" /> Generate Call Analytics
                  </div>
                )}
              </Button>
            ) : (
              <div className="mt-2">
                {isLabel["Customer Persona"] && (
                  <div className="op label mb-2">
                    {t("ProspectDetail_accordian_timeline_desc_cust_persona")} : {isLabel["Customer Persona"]}
                  </div>
                )}
                {isLabel["Purpose of Call"] && (
                  <div className="op label mb-2">
                    {t("ProspectDetail_accordian_timeline_desc_pur_call")} : {isLabel["Purpose of Call"]}
                  </div>
                )}
                {isLabel["Type of Project"] && (
                  <div className="op label mb-2">
                    {t("ProspectDetail_accordian_timeline_desc_typeof_proj")} : {isLabel["Type of Project"]}
                  </div>
                )}
                {isLabel["Interested Property Type"] && (
                  <div className="op label mb-2">
                    {t("ProspectDetail_accordian_timeline_desc_intr_prop_type")} : {isLabel["Interested Property Type"]}
                  </div>
                )}
                {isLabel["Requested Info"] && (
                  <div className="op label mb-2">
                    {t("ProspectDetail_accordian_timeline_desc_req_info")} : {isLabel["Requested Info"]}
                  </div>
                )}
                {isLabel["Call Sentiment"] && (
                  <div className="op label mb-2">
                    {t("ProspectDetail_accordian_timeline_desc_call_sentiment")} : {isLabel["Call Sentiment"]}
                  </div>
                )}
                {isLabel["Appointment Availability"] && (
                  <div className="op label mb-2">
                    {t("ProspectDetail_accordian_timeline_desc_app_ava")} : {isLabel["Appointment Availability"]}
                  </div>
                )}
              </div>
            )}
          </div>
        );
      } else {
        return ReactHtmlParser(record_description); //<div dangerouslySetInnerHTML={{ __html: ReactHtmlParser(record_description) }} />;
      }
    } else if (record_type === "Archieve") {
      return <div dangerouslySetInnerHTML={{ __html: ReactHtmlParser(record_comment) }} />;
    } else if (record_type === "Email") {
      return <div dangerouslySetInnerHTML={{ __html: ReactHtmlParser(record_description) }} />;
    } else if (record_type === "Note_Prospect") {
      return ReactHtmlParser(record_description); //<div dangerouslySetInnerHTML={{ __html: ReactHtmlParser(record_description) }} />;
    } else if (record_type === "Note") {
      return ReactHtmlParser(record_description);
    } else {
      return "";
    }
  };

  const RenderTimelineDocument = ({ record }) => {
    if (record.record_file_ext === "image" && record.record_file_url !== "") {
      return <RenderImage url={record.record_file_url} title={setFilename(record.record_file_url)} />;
    } else if (record.record_file_ext === "voice" && record.record_file_url !== "") {
      return <RenderAudio url={record.record_file_url} />;
    } else if (record.record_file_ext === "document" && record.record_file_url !== "") {
      return <RenderFile url={record.record_file_url} title={setFilename(record.record_file_url)} />;
    } else {
      return "";
    }
  };

  const RenderFile = ({ url = "", title = "" }) => {
    return (
      <a className="btn btn-light d-flex align-items-center shadow-sm border mb-3 p-3" href={url} target="_blank">
        <FontAwesomeIcon icon={faFile} size="xl" className="me-2" />
        {title}
      </a>
    );
  };

  const RenderImage = ({ url = "", title = "" }) => {
    return (
      <a className="btn btn-light d-flex align-items-center shadow-sm border mb-3 p-3" href={url} target="_blank">
        <FontAwesomeIcon icon={faFileImage} size="xl" className="me-2" />
        {title}
      </a>
    );
  };

  const RenderAudio = ({ url = "" }) => {
    return (
      <audio controls className="w-100">
        <source src={url} type="audio/ogg" />
      </audio>
    );
  };

  const sendCallAnalytics = (url, record_activity_id) => {
    if (isGenerateCall) {
      Swal.fire({
        icon: "warning",
        title: t("ProspectDetail_alert_warning_title_callAnalytic"),
        text: t("ProspectDetail_alert_warning_text_callAnalytic"),
        timer: 2000,
      });
      return;
    }

    setIsGenerateCall(true);
    axios
      .get("https://app.outperformhq.io/cloud_staging/ws/ws_sentiment_analysis.php", {
        params: {
          task: "glomac_call_insight",
          url: encodeURIComponent(url),
        },
      })
      .then((response) => {
        var data = response.data;
        const labels = data.response.split("--").filter(Boolean);
        const resultObject = {};
        labels.forEach((section) => {
          const keyValuePairs = section.split(":").map((item) => item.trim());
          const key = keyValuePairs[0];
          const value = keyValuePairs[1];
          resultObject[key] = value;
        });

        resultObject.record_activity_id = record_activity_id;
        setCallAnalytics((prevCallAnalytics) => [...prevCallAnalytics, { ...resultObject }]);
        setIsGenerateCall(false);
      });
  };

  const getProsAnalytic = () => {
    axios
      .get("ws/ws_prospect.php", {
        params: {
          task: "21",
          prospect: prospect_id,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      })
      .then((res) => {
        let data = res.data;

        setAnalyticData(data);

        if (Number(data.total_open) > 0) {
          setIsOpenDeal(true);
          setOpenDealRowData(data.record_open);
        } else {
          setIsOpenDeal(false);
          setOpenDealRowData([]);
        }

        if (Number(data.total_won) > 0) {
          setIsWonDeal(true);
          setWonDealRowData(data.record_won);
        } else {
          setIsWonDeal(false);
          setWonDealRowData([]);
        }

        if (Number(data.total_inbound) > 0) {
          setIsInbLd(true);
          setInbLdRowData(data.record_inbound);
        } else {
          setIsInbLd(false);
          setInbLdRowData([]);
        }
      })
      .catch((error) => {
        setIsOpenDeal(false);
        setOpenDealRowData([]);
        setIsWonDeal(false);
        setWonDealRowData([]);
        setIsInbLd(false);
        setInbLdRowData([]);
      });
  };

  const getAllUSer = () => {
    axios
      .get("ws/ws_user.php", {
        params: {
          task: "4b",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      })
      .then((res) => {
        let data = res.data;

        if (data.status === 0) {
          var userData = data.record.map((data) => {
            return { label: data.user_name, value: data.user_id };
          });

          setUserList(userData);
        } else {
          setUserList([]);
        }
      });
  };

  const getAllPipeline = () => {
    axios
      .get("ws/ws_setting.php", {
        params: {
          task: "getAllPipeline",
          company: session.company_id,
        },
      })
      .then((res) => {
        let data = res.data;

        if (data.status === 0) {
          var pipeData = data.record.map((data) => {
            return { label: data.pipeline_title, value: data.pipeline_id };
          });

          setPipeData(pipeData);
        } else {
          setPipeData([]);
        }
      });
  };

  const getAllSource = () => {
    axios
      .get("ws/ws_source.php", {
        params: {
          task: "2",
          disp: "show",
          company: session.company_id,
        },
      })
      .then((res) => {
        let data = res.data;

        if (data.status === 0) {
          var sourceData = data.record.map((data) => {
            return { label: data.ds_title, value: data.ds_id };
          });

          setDsData(sourceData);
        } else {
          setDsData([]);
        }
      });
  };

  const getCountry = async () => {
    axios
      .get("ws/ws_listplace.php", {
        params: {
          task: "listCountry",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      })
      .then((response) => {
        const data = response.data;
        const options = data.record.map((record) => ({
          label: record.country_title,
          value: record.country_id,
        }));
        setCountryData(options);
      });
  };

  const getState = async () => {
    axios
      .get("ws/ws_listplace.php", {
        params: {
          task: "listState",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      })
      .then((response) => {
        const data = response.data;
        const options = data.record.map((record) => ({
          label: record.state_title,
          value: record.state_id,
          country: record.country_id,
        }));
        setStateData(options);
      });
  };

  const getCity = async () => {
    axios
      .get("ws/ws_listplace.php", {
        params: {
          task: "listCity",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      })
      .then((response) => {
        const data = response.data;
        const options = data.record.map((record) => ({
          label: record.city_title,
          value: record.city_id,
          state: record.state_id,
        }));
        setCityData(options);
      });
  };

  const getAddDealCF = (pipeID) => {
    axios
      .get("ws/ws_custom_field.php", {
        params: {
          task: "5",
          area: "deal",
          company: session.company_id,
          v_add: "yes",
        },
      })
      .then((res) => {
        let data = res.data;

        if (data.status === 0) {
          setAddDealCf(data.record);
          setIsAddDeal(true);
        } else {
          setAddDealCf([]);
          setIsAddDeal(true);
        }
      });
  };

  const getProsTimeline = () => {
    axios
      .get("ws/ws_prospect.php", {
        params: {
          task: "getProspectTimeline",
          prospect: prospect_id,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      })
      .then((res) => {
        let data = res.data;

        if (data.record) {
          setTimelineData(data.record);
        } else {
          setTimelineData([]);
        }
      });
  };

  const getDefCsID = () => {
    axios
      .get("ws/ws_setting.php", {
        params: {
          task: 5,
          company: session.company_id,
        },
      })
      .then((res) => {
        let data = res.data;

        if (data.status === 0) {
          setDefaultCsId(data.record[0].cs_id);
        } else {
          setDefaultCsId("");
        }
      });
  };

  const getContactPref = () => {
    axios
      .get("ws/ws_contact_preferences.php", {
        params: {
          task: "2",
          prospect_id: prospect_id,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      })
      .then((res) => {
        let data = res.data;

        if (data.status === 0) {
          setContactPrefData(data.record[0]);
        } else {
          setContactPrefData({});
        }
      });
  };

  const containerStyle = useMemo(() => ({ width: "100%", height: isProsDeal ? "70vh" : "0vh", paddingTop: 20 }), [isProsDeal]);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const containerStyleEdm = useMemo(() => ({ width: "100%", height: isEdm ? "70vh" : "0vh", paddingTop: 20 }), [isEdm]);
  const gridStyleEdm = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const containerStyleAuHis = useMemo(() => ({ width: "100%", height: isAuHis ? "70vh" : "0vh", paddingTop: 20 }), [isAuHis]);
  const gridStyleAuHis = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const containerStyleOpenDeal = useMemo(() => ({ width: "100%", height: isOpenDeal ? "70vh" : "0vh", paddingTop: 20 }), [isOpenDeal]);
  const gridStyleOpenDeal = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const containerStyleWonDeal = useMemo(() => ({ width: "100%", height: isWonDeal ? "70vh" : "0vh", paddingTop: 20 }), [isWonDeal]);
  const gridStyleWonDeal = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const containerStyleInbLd = useMemo(() => ({ width: "100%", height: isInbLd ? "70vh" : "0vh", paddingTop: 20 }), [isInbLd]);
  const gridStyleInbLd = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const containerStyleLostDeal = useMemo(() => ({ width: "100%", height: isLostDeal ? "70vh" : "0vh", paddingTop: 20 }), [isLostDeal]);
  const gridStyleLostDeal = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const [analyticColumn, setAnalyticColumn] = useState([
    {
      headerName: t("ProspectDetail_analytic_header_1"),
      field: "deal_title",
      resizable: true,
      width: 250,
      onCellClicked: function (params) {
        if (params.data) {
          window.open(session.origin + "/deal/" + params.data.deal_id);
        }
      },
    },
    {
      headerName: t("ProspectDetail_analytic_header_2"),
      field: "deal_value",
      resizable: true,
      width: 250,
    },
    {
      headerName: t("ProspectDetail_analytic_header_3"),
      field: "archieve_remark",
      resizable: true,
      width: 250,
    },
    {
      headerName: t("ProspectDetail_analytic_header_4"),
      field: "deal_expected_close_date",
      resizable: true,
      width: 250,
    },
  ]);

  const reportColDef = useMemo(() => {
    return {
      sortable: false,
      filter: false,
    };
  }, []);

  const [prosDealColumn, setProsDealColumn] = useState([
    {
      headerName: t("ProspectDetail_prosDeal_headername_1"),
      field: "deal_title",
      resizable: true,
      width: 250,
      onCellClicked: function (params) {
        if (params.data) {
          window.open(session.origin + "/deal/" + params.data.deal_id, "_blank");
        }
      },
    },
    {
      headerName: t("ProspectDetail_prosDeal_headername_2"),
      field: "deal_archieve",
      resizable: true,
      width: 250,
      cellRenderer: (params) => {
        if (params.data) {
          if (params.data.deal_archieve === "") {
            return "Open";
          } else {
            return params.data.deal_archieve;
          }
        }
      },
    },
    {
      headerName: t("ProspectDetail_prosDeal_headername_3"),
      field: "cs_title",
      resizable: true,
      width: 250,
    },
    {
      headerName: t("ProspectDetail_prosDeal_headername_4"),
      field: "pipeline_title",
      resizable: true,
      width: 250,
    },
    {
      headerName: t("ProspectDetail_prosDeal_headername_5"),
      field: "",
      resizable: true,
      width: 250,
    },
    {
      headerName: t("ProspectDetail_prosDeal_headername_6"),
      field: "user_name",
      resizable: true,
      width: 250,
    },
    {
      headerName: t("ProspectDetail_prosDeal_headername_7"),
      field: "deal_date_time_create",
      resizable: true,
      width: 250,
    },
    {
      headerName: t("ProspectDetail_prosDeal_headername_8"),
      resizable: true,
      width: 250,
      cellRenderer: (params) => {
        if (params.data) {
          if (params.data.deal_archieve === "") {
            return params.data.deal_date_time_update;
          } else if (params.data.deal_archieve === "won") {
            return params.data.deal_archieve_won_log;
          } else if (params.data.deal_archieve === "lost") {
            return params.data.deal_archieve_lost_log;
          } else if (params.data.deal_archieve === "deleted") {
            return params.data.deal_archieve_deleted_log;
          } else {
            return "";
          }
        }
      },
    },
  ]);

  const [edmColumn, setEdmColumn] = useState([
    {
      headerName: t("ProspectDetail_edm_headername_1"),
      field: "event_date_time",
      resizable: true,
      width: 300,
    },
    {
      headerName: t("ProspectDetail_edm_headername_2"),
      field: "email_template_name",
      resizable: true,
      width: 300,
    },
    {
      headerName: t("ProspectDetail_edm_headername_3"),
      field: "event_type",
      resizable: true,
      width: 300,
    },
  ]);

  const getProspectDeals = () => {
    axios
      .get("ws/ws_prospect.php", {
        params: {
          task: "getProspectDeals",
          prospect: prospect_id,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      })
      .then((res) => {
        let data = res.data;

        if (Number(data.total_open) > 0) {
          setIsProsDeal(true);
          setProsDealRowData(data.record_open);
        } else {
          setIsProsDeal(false);
          setProsDealRowData([]);
        }
      })
      .catch((error) => {
        setIsProsDeal(false);
        setProsDealRowData([]);
      });
  };

  const getEdm = (data) => {
    var email = "";

    if (data) {
      if (data.email1 !== "") {
        email = data.email1;
      } else if (data.email2 !== "") {
        email = data.email2;
      } else if (data.email3 !== "") {
        email = data.email3;
      }
    }

    axios
      .get("ext/glRedemption/api_edm.php", {
        params: {
          task: "getProspectEDM",
          email: email,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      })
      .then((res) => {
        let data = res.data;

        if (data.status === 0) {
          setIsEdm(true);
          setEdmRowData(data.record);
        } else {
          setIsEdm(false);
          setEdmRowData([]);
        }
      })
      .catch((error) => {
        setIsEdm(false);
        setEdmRowData([]);
      });
  };

  const [auHisColumn, setAuHisColumn] = useState([
    {
      headerName: t("ProspectDetail_auHis_headername_1"),
      field: "log_timestamp",
      resizable: true,
      width: 250,
    },
    {
      headerName: t("ProspectDetail_auHis_headername_2"),
      field: "username",
      resizable: true,
      width: 250,
    },
    {
      headerName: t("ProspectDetail_auHis_headername_3"),
      resizable: true,
      width: 250,
    },
    {
      headerName: t("ProspectDetail_auHis_headername_4"),
      resizable: true,
      flex: 1,
      cellRenderer: (params) => {
        if (params.data) {
          if (params.data.log_old_data[0]) {
            var log = params.data.log_old_data[0].label;
            var log2 = [];

            Object.keys(log).forEach((key) => {
              if (key) {
                if (params.data.log_old_data[0].label[key]) {
                  log2.push(params.data.log_old_data[0].label[key]);
                }
              }
            });

            return (
              <div style={{ overflow: "auto", width: "300px", height: "100px" }}>
                {log2.map((data, index) => (
                  <p key={index}>{data}</p>
                ))}
              </div>
            );
          }
        }
      },
    },
    {
      headerName: t("ProspectDetail_auHis_headername_5"),
      resizable: true,
      flex: 1,
      cellRenderer: (params) => {
        if (params.data) {
          if (params.data.log_old_data[0]) {
            var log = params.data.log_old_data[0].value;
            var log2 = [];

            Object.keys(log).forEach((key) => {
              if (key) {
                if (params.data.log_old_data[0].value[key]) {
                  log2.push(params.data.log_old_data[0].value[key]);
                }
              }
            });

            return (
              <div style={{ overflow: "auto", width: "300px", height: "100px" }}>
                {log2.map((data, index) => (
                  <p key={index}>{data}</p>
                ))}
              </div>
            );
          }
        }
      },
    },
    {
      headerName: t("ProspectDetail_auHis_headername_6"),
      resizable: true,
      flex: 1,
      cellRenderer: (params) => {
        if (params.data) {
          if (params.data.log_new_data[0]) {
            var log = params.data.log_new_data[0].value;
            var log2 = [];

            Object.keys(log).forEach((key) => {
              if (key) {
                if (params.data.log_new_data[0].value[key]) {
                  log2.push(params.data.log_new_data[0].value[key]);
                }
              }
            });

            return (
              <div style={{ overflow: "auto", width: "300px", height: "100px" }}>
                {log2.map((data, index) => (
                  <p key={index}>{data}</p>
                ))}
              </div>
            );
          }
        }
      },
    },
  ]);

  const getAuditHistory = () => {
    axios
      .get("ws/ws_auditHistory.php", {
        params: {
          task: "view",
          prospect_id: prospect_id,
          hq_prospect_id: 0,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      })
      .then((res) => {
        let data = res.data;

        if (data.record.length > 0) {
          // Object.keys(data.record[0].log_new_data[0].value).forEach((key, index) => {
          //     console.log(`${key}: ${data.record[0].log_new_data[0].value[key]}`);
          // });
          setIsAuHis(true);
          setAuHisRowData(data.record);
        } else {
          setIsAuHis(false);
          setAuHisRowData([]);
        }
      })
      .catch((error) => {
        setIsAuHis(false);
        setAuHisRowData([]);
      });
  };

  useEffect(() => {
    if (!init.current) {
      init.current = true;
      getprospectDet();
      getProsAnalytic();
      getAllUSer();
      getAllPipeline();
      getAllSource();
      getCountry();
      getState();
      getCity();
      getProsTimeline();
      getDefCsID();
      // getContactPref();
      // getProspectDeals();
      // getAuditHistory();
    } 
  }, [prospect_id, init.current]);

  useEffect(() => {
    if (init.current) {
      setIsLoading(true);
      init.current = false;
    }
  }, [prospect_id]);

  const RenderAccordionButton = ({ children, eventKey }) => {
    const [isOpen, setIsOpen] = useState(false);
    const decoratedOnClick = useAccordionButton(eventKey, () => setIsOpen(!isOpen));
    return (
      <button onClick={decoratedOnClick} className="accordion-button">
        <FontAwesomeIcon icon={faChevronDown} className={`icon-transition ${isOpen ? "rotate-up" : "rotate-down"} me-2`} />
        <div className="mt-1">{children}</div>
      </button>
    );
  };

  const addDealSchema = yup.object().shape({
    deal_title: yup.string().required("Deal title is required"),
    ds_id: yup.string().required("Deal source is required"),
  });

  const editProsSchema = yup.object().shape({
    prosName: yup.string().required("Name is required"),
  });

  const addContrSchema = yup.object().shape({
    contr_user_id: yup.string().required("Please Choose user"),
  });

  const getExistingDesignation = (inputValue, callback) => {
    setIsFindDesignation(false);
    axios
      .get("ws/ws_prospect.php", {
        params: {
          task: 11,
          company: session.company_id,
          q: inputValue,
        },
      })
      .then((response) => {
        var data = response.data;
        if (data.status === 0) {
          var options = data.record
            .filter((record) => {
              return Number(record.designation_id) > 0;
            })
            .map((record) => ({
              label: record.designation_title,
              value: record.designation_id,
              customLabel: "",
            }));

          var newOptions = data.record.find((record) => Number(record.designation_id) === 0);
          if (newOptions) {
            options.unshift({
              label: inputValue,
              value: 0,
              customLabel: newOptions.msg,
              contact1: "",
              contact_id: "",
            });
          }

          callback(options);
          setIsFindDesignation(true);
        } else {
          var options = [{ label: inputValue, value: 0, customLabel: data.error[0].msg }];
          callback(options);
          setIsFindDesignation(true);
        }
      });
  };

  const sendAddDeal = (values) => {
    var cf_id = [];
    var fd_value = [];

    values.cf_deal.map((record) => {
      cf_id.push(record.cf_id);
      fd_value.push(record.fd_values);
    });

    axios
      .get("ws/ws_deal.php", {
        params: {
          task: "addDealWithCf",
          title: values.deal_title,
          value: values.deal_value,
          source: values.ds_id,
          pipeid: values.pipe_id,
          close_date: values.deal_close_date,
          prospect: values.prospect,
          prospect_name: values.prospectName,
          organization: "",
          organization_name: "",
          user: session.user_id,
          company: session.company_id,
          cs: defaultCsId,
          cfid_arr: cf_id.toString(),
          cfval_arr: fd_value.toString(),
        },
      })
      .then((res) => {
        let data = res.data;

        if (data.status === 0) {
          Swal.fire({
            icon: "success",
            title: t("ProspectDetail_alert_success_title"),
            text: t("ProspectDetail_alert_success_add_deal_text"),
            timer: 1500,
          }).then((result) => {
            setIsAddDeal(false);
            getprospectDet();
            getProsTimeline();
            getProsAnalytic();
          });
        } else {
          Swal.fire({
            icon: "error",
            title: t("ProspectDetail_alert_failed_title"),
            text: t("ProspectDetail_alert_failed_text"),
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: error.message,
        });
      });
  };

  const sendEditPros = (values) => {
    console.log(values);

    axios
      .get("ws/ws_prospect.php", {
        params: {
          task: "2",
          des_title: values.designationTitleEdit,
          des: values.designationIDEdit,
          organization: "",
          prospect_address: "",
          prospect: values.prosID,
          name: values.prosName,
          owner_id: values.prosOwner,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      })
      .then((res) => {
        let data = res.data;

        if (data.status === 0) {
          triggerEditProspect2(values);
        } else {
          Swal.fire({
            icon: "error",
            title: t("ProspectDetail_alert_failed_title"),
            text: t("ProspectDetail_alert_failed_text"),
            timer: 1500,
          });
        }
      });
  };

  const triggerEditProspect2 = (values) => {
    axios
      .get("ws/ws_edit_prospect_details.php", {
        params: {
          task: "updateContacts",
          prospect_id: values.prosID,
          contact1_prefix: values.prosContact1Pref,
          contact1: values.prosContact1,
          contact1_old: "",
          contact2_prefix: values.prosContact2Pref,
          contact2: values.prosContact2,
          contact3_prefix: values.prosContact3Pref,
          contact3: values.prosContact3,
          contact4_prefix: values.prosContact4Pref,
          contact4: values.prosContact4,
          email1: values.prosEmail1,
          email2: values.prosEmail2,
          address1: values.prosAddress1,
          address2: values.prosAddress2,
          address3: values.prosAddress3,
          state_id: values.stateID,
          city_id: values.cityID,
          postcode: values.postcode,
          country_id: values.countryID,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      })
      .then((res) => {
        let data = res.data;

        if (data.status === 0) {
          triggerEditProspect3(values.prosID, values.cf_person);
        } else {
          Swal.fire({
            icon: "error",
            title: t("ProspectDetail_alert_failed_title"),
            text: t("ProspectDetail_alert_failed_text"),
            timer: 1500,
          });
        }
      });
  };

  const triggerEditProspect3 = (prosID, prosCFData) => {
    var record = [];
    var cf_id = [];
    var cf_val = [];

    prosCFData.map((data) => {
      record.push(prosID);
      cf_id.push(data.cf_id);
      cf_val.push(data.fd_values);
    });

    axios
      .get("ws/ws_custom_field.php", {
        params: {
          task: "updateOrAddCF",
          record: record.toString(),
          hq_prospect_id: "",
          cf_label: "",
          cf: cf_id.toString(),
          value: cf_val.toString(),
          user_id: session.user_id,
          company_id: session.company_id,
          secret_key: session.company_token,
        },
      })
      .then((res) => {
        let data = res.data;

        if (data.status === 0) {
          Swal.fire({
            icon: "success",
            title: t("ProspectDetail_alert_success_title"),
            text: t("ProspectDetail_alert_success_edit_deal_text"),
            timer: 1500,
          }).then((result) => {
            setIsEditProspect(false);
            getprospectDet();
          });
        } else {
          Swal.fire({
            icon: "error",
            title: t("ProspectDetail_alert_failed_title"),
            text: t("ProspectDetail_alert_failed_text"),
            timer: 1500,
          });
        }
      });
  };

  const sendAddContr = (values) => {
    axios
      .get("ws/ws_prospect.php", {
        params: {
          task: "addProspectContributor",
          prospectid: prospect_id,
          userid: values.contr_user_id,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      })
      .then((res) => {
        let data = res.data;

        if (data.status === 0) {
          Swal.fire({
            icon: "success",
            title: t("ProspectDetail_alert_success_title"),
            text: t("ProspectDetail_alert_success_add_contr"),
            timer: 1500,
          }).then((result) => {
            setIsAddContr(false);
            getprospectDet();
          });
        } else {
          Swal.fire({
            icon: "error",
            title: t("ProspectDetail_alert_failed_title"),
            text: t("ProspectDetail_alert_failed_text"),
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: error.message,
        });
      });
  };

  const removeProsContr = (userID) => {
    Swal.fire({
      icon: "warning",
      title: t("ProspectDetail_alert_warning_title"),
      text: t("ProspectDetail_alert_warning_text_2"),
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("ProspectDetail_alert_warning_confirm_2"),
      cancelButtonText: t("ProspectDetail_alert_warning_cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .get("ws/ws_prospect.php", {
            params: {
              task: "delContributor",
              prospectid: prospect_id,
              userid: userID,
              utoken: session.user_ac_token,
              ctoken: session.company_token,
            },
          })
          .then((res) => {
            let data = res.data;

            if (data.status === 0) {
              Swal.fire({
                icon: "success",
                title: t("ProspectDetail_alert_success_title"),
                text: t("ProspectDetail_alert_success_delete_contr"),
                timer: 1500,
              }).then((result) => {
                getprospectDet();
              });
            } else {
              Swal.fire({
                icon: "error",
                title: t("ProspectDetail_alert_failed_title"),
                text: t("ProspectDetail_alert_failed_text"),
              });
            }
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: error.message,
            });
          });
      }
    });
  };

  const handleProsTab = (values) => {
    setIsProsTab(values);

    if (values === "contact") {
      setIsMain("");
    } else {
      setIsMain("d-none");
    }
  };

  const sendEditContactPref = (values) => {
    //console.log(values);
    axios
      .get("ws/ws_contact_preferences.php", {
        params: {
          task: "1",
          prospect_id: prospect_id,
          email: values.email,
          email_bulk: values.email_bulk,
          phone: values.phone,
          fax: values.fax,
          mail: values.mail,
          whatsapp: values.whatsapp,
          pdpa: values.pdpa,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      })
      .then((res) => {
        let data = res.data;

        if (data.status === 0) {
          Swal.fire({
            icon: "success",
            title: t("ProspectDetail_alert_success_title"),
            text: t("ProspectDetail_alert_success_edit_contactPref_text"),
            timer: 1500,
          }).then((result) => {
            setIsEditContactPref(false);
            getContactPref();
          });
        } else {
          Swal.fire({
            icon: "error",
            title: t("ProspectDetail_alert_failed_title"),
            text: t("ProspectDetail_alert_failed_text"),
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: error.message,
        });
      });
  };

  const RenderOtherTab = ({ tab }) => {
    switch (tab) {
      case "deal":
        return (
          <div className="section-content w-100">
            <Container fluid>
              <div style={containerStyle}>
                <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                  <AgGridReact ref={prosDealGridRef} columnDefs={prosDealColumn} rowData={prosDealRowData} defaultColDef={reportColDef} rowHeight={70} pagination={true} paginationPageSize={30} paginationPageSizeSelector={false} />
                </div>
              </div>
              {!isProsDeal && <NoRecord message="No record found." width={300} />}
            </Container>
          </div>
        );

      case "edm":
        return (
          <div className="section-content w-100">
            <Container fluid>
              <div style={containerStyleEdm}>
                <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyleEdm }}>
                  <AgGridReact ref={edmGridRef} columnDefs={edmColumn} rowData={edmRowData} defaultColDef={reportColDef} rowHeight={70} pagination={true} paginationPageSize={30} paginationPageSizeSelector={false} />
                </div>
              </div>
              {!isEdm && <NoRecord message="No record found." width={300} />}
            </Container>
          </div>
        );

      case "contactPref":
        return (
          <div className="section-content w-100">
            <Container fluid className="p-4">
              <Card>
                <Card.Header className="rounded border-0 shadow-sm">
                  <div style={{ padding: "10px 20px" }}>
                    <Stack direction="horizontal" gap={1}>
                      <h6 className="mt-2">{t("ProspectDetail_contactPref_header")}</h6>
                      <button type="button" className="btn btn-light border ms-auto" onClick={setIsEditContactPref}>
                        <FontAwesomeIcon icon={faPenToSquare} />
                      </button>
                    </Stack>
                  </div>
                </Card.Header>
              </Card>

              <Row className="mt-5">
                <Col sm={3}>
                  <Card className="shadow-sm border-0">
                    <Card.Body className="d-flex justify-content-between align-items-center">
                      <div>
                        <Card.Title as={"h6"} className="fw-bold">
                          {t("ProspectDetail_contactPref_card_title_1")}
                        </Card.Title>
                        <Card.Text style={{ fontSize: 13 }}>{contactPrefData.email === "Allow" ? "Yes" : "No"}</Card.Text>
                      </div>
                      <FontAwesomeIcon icon={faEnvelope} size="2xl" />
                    </Card.Body>
                  </Card>
                </Col>
                <Col sm={3}>
                  <Card className="shadow-sm border-0">
                    <Card.Body className="d-flex justify-content-between align-items-center">
                      <div>
                        <Card.Title as={"h6"} className="fw-bold">
                          {t("ProspectDetail_contactPref_card_title_2")}
                        </Card.Title>
                        <Card.Text style={{ fontSize: 13 }}>{contactPrefData.email_bulk === "Allow" ? "Yes" : "No"}</Card.Text>
                      </div>
                      <FontAwesomeIcon icon={faEnvelopesBulk} size="2xl" />
                    </Card.Body>
                  </Card>
                </Col>
                <Col sm={3}>
                  <Card className="shadow-sm border-0">
                    <Card.Body className="d-flex justify-content-between align-items-center">
                      <div>
                        <Card.Title as={"h6"} className="fw-bold">
                          {t("ProspectDetail_contactPref_card_title_3")}
                        </Card.Title>
                        <Card.Text style={{ fontSize: 13 }}>{contactPrefData.phone === "Allow" ? "Yes" : "No"}</Card.Text>
                      </div>
                      <FontAwesomeIcon icon={faPhone} size="2xl" />
                    </Card.Body>
                  </Card>
                </Col>
                <Col sm={3}>
                  <Card className="shadow-sm border-0">
                    <Card.Body className="d-flex justify-content-between align-items-center">
                      <div>
                        <Card.Title as={"h6"} className="fw-bold">
                          {t("ProspectDetail_contactPref_card_title_4")}
                        </Card.Title>
                        <Card.Text style={{ fontSize: 13 }}>{contactPrefData.fax === "Allow" ? "Yes" : "No"}</Card.Text>
                      </div>
                      <FontAwesomeIcon icon={faFax} size="2xl" />
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col sm={3}>
                  <Card className="shadow-sm border-0">
                    <Card.Body className="d-flex justify-content-between align-items-center">
                      <div>
                        <Card.Title as={"h6"} className="fw-bold">
                          {t("ProspectDetail_contactPref_card_title_5")}
                        </Card.Title>
                        <Card.Text style={{ fontSize: 13 }}>{contactPrefData.mail === "Allow" ? "Yes" : "No"}</Card.Text>
                      </div>
                      <FontAwesomeIcon icon={faEnvelopeOpenText} size="2xl" />
                    </Card.Body>
                  </Card>
                </Col>
                <Col sm={3}>
                  <Card className="shadow-sm border-0">
                    <Card.Body className="d-flex justify-content-between align-items-center">
                      <div>
                        <Card.Title as={"h6"} className="fw-bold">
                          {t("ProspectDetail_contactPref_card_title_6")}
                        </Card.Title>
                        <Card.Text style={{ fontSize: 13 }}>{contactPrefData.whatsapp === "Allow" ? "Yes" : "No"}</Card.Text>
                      </div>
                      <FontAwesomeIcon icon={faWhatsapp} size="2xl" />
                    </Card.Body>
                  </Card>
                </Col>
                <Col sm={3}>
                  <Card className="shadow-sm border-0">
                    <Card.Body className="d-flex justify-content-between align-items-center">
                      <div>
                        <Card.Title as={"h6"} className="fw-bold">
                          {t("ProspectDetail_contactPref_card_title_7")}
                        </Card.Title>
                        <Card.Text style={{ fontSize: 13 }}>{contactPrefData.pdpa === "Allow" ? "Yes" : "No"}</Card.Text>
                      </div>
                      <FontAwesomeIcon icon={faShieldCheck} size="2xl" />
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        );

      case "auHis":
        return (
          <div className="section-content w-100">
            <Container fluid>
              <div style={containerStyleAuHis}>
                <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyleAuHis }}>
                  <AgGridReact ref={auHisGridRef} columnDefs={auHisColumn} rowData={auHisRowData} defaultColDef={reportColDef} rowHeight={150} pagination={true} paginationPageSize={30} paginationPageSizeSelector={false} />
                </div>
              </div>
            </Container>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <>
      {isLoading === true ? (
        <Loader />
      ) : (
        <div className="main">
          <div className="section-header">
            <Row>
              <Col xxl={12} className="py-2 px-5">
                <Stack direction="horizontal" gap={2}>
                  <div>
                    <Stack direction="horizontal" gap={2}>
                      <div className="d-flex">
                        <div className="avatar me-2">
                          <div className="avatar-img" style={{ width: 70, height: 70, backgroundColor: "#eee" }}>
                            <div className="avatar-txt text-uppercase" style={{ fontSize: "35px" }}>
                              {initial}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="d-flex align-items-center">
                          <div className="op-text-bigger fs-5 me-2">{prosDetData.prospect_name}</div>
                        </div>
                        <div className="op-text-small">
                          {prosDetData.designation_title}, {prosDetData.organization_title === "" ? t("ProspectDetail_Individual") : prosDetData.organization_title}
                        </div>
                        <div className="op-text-small">
                          {t("ProspectDetail_added_by")} {prosDetData.owner_name}
                        </div>
                      </div>
                    </Stack>
                  </div>
                  <button
                    className="btn btn-success ms-auto"
                    onClick={() => {
                      getAddDealCF("");
                    }}
                  >
                    {t("ProspectDetail_btn_addDeal")}
                  </button>
                </Stack>
              </Col>
              <Col xxl={12}>
                <Form.Group style={{ paddingLeft: "10px", paddingRight: "10px", paddingBottom: "10px" }}>
                  <ButtonGroup className="activity-tab" style={{ width: "100%" }}>
                    <ToggleButton
                      variant=""
                      type="radio"
                      name="pros_tab"
                      value="contact"
                      checked={isProsTab === "contact"}
                      onClick={() => {
                        handleProsTab("contact");
                      }}
                    >
                      {t("ProspectDetail_toggleButton_1")}
                    </ToggleButton>
                    <ToggleButton
                      variant=""
                      type="radio"
                      name="pros_tab"
                      value="deal"
                      checked={isProsTab === "deal"}
                      onClick={() => {
                        handleProsTab("deal");
                        getProspectDeals();
                      }}
                    >
                      {t("ProspectDetail_toggleButton_2")}
                    </ToggleButton>
                    <ToggleButton
                      variant=""
                      type="radio"
                      name="pros_tab"
                      value="edm"
                      checked={isProsTab === "edm"}
                      onClick={() => {
                        handleProsTab("edm");
                      }}
                    >
                      {t("ProspectDetail_toggleButton_3")}
                    </ToggleButton>
                    <ToggleButton
                      variant=""
                      type="radio"
                      name="pros_tab"
                      value="contactPref"
                      checked={isProsTab === "contactPref"}
                      onClick={() => {
                        handleProsTab("contactPref");
                        getContactPref();
                      }}
                    >
                      {t("ProspectDetail_toggleButton_4")}
                    </ToggleButton>
                    <ToggleButton
                      variant=""
                      type="radio"
                      name="pros_tab"
                      value="auHis"
                      checked={isProsTab === "auHis"}
                      onClick={() => {
                        handleProsTab("auHis");
                        getAuditHistory();
                      }}
                    >
                      {t("ProspectDetail_toggleButton_5")}
                    </ToggleButton>
                  </ButtonGroup>
                </Form.Group>
              </Col>
            </Row>
          </div>
          <div className="section-body">
            <div className={`section-sidebar ${isMain}`}>
              <Accordion defaultActiveKey={["0", "1", "2"]} alwaysOpen>
                <div className="sidebar-accordion">
                  <div className="d-flex justify-content-between align-items-center">
                    <RenderAccordionButton eventKey="0">{t("ProspectDetail_accordian_personal_Info")}</RenderAccordionButton>
                    <div className="btn-group shadow-sm">
                      <button
                        type="button"
                        className="btn btn-light border"
                        onClick={() => {
                          getEditProspect();
                        }}
                      >
                        <FontAwesomeIcon icon={faPenToSquare} />
                      </button>
                    </div>
                  </div>

                  <Accordion.Collapse eventKey="0">
                    <div className="accordion-body">
                      <div className="d-flex justify-content-between align-items-center p-2 w-100">
                        <div className="d-flex">
                          <div className="me-2 text-center" style={{ width: 15 }}>
                            <FontAwesomeIcon icon={faUser} size="lg" />
                          </div>
                          {t("ProspectDetail_accordian_personal_Info_content_1")}
                        </div>
                        <span>{prosDetData.contact1 !== "" ? prosDetData.contact1 : prosDetData.contact_count === 1 ? prosDetData.contacts[0].contact_num : "-"}</span>
                      </div>
                      <div className="d-flex justify-content-between align-items-center p-2 w-100">
                        <div className="d-flex">
                          <div className="me-2 text-center" style={{ width: 15 }}>
                            <FontAwesomeIcon icon={faUser} size="lg" />
                          </div>
                          {t("ProspectDetail_accordian_personal_Info_content_2")}
                        </div>
                        <span>{prosDetData.contact2 !== "" ? prosDetData.contact2 : prosDetData.contact_count === 2 ? prosDetData.contacts[1].contact_num : "-"}</span>
                      </div>
                      <div className="d-flex justify-content-between align-items-center p-2 w-100">
                        <div className="d-flex">
                          <div className="me-2 text-center" style={{ width: 15 }}>
                            <FontAwesomeIcon icon={faEnvelope} size="lg" />
                          </div>
                          {t("ProspectDetail_accordian_personal_Info_content_3")}
                        </div>
                        <span>{prosDetData.email1 !== "" ? prosDetData.email1 : prosDetData.email_count === 0 ? "-" : prosDetData.emails[0] ? prosDetData.emails[0].email_title : "-"}</span>
                      </div>
                      <div className="d-flex justify-content-between align-items-center p-2 w-100">
                        <div className="d-flex">
                          <div className="me-2 text-center" style={{ width: 15 }}>
                            <FontAwesomeIcon icon={faEnvelope} size="lg" />
                          </div>
                          {t("ProspectDetail_accordian_personal_Info_content_4")}
                        </div>
                        <span>{prosDetData.email2 !== "" ? prosDetData.email2 : prosDetData.email_count === 0 ? "-" : prosDetData.emails[1] ? prosDetData.emails[1].email_title : "-"}</span>
                      </div>
                      <div className="d-flex justify-content-between align-items-center p-2 w-100">
                        <div className="d-flex">
                          <div className="me-2 text-center" style={{ width: 15 }}>
                            <FontAwesomeIcon icon={faBuilding} size="lg" />
                          </div>
                          {t("ProspectDetail_accordian_personal_Info_content_5")}
                        </div>
                        <span>{prosDetData.address_line1 !== "" ? prosDetData.address_line1 : prosDetData.prospect_address === "" ? "-" : prosDetData.prospect_address}</span>
                      </div>
                      <div className="d-flex justify-content-between align-items-center p-2 w-100">
                        <div className="d-flex">
                          <div className="me-2 text-center" style={{ width: 15 }}>
                            <FontAwesomeIcon icon={faBuilding} size="lg" />
                          </div>
                          {t("ProspectDetail_accordian_personal_Info_content_6")}
                        </div>
                        <span>{prosDetData.address_line2 === "" ? "-" : prosDetData.address_line2}</span>
                      </div>
                      <div className="d-flex justify-content-between align-items-center p-2 w-100">
                        <div className="d-flex">
                          <div className="me-2 text-center" style={{ width: 15 }}>
                            <FontAwesomeIcon icon={faBuilding} size="lg" />
                          </div>
                          {t("ProspectDetail_accordian_personal_Info_content_7")}
                        </div>
                        <span>{prosDetData.address_line3 === "" ? "-" : prosDetData.address_line3}</span>
                      </div>
                      <div className="d-flex justify-content-between align-items-center p-2 w-100">
                        <div className="d-flex">
                          <div className="me-2 text-center" style={{ width: 15 }}>
                            <FontAwesomeIcon icon={faBuilding} size="lg" />
                          </div>
                          {t("ProspectDetail_accordian_personal_Info_content_8")}
                        </div>
                        <span>{prosDetData.postal_code === "0" ? "-" : prosDetData.postal_code}</span>
                      </div>
                      <div className="d-flex justify-content-between align-items-center p-2 w-100">
                        <div className="d-flex">
                          <div className="me-2 text-center" style={{ width: 15 }}>
                            <FontAwesomeIcon icon={faBuilding} size="lg" />
                          </div>
                          {t("ProspectDetail_accordian_personal_Info_content_9")}
                        </div>
                        <span>-</span>
                      </div>
                      <div className="d-flex justify-content-between align-items-center p-2 w-100">
                        <div className="d-flex">
                          <div className="me-2 text-center" style={{ width: 15 }}>
                            <FontAwesomeIcon icon={faBuilding} size="lg" />
                          </div>
                          {t("ProspectDetail_accordian_personal_Info_content_10")}
                        </div>
                        <span>-</span>
                      </div>
                      <div className="d-flex justify-content-between align-items-center p-2 w-100">
                        <div className="d-flex">
                          <div className="me-2 text-center" style={{ width: 15 }}>
                            <FontAwesomeIcon icon={faBuilding} size="lg" />
                          </div>
                          {t("ProspectDetail_accordian_personal_Info_content_11")}
                        </div>
                        <span>-</span>
                      </div>
                      {Number(prosDetData.custom_field) > 0 ? (
                        <>
                          {prosDetData.custom_field_data.map((data, i) => (
                            <div key={i} className="d-flex justify-content-between align-items-center p-2 w-100">
                              <div className="d-flex">
                                <div className="me-2 text-center" style={{ width: 15 }}>
                                  <FontAwesomeIcon icon={faBallot} size="lg" />
                                </div>
                                {data.cf_label}
                              </div>
                              <span>{data.fd_value ? data.fd_value : "-"}</span>
                            </div>
                          ))}
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </Accordion.Collapse>
                </div>
                <div className="sidebar-accordion">
                  <div className="d-flex justify-content-between align-items-center">
                    <RenderAccordionButton eventKey="1">{t("ProspectDetail_accordian_analytics")}</RenderAccordionButton>
                  </div>

                  <Accordion.Collapse eventKey="1">
                    <div className="accordion-body">
                      <div className="d-flex justify-content-between align-items-center">
                        <div
                          className="d-flex align-items-center w-30"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setAnalyticOpen(true);
                          }}
                        >
                          <FontAwesomeIcon icon={faChartLineUp} size="2xl" />
                          <div className="ms-2">
                            <span className="op-text-bigger fw-bold">{setCurrency(analyticData.open_amount)}</span> <br />
                            <span className="op-text-medium">
                              {Number(analyticData.total_open) === 1
                                ? t("ProspectDetail_accordian_analytics_content_1_record", { count: analyticData.total_open })
                                : Number(analyticData.total_open) > 1
                                ? t("ProspectDetail_accordian_analytics_content_1_record", { count: analyticData.total_open })
                                : t("ProspectDetail_accordian_analytics_content_1_record_no")}
                            </span>
                          </div>
                        </div>
                        <div
                          className="d-flex align-items-center w-60"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setAnalyticInbLd(true);
                          }}
                        >
                          <FontAwesomeIcon icon={faChartLineUp} size="2xl" />
                          <div className="ms-2">
                            <span className="op-text-bigger fw-bold">{setCurrency(analyticData.inbound_amount)}</span> <br />
                            <span className="op-text-medium">
                              {Number(analyticData.total_inbound) === 1
                                ? t("ProspectDetail_accordian_analytics_content_2_record", { count: analyticData.total_inbound })
                                : Number(analyticData.total_inbound) > 1
                                ? t("ProspectDetail_accordian_analytics_content_2_record", { count: analyticData.total_inbound })
                                : t("ProspectDetail_accordian_analytics_content_2_record_no")}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between align-items-center">
                        <div
                          className="d-flex align-items-center w-30"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setAnalyticWon(true);
                          }}
                        >
                          <FontAwesomeIcon icon={faChartLineUp} size="2xl" />
                          <div className="ms-2">
                            <span className="op-text-bigger fw-bold">{setCurrency(analyticData.won_amount)}</span> <br />
                            <span className="op-text-medium">
                              {Number(analyticData.total_won) === 1
                                ? t("ProspectDetail_accordian_analytics_content_3_record", { count: analyticData.total_won })
                                : Number(analyticData.total_won) > 1
                                ? t("ProspectDetail_accordian_analytics_content_3_record", { count: analyticData.total_won })
                                : t("ProspectDetail_accordian_analytics_content_3_record_no")}
                            </span>
                          </div>
                        </div>
                        <div
                          className="d-flex align-items-center w-60"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setAnalyticLost(true);
                          }}
                        >
                          <FontAwesomeIcon icon={faChartLineDown} size="2xl" />
                          <div className="ms-2">
                            <span className="op-text-bigger fw-bold">{setCurrency(analyticData.lost_amount)}</span> <br />
                            <span className="op-text-medium">
                              {Number(analyticData.total_lost) === 1
                                ? t("ProspectDetail_accordian_analytics_content_4_record", { count: analyticData.total_lost })
                                : Number(analyticData.total_lost) > 1
                                ? t("ProspectDetail_accordian_analytics_content_4_record", { count: analyticData.total_lost })
                                : t("ProspectDetail_accordian_analytics_content_4_record_no")}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Accordion.Collapse>
                </div>
                <div className="sidebar-accordion">
                  <div className="d-flex justify-content-between align-items-center">
                    <RenderAccordionButton eventKey="2">{t("ProspectDetail_accordian_contributor")}</RenderAccordionButton>
                    <div className="btn-group shadow-sm">
                      <button
                        type="button"
                        className="btn btn-light border"
                        onClick={() => {
                          setIsAddContr(true);
                        }}
                      >
                        <FontAwesomeIcon icon={faPlus} />
                      </button>
                    </div>
                  </div>

                  <Accordion.Collapse eventKey="2">
                    {Number(prosDetData.contributor_count) > 0 ? (
                      <div className="accordion-body">
                        {prosDetData.contributor.map((data, i) => (
                          <Stack className="mb-2" direction="horizontal" key={i}>
                            <FontAwesomeIcon icon={faUserTie} size="lg" className="me-2" />
                            <span>{data.user_name}</span>
                            <FontAwesomeIcon
                              className="ms-auto"
                              icon={faCircleXmark}
                              size="lg"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                removeProsContr(data.user_id);
                              }}
                            />
                          </Stack>
                        ))}
                      </div>
                    ) : (
                      <div className="accordion-body">{t("ProspectDetail_accordian_contributor_content_no")}</div>
                    )}
                  </Accordion.Collapse>
                </div>
              </Accordion>
            </div>
            <div className={`section-content ${isMain}`}>
              <Container fluid className="p-4">
                <Card>
                  <Card.Header className="d-flex justify-content-between align-items-center" style={{ padding: "0 20px" }}>
                    <Nav variant="underline" defaultActiveKey="notes" onSelect={onchangeActionTabs}>
                      <Nav.Item>
                        <Nav.Link eventKey="notes">
                          <FontAwesomeIcon icon={faNoteSticky} className="me-2" size="lg" />
                          {t("ProspectDetail_note")}
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="activity">
                          <FontAwesomeIcon icon={faCalendarDay} className="me-2" size="lg" />
                          {t("ProspectDetail_activity")}
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                    {isActiveTab && <CloseButton className="float-end me-2" onClick={() => setIsActiveTab(false)} />}
                  </Card.Header>
                  <RenderActionTab tab={activeTab} active={isActiveTab} userListNotes={userList} activityUser={userList} />
                </Card>

                {timelineData.length > 0 && (
                  <Accordion defaultActiveKey={["0"]} alwaysOpen className="position-relative">
                    <div className="sidebar-accordion">
                      <div className="d-flex justify-content-between align-items-center">
                        <RenderAccordionButton eventKey="0">{t("ProspectDetail_accordian_timeline")}</RenderAccordionButton>
                      </div>

                      <Accordion.Collapse eventKey="0">
                        <div className="accordion-body">
                          <VerticalTimeline layout="1-column-left" style={{ width: "100%" }}>
                            {timelineData.length > 0 &&
                              timelineData.map((record, index) => (
                                <VerticalTimelineElement key={index} date={setTimelineDateTime(record)} iconStyle={{ background: "#fff" }} icon={<RenderTimelineIcon record={record} />}>
                                  <Row>
                                    <Col sm={10}>
                                      <div className="op-text-bigger fw-bold">
                                        <RenderTimelineHeader record={record} />
                                      </div>
                                      <div className="op-text-medium">
                                        <RenderTimelineDescription record={record} />
                                      </div>
                                      <RenderTimelineDocument record={record} />
                                    </Col>
                                    {record.record_type === "Activity" || record.record_type === "CustomActivity" ? (
                                      <Col sm={1}>
                                        <div className="w-100 d-flex justify-content-center mt-2" style={{ cursor: "pointer" }} onClick={() => sendMarkAsDone(record)}>
                                          {record.record_activity_mad === "yes" ? <FontAwesomeIcon icon={faCircleCheck} size="2xl" /> : <FontAwesomeIcon icon={faCircleLight} size="2xl" />}
                                        </div>
                                      </Col>
                                    ) : (
                                      ""
                                    )}
                                    {Number(session.readwrite_permission) === 0 || Number(session.readwrite_permission) === 1 ? (
                                      <Col sm={1}>
                                        <RenderTimelineAction record={record} />
                                      </Col>
                                    ) : (
                                      ""
                                    )}
                                  </Row>
                                </VerticalTimelineElement>
                              ))}
                          </VerticalTimeline>
                        </div>
                      </Accordion.Collapse>
                    </div>
                  </Accordion>
                )}
              </Container>
            </div>
            <RenderOtherTab tab={isProsTab} />
          </div>

          <Modal show={isAddDeal} onHide={setIsAddDeal}>
            <Formik
              onSubmit={sendAddDeal}
              validationSchema={addDealSchema}
              initialValues={{
                prospect: prosDetData.prospect_id,
                prospectName: prosDetData.prospect_name,
                deal_title: "",
                ds_id: "",
                deal_value: "",
                deal_close_date: "",
                pipe_id: "",
                cf_deal:
                  addDealCf.length > 0
                    ? addDealCf.map((record) => ({
                        cf_id: record.cf_id,
                        name: record.cf_id,
                        fd_values: "",
                      }))
                    : "",
              }}
            >
              {({ handleSubmit, handleChange, values, errors, touched }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Modal.Header closeButton>
                    <Modal.Title as={"h6"}>{t("ProspectDetail_ModalHeader_title")}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("ProspectDetail_modalBody_addDeal_title_1")}</Form.Label>
                      <Form.Control type="text" isInvalid={errors.deal_title && touched.deal_title} onChange={handleChange("deal_title")} />
                      {errors.deal_title && touched.deal_title && <div className="op-error-message">{errors.deal_title}</div>}
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("ProspectDetail_modalBody_addDeal_title_2")}</Form.Label>
                      <FormSelect
                        placeholder={t("ProspectDetail_modalBody_addDeal_title_2_placeholder")}
                        options={dsData}
                        isClearable={true}
                        isSearchable={true}
                        isInvalid={errors.ds_id && touched.ds_id}
                        onChange={(e) => {
                          handleChange("ds_id")(e ? e.value : "");
                        }}
                      />
                      {errors.ds_id && touched.ds_id && <div className="op-error-message">{errors.ds_id}</div>}
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("ProspectDetail_modalBody_addDeal_title_3")}</Form.Label>
                      <Form.Control type="text" onChange={handleChange("deal_value")} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("ProspectDetail_modalBody_addDeal_title_4")}</Form.Label>
                      <Form.Control type="date" onChange={handleChange("deal_close_date")} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("ProspectDetail_modalBody_addDeal_title_5")}</Form.Label>
                      <FormSelect
                        placeholder={t("ProspectDetail_modalBody_addDeal_title_5_placeholder")}
                        options={pipeData}
                        isClearable={true}
                        isSearchable={true}
                        isInvalid={errors.pipe_id && touched.pipe_id}
                        onChange={(e) => {
                          handleChange("pipe_id")(e ? e.value : "");
                          getAddDealCF(e ? e.value : "");
                        }}
                      />
                      {errors.pipe_id && touched.pipe_id && <div className="op-error-message">{errors.pipe_id}</div>}
                    </Form.Group>
                    {addDealCf.map((record, index) => {
                      return (
                        (values.pipe_id === record.pipe_id || record.pipe_id === "0") && (
                          <FormCustomField
                            key={record.cf_id}
                            id={record.cf_id}
                            label={record.cf_label}
                            options={record.cf_value}
                            type={record.ctf_title}
                            name={record.cf_id}
                            placeholder=""
                            onChange={(info) => {
                              const updatedCustomFields = [...values.cf_deal];
                              if (record.ctf_title === "Select") {
                                updatedCustomFields[index] = {
                                  cf_id: record.cf_id,
                                  fd_values: info.value,
                                };
                              } else if (record.ctf_title === "Radio") {
                                updatedCustomFields[index] = {
                                  cf_id: record.cf_id,
                                  fd_values: info.target.checked ? info.target.value : "",
                                };
                              } else if (record.ctf_title === "Checkbox") {
                                const { name, value, checked } = info.target;
                                const currentValues = typeof values.cf_deal[index].fd_values === "string" ? values.cf_deal[index].fd_values : "";
                                const updatedValues = currentValues.split(",");
                                const emptyIndex = updatedValues.indexOf("");

                                if (emptyIndex !== -1) {
                                  updatedValues.splice(emptyIndex, 1);
                                }

                                if (checked) {
                                  if (!updatedValues.includes(value)) {
                                    updatedValues.push(value);
                                  }
                                } else {
                                  const indexToRemove = updatedValues.indexOf(value);
                                  if (indexToRemove !== -1) {
                                    updatedValues.splice(indexToRemove, 1);
                                  }
                                }

                                updatedCustomFields[index] = {
                                  cf_id: record.cf_id,
                                  name: record.cf_id,
                                  fd_values: updatedValues.join(","),
                                };
                              } else {
                                updatedCustomFields[index] = {
                                  cf_id: record.cf_id,
                                  fd_values: info.target.value,
                                };
                              }

                              handleChange({
                                target: { name: "cf_deal", value: updatedCustomFields },
                              });
                            }}
                          />
                        )
                      );
                    })}
                  </Modal.Body>
                  <Modal.Footer>
                    <button type="submit" className="btn op-button op-primary-color text-light shadow">
                      {t("ProspectDetail_modalFooter_submit")}
                    </button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal>

          <Modal show={isEditProspect} onHide={setIsEditProspect}>
            <Formik onSubmit={sendEditPros} validationSchema={editProsSchema} initialValues={initialVal}>
              {({ handleSubmit, handleChange, values, errors, touched }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Modal.Header closeButton>
                    <Modal.Title as={"h6"}>{t("ProspectDetail_modalHeader_edit_title")}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("ProspectDetail_modalBody_edit_title_1")}</Form.Label>
                      <Form.Control type="text" value={values.prosName} isInvalid={errors.prosName && touched.prosName} onChange={handleChange("prosName")} />
                      {errors.prosName && touched.prosName && <div className="op-error-message">{errors.prosName}</div>}
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("ProspectDetail_modalBody_edit_title_2")}</Form.Label>
                      <FormSelectCreatable
                        loadOptions={getExistingDesignation}
                        isLoading={isFindDesignation}
                        value={values.designationTitleEdit}
                        onChange={(info) => {
                          handleChange({
                            target: { name: "designationIDEdit", value: info.value },
                          });

                          handleChange({
                            target: { name: "designationTitleEdit", value: info.label },
                          });
                        }}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("ProspectDetail_modalBody_edit_title_3")}</Form.Label>
                      <FormSelect
                        options={userList}
                        isClearable={true}
                        isSearchable={true}
                        valueDefault={userList.filter((option) => option.value === values.prosOwner)}
                        onChange={(e) => {
                          handleChange("prosOwner")(e ? e.value : "");
                        }}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("ProspectDetail_modalBody_edit_title_4")}</Form.Label>
                      <Stack direction="horizontal" gap={2}>
                        <Form.Group className="w-25">
                          <FormSelect
                            placeholder={t("ProspectDetail_modalBody_edit_prefix_placeholder")}
                            options={prefix}
                            valueDefault={prefix.filter((option) => option.value === values.prosContact1Pref)}
                            onChange={(e) => {
                              handleChange("prosContact1Pref")(e.value);
                            }}
                          />
                        </Form.Group>
                        <Form.Group className="w-75">
                          <Form.Control type="text" value={values.prosContact1} onChange={handleChange("prosContact1")} />
                        </Form.Group>
                      </Stack>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("ProspectDetail_modalBody_edit_title_5")}</Form.Label>
                      <Stack direction="horizontal" gap={2}>
                        <Form.Group className="w-25">
                          <FormSelect
                            placeholder={t("ProspectDetail_modalBody_edit_prefix_placeholder")}
                            options={prefix}
                            valueDefault={prefix.filter((option) => option.value === values.prosContact2Pref)}
                            onChange={(e) => {
                              handleChange("prosContact2Pref")(e.value);
                            }}
                          />
                        </Form.Group>
                        <Form.Group className="w-75">
                          <Form.Control type="text" value={values.prosContact2} onChange={handleChange("prosContact2")} />
                        </Form.Group>
                      </Stack>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("ProspectDetail_modalBody_edit_title_6")}</Form.Label>
                      <Stack direction="horizontal" gap={2}>
                        <Form.Group className="w-25">
                          <FormSelect
                            placeholder={t("ProspectDetail_modalBody_edit_prefix_placeholder")}
                            options={prefix}
                            valueDefault={prefix.filter((option) => option.value === values.prosContact3Pref)}
                            onChange={(e) => {
                              handleChange("prosContact3Pref")(e.value);
                            }}
                          />
                        </Form.Group>
                        <Form.Group className="w-75">
                          <Form.Control type="text" value={values.prosContact3} onChange={handleChange("prosContact3")} />
                        </Form.Group>
                      </Stack>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("ProspectDetail_modalBody_edit_title_7")}</Form.Label>
                      <Stack direction="horizontal" gap={2}>
                        <Form.Group className="w-25">
                          <FormSelect
                            placeholder={t("ProspectDetail_modalBody_edit_prefix_placeholder")}
                            options={prefix}
                            valueDefault={prefix.filter((option) => option.value === values.prosContact4Pref)}
                            onChange={(e) => {
                              handleChange("prosContact4Pref")(e.value);
                            }}
                          />
                        </Form.Group>
                        <Form.Group className="w-75">
                          <Form.Control type="text" value={values.prosContact4} onChange={handleChange("prosContact4")} />
                        </Form.Group>
                      </Stack>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("ProspectDetail_modalBody_edit_title_8")}</Form.Label>
                      <Form.Control type="text" value={values.prosEmail1} onChange={handleChange("prosEmail1")} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("ProspectDetail_modalBody_edit_title_9")}</Form.Label>
                      <Form.Control type="text" value={values.prosEmail2} onChange={handleChange("prosEmail2")} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("ProspectDetail_modalBody_edit_title_10")}</Form.Label>
                      <Form.Control type="text" value={values.prosEmail3} onChange={handleChange("prosEmail3")} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("ProspectDetail_modalBody_edit_title_11")}</Form.Label>
                      <Form.Control type="text" value={values.prosAddress1} onChange={handleChange("prosAddress1")} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("ProspectDetail_modalBody_edit_title_12")}</Form.Label>
                      <Form.Control type="text" value={values.prosAddress2} onChange={handleChange("prosAddress2")} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("ProspectDetail_modalBody_edit_title_13")}</Form.Label>
                      <Form.Control type="text" value={values.prosAddress3} onChange={handleChange("prosAddress3")} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("ProspectDetail_modalBody_edit_title_14")}</Form.Label>
                      <Form.Control type="text" value={values.postcode} onChange={handleChange("postcode")} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("ProspectDetail_modalBody_edit_title_15")}</Form.Label>
                      <FormSelect
                        placeholder={t("ProspectDetail_modalBody_edit_title_15_placeholder")}
                        options={countryData}
                        valueDefault={countryData.filter((option) => Number(option.value) === Number(values.countryID))}
                        isClearable={true}
                        isSearchable={true}
                        onChange={(e) => {
                          handleChange("countryID")(e ? e.value : "0");
                          handleChange("stateID")("0");
                          handleChange("cityID")("0");
                        }}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("ProspectDetail_modalBody_edit_title_16")}</Form.Label>
                      <FormSelect
                        placeholder={t("ProspectDetail_modalBody_edit_title_16_placeholder")}
                        options={Number(values.countryID) > 0 ? stateData.filter((option) => Number(option.country) === Number(values.countryID)) : stateData}
                        valueDefault={stateData.filter((option) => Number(option.value) === Number(values.stateID))}
                        isClearable={true}
                        isSearchable={true}
                        onChange={(e) => {
                          handleChange("stateID")(e ? e.value : "0");
                          handleChange("cityID")("0");
                        }}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("ProspectDetail_modalBody_edit_title_17")}</Form.Label>
                      <FormSelect
                        placeholder={t("ProspectDetail_modalBody_edit_title_17_placeholder")}
                        options={Number(values.stateID) > 0 ? cityData.filter((option) => Number(option.state) === Number(values.stateID)) : cityData}
                        valueDefault={cityData.filter((option) => Number(option.value) === Number(values.cityID))}
                        isClearable={true}
                        isSearchable={true}
                        onChange={(e) => {
                          handleChange("cityID")(e ? e.value : "0");
                        }}
                      />
                    </Form.Group>
                    {Number(editProsCf.length) > 0 ? (
                      <>
                        {editProsCf.map((record, index) => (
                          <FormCustomField
                            key={record.cf_id}
                            id={record.cf_id}
                            label={record.cf_label}
                            options={record.cf_value}
                            type={record.ctf_title}
                            name={record.cf_id}
                            placeholder=""
                            value={record.fd_value}
                            onChange={(info) => {
                              const updatedCustomFields = [...values.cf_person];
                              if (record.ctf_title === "Select") {
                                updatedCustomFields[index] = {
                                  cf_id: record.cf_id,
                                  fd_values: info.value,
                                };
                              } else if (record.ctf_title === "Radio") {
                                updatedCustomFields[index] = {
                                  cf_id: record.cf_id,
                                  fd_values: info.target.checked ? info.target.value : "",
                                };
                              } else if (record.ctf_title === "Checkbox") {
                                const { name, value, checked } = info.target;
                                const currentValues = typeof values.cf_person[index].fd_values === "string" ? values.cf_person[index].fd_values : "";
                                const updatedValues = currentValues.split(",");
                                const emptyIndex = updatedValues.indexOf("");

                                if (emptyIndex !== -1) {
                                  updatedValues.splice(emptyIndex, 1);
                                }

                                if (checked) {
                                  if (!updatedValues.includes(value)) {
                                    updatedValues.push(value);
                                  }
                                } else {
                                  const indexToRemove = updatedValues.indexOf(value);
                                  if (indexToRemove !== -1) {
                                    updatedValues.splice(indexToRemove, 1);
                                  }
                                }

                                updatedCustomFields[index] = {
                                  cf_id: record.cf_id,
                                  name: record.cf_id,
                                  fd_values: updatedValues.join(","),
                                };
                              } else {
                                updatedCustomFields[index] = {
                                  cf_id: record.cf_id,
                                  fd_values: info.target.value,
                                };
                              }

                              handleChange({
                                target: { name: "cf_person", value: updatedCustomFields },
                              });
                            }}
                          />
                        ))}
                      </>
                    ) : (
                      ""
                    )}
                  </Modal.Body>
                  <Modal.Footer>
                    <button type="submit" className="btn op-button op-primary-color text-light shadow">
                      {t("ProspectDetail_modalFooter_submit")}
                    </button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal>

          <Modal show={isAddContr} onHide={setIsAddContr}>
            <Formik
              onSubmit={sendAddContr}
              validationSchema={addContrSchema}
              initialValues={{
                contr_user_id: "",
                pros_owner_id: prosDetData.prospect_user_id,
              }}
            >
              {({ handleSubmit, handleChange, values, errors, touched }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Modal.Header closeButton>
                    <Modal.Title as={"h6"}>{t("ProspectDetail_modalHeader_contributor_title")}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form.Group>
                      <Form.Label>{t("ProspectDetail_modalBody_contributor_title_1")}</Form.Label>
                      <FormSelect
                        placeholder={t("ProspectDetail_modalBody_contributor_title_1_placeholder")}
                        options={userList.filter((option) => selectedContr.includes(option.value) === false && option.value !== values.pros_owner_id)}
                        isClearable={true}
                        isSearchable={true}
                        isInvalid={errors.contr_user_id && touched.contr_user_id}
                        onChange={(e) => {
                          handleChange("contr_user_id")(e ? e.value : "");
                        }}
                      />
                      {errors.contr_user_id && touched.contr_user_id && <div className="op-error-message">{errors.contr_user_id}</div>}
                    </Form.Group>
                  </Modal.Body>
                  <Modal.Footer>
                    <button type="submit" className="btn op-button op-primary-color text-light shadow">
                      {t("ProspectDetail_modalFooter_submit")}
                    </button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal>

          <Modal show={isEditNote} onHide={setIsEditNote} size="lg">
            <Formik validationSchema={editNoteSchema} onSubmit={sendEditNote} initialValues={editNoteInit}>
              {({ handleSubmit, handleChange, values, errors, touched }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Modal.Header closeButton>
                    <Modal.Title as={"h6"}>{t("ProspectDetail_modalHeader_edit_note_title")}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form.Group className="mb-3">
                      <NoteEditor
                        value={values.activity_note}
                        onChangeValue={(value) => {
                          handleChange("activity_note")(value);
                        }}
                        height={300}
                      />

                      {errors.activity_note && touched.activity_note && <div className="op-error-message">{errors.activity_note}</div>}
                    </Form.Group>
                  </Modal.Body>
                  <Modal.Footer>
                    <button type="submit" className="btn op-button op-primary-color text-light shadow">
                      {t("ProspectDetail_modalFooter_submit")}
                    </button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal>

          <Modal show={isEditActivity} onHide={setIsEditActivity} size="lg">
            <Formik validationSchema={editActivitySchema} onSubmit={sendEditActivity} initialValues={editActivityInit}>
              {({ handleSubmit, handleChange, values, errors, touched }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Modal.Header closeButton>
                    <Modal.Title as={"h6"}>{t("ProspectDetail_modalHeader_edit_activity_title")}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="d-flex mb-3">
                      <div style={{ width: "5%" }}></div>
                      <div style={{ width: "95%" }}>
                        <Form.Group>
                          <Form.Control type="text" placeholder={t("ProspectDetail_modalBody_edit_activity_title_Placeholder")} value={values.activity_title} isInvalid={errors.activity_title && touched.activity_title} onChange={handleChange("activity_title")} />
                          {errors.activity_title && touched.activity_title && <div className="op-error-message">{errors.activity_title}</div>}
                        </Form.Group>
                      </div>
                    </div>

                    <div className="d-flex mb-3">
                      <div style={{ width: "5%" }}></div>
                      <div style={{ width: "95%" }}>
                        <Form.Group>
                          <ButtonGroup className="activity-tab" style={{ width: "100%" }}>
                            <ToggleButton variant="" type="radio" name="activity_type" id="activity_type_1" value="1" checked={values.activity_type === "1"} onChange={() => handleChange("activity_type")("1")}>
                              <FontAwesomeIcon icon={faPhoneVolume} className="me-1" />
                              {t("ProspectDetail_modalBody_edit_activity_toggleBtn_1")}
                            </ToggleButton>
                            <ToggleButton variant="" type="radio" name="activity_type" id="activity_type_2" value="2" checked={values.activity_type === "2"} onChange={() => handleChange("activity_type")("2")}>
                              <FontAwesomeIcon icon={faUsers} className="me-1" />
                              {t("ProspectDetail_modalBody_edit_activity_toggleBtn_2")}
                            </ToggleButton>
                            <ToggleButton variant="" type="radio" name="activity_type" id="activity_type_3" value="3" checked={values.activity_type === "3"} onChange={() => handleChange("activity_type")("3")}>
                              <FontAwesomeIcon icon={faStopwatch} className="me-1" />
                              {t("ProspectDetail_modalBody_edit_activity_toggleBtn_3")}
                            </ToggleButton>
                            <ToggleButton variant="" type="radio" name="activity_type" id="activity_type_4" value="4" checked={values.activity_type === "4"} onChange={() => handleChange("activity_type")("4")}>
                              <FontAwesomeIcon icon={faFlag} className="me-1" />
                              {t("ProspectDetail_modalBody_edit_activity_toggleBtn_4")}
                            </ToggleButton>
                            <ToggleButton variant="" type="radio" name="activity_type" id="activity_type_5" value="5" checked={values.activity_type === "5"} onChange={() => handleChange("activity_type")("5")}>
                              <FontAwesomeIcon icon={faEnvelopeOpenText} className="me-1" />
                              {t("ProspectDetail_modalBody_edit_activity_toggleBtn_5")}
                            </ToggleButton>
                          </ButtonGroup>
                          {errors.activity_type && touched.activity_type && <div className="op-error-message">{errors.activity_type}</div>}
                        </Form.Group>
                      </div>
                    </div>

                    <div className="d-flex mb-3">
                      <div className="d-flex justify-content-center align-items-center" style={{ width: "5%" }}>
                        <FontAwesomeIcon icon={faCalendarClock} size="lg" />
                      </div>
                      <div style={{ width: "95%" }}>
                        <Stack direction="horizontal" gap={1}>
                          <Row style={{ width: "100%" }}>
                            <Col sm={4}>
                              <Form.Group>
                                <Form.Control type="date" value={values.activity_date} onChange={handleChange("activity_date")} />
                              </Form.Group>
                            </Col>
                            <Col sm={4}>
                              <Form.Group>
                                <FormSelect placeholder={t("ProspectDetail_modalBody_edit_activity_time_placeholder")} options={timings} valueDefault={timings.filter((option) => option.value === values.activity_time)} onChange={(e) => handleChange("activity_time")(e.value)} />
                              </Form.Group>
                            </Col>
                            <Col sm={4}>
                              <Form.Group>
                                <FormSelect placeholder={t("ProspectDetail_modalBody_edit_activity_dur_placeholder")} options={durationList} valueDefault={durationList.filter((option) => option.value === values.activity_dur)} onChange={(e) => handleChange("activity_dur")(e.value)} />
                              </Form.Group>
                            </Col>
                          </Row>
                        </Stack>
                      </div>
                    </div>

                    <div className="d-flex mb-3">
                      <div className="d-flex justify-content-center align-items-center" style={{ width: "5%" }}>
                        <FontAwesomeIcon icon={faCircleUser} size="lg" />
                      </div>

                      <div style={{ width: "95%" }}>
                        <Form.Group>
                          <FormSelect
                            placeholder={t("ProspectDetail_modalBody_edit_activity_user_placeholder")}
                            options={userList}
                            valueDefault={userList.filter((option) => option.value === values.activity_user)}
                            isInvalid={errors.activity_user && touched.activity_user}
                            isClearable={true}
                            isSearchable={true}
                            onChange={(e) => {
                              handleChange("activity_user")(e ? e.value : "");
                            }}
                          />
                          {errors.activity_user && touched.activity_user && <div className="op-error-message">{errors.activity_user}</div>}
                        </Form.Group>
                      </div>
                    </div>

                    <div className="d-flex mb-3">
                      <div className="d-flex justify-content-center" style={{ width: "5%" }}>
                        <FontAwesomeIcon icon={faNoteSticky} size="lg" className="mt-2" />
                      </div>
                      <div style={{ width: "95%" }}>
                        <Form.Group className="activity-notes">
                          <NoteEditor
                            value={values.activity_note}
                            onChangeValue={(value) => {
                              handleChange("activity_note")(value);
                            }}
                            height={300}
                          />
                        </Form.Group>
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Form.Check
                      type="switch"
                      label={t("ProspectDetail_modalBody_edit_activity_mad_label")}
                      className="d-flex justify-content-center align-items-center me-3"
                      checked={values.activity_mad === "yes"}
                      onChange={(e) => {
                        if (e.target.checked === true) {
                          handleChange("activity_mad")("yes");
                        } else {
                          handleChange("activity_mad")("no");
                        }
                      }}
                    />
                    <button type="submit" className="btn op-button op-primary-color text-light shadow">
                      {t("ProspectDetail_modalFooter_submit")}
                    </button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal>

          <Modal show={isEditContactPref} onHide={setIsEditContactPref}>
            <Formik
              onSubmit={sendEditContactPref}
              initialValues={{
                email: contactPrefData.email === "Allow" ? "1" : "0",
                email_bulk: contactPrefData.email_bulk === "Allow" ? "1" : "0",
                phone: contactPrefData.phone === "Allow" ? "1" : "0",
                fax: contactPrefData.fax === "Allow" ? "1" : "0",
                mail: contactPrefData.mail === "Allow" ? "1" : "0",
                whatsapp: contactPrefData.whatsapp === "Allow" ? "1" : "0",
                pdpa: contactPrefData.pdpa === "Allow" ? "1" : "0",
              }}
            >
              {({ handleSubmit, handleChange, values, errors, touched }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Modal.Header closeButton>
                    <Modal.Title as={"h6"}>{t("ProspectDetail_modalHeader_title")}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form.Group className="mb-3 mt-3 px-4">
                      <Stack direction="horizontal" gap={1} style={{ width: "100%" }}>
                        <Form.Group style={{ width: "20%" }}>
                          <Form.Label>{t("ProspectDetail_modalBody_title_1")} :</Form.Label>
                        </Form.Group>
                        <Form.Group style={{ width: "80%" }}>
                          <FormSelect
                            placeholder="Select..."
                            options={contactPrefList}
                            valueDefault={contactPrefList.filter((option) => option.value === values.email)}
                            onChange={(e) => {
                              handleChange("email")(e.value);
                            }}
                          />
                        </Form.Group>
                      </Stack>
                    </Form.Group>
                    <Form.Group className="mb-3 px-4">
                      <Stack direction="horizontal" gap={1} style={{ width: "100%" }}>
                        <Form.Group style={{ width: "20%" }}>
                          <Form.Label>{t("ProspectDetail_modalBody_title_2")} :</Form.Label>
                        </Form.Group>
                        <Form.Group style={{ width: "80%" }}>
                          <FormSelect
                            placeholder="Select..."
                            options={contactPrefList}
                            valueDefault={contactPrefList.filter((option) => option.value === values.email_bulk)}
                            onChange={(e) => {
                              handleChange("email_bulk")(e.value);
                            }}
                          />
                        </Form.Group>
                      </Stack>
                    </Form.Group>
                    <Form.Group className="mb-3 px-4">
                      <Stack direction="horizontal" gap={1} style={{ width: "100%" }}>
                        <Form.Group style={{ width: "20%" }}>
                          <Form.Label>{t("ProspectDetail_modalBody_title_3")} :</Form.Label>
                        </Form.Group>
                        <Form.Group style={{ width: "80%" }}>
                          <FormSelect
                            placeholder="Select..."
                            options={contactPrefList}
                            valueDefault={contactPrefList.filter((option) => option.value === values.phone)}
                            onChange={(e) => {
                              handleChange("phone")(e.value);
                            }}
                          />
                        </Form.Group>
                      </Stack>
                    </Form.Group>
                    <Form.Group className="mb-3 px-4">
                      <Stack direction="horizontal" gap={1} style={{ width: "100%" }}>
                        <Form.Group style={{ width: "20%" }}>
                          <Form.Label>{t("ProspectDetail_modalBody_title_4")} :</Form.Label>
                        </Form.Group>
                        <Form.Group style={{ width: "80%" }}>
                          <FormSelect
                            placeholder="Select..."
                            options={contactPrefList}
                            valueDefault={contactPrefList.filter((option) => option.value === values.fax)}
                            onChange={(e) => {
                              handleChange("fax")(e.value);
                            }}
                          />
                        </Form.Group>
                      </Stack>
                    </Form.Group>
                    <Form.Group className="mb-3 px-4">
                      <Stack direction="horizontal" gap={1} style={{ width: "100%" }}>
                        <Form.Group style={{ width: "20%" }}>
                          <Form.Label>{t("ProspectDetail_modalBody_title_5")} :</Form.Label>
                        </Form.Group>
                        <Form.Group style={{ width: "80%" }}>
                          <FormSelect
                            placeholder="Select..."
                            options={contactPrefList}
                            valueDefault={contactPrefList.filter((option) => option.value === values.mail)}
                            onChange={(e) => {
                              handleChange("mail")(e.value);
                            }}
                          />
                        </Form.Group>
                      </Stack>
                    </Form.Group>
                    <Form.Group className="mb-3 px-4">
                      <Stack direction="horizontal" gap={1} style={{ width: "100%" }}>
                        <Form.Group style={{ width: "20%" }}>
                          <Form.Label>{t("ProspectDetail_modalBody_title_6")} :</Form.Label>
                        </Form.Group>
                        <Form.Group style={{ width: "80%" }}>
                          <FormSelect
                            placeholder="Select..."
                            options={contactPrefList}
                            valueDefault={contactPrefList.filter((option) => option.value === values.whatsapp)}
                            onChange={(e) => {
                              handleChange("whatsapp")(e.value);
                            }}
                          />
                        </Form.Group>
                      </Stack>
                    </Form.Group>
                    <Form.Group className="mb-3 px-4">
                      <Stack direction="horizontal" gap={1} style={{ width: "100%" }}>
                        <Form.Group style={{ width: "20%" }}>
                          <Form.Label>{t("ProspectDetail_modalBody_title_7")} :</Form.Label>
                        </Form.Group>
                        <Form.Group style={{ width: "80%" }}>
                          <FormSelect
                            placeholder="Select..."
                            options={contactPrefList}
                            valueDefault={contactPrefList.filter((option) => option.value === values.pdpa)}
                            onChange={(e) => {
                              handleChange("pdpa")(e.value);
                            }}
                          />
                        </Form.Group>
                      </Stack>
                    </Form.Group>
                  </Modal.Body>
                  <Modal.Footer>
                    <button type="submit" className="btn op-button op-primary-color text-light shadow">
                      {t("ProspectDetail_modalFooter_submit")}
                    </button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal>

          <Modal show={analyticOpen} onHide={setAnalyticOpen} size="lg">
            <Modal.Header closeButton>
              <Modal.Title as={"h6"}>{t("ProspectDetail_modalHeader_analytic_openDeal")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div style={containerStyleOpenDeal}>
                <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyleOpenDeal }}>
                  <AgGridReact ref={openDealGridRef} columnDefs={analyticColumn} rowData={openDealRowData} defaultColDef={reportColDef} rowHeight={70} pagination={true} paginationPageSize={30} paginationPageSizeSelector={false} />
                </div>
              </div>
              {!isOpenDeal && <NoRecord message={t("ProspectDetail_NoRecordAtMoment")} width={200} />}
            </Modal.Body>
          </Modal>

          <Modal show={analyticWon} onHide={setAnalyticWon} size="lg">
            <Modal.Header closeButton>
              <Modal.Title as={"h6"}>{t("ProspectDetail_modalHeader_analytic_wonDeal")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div style={containerStyleWonDeal}>
                <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyleWonDeal }}>
                  <AgGridReact ref={wonDealGridRef} columnDefs={analyticColumn} rowData={wonDealRowData} defaultColDef={reportColDef} rowHeight={70} pagination={true} paginationPageSize={30} paginationPageSizeSelector={false} />
                </div>
              </div>
              {!isWonDeal && <NoRecord message={t("ProspectDetail_NoRecordAtMoment")} width={100} />}
            </Modal.Body>
          </Modal>

          <Modal show={analyticInbLd} onHide={setAnalyticInbLd} size="lg">
            <Modal.Header closeButton>
              <Modal.Title as={"h6"}>{t("ProspectDetail_modalHeader_analytic_inbLd")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div style={containerStyleInbLd}>
                <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyleInbLd }}>
                  <AgGridReact ref={inbLdGridRef} columnDefs={analyticColumn} rowData={inbLdRowData} defaultColDef={reportColDef} rowHeight={70} pagination={true} paginationPageSize={30} paginationPageSizeSelector={false} />
                </div>
              </div>
              {!isInbLd && <NoRecord message={t("ProspectDetail_NoRecordAtMoment")} width={100} />}
            </Modal.Body>
          </Modal>

          <Modal show={analyticLost} onHide={setAnalyticLost} size="lg">
            <Modal.Header closeButton>
              <Modal.Title as={"h6"}>{t("ProspectDetail_modalHeader_analytic_lostDeal")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div style={containerStyleLostDeal}>
                <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyleLostDeal }}>
                  <AgGridReact ref={lostDealGridRef} columnDefs={analyticColumn} rowData={lostDealRowData} defaultColDef={reportColDef} rowHeight={70} pagination={true} paginationPageSize={30} paginationPageSizeSelector={false} />
                </div>
              </div>
              {!isLostDeal && <NoRecord message={t("ProspectDetail_NoRecordAtMoment")} width={100} />}
            </Modal.Body>
          </Modal>
        </div>
      )}
    </>
  );
};

export default ProspectDetails;
