import { useState, useRef, useMemo, useCallback, useEffect } from "react";
import { Nav, Stack, Badge, Form, Button, Modal, ButtonGroup, ToggleButton, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import {
  faFlag,
  faUsers,
  faPhone,
  faListCheck,
  faEnvelopeCircleCheck,
  faPhoneArrowDownLeft,
  faPhoneArrowUpRight,
  faLink,
  faLocationCheck,
  faCircleMinus,
  faDollarCircle,
  faUser,
  faStopwatch,
  faCalendarCircleUser,
  faPhoneVolume,
  faEnvelopeOpenText,
} from "@fortawesome/pro-duotone-svg-icons";
import { AgGridReact } from "ag-grid-react";
import { useAuth } from "../auth/AuthContext";
import { useActivity } from "./Activity";
import axios from "../api/axios";
import NoRecord from "../includes/NoRecord";
import { faEllipsisStrokeVertical } from "@fortawesome/pro-regular-svg-icons";
import Tippy from "@tippyjs/react";
import { useLocation } from "react-router-dom";
import { OverlayTrigger, Popover } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { FormSelect, FormDate } from "../includes/FormCustom";
import { useTranslation } from "react-i18next";
import NoteEditor from "../includes/NoteEditor";
import * as formik from "formik";


const MySwal = withReactContent(Swal);

const durationList = [
  { label: "5 Mins", value: "5min" },
  { label: "10 Mins", value: "10min" },
  { label: "15 Mins", value: "15min" },
  { label: "20 Mins", value: "20min" },
  { label: "30 Mins", value: "30min" },
  { label: "35 Mins", value: "35min" },
  { label: "40 Mins", value: "40min" },
  { label: "45 Mins", value: "45min" },
  { label: "50 Mins", value: "50min" },
  { label: "55 Mins", value: "55min" },
  { label: "1 Hour", value: "60min" },
  { label: "1 Hour 30 Mins", value: "90min" },
  { label: "2 Hours", value: "120min" },
  { label: "2 Hours 30 Mins", value: "150min" },
  { label: "3 Hours", value: "180min" },
  { label: "3 Hours 30 Mins", value: "210min" },
  { label: "4 Hours", value: "240min" },
  { label: "4 Hours 30 Mins", value: "270min" },
  { label: "5 Hours", value: "240min" },
  { label: "5 Hours 30 Mins", value: "330min" },
  { label: "6 Hours", value: "240min" },
  { label: "6 Hours 30 Mins", value: "390min" },
  { label: "7 Hours", value: "240min" },
  { label: "7 Hours 30 Mins", value: "450min" },
  { label: "8 Hours", value: "240min" },
  { label: "8 Hours 30 Mins", value: "510min" },
  { label: "9 Hours", value: "240min" },
  { label: "9 Hours 30 Mins", value: "570min" },
  { label: "10 Hours", value: "240min" },
];

const timings = [
  { label: "8:00", value: "08:00" },
  { label: "8:15", value: "08:15" },
  { label: "8:30", value: "08:30" },
  { label: "8:45", value: "08:45" },
  { label: "9:00", value: "09:00" },
  { label: "9:15", value: "09:15" },
  { label: "9:30", value: "09:30" },
  { label: "9:45", value: "09:45" },
  { label: "10:00", value: "10:00" },
  { label: "10:15", value: "10:15" },
  { label: "10:30", value: "10:30" },
  { label: "10:45", value: "10:45" },
  { label: "11:00", value: "11:00" },
  { label: "11:15", value: "11:15" },
  { label: "11:30", value: "11:30" },
  { label: "11:45", value: "11:45" },
  { label: "12:00", value: "12:00" },
  { label: "12:15", value: "12:15" },
  { label: "12:30", value: "12:30" },
  { label: "12:45", value: "12:45" },
  { label: "13:00", value: "13:00" },
  { label: "13:15", value: "13:15" },
  { label: "13:30", value: "13:30" },
  { label: "13:45", value: "13:45" },
  { label: "14:00", value: "14:00" },
  { label: "14:15", value: "14:15" },
  { label: "14:30", value: "14:30" },
  { label: "14:45", value: "14:45" },
  { label: "15:00", value: "15:00" },
  { label: "15:15", value: "15:15" },
  { label: "15:30", value: "15:30" },
  { label: "15:45", value: "15:45" },
  { label: "16:00", value: "16:00" },
  { label: "16:15", value: "16:15" },
  { label: "16:30", value: "16:30" },
  { label: "16:45", value: "16:45" },
  { label: "17:00", value: "17:00" },
  { label: "17:15", value: "17:15" },
  { label: "17:30", value: "17:30" },
  { label: "17:45", value: "17:45" },
  { label: "18:00", value: "18:00" },
  { label: "18:15", value: "18:15" },
  { label: "18:30", value: "18:30" },
  { label: "18:45", value: "18:45" },
  { label: "19:00", value: "19:00" },
  { label: "19:15", value: "19:15" },
  { label: "19:30", value: "19:30" },
  { label: "19:45", value: "19:45" },
  { label: "20:00", value: "20:00" },
  { label: "20:15", value: "20:15" },
  { label: "20:30", value: "20:30" },
  { label: "20:45", value: "20:45" },
  { label: "21:00", value: "21:00" },
  { label: "21:15", value: "21:15" },
  { label: "21:30", value: "21:30" },
  { label: "21:45", value: "21:45" },
  { label: "22:00", value: "22:00" },
];

const DayTime = [
  { label: 'Full Day', value: 'full-day' },
  { label: 'AM', value: 'am' },
  { label: 'PM', value: 'pm' },
]

const ActivityList = () => {
  const { t } = useTranslation();
  const { Formik } = formik;
  const initialized = useRef(false);
  const { session } = useAuth();
  const { ctxActivity, updateGrid } = useActivity();
  const [isActivity, setisActivity] = useState(true);
  const [isSelectedDate, setisSelectedDate] = useState("all");
  const [dateRange, setdateRange] = useState({
    ds: "",
    de: "",
  });
  const [activityCounting, setactivityCounting] = useState({
    all: 0,
    overdue: 0,
    upcoming: 0,
    today: 0,
    done: 0,
  });
  const [activityUser, setActivityUser] = useState([]);
  const addActivityRefNotes = useRef(null);
  const [gridKey, setGridKey] = useState(1);
  const [getFormattedDate, setGetFormattedDate] = useState([]);
  const [getFormattedTime, setGetFormattedTime] = useState([]);
  const [getAssignUser, setGetAssignUser] = useState([]);
  const [editActivityData, setEditActivityData] = useState([]);


  const currentLocation = useLocation();

  useEffect(() => {
    sessionStorage.removeItem("recentActivity");
    sessionStorage.setItem("recentActivity", currentLocation.pathname);
  }, [currentLocation]);

  // GET FUNCTION ----------------------------------------------

  const getActivityCounting = (loginData) => {
    axios
      .get("ws/ws_deal.php", {
        params: {
          task: 31,
          utoken: loginData.user_ac_token,
          ctoken: loginData.company_token,
          company: loginData.company_id,
          user: Number(loginData.role_id) !== 1 ? loginData.user_id : ctxActivity.user > 0 ? ctxActivity.user : "",
        },
      })
      .then((response) => {
        var data = response.data;
        setactivityCounting({
          all: data.all_count,
          overdue: data.overdue_count,
          upcoming: data.upcoming_count,
          today: data.today_count,
          done: data.done_count,
        });
      });
  };

  const getUser = async (loginData) => {
    try {
      const response = await axios.get("ws/ws_user.php", {
        params: {
          task: "4b",
          utoken: loginData.user_ac_token,
          ctoken: loginData.company_token,
        },
      });

      var data = response.data;
      if (data.status === 0) {
        var recordOptions = data.record.map((record) => ({
          label: record.user_name,
          value: record.user_id,
        }));

        recordOptions.unshift({
          label: "All User",
          value: 0,
        });

        var recordOptions2 = data.record.map((record) => ({
          label: record.user_name,
          value: record.user_id,
        }));

        setActivityUser(recordOptions2);
      } else {

      }
    } catch (error) {

    }
  };

  useEffect(() => {
    const initializeData = async () => {
      if (!initialized.current) {
        initialized.current = true;
        try {
          await getUser(session);
        } catch (error) {
          console.error(error);
        }
      }
    };

    initializeData();
  }, [session]);

  // ONCHANGE FUNCTION -----------------------------------------

  const onchangeDateRange = (mode, value) => {

    setdateRange((prevState) => ({
      ...prevState,
      [mode]: value,
    }));
  };

  // const onEditActivity = (params) => { }
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedActivityData, setSelectedActivityData] = useState({});

  const handleCloseEditModal = () => {
    setShowEditModal(false);
    setSelectedActivityData(null);
  };

  const handleShowEditModal = (activityData) => {
    if (activityData.record_date_time) {
      const jsDate = new Date(activityData.record_date_time);
      const year = jsDate.getFullYear();
      const month = (jsDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
      const day = jsDate.getDate().toString().padStart(2, '0');

      const formattedDate = `${year}-${month}-${day}`;

      const hours = jsDate.getHours();
      const minutes = jsDate.getMinutes();
      const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

      setGetFormattedDate(formattedDate);
      setGetFormattedTime(formattedTime)
    }

    setGetAssignUser(activityData.record_assign_user_id);
    setSelectedActivityData(activityData);
    handleGetSelectedActivity(activityData)
    setShowEditModal(true);
  };

  const handleGetSelectedActivity = async (activityData) => {
    try {
      const res = await axios.get("ws/ws_deal.php", {
        params: {
          activity: activityData.record_activity_id,
          task: 18
        }
      })
      const data = res.data;

      if (data.status === 0) {
        setEditActivityData(data.record);
      } else {
        setEditActivityData([]);
      }
    } catch {

    }
  }

  const onEditActivity = (params) => {
    handleShowEditModal(params.data);
  };

  const EditActivityModal = ({ show, handleClose, activityData }) => {
    const [activityDataChange, setActivityDataChange] = useState(activityData);
    const [formattedDate, setFormattedDate] = useState(getFormattedDate);
    const [selectedTime, setSelectedTime] = useState(getFormattedTime);
    const [getDescription, setGetDescription] = useState("");
    const [selectedDuration, setSelectedDuration] = useState("");
    const [timeOptions] = useState(timings);
    const [isMarkedAsDone, setIsMarkedAsDone] = useState(
      activityDataChange?.record_activity_mad == "yes"
    );


    const handleSwitchChange = (e) => {
      const isChecked = e.target.checked;
      setIsMarkedAsDone(isChecked);
    };

    useEffect(() => {
      if (activityDataChange && activityDataChange.record_date_time) {
        setGetDescription(activityDataChange.record_description)
      }
    }, [activityDataChange, timeOptions]);

    if (!activityDataChange) {
      return null;
    }

    const Change = (field) => (value) => {
      setActivityDataChange((prevData) => {
        const newData = {
          ...prevData,
          [field]: value,
        };

        return newData;
      });
    };

    const onUpdateClick = (values) => {
      setActivityDataChange((prevData) => {
        const { record_activity_id, record_title, record_date_time, record_activity_mad, record_create_user_id, record_assign_user_id, record_activity_ca_id, record_activity_type_id, record_description, select_date, new_record_description } = prevData;

        // Parse the date string into a JavaScript Date object
        const jsDate = new Date(record_date_time);

        const hours = jsDate.getHours();
        const minutes = jsDate.getMinutes();
        const seconds = jsDate.getSeconds();

        // Format the date as "YYYY-MM-DD" for the input type="date"
        const formattedDate = jsDate.toISOString().split("T")[0];
        setFormattedDate(formattedDate);

        const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
        setSelectedTime(formattedTime);

        handleUpdate(record_activity_id, record_title, formattedDate, selectedTime, isMarkedAsDone, record_create_user_id, record_assign_user_id, record_activity_ca_id, record_activity_type_id, record_description, select_date, new_record_description, values.record_description);

        setGridKey((prevKey) => prevKey + 1);

        handleClose();
        return prevData;
      });
    };

    const handleUpdateList = async (values) => {
      try {
        const payload = {
          activity_id: values.aid,
          ca_id: selectedActivityData.record_activity_ca_id ? selectedActivityData.record_activity_ca_id : 0,
          description: values.content,
          assign_user: values.assignUser,
          create_user: values.createUser,
          dat_id: values.dat,
          dated: getFormattedDate,
          duration: values.duration,
          mad: values.mad,
          timed: values.time,
          title: values.title,
        };

        const response = await axios.get("ws/ws_deal.php", {
          params: {
            aid: payload.activity_id,
            assign_user: payload.assign_user,
            ca_id: payload.ca_id,
            content: payload.description,
            create_user: payload.create_user,
            dat: payload.dat_id,
            dated: payload.dated,
            duration: payload.duration,
            mad: payload.mad,
            task: "12",
            timed: payload.timed,
            title: payload.title,
          },
        })

        var data = response.data;

        if (data.status === 0) {
          setGridKey((prevKey) => prevKey + 1);
          handleClose();
        }

      } catch {

      }
    }

    const handleUpdate = async (activityId, updatedRecordTitle, formattedDate, selectedTime, activityMad, createUserId, assignUserId, caId, typeId, description, selectDate, newDescription, values_record_description) => {
      try {
        const madValue = activityMad ? "yes" : "no";

        const finalDate = selectDate || formattedDate;
        const finalDesc = newDescription || values_record_description || description;
        const finalCaId = caId || "";

        const response = await axios.post(
          `ws/ws_deal.php?aid=${activityId}&assign_user=${assignUserId}&ca_id=${finalCaId}&content=${finalDesc}&
          create_user=${createUserId}&dat=${typeId}&dated=${finalDate}&duration=${selectedDuration}&mad=${madValue}&
          task=12&timed=${selectedTime}&title=${updatedRecordTitle}`,
          {
            user: session.user_id,
          }
        );

        if (response.data.status === 0) {
          console.error("Update failed:", response.data.message);
        }
      } catch (error) {
        console.error("Error updating activity:", error);
      }
    };

    const getTimePeriod = (time) => {
      if (!time || typeof time !== 'string') {
        return ''; // Return an empty string or some default value if time is invalid
      }

      const hour = parseInt(time.split(':')[0], 10); // Get the hour from the time
      if (hour >= 0 && hour < 12) {
        return 'am'; // AM
      } else if (hour >= 12 && hour < 17) {
        return 'pm'; // PM
      } else {
        return 'full-day'; // Full Day
      }
    };

    const handleDayTimeChange = (selectedOption, setFieldValue) => {
      if (selectedOption.value === 'am') {
        setFieldValue('time', '09:00');
        setFieldValue('duration', '240min'); // Reset duration if necessary
      } else if (selectedOption.value === 'pm') {
        setFieldValue('time', '14:00');
        setFieldValue('duration', '240min'); // Reset duration if necessary
      } else if (selectedOption.value === 'full-day') {
        setFieldValue('time', '09:00'); // Clear or leave as is
        setFieldValue('duration', '540min'); // Set duration to 540 minutes
      }
    };

    const handleDeleteBlockRecord = async (values) => {
      MySwal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          if (values && values.aid) {
            sendDelete(values);
          } else {
            MySwal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Invalid data for deletion',
              timer: 1500
            });
          }
        }
      });
    };

    const sendDelete = async (values) => {
      try {
        const res = await axios.get("ws/ws_deal.php", {
          params: {
            activity: values.aid,
            task: 23,
          }
        })
        const data = res.data;

        if (data.status == '0') {
          setGridKey((prevKey) => prevKey + 1);
          handleClose();
        }
      } catch {

      }
    }

    const redirectProspect = () => {
      window.open(`${session.origin}/prospect/${editActivityData.prospect_id}`, "_blank");
    }
  
    const redirectDeal = () => {
      window.open(`${session.origin}/deal/${editActivityData.deal_id}`, "_blank");
    }

    return (
      <div>
        {activityDataChange.record_activity_type_id == '11' ? (
          <Modal show={show} onHide={handleClose} size="lg">
            <Formik
              onSubmit={handleUpdateList}
              initialValues={
                {
                  title: editActivityData.activity_title,
                  date: editActivityData.activity_dated,
                  time: editActivityData.activity_timed,
                  user: editActivityData.assign_user_id,
                  aid: editActivityData.activity_id,
                  assignUser: editActivityData.assign_user_id,
                  content: editActivityData.activity_content,
                  createUser: editActivityData.create_user_id,
                  dat: editActivityData.dat_id,
                  duration: editActivityData.activity_duration,
                  mad: editActivityData.activity_mark_as_done,
                }
              }
            >
              {({ handleSubmit, handleChange, setFieldValue, values, errors, touched }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Modal.Header closeButton>
                    <Modal.Title>{t("ActivityList_edit_activity_details")}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div>
                      <div className="d-flex mb-3">
                        <div style={{ width: "100%" }}>
                          <Form.Group>
                            <Form.Control
                              type="text"
                              placeholder={t("ActivityCalendar_activity_title")}
                              value={values.title}
                              onChange={handleChange}
                              name="title"
                            />
                          </Form.Group>
                        </div>
                      </div>

                      <div className="d-flex mb-3">
                        <div style={{ width: "100%" }}>
                          <Row>
                            <Form.Group as={Col}>
                              <Form.Control
                                type="date"
                                value={getFormattedDate}
                                onChange={(e) => setGetFormattedDate(e.target.value)}
                              />
                            </Form.Group>
                            <Form.Group as={Col}>
                              <FormSelect
                                options={DayTime}
                                isSearchable={true}
                                valueDefault={DayTime.find(option => option.value === getTimePeriod(values.time))}
                                placeholder={"Select time period"}
                                onChange={(selectedOption) =>
                                  handleDayTimeChange(selectedOption, setFieldValue)
                                }
                              />
                            </Form.Group>
                          </Row>
                        </div>
                      </div>

                      <div className="d-flex mb-3">
                        <div style={{ width: "100%" }}>
                          <Form.Group>
                            <FormSelect
                              placeholder={t("ActivityList_select_user")}
                              // options={activityUser}
                              options={
                                session.role_id == 3
                                  ? activityUser.filter(user => user.value === session.user_id.toString())
                                  : activityUser
                              }
                              valueDefault={activityUser.find(option => option.value === getAssignUser)}
                              onChange={(selectedOption) => {
                                Change("record_assign_user_id")(selectedOption.value);
                              }}
                            />
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={() => handleDeleteBlockRecord(values)}>
                      {t("ActivityList_delete")}
                    </Button>
                    <Button variant="primary" type="submit">
                      {t("ActivityList_update")}
                    </Button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal>
        ) : (
          <Modal show={show} onHide={handleClose} size="lg">
            <Formik
              onSubmit={onUpdateClick}
              initialValues={
                {
                  record_description: activityDataChange ? activityDataChange.record_description : "",
                }
              }
            >
              {({ handleSubmit, handleChange, values, errors, touched }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Modal.Header closeButton>
                    <Modal.Title>{t("ActivityList_edit_activity_detail")}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div>
                      <div className="d-flex mb-3">
                        {activityDataChange.deal_title && (
                          <div
                            className="me-3"
                            onClick={redirectDeal}
                            onMouseEnter={(e) => (e.currentTarget.style.textDecoration = "underline")}
                            onMouseLeave={(e) => (e.currentTarget.style.textDecoration = "none")}
                            style={{
                              cursor: "pointer"
                            }}
                          >
                            <FontAwesomeIcon icon={faDollarCircle} className="me-1" /> {activityDataChange.deal_title}
                          </div>
                        )}
                        {activityDataChange.prospect_name_alt && (
                          <div
                            className="me-3"
                            onClick={redirectProspect}
                            onMouseEnter={(e) => (e.currentTarget.style.textDecoration = "underline")}
                            onMouseLeave={(e) => (e.currentTarget.style.textDecoration = "none")}
                            style={{
                              cursor: "pointer"
                            }}
                          >
                            <FontAwesomeIcon icon={faUser} /> {activityDataChange.prospect_name_alt}
                          </div>
                        )}
                      </div>

                      <div className="d-flex mb-3">
                        <Form.Group>
                          <ButtonGroup className="activity-tab">
                            <ToggleButton variant="" type="radio" name="activity_type" id="activity_type_1" value="1" checked={activityDataChange.record_activity_type_id === "1"} onChange={(e) => Change("record_activity_type_id")(e.target.value)}>
                              <FontAwesomeIcon icon={faPhoneVolume} className="me-1" />
                              {t("ActivityList_call")}
                            </ToggleButton>
                            <ToggleButton variant="" type="radio" name="activity_type" id="activity_type_2" value="2" checked={activityDataChange.record_activity_type_id === "2"} onChange={(e) => Change("record_activity_type_id")(e.target.value)}>
                              <FontAwesomeIcon icon={faUsers} className="me-1" />
                              {t("ActivityList_meeting")}
                            </ToggleButton>
                            <ToggleButton variant="" type="radio" name="activity_type" id="activity_type_3" value="3" checked={activityDataChange.record_activity_type_id === "3"} onChange={(e) => Change("record_activity_type_id")(e.target.value)}>
                              <FontAwesomeIcon icon={faStopwatch} className="me-1" />
                              {t("ActivityList_task")}
                            </ToggleButton>
                            <ToggleButton variant="" type="radio" name="activity_type" id="activity_type_4" value="4" checked={activityDataChange.record_activity_type_id === "4"} onChange={(e) => Change("record_activity_type_id")(e.target.value)}>
                              <FontAwesomeIcon icon={faFlag} className="me-1" />
                              {t("ActivityList_deadline")}
                            </ToggleButton>
                            <ToggleButton variant="" type="radio" name="activity_type" id="activity_type_5" value="5" checked={activityDataChange.record_activity_type_id === "5"} onChange={(e) => Change("record_activity_type_id")(e.target.value)}>
                              <FontAwesomeIcon icon={faEnvelopeOpenText} className="me-1" />
                              {t("ActivityList_email")}
                            </ToggleButton>
                          </ButtonGroup>
                        </Form.Group>
                      </div>

                      <div className="d-flex mb-3">
                        <div style={{ width: "100%" }}>
                          <Form.Group>
                            <Form.Control type="text" placeholder="Activity title" value={activityDataChange.record_title} onChange={(e) => Change("record_title")(e.target.value)} />
                          </Form.Group>
                        </div>
                      </div>

                      <div>
                        <div className="d-flex mb-3">
                          <div style={{ width: "100%" }}>
                            <Row>
                              <Form.Group as={Col}>
                                <Form.Control
                                  type="date"
                                  value={formattedDate}
                                  onChange={(e) => {
                                    setFormattedDate(e.target.value)
                                    Change("select_date")(e.target.value)
                                  }}
                                />
                              </Form.Group>
                              <Form.Group as={Col}>
                                <FormSelect
                                  options={timings}
                                  isSearchable={true}
                                  valueDefault={timings.find(option => option.value === getFormattedTime)}
                                  onChange={(selectedOption) => setSelectedTime(selectedOption.value)}
                                  placeholder={t("ActivityList_select_time")}
                                />
                              </Form.Group>
                              <Form.Group as={Col}>
                                <FormSelect
                                  options={durationList}
                                  isSearchable={true}
                                  valueDefault={durationList.find(option => option.value == editActivityData.activity_duration)}
                                  onChange={(selectedOption) => setSelectedDuration(selectedOption.value)}
                                  placeholder={t("ActivityList_select_duration")}
                                />
                              </Form.Group>
                            </Row>
                          </div>
                        </div>

                        <div>{t("ActivityList_assigned_to")}</div>
                        <div className="d-flex mb-3">
                          <div style={{ width: "100%" }}>
                            <Form.Group>
                              <FormSelect
                                placeholder={t("ActivityList_select_user")}
                                // options={activityUser}
                                options={
                                  session.role_id == 3
                                    ? activityUser.filter(user => user.value === session.user_id.toString())
                                    : activityUser
                                }
                                valueDefault={activityUser.find(option => option.value === getAssignUser)}
                                onChange={(selectedOption) => {
                                  Change("record_assign_user_id")(selectedOption.value);
                                }}
                              />
                            </Form.Group>
                          </div>
                        </div>
                      </div>

                      <div className="d-flex mb-3">
                        <div style={{ width: "100%" }}>
                          <Form.Group className="activity-notes">
                            <NoteEditor value={values.record_description} onChangeValue={(value) => handleChange("record_description")(value)} />
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <div className="w-auto">
                      <Form.Check
                        type="switch"
                        name="mad"
                        id="mad"
                        label={t("ActivityList_mad")}
                        className="d-flex justify-content-center align-items-center me-3"
                        checked={isMarkedAsDone}
                        onChange={handleSwitchChange}
                      />
                    </div>
                    <Button variant="secondary" onClick={handleClose}>
                      {t("ActivityList_close")}
                    </Button>
                    <Button variant="primary" type="submit">
                      {t("ActivityList_update")}
                    </Button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal>
        )}
      </div>
    );
  };

  // SEND FUNCTION ---------------------------------------------

  const sendDeleteActivity = (params) => {
    MySwal.fire({
      title: t("ActivityList_are_you_sure"),
      text: t("ActivityList_you_wont_be_able_to_revert_this"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("ActivityList_yes_delete_it"),
      cancelButtonText: t("Activity_cancel")
    }).then((result) => {
      if (result.isConfirmed) {
        if (params && params.record_activity_id) {
          performDeleteActivity(params);
        } else {
          console.error("Invalid params.data:", params.data);
          MySwal.fire(t("ActivityList_error"), t("ActivityList_invalid_data_for_deletion"), "error");
        }
      }
    });
  };

  const performDeleteActivity = (params) => {
    axios
      .get("ws/ws_deal.php", {
        params: {
          task: 23,
          activity: params.record_activity_id,
          user: session.user_id,
        },
      })
      .then((response) => {
        setGridKey((prevKey) => prevKey + 1);
        MySwal.fire(t("ActivityList_deleted"), t("ActivityList_your_record_has_been_deleted"), "success");
      })
      .catch((error) => {
        console.error("Error deleting record:", error);
        MySwal.fire(t("ActivityList_error"), t("ActivityList_an_error_occured_while_deleting_record"), "error");
      });
  };

  // GRID SETUP ------------------------------------------------

  const gridRef = useRef(null);
  const containerStyle = useMemo(() => ({ width: "100%", height: isActivity ? "100%" : "0%" }), [isActivity]);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const RenderActivityIcon = ({ record }) => {
    if (record.record_type === "Activity") {
      if (Number(record.record_activity_type_id) === 1) {
        return <FontAwesomeIcon icon={faPhone} size="xl" />;
      } else if (Number(record.record_activity_type_id) === 2) {
        return <FontAwesomeIcon icon={faUsers} size="xl" />;
      } else if (Number(record.record_activity_type_id) === 3) {
        return <FontAwesomeIcon icon={faListCheck} size="xl" />;
      } else if (Number(record.record_activity_type_id) === 4) {
        return <FontAwesomeIcon icon={faFlag} size="xl" />;
      } else if (Number(record.record_activity_type_id) === 5) {
        return <FontAwesomeIcon icon={faEnvelopeCircleCheck} size="xl" />;
      } else if (Number(record.record_activity_type_id) === 6) {
        return <FontAwesomeIcon icon={faPhoneArrowDownLeft} size="xl" />;
      } else if (Number(record.record_activity_type_id) === 7) {
        return <FontAwesomeIcon icon={faPhoneArrowUpRight} size="xl" />;
      } else if (Number(record.record_activity_type_id) === 8) {
        return <FontAwesomeIcon icon={faWhatsapp} size="xl" />;
      } else if (Number(record.record_activity_type_id) === 9) {
        return <FontAwesomeIcon icon={faLink} size="xl" />;
      } else if (Number(record.record_activity_type_id) === 10) {
        return <FontAwesomeIcon icon={faLocationCheck} size="xl" />;
      } else if (Number(record.record_activity_type_id) === 11) {
        return <FontAwesomeIcon icon={faCircleMinus} size="xl" />;
      }
    } else if (record.record_type === "CustomActivity") {
      return <FontAwesomeIcon icon={faListCheck} size="xl" />;
    } else if (record.record_type === "NoEditActivity") {
      if (Number(record.record_activity_type_id) === 1) {
        return <FontAwesomeIcon icon={faPhone} size="xl" />;
      } else if (Number(record.record_activity_type_id) === 2) {
        return <FontAwesomeIcon icon={faUsers} size="xl" />;
      } else if (Number(record.record_activity_type_id) === 3) {
        return <FontAwesomeIcon icon={faListCheck} size="xl" />;
      } else if (Number(record.record_activity_type_id) === 4) {
        return <FontAwesomeIcon icon={faFlag} size="xl" />;
      } else if (Number(record.record_activity_type_id) === 5) {
        return <FontAwesomeIcon icon={faEnvelopeCircleCheck} size="xl" />;
      } else if (Number(record.record_activity_type_id) === 6) {
        return <FontAwesomeIcon icon={faPhoneArrowDownLeft} size="xl" />;
      } else if (Number(record.record_activity_type_id) === 7) {
        return <FontAwesomeIcon icon={faPhoneArrowUpRight} size="xl" />;
      } else if (Number(record.record_activity_type_id) === 8) {
        return <FontAwesomeIcon icon={faWhatsapp} size="xl" />;
      } else if (Number(record.record_activity_type_id) === 9) {
        return <FontAwesomeIcon icon={faLink} size="xl" />;
      } else if (Number(record.record_activity_type_id) === 10) {
        return <FontAwesomeIcon icon={faLocationCheck} size="xl" />;
      } else if (Number(record.record_activity_type_id) === 11) {
        return <FontAwesomeIcon icon={faCircleMinus} size="xl" />;
      }
    } else {
      return "";
    }
  };

  // remove p tag
  function removePTags(htmlString) {
    const doc = new DOMParser().parseFromString(htmlString, 'text/html');
    const textWithoutPTags = doc.body.textContent || "";
    return textWithoutPTags;
  }

  const RenderActivityRows = (params) => {
    if (params.data) {
      const descriptionTooltip = (
        <Popover id={`popover-description-${params.data.record_activity_id}`} style={{ maxWidth: "500px" }}>
          <Popover.Body>{params.data.record_description}</Popover.Body>
        </Popover>
      );

      const cleanDescription = removePTags(params.data.record_description);

      return (
        <Stack direction="horizontal" gap={3} style={{ padding: "10px 0" }}>
          <div className="shadow-sm border text-center rounded-circle d-flex justify-content-center align-items-center" style={{ height: 50, width: 50 }}>
            <RenderActivityIcon record={params.data} />
          </div>

          <Stack direction="vertical" className="justify-content-center" gap={1}>
            {params.data.record_title && <p style={{ fontSize: 15, lineHeight: 1.5, width: 800, marginBottom: 5, fontWeight: 600 }}>{params.data.record_title}</p>}
            {params.data.record_description && (
              <div style={{ maxWidth: 700 }}>
                <OverlayTrigger trigger="hover" placement="top" overlay={descriptionTooltip}>
                  <p
                    style={{
                      fontSize: 12,
                      lineHeight: 1.5,
                      marginBottom: 5,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {cleanDescription}
                  </p>
                </OverlayTrigger>
              </div>
            )}

            <Stack direction="horizontal" gap={4}>
              {params.data.CREATE_USER && (
                <p className="text-capitalize" style={{ fontSize: 12, lineHeight: 1.5, margin: 0 }}>
                  <FontAwesomeIcon icon={faCalendarCircleUser} size="lg" className="me-2" />
                  {t("ActivityList_created_by", { user: params.data.CREATE_USER })}
                </p>
              )}

              {params.data.record_date_time && (
                <p className="text-capitalize" style={{ fontSize: 12, lineHeight: 1.5, margin: 0 }}>
                  <FontAwesomeIcon icon={faStopwatch} size="lg" className="me-2" />
                  {/* {t("ActivityList_record_activity_type_and_time", { actType: params.data.record_activity_type ? params.data.record_activity_type : "Due", actTime: params.data.record_date_time })} */}
                  {t("ActivityList_record_activity_type_and_time", {
                    actType: params.data.record_activity_type ? params.data.record_activity_type : "Due",
                    actTime: params.data.record_date_time.replace(/am|pm/i, (match) => match.toUpperCase()),
                  })}
                </p>
              )}

              {params.data.deal_title && (
                <p className="text-decoration-none text-dark" style={{ lineHeight: 1.5 }}>
                  <FontAwesomeIcon icon={faDollarCircle} size="lg" className="me-1" /> {params.data.deal_title}
                </p>
              )}

              {params.data.prospect_name_alt && (
                <p className="text-decoration-none text-dark" style={{ lineHeight: 1.5 }}>
                  <FontAwesomeIcon icon={faUser} size="lg" className="me-1" /> {params.data.prospect_name_alt}
                </p>
              )}
            </Stack>
          </Stack>
        </Stack>
      );
    }
  };

  const RenderActivityAction = (params) => {
    const tippyRef = useRef();
    const [visible, setVisible] = useState(false);
    const show = () => setVisible(true);
    const hide = () => setVisible(false);

    const dropDownContent = (
      <div className="op-ag-action">
        <div onClick={() => onClickHandler("edit")} className="op-ag-action-item">
          {t("ActivityList_edit_activity")}
        </div>
        <div onClick={() => onClickHandler("delete")} className="op-ag-action-item">
          {t("ActivityList_delete_activity")}
        </div>
      </div>
    );

    const onClickHandler = (option) => {
      hide();

      if (option === "delete") {
        sendDeleteActivity(params.data);
      }

      if (option === "edit") {
        onEditActivity(params);
      }
    };

    if (params.data !== undefined) {
      return (
        <Tippy ref={tippyRef} content={dropDownContent} visible={visible} onClickOutside={hide} allowHTML={true} arrow={false} appendTo={document.body} interactive={true} placement="left">
          <button className="btn btn-light" onClick={visible ? hide : show}>
            <FontAwesomeIcon icon={faEllipsisStrokeVertical} size="lg" />
          </button>
        </Tippy>
      );
    }
  };

  const gridColumn = [
    {
      headerName: "",
      field: "",
      cellClass: "center",
      flex: 0.1,
      cellRenderer: (params) => {
        if (params.data !== undefined) {
          const markasdone = (mad, activity_id) => {
            if (activity_id === 6 || activity_id === 7) {
              return;
            }

            var strMad = "";
            if (mad === "yes") {
              strMad = "no";
            } else {
              strMad = "yes";
            }

            axios
              .get("ws/ws_deal.php", {
                params: {
                  task: 16,
                  activity: activity_id,
                  status: strMad,
                  user: session.user_id,
                },
              })
              .then((response) => {
                params.node.setData({ ...params.data, record_activity_mad: strMad });
              });
          };
          return (
            <Form.Check className="d-flex align-items-center justify-content-center" type="checkbox" id={params.data.record_activity_id} checked={params.data.record_activity_mad === "yes" ? true : false} onChange={() => markasdone(params.data.record_activity_mad, params.data.record_activity_id)} />
          );
        } else {
          return <img src="https://www.ag-grid.com/example-assets/loading.gif" alt="loading" />;
        }
      },
    },
    {
      headerName: "",
      field: "",
      cellRenderer: RenderActivityRows,
      flex: 0.9,
    },
    {
      headerName: "",
      field: "",
      cellClass: "center",
      flex: 0.1,
      cellRenderer: RenderActivityAction,
    },
  ];

  const gridDataSource = useMemo(() => {
    if (isSelectedDate === "range") {
      if (!(dateRange.ds && dateRange.de)) {
        return;
      }
    }

    return {
      rowCount: undefined,
      getRows: (params) => {
        axios
          .get("ws/ws_deal.php", {
            params: {
              task: "26",
              company: session.company_id,
              utoken: session.user_ac_token,
              ctoken: session.company_token,
              sorting: "",
              sortinType: "",
              ca_id: Number(ctxActivity.customActivity) > 0 ? ctxActivity.customActivity : "",
              user: Number(session.role_id) !== 1 ? session.user_id : Number(ctxActivity.user) > 0 ? Number(ctxActivity.user) : "",
              dat: Number(ctxActivity.activity) > 0 ? ctxActivity.activity : "",
              df: isSelectedDate,
              ds: dateRange.ds ? dateRange.ds : "",
              de: dateRange.de ? dateRange.de : "",
              page: Math.floor(params.startRow / (params.endRow - params.startRow)) + 1,
              size: params.endRow - params.startRow,
            },
          })
          .then((response) => {
            const data = response.data;

            if (data.status === 0) {
              if (Number(data.count) > 0) {
                setisActivity(true);
              } else {
                setisActivity(false);
              }
            } else {
              setisActivity(false);
            }

            setTimeout(() => {
              var gridInfo = [];
              var totalRecord = 0;
              if (data.status === 0) {
                gridInfo = data.record;
                totalRecord = Number(data.count);
              } else {
                gridInfo = [];
                totalRecord = 0;
              }

              var rowsThisPage = gridInfo;
              var lastRow = -1;

              if (Number(totalRecord) <= params.endRow) {
                lastRow = totalRecord;
              }

              params.successCallback(rowsThisPage, lastRow);
            }, 500);
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      },
    };
  }, [dateRange, isSelectedDate, ctxActivity, session]);

  const gridColDef = useMemo(() => {
    return {
      flex: 1,
      sortable: false,
      filter: false,
    };
  }, []);

  const gridRowId = useCallback(function (params) {
    return params.data.record_activity_id.toString();
  }, []);

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      getActivityCounting(session);
    }

    if (updateGrid) {
      setGridKey((prevKey) => prevKey + 1);
    }
  }, [updateGrid]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <section className="content-body">
      <div className="section-sidebar">
        <Stack direction="vertical">
          <Nav variant="pills" className="flex-column mx-2 mt-3 activity-filter" defaultActiveKey="all" onSelect={(key) => setisSelectedDate(key)}>
            <Nav.Item className="mb-2">
              <Nav.Link className="d-flex justify-content-between align-items-center" eventKey="all">
                {t("ActivityList_all")} {activityCounting.all > 0 && <Badge bg="primary">{activityCounting.all}</Badge>}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="mb-2">
              <Nav.Link className="d-flex justify-content-between align-items-center" eventKey="overdue">
                {t("ActivityList_overdue")} {activityCounting.overdue > 0 && <Badge bg="primary">{activityCounting.overdue}</Badge>}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="mb-2">
              <Nav.Link className="d-flex justify-content-between align-items-center" eventKey="upcoming">
                {t("ActivityList_upcoming")} {activityCounting.upcoming > 0 && <Badge bg="primary">{activityCounting.upcoming}</Badge>}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="mb-2">
              <Nav.Link className="d-flex justify-content-between align-items-center" eventKey="today">
                {t("ActivityList_today")} {activityCounting.today > 0 && <Badge bg="primary">{activityCounting.today}</Badge>}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="mb-2">
              <Nav.Link className="d-flex justify-content-between align-items-center" eventKey="done">
                {t("ActivityList_done")} {activityCounting.done > 0 && <Badge bg="primary">{activityCounting.done}</Badge>}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="range">
                <Form.Group>
                  <Form.Label className="mb-2">{t("ActivityList_select_period")}</Form.Label>
                  <Form.Group className="mb-2">
                    <FormDate placeholder={t("ActivityList_from")} value={dateRange.ds} isClearable onChange={(date) => onchangeDateRange("ds", date)} />
                  </Form.Group>
                  <Form.Group>
                    <FormDate placeholder={t("ActivityList_to")} value={dateRange.de} isClearable onChange={(date) => onchangeDateRange("de", date)} />
                  </Form.Group>
                </Form.Group>
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Stack>
      </div>
      <div className="section-content p-3">
        <div style={containerStyle}>
          <div className={"ag-theme-quartz ag-op ag-hide-header"} style={{ ...gridStyle }}>
            <AgGridReact
              key={gridKey}
              ref={gridRef}
              headerHeight={0}
              columnDefs={gridColumn}
              datasource={gridDataSource}
              defaultColDef={gridColDef}
              rowSelection={"multiple"}
              rowModelType={"infinite"}
              cacheBlockSize={100}
              cacheOverflowSize={2}
              maxConcurrentDatasourceRequests={2}
              infiniteInitialRowCount={10}
              maxBlocksInCache={2}
              getRowId={gridRowId}
              rowHeight={150}
              pagination={true}
              paginationPageSize={100}
              paginationPageSizeSelector={false}
              suppressRowClickSelection={true}
            />
          </div>
        </div>
        {!isActivity && <NoRecord message={t("ActivityList_no_record_msg")} description={t("ActivityList_no_record_desc")} width={300} />}
      </div>
      <EditActivityModal show={showEditModal} handleClose={handleCloseEditModal} activityData={selectedActivityData} />
    </section>
  );
};

export default ActivityList;
