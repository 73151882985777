import { useState, useEffect, useRef } from "react";
import { Button, Col, Container, Row, Stack, Nav, Card, Image, Modal, Offcanvas } from "react-bootstrap";
import axios from "../api/axios";
import Loader from "../includes/Loader";
import { useNavigate, useParams } from "react-router-dom";
import "../lib/scss/unitDetails.scss";
import { faHouseBuilding, faHouseChimneyWindow, faSackDollar, faHouse as faHouse2, faHouseFlag, faBorderBottomRight, faStreetView, faBedFront, faBath, faSquareParking } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse } from "@fortawesome/free-solid-svg-icons";
import { setCurrency } from "../lib/js/Function";
import { faChevronRight, faX } from "@fortawesome/pro-light-svg-icons";
import MortgageCalculator from "./MortgageCalculator";
import { Swiper, SwiperSlide } from "swiper/react";
import { Zoom, FreeMode, Navigation, Thumbs, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "swiper/css/pagination";
import { useKit } from "./CustomerKit";

const CustomerKitUnitDetails = () => {
  const init = useRef(false);
  const { key, project_id, phase_id, unit_number } = useParams();
  const [loading, setLoading] = useState(true);
  const [unitData, setUnitData] = useState({});
  const [tab, setTab] = useState("details");
  const navigate = useNavigate();
  const [unitImages, setUnitImages] = useState([]);
  const [floorPlan, setFloorPlan] = useState([]);
  const [isCalculator, setIsCalculator] = useState(false);
  const [isUnit, setIsUnit] = useState(false);
  const [unitSwiper, setUnitSwiper] = useState(false);
  const [unitSwiperIndex, setUnitSwiperIndex] = useState(0);
  const [unitModalSwiperIndex, setUnitModalSwiperIndex] = useState(0);
  const [isFloorPlan, setIsFloorPlan] = useState(false);
  const [floorPlanSwiper, setFloorPlanSwiper] = useState(false);
  const [floorPlanSwiperIndex, setFloorPlanSwiperIndex] = useState(0);
  const [floorPlanModalSwiperIndex, setFloorPlanModalSwiperIndex] = useState(0);
  const [modules, setModules] = useState({});
  const { hostUrl, hostUrlType, company_id } = useKit();

  // GET FUNCTION -----------------------------------------

  const getViewUnit = async () => {
    try {
      const response = await axios.get("ws/ws_rea_sales_kit.php", {
        params: {
          task: "SalesKit_saleschart-unit-details",
          key: key,
          project_id: project_id,
          phase_id: phase_id,
          unit_number: unit_number,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        data.record.unit_status = Number(data.record.on_hold) === 1 ? "on_hold" : data.record.unit_status;
        setUnitData(data.record);
        setUnitImages(data.record.unit_images);
        setModules(data.modules);

        if (data.record.unit_floor_plan.length > 0) {
          setFloorPlan(data.record.unit_floor_plan);
        } else {
          setFloorPlan(data.record.floor_plan);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  // OPEN FUNCTION ----------------------------------------

  const openFindUnit = () => {
    const params = {
      rea_project_id: unitData.rea_project_id,
      rea_phase_id: unitData.rea_phase_id,
      rea_unit_id: unitData.rea_unit_id,
      level_id: unitData.unit_level_title,
      carpark_id: unitData.unit_car_park_title,
    };
    sessionStorage.removeItem("findUnit");
    sessionStorage.setItem("findUnit", JSON.stringify(params));
    navigate(`/rea-sales-kit/${key}/${project_id}/${phase_id}/saleschart?search=true`);
  };

  const openFindLevel = () => {
    // const params = {
    //   rea_project_id: unitData.rea_project_id,
    //   rea_phase_id: unitData.rea_phase_id,
    //   rea_unit_id: unitData.rea_unit_id,
    //   level_id: unitData.unit_level_title,
    //   carpark_id: unitData.unit_car_park_title,
    // };
    // sessionStorage.removeItem("findUnit");
    // sessionStorage.setItem("findUnit", JSON.stringify(params));
    // navigate(`/sales-chart/level/${deal_id}/${prospect_id}/${unitData.rea_project_id}/${unitData.rea_phase_id}/${unitData.unit_level_title}?search=true`);
  };

  const openFindCarpark = () => {
    // const params = {
    //   rea_project_id: unitData.rea_project_id,
    //   rea_phase_id: unitData.rea_phase_id,
    //   rea_unit_id: unitData.rea_unit_id,
    //   level_id: unitData.unit_level_title,
    //   carpark_id: unitData.unit_car_park_title,
    // };
    // sessionStorage.removeItem("findUnit");
    // sessionStorage.setItem("findUnit", JSON.stringify(params));
    // navigate(`/sales-chart/carpark/${deal_id}/${prospect_id}/${unitData.rea_project_id}/${unitData.rea_phase_id}/${unitData.unit_car_park_title}?search=true`);
  };

  // SET FUNCTION -----------------------------------------
  const setStatusColor = (status) => {
    if (status === "available") {
      return "success";
    } else if (status === "booked") {
      return "warning";
    } else if (status === "reserved") {
      return "primary";
    } else if (status === "sold") {
      return "danger";
    } else if (status === "unavailable") {
      return "secondary";
    } else if (status === "on_hold") {
      return "orange";
    } else {
      return "";
    }
  };

  const setUnitStatusLabel = (status) => {
    if (status === "available") {
      return "Available";
    } else if (status === "booked") {
      return "Booked";
    } else if (status === "reserved") {
      return "Reserved";
    } else if (status === "sold") {
      return "Sold";
    } else if (status === "unavailable") {
      return "Unavailable";
    } else if (status === "on_hold") {
      return "On Hold";
    } else {
      return "";
    }
  };

  const setSwiperLabel = (index, images) => {
    const cleanLabel = (filename) => {
      let decodedFilename = decodeURIComponent(filename);
      decodedFilename = decodedFilename.replace(/\.[^/.]+$/, "");
      decodedFilename = decodedFilename.replace(/_/g, " ");
      return decodedFilename;
    };

    if (index < images.length) {
      return cleanLabel(images[index]);
    }

    return "Unnamed";
  };

  const setProjectImage = (image) => {
    if (image !== "generic.png") {
      return `${hostUrl}/${hostUrlType}/assets/rea_booking/${company_id}/project/${image}`;
    } else {
      return `${hostUrl}/${hostUrlType}/assets/rea_booking/generic.png`;
    }
  };

  const setFormat = (format) => {
    return Number(format) === 1;
  };

  // SEND FUNCTION ---------------------------------------

  const RenderImageUnavailable = () => (
    <div className="position-relative w-100 d-flex align-items-center justify-content-center" style={{ height: 400 }}>
      <FontAwesomeIcon icon={faHouse} size="10x" style={{ opacity: 0.05 }} />
      <h6 style={{ position: "absolute" }}>Image Not Available</h6>
    </div>
  );

  useEffect(() => {
    const initData = async () => {
      if (!init.current) {
        try {
          await getViewUnit();
          init.current = true;
          setTimeout(() => setLoading(false), 600);
        } catch (error) {
          setLoading(false);
        }
      }
    };
    initData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container fluid className="m-0 p-0">
      {loading ? (
        <Loader />
      ) : (
        <div className="unit-details mt-3">
          <Container fluid className="py-3 px-0">
            <Row>
              <Col xxl={12} className="mb-4">
                <Card className="border-0 shadow-sm w-100">
                  <Card.Body>
                    <Row className="h-100">
                      <Col xxl={8} className="h-100">
                        <div className="op-text-bigger fs-5 fw-bold d-flex align-items-center h-100">{unitData.unit_number}</div>
                      </Col>
                      <Col xxl={4} className="h-100">
                        <Stack direction="horizontal" gap={2} className="h-100 d-flex flex-row-reverse">
                          {setFormat(modules.is_bumi_reserved) && (
                            <Button variant="" className="op-primary-color text-light" onClick={() => openFindUnit()}>
                              Find Unit
                            </Button>
                          )}
                        </Stack>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
              {(setFormat(modules.up_spa_price) || setFormat(modules.up_selling_psf) || setFormat(modules.up_net_price_after_discount) || setFormat(modules.up_max_discount) || setFormat(modules.up_mortgage_calculator) || setFormat(modules.up_bumi_selling_psf)) && (
                <Col xxl={12} className="mb-4">
                  <Card className="border-0 shadow-sm w-100">
                    <Card.Header className="fw-bold">Unit Pricing</Card.Header>
                    <Card.Body>
                      <Row>
                        {[
                          { key: "up_spa_price", label: modules.up_spa_price_label, icon: faSackDollar, value: setCurrency(unitData.unit_selling_price || 0) },
                          { key: "up_selling_psf", label: modules.up_selling_psf_label, icon: faSackDollar, value: unitData.selling_psf },
                          { key: "up_net_price_after_discount", label: modules.up_net_price_after_disc_label, icon: faSackDollar, value: unitData.net_price_after_disc },
                          { key: "up_bumi_selling_psf", label: modules.up_bumi_selling_psf_label, icon: faSackDollar, value: unitData.bumi_selling_psf },
                          { key: "up_max_discount", label: modules.up_max_discount_label, icon: faSackDollar, value: unitData.max_discount },
                        ].map(
                          ({ key, label, icon, value }) =>
                            setFormat(modules[key]) && (
                              <Col xxl={4} className="mb-3" key={key}>
                                <div className="w-100">
                                  <div className="d-flex align-items-center">
                                    <div className="me-2" style={{ width: 30 }}>
                                      <FontAwesomeIcon icon={icon} size="2x" />
                                    </div>
                                    <div>
                                      <span className="fw-bold">{label}</span> <br />
                                      <span className="text-muted">{value}</span>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            )
                        )}
                      </Row>
                    </Card.Body>
                    {setFormat(modules.up_mortgage_calculator) && (
                      <Card.Footer className="py-3 d-flex justify-content-between align-items-center cursor-pointer" onClick={setIsCalculator}>
                        <span>{modules.up_mortgage_calculator_label}</span>
                        <span>
                          <FontAwesomeIcon icon={faChevronRight} size="xl" />
                        </span>
                      </Card.Footer>
                    )}
                  </Card>
                </Col>
              )}

              {(setFormat(modules.ud_project) ||
                setFormat(modules.ud_phase) ||
                setFormat(modules.ud_unit_type) ||
                setFormat(modules.ud_unit_number) ||
                setFormat(modules.ud_bedroom) ||
                setFormat(modules.ud_bathroom) ||
                setFormat(modules.ud_builtup_area) ||
                setFormat(modules.ud_builtup_area_sqm) ||
                setFormat(modules.ud_land_area) ||
                setFormat(modules.ud_land_area_sqm) ||
                setFormat(modules.ud_unit_level) ||
                setFormat(modules.ud_facing) ||
                setFormat(modules.ud_unit_car_park_no) ||
                setFormat(modules.ud_total_carpark)) && (
                <Col xxl={12} className="mb-4">
                  <Card className="border-0 shadow-sm w-100">
                    <Card.Header className="fw-bold">Unit Details</Card.Header>
                    <Card.Body>
                      <Row>
                        <Col xxl={12}>
                          <Row>
                            {[
                              { key: "ud_project", label: modules.ud_project_label, icon: faHouseBuilding, value: unitData.project_name || "Unspecified" },
                              { key: "ud_phase", label: modules.ud_phase_label, icon: faHouseChimneyWindow, value: unitData.phase_name || "Unspecified" },
                              { key: "ud_unit_type", label: modules.ud_unit_type_label, icon: faHouseChimneyWindow, value: `${unitData.unit_title} ${unitData.unit_type}` },
                              { key: "ud_unit_number", label: modules.ud_unit_number_label, icon: faHouse2, value: unitData.unit_number || "Unspecified" },
                              { key: "ud_bedroom", label: modules.ud_bedroom_label, icon: faBedFront, value: unitData.unit_total_bedroom || "Unspecified" },
                              { key: "ud_bathroom", label: modules.ud_bathroom_label, icon: faBath, value: unitData.unit_total_bathroom || "Unspecified" },
                              { key: "ud_builtup_area", label: modules.ud_builtup_area_label, icon: faHouseFlag, value: unitData.unit_builtup_area || "Unspecified" },
                              { key: "ud_builtup_area_sqm", label: modules.ud_builtup_area_sqm_label, icon: faHouseFlag, value: unitData.unit_builtup_area_sqm },
                              { key: "ud_land_area", label: modules.ud_land_area_label, icon: faBorderBottomRight, value: unitData.unit_land_area || "Unspecified" },
                              { key: "ud_land_area_sqm", label: modules.ud_land_area_sqm_label, icon: faBorderBottomRight, value: unitData.unit_land_area_sqm },
                              { key: "ud_unit_level", label: modules.ud_unit_level_label, icon: faHouse2, value: unitData.unit_level_title || "Unspecified", condition: unitData.phase_layout === "condo" },
                              { key: "ud_facing", label: modules.ud_facing_label, icon: faStreetView, value: unitData.unit_facing || "Unspecified" },
                              { key: "ud_unit_car_park_no", label: modules.ud_unit_car_park_no_label, icon: faSquareParking, value: unitData.unit_car_park_no || "Unspecified" },
                              { key: "ud_total_carpark", label: modules.ud_total_carpark_label, icon: faSquareParking, value: unitData.total_carpark || "Unspecified" },
                            ].map(
                              ({ key, label, icon, value, condition = true }) =>
                                setFormat(modules[key]) &&
                                condition && (
                                  <Col xxl={4} className="mb-3" key={key}>
                                    <div className="w-100">
                                      <div className="d-flex align-items-center">
                                        <div className="me-2" style={{ width: 30 }}>
                                          <FontAwesomeIcon icon={icon} size="2x" />
                                        </div>
                                        <div>
                                          <span className="fw-bold">{label}</span> <br />
                                          <span className="text-muted">{value}</span>
                                        </div>
                                      </div>
                                    </div>
                                  </Col>
                                )
                            )}
                          </Row>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              )}

              {setFormat(modules.is_solicitor) && (
                <Col xxl={12} className="mb-4">
                  <Card className="border-0 shadow-sm w-100">
                    <Card.Header className="fw-bold">Solicitor Details</Card.Header>
                    <Card.Body>
                      {[
                        { label: "Solicitor", value: unitData.solicitor_info },
                        { label: "Solicitor Contact", value: unitData.solicitor_contact },
                        { label: "Solicitor Address", value: unitData.solicitor_address },
                        { label: "Solicitor PIC", value: unitData.solicitor_pic },
                      ].map(({ label, value }, index) => (
                        <section className="mb-3" key={index}>
                          <div className="w-100">
                            <span className="fw-bold">{label}</span> <br />
                            <span className="text-muted">{value || "Unspecified"}</span>
                          </div>
                        </section>
                      ))}
                    </Card.Body>
                  </Card>
                </Col>
              )}

              {setFormat(modules.is_remark) && (
                <Col xxl={12} className="mb-4">
                  <Card className="border-0 shadow-sm w-100 ">
                    <Card.Header className="fw-bold">Remark</Card.Header>
                    <Card.Body>{unitData.remark || "Unspecified"}</Card.Body>
                  </Card>
                </Col>
              )}

              {setFormat(modules.is_unit_gallery) && (
                <Col xxl={6} className="mb-4">
                  <Card className="border-0 shadow-sm w-100">
                    <Card.Header className="fw-bold">Unit Gallery</Card.Header>
                    <Card.Body>
                      {unitImages.length > 0 ? (
                        <Swiper spaceBetween={10} pagination={true} modules={[Pagination]} className="swiperpd3">
                          {unitImages.map((data, i) => (
                            <SwiperSlide key={i}>
                              <img
                                className="cursor-pointer"
                                style={{ height: 400, borderRadius: 5 }}
                                src={setProjectImage(data)}
                                alt="mediaimage"
                                onClick={() => {
                                  setUnitSwiperIndex(i);
                                  setUnitSwiper(null);
                                  setIsUnit(true);
                                }}
                              />
                            </SwiperSlide>
                          ))}
                        </Swiper>
                      ) : (
                        <RenderImageUnavailable />
                      )}
                    </Card.Body>
                  </Card>
                </Col>
              )}

              {setFormat(modules.is_floor_plan) && (
                <Col xxl={6} className="mb-4">
                  <Card className="border-0 shadow-sm w-100">
                    <Card.Header className="fw-bold">Floor Plan</Card.Header>
                    <Card.Body>
                      <Swiper spaceBetween={10} pagination={true} modules={[Pagination]} className="swiperpd3">
                        {floorPlan.map((data, i) => (
                          <SwiperSlide key={i}>
                            <img
                              className="cursor-pointer"
                              style={{ height: 400, borderRadius: 5 }}
                              src={setProjectImage(data)}
                              alt="mediaimage"
                              onClick={() => {
                                setFloorPlanSwiperIndex(i);
                                setFloorPlanSwiper(null);
                                setIsFloorPlan(true);
                              }}
                            />
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    </Card.Body>
                  </Card>
                </Col>
              )}

              {unitData.phase_layout === "condo" && setFormat(modules.is_level) && (
                <Col xxl={6} className="mb-4">
                  <Card className="border-0 shadow-sm w-100">
                    <Card.Header className="fw-bold">Level Site Plan ({unitData.unit_level_title || "Unspecified"})</Card.Header>
                    <Card.Body onClick={() => openFindLevel()}>
                      <Image className="cursor-pointer" src={setProjectImage(unitData.unit_level_image)} style={{ width: "100%", height: 400, objectFit: "cover", borderRadius: 5 }} />
                    </Card.Body>
                  </Card>
                </Col>
              )}

              {unitData.phase_layout === "condo" && setFormat(modules.is_carpark) && (
                <Col xxl={6} className="mb-4">
                  <Card className="border-0 shadow-sm w-100">
                    <Card.Header className="fw-bold">Car Park Site Plan ({unitData.unit_car_park_title || "Unspecified"})</Card.Header>
                    <Card.Body onClick={() => openFindCarpark()}>
                      <Image className="cursor-pointer" src={setProjectImage(unitData.unit_car_park_image)} style={{ width: "100%", height: 400, objectFit: "cover", borderRadius: 5 }} />
                    </Card.Body>
                  </Card>
                </Col>
              )}
            </Row>
          </Container>
        </div>
      )}

      <Modal show={isUnit} onHide={setIsUnit} fullscreen={true} dialogClassName="op-modal-dialog-fullscreen">
        <Modal.Header className="position-relative border-0 py-1" style={{ background: "#505050" }}>
          <div className="d-flex flex-row-reverse w-100">
            <Button variant="link" className="m-0 text-light text-decoration-none" onClick={() => setIsUnit(false)}>
              <FontAwesomeIcon icon={faX} size="xl" style={{ color: "#fff" }} />
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body style={{ background: "#505050" }}>
          <Swiper
            initialSlide={unitSwiperIndex}
            onSlideChange={(swiper) => setUnitModalSwiperIndex(swiper.activeIndex)}
            spaceBetween={10}
            navigation={true}
            zoom={true}
            thumbs={{ swiper: unitSwiper }}
            modules={[Zoom, FreeMode, Navigation, Thumbs, Pagination]}
            className="mySwiper2"
            style={{
              "--swiper-navigation-color": "#fff",
              "--swiper-pagination-color": "#fff",
              zIndex: 0,
            }}
          >
            {unitImages.map((data, i) => (
              <SwiperSlide key={i} className="d-flex justify-content-center w-100">
                <div className="swiper-zoom-container">
                  <img className="cursor-pointer" src={setProjectImage(data)} style={{ width: "auto", height: "65vh", objectFit: "cover" }} alt="mediaimage" />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>

          <Row className="g-0">
            <Col md={{ span: 6, offset: 3 }}>
              <label className="my-3 text-light fw-bold">{setSwiperLabel(unitModalSwiperIndex, unitImages)}</label>
              <Swiper onSwiper={setUnitSwiper} spaceBetween={10} slidesPerView={8} freeMode={true} watchSlidesProgress={true} modules={[FreeMode, Navigation, Thumbs]}>
                {unitImages.map((data, i) => (
                  <SwiperSlide key={i} style={{ cursor: "pointer" }}>
                    <img src={setProjectImage(data)} style={{ width: "100%", height: 55, objectFit: "cover", borderRadius: 5 }} alt="mediaimage" />
                  </SwiperSlide>
                ))}
              </Swiper>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <Modal show={isFloorPlan} onHide={setIsFloorPlan} fullscreen={true} dialogClassName="op-modal-dialog-fullscreen">
        <Modal.Header className="position-relative border-0 py-1" style={{ background: "#505050" }}>
          <div className="d-flex flex-row-reverse w-100">
            <Button variant="link" className="m-0 text-light text-decoration-none" onClick={() => setIsFloorPlan(false)}>
              <FontAwesomeIcon icon={faX} size="xl" style={{ color: "#fff" }} />
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body style={{ background: "#505050" }}>
          <Swiper
            initialSlide={floorPlanSwiperIndex}
            onSlideChange={(swiper) => setFloorPlanModalSwiperIndex(swiper.activeIndex)}
            spaceBetween={10}
            navigation={true}
            zoom={true}
            thumbs={{ swiper: floorPlanSwiper }}
            modules={[Zoom, FreeMode, Navigation, Thumbs, Pagination]}
            className="mySwiper2"
            style={{
              "--swiper-navigation-color": "#fff",
              "--swiper-pagination-color": "#fff",
              zIndex: 0,
            }}
          >
            {floorPlan.map((data, i) => (
              <SwiperSlide key={i} className="d-flex justify-content-center w-100">
                <div className="swiper-zoom-container">
                  <img className="cursor-pointer" src={setProjectImage(data)} style={{ width: "auto", height: "65vh", objectFit: "cover" }} alt="mediaimage" />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>

          <Row className="g-0">
            <Col md={{ span: 6, offset: 3 }}>
              <label className="my-3 text-light fw-bold">{setSwiperLabel(floorPlanModalSwiperIndex, floorPlan)}</label>
              <Swiper onSwiper={setFloorPlanSwiper} spaceBetween={10} slidesPerView={8} freeMode={true} watchSlidesProgress={true} modules={[FreeMode, Navigation, Thumbs]}>
                {floorPlan.map((data, i) => (
                  <SwiperSlide key={i} style={{ cursor: "pointer" }}>
                    <img src={setProjectImage(data)} style={{ width: "100%", height: 55, objectFit: "cover", borderRadius: 5 }} alt="mediaimage" />
                  </SwiperSlide>
                ))}
              </Swiper>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <Offcanvas show={isCalculator} onHide={setIsCalculator} placement="bottom" style={{ height: "100vh" }}>
        <MortgageCalculator getUnitData={unitData} />
      </Offcanvas>
    </Container>
  );
};

export default CustomerKitUnitDetails;
