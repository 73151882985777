import { useState, useRef, useEffect, useMemo } from "react";
import { Container, Modal, Image, Button, ListGroup, Row, Col, Form, Spinner, Stack } from "react-bootstrap";
import axios from "../api/axios";
import { useAuth } from "../auth/AuthContext";
import Loader from "../includes/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { faArrowUpRight, faTag } from "@fortawesome/pro-light-svg-icons";
import { faPlus, faTrashAlt } from "@fortawesome/pro-solid-svg-icons";
import "../lib/css/settingStyle.css";
import { faFacebook, faFacebookF } from "@fortawesome/free-brands-svg-icons";
import { immediateToast } from "izitoast-react";

const SettingFBConnection = () => {
  const init = useRef(false);
  const { session } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [fbUserId, setFbUserId] = useState(0);
  const [authorized, setAuthorized] = useState(false);
  const [FBPages, setFBPages] = useState([]);
  const [FBInfo, setFBInfo] = useState({});
  const [isLogin, setIsLogin] = useState(2);
  const [reset, setReset] = useState(false);
  const [loadingBtnId, setLoadingBtnId] = useState(0);
  const [loadingLogin, setLoadingLogin] = useState(false);
  const [loadingPages, setLoadingPages] = useState(true);

  // GET FUNCTION ----------------------------------------------------------------

  const getInit = async () => {
    try {
      window.fbAsyncInit = () => {
        window.FB.init({
          appId: "1251640265573174",
          xfbml: true,
          version: "v18.0",
        });

        window.FB.getLoginStatus((response) => {
          if (response.authResponse !== null) {
            getPages(response.authResponse.userID);
            setIsLogin(0);
          } else {
            setIsLogin(1);
          }
        });
      };

      (function (d, s, id) {
        var js;
        var fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s);
        js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
      })(document, "script", "facebook-jssdk");
    } catch (error) {
      console.error(error);
    } finally {
      init.current = true;
      setIsLoading(false);
      setLoadingLogin(false);
      setReset(false);
    }
  };

  const getPages = async (user_id, login = false) => {
    setFbUserId(user_id);
    setLoadingPages(true);
    try {
      const response = await axios.get("/ws/ws_facebook.php", {
        params: {
          task: "listFBPages",
          ctoken: session.company_token,
          utoken: session.user_ac_token,
        },
      });

      const data = response.data;
      let added_pages = [];
      if (Number(data.status) === 0) {
        added_pages = data.record.map((item) => item.fb_page_id);
      }

      await window.FB.api(`/${user_id}/`, (response) => {
        if (response && !response.error) {
          setFBInfo(response);
          setAuthorized(true);
        }
      });

      await window.FB.api("/me/accounts", (response) => {
        if (response.data) {
          const updatedFBPages = response.data.map((item) => ({ ...item, added: added_pages.includes(item.id) }));
          console.log(updatedFBPages, added_pages);
          setFBPages(updatedFBPages);
          if (loadingPages) setLoadingPages(false);
          if (login) setIsLogin(0);
        }
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
      setLoadingLogin(false);
    }
  };

  // HANDLE FUNCTION ----------------------------------------------------------------

  const handleSubscribeApp = async (page_id, page_access_token, page_name, userID) => {
    setLoadingBtnId(page_id);
    try {
      await window.FB.api(`/${page_id}/subscribed_apps`, "post", {
        access_token: page_access_token,
        subscribed_fields: ["leadgen"],
      });

      await handleInsertFBPage(page_name, page_id, page_access_token, userID);
    } catch (error) {
      console.error(error);
    }
  };

  const handleInsertFBPage = async (page_name, page_id, page_access_token, userID) => {
    try {
      const response = await axios.get("/ws/ws_facebook.php", {
        params: {
          task: "insertFBPageV3",
          ctoken: session.company_token,
          utoken: session.user_ac_token,
          fb_page: page_name,
          fb_page_id: page_id,
          fb_page_access_token: page_access_token,
          user_id: userID,
        },
      });

      const data = response.data;
      if (Number(data.status) === 0) {
        const page_index = FBPages.findIndex((item) => item.id === page_id);
        const page_record = [...FBPages];
        page_record[page_index].added = true;
        setFBPages(page_record);

        immediateToast("success", {
          title: "Success",
          message: "Page inserted successfully",
          timeout: 2000,
        });
      } else {
        immediateToast("error", {
          title: "Failed",
          message: "Failed to insert page",
          timeout: 2000,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingBtnId(0);
    }
  };

  const handleUnSubscribeApp = async (page_id, page_access_token) => {
    setLoadingBtnId(page_id);
    try {
      await window.FB.api(`/${page_id}/subscribed_apps`, "delete", {
        access_token: page_access_token,
      });

      await handleRemoveFBPage(page_id);
    } catch (error) {
      console.error(error);
    }
  };

  const handleRemoveFBPage = async (page_id) => {
    try {
      const response = await axios.get("/ws/ws_facebook.php", {
        params: {
          task: "removeFBPage",
          ctoken: session.company_token,
          utoken: session.user_ac_token,
          fb_page_id: page_id,
        },
      });

      const data = response.data;
      if (Number(data.status) === 0) {
        const page_index = FBPages.findIndex((item) => item.id === page_id);
        const page_record = [...FBPages];
        page_record[page_index].added = false;
        setFBPages(page_record);

        immediateToast("success", {
          title: "Success",
          message: "Page removed successfully",
          timeout: 2000,
        });
      } else {
        immediateToast("error", {
          title: "Failed",
          message: "Failed to remove page",
          timeout: 2000,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingBtnId(0);
    }
  };

  const handleLogout = async () => {
    setReset(true);
    window.FB.logout();
    setIsLogin(2);

    const fbScript = document.getElementById("facebook-jssdk");
    if (fbScript) {
      fbScript.parentNode.removeChild(fbScript);
    }

    window.FB = null;
    await getInit();
  };

  // SEND FUNCTION ----------------------------------------------------------------

  const sendFBConnection = async () => {
    setLoadingLogin(true);
    setLoadingPages(true);
    try {
      await window.FB.login(
        (response) => {
          getPages(response.authResponse.userID, true);
        },
        {
          scope: ["pages_show_list", "leads_retrieval", "ads_management", "pages_manage_ads", "pages_manage_metadata", "pages_read_engagement"],
        }
      );
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!init.current) {
      getInit();
    }

    return () => {
      const fbScript = document.getElementById("facebook-jssdk");
      if (fbScript) {
        fbScript.parentNode.removeChild(fbScript);
      }

      window.FB = null;
    };
  }, [init.current]);

  return (
    <Container fluid className="p-0 overflow-x-hidden" style={{ backgroundColor: "#fff", height: "calc(100vh - 56px)", borderTop: "1px solid #e0e0e0" }}>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {isLogin === 1 ? (
            <Row>
              <Col sm={12}>
                <div style={{ borderBottom: "1px solid #e0e0e0", padding: "2rem" }}>
                  <h5>Facebook Leads Ad</h5>
                </div>
              </Col>
              <Col sm={12}>
                <div className="d-flex justify-content-between" style={{ padding: "1rem 2rem", borderBottom: "1px solid #e0e0e0" }}>
                  <div>
                    <div className="w-75" style={{ fontSize: "15px" }}>
                      Integrating Facebook Leads Ads with Outperform ensures that lead data is seamlessly transferred from Facebook to Outperform for efficient management and engagement. This setup helps businesses streamline their lead-handling process and maximize conversion opportunities through
                      automated workflows and tracking.
                    </div>
                    <ListGroup className="mt-3 w-75">
                      <ListGroup.Item className="d-flex border-0 mb-2 px-0">
                        <FontAwesomeIcon icon={faCheck} size="xl" className="me-3" color="#198754" />
                        <div>
                          <h6 className="mb-1">Real-Time Lead Sync</h6>
                          <div className="text-muted">Automatically sync lead information from Facebook Lead Ads to Outperform in real time, minimizing manual data entry and ensuring quick follow-ups.</div>
                        </div>
                      </ListGroup.Item>

                      <ListGroup.Item className="d-flex border-0 mb-2 px-0">
                        <FontAwesomeIcon icon={faCheck} size="xl" className="me-3" color="#198754" />
                        <div>
                          <h6 className="mb-1">Centralized Management</h6>
                          <div className="text-muted">Store, track, and organize leads directly within Outperform, simplifying workflows and ensuring all data is accessible in one place.</div>
                        </div>
                      </ListGroup.Item>

                      <ListGroup.Item className="d-flex border-0 px-0">
                        <FontAwesomeIcon icon={faCheck} size="xl" className="me-3" color="#198754" />
                        <div>
                          <h6 className="mb-1">Improved Engagement</h6>
                          <div className="text-muted">Use Outperform’s automation tools, like notifications and workflows, to follow up with leads instantly and improve conversion rates.</div>
                        </div>
                      </ListGroup.Item>
                    </ListGroup>

                    <Button className="mt-3 op-primary-color text-light" variant="" disabled={loadingLogin} onClick={sendFBConnection}>
                      {loadingLogin ? (
                        <div className="d-flex align-items-center">
                          <Spinner animation="border" role="status" size="sm" className="me-2"></Spinner>
                          <span>Authorizing...</span>
                        </div>
                      ) : (
                        <div className="d-flex align-items-center">
                          <FontAwesomeIcon icon={faFacebook} size="xl" className="me-2" />
                          Connect with Facebook
                        </div>
                      )}
                    </Button>
                  </div>
                  <Image src={require("../assets/fb_connection.png")} width={180} height={180} />
                </div>
              </Col>
              <Col sm={12}>
                <div style={{ padding: "2rem" }}>
                  <h6 className="fw-bold" style={{ fontSize: "15px" }}>
                    Need more support?
                  </h6>
                  <p style={{ fontSize: "13px", width: "600px" }}>If you’re having trouble setting up the sync or just have additional questions, our Knowledge Base is likely to have an answer.</p>

                  <a className="text-decoration-none" href="https://support.nexible.com.my/hc/category/integrations/facebook-lead-ads/" target="_blank">
                    Learn more about the Facebook connection sync <FontAwesomeIcon icon={faArrowUpRight} />
                  </a>
                </div>
              </Col>
            </Row>
          ) : isLogin === 0 ? (
            <Row>
              <Col sm={12}>
                <div style={{ borderBottom: "1px solid #e0e0e0", padding: "2rem" }}>
                  <h5>Set up Facebook Leads Ad Sync</h5>
                </div>
              </Col>
              <Col sm={12}>
                <div style={{ padding: "1rem 2rem" }}>
                  <div className="border rounded p-4 d-flex align-items-center mb-2">
                    <div className="d-flex justify-content-center align-items-center" style={{ width: 40, height: 40 }}>
                      <FontAwesomeIcon icon={faFacebookF} size="2x" className="me-3" color="#3b5998" />
                    </div>
                    <div>
                      {authorized && <div style={{ fontSize: 15 }}>{FBInfo.name ? FBInfo.name : "Unnamed"}</div>}
                      {authorized ? (
                        <div className="d-flex align-items-center">
                          <FontAwesomeIcon icon={faCheck} size="lg" color="#3b5998" className="me-1" />
                          <div className="fw-bold text-muted" style={{ fontSize: 15 }}>
                            Authorized
                          </div>
                        </div>
                      ) : (
                        <div className="d-flex align-items-center">
                          <Spinner animation="border" role="status" size="sm" className="me-2"></Spinner>
                          <div className="fw-bold text-muted" style={{ fontSize: 15, marginTop: 2 }}>
                            Initializing...
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="text-muted" style={{ fontSize: 14 }}>
                    Not the account you want to sync with Outperform FB Connection?
                    {reset ? (
                      <Spinner animation="border" role="status" size="sm" className="ms-3" style={{ marginTop: 2 }}></Spinner>
                    ) : (
                      <Button variant="link" className="text-decoration-none" style={{ fontSize: 15 }} onClick={handleLogout}>
                        Log Out
                      </Button>
                    )}
                  </div>
                </div>
              </Col>
              <Col sm={12}>
                <div style={{ padding: "1rem 2rem" }}>
                  <Form.Group>
                    <Form.Label column sm={2} className="mb-0" style={{ fontSize: 15 }}>
                      Select a Page to setup leads
                    </Form.Label>
                    {loadingPages ? (
                      <div className="d-flex justify-content-center align-items-center mt-3" style={{ zIndex: 2 }}>
                        <div className="rounded-circle shadow d-flex justify-content-center align-items-center" style={{ height: 30, width: 30 }}>
                          <Spinner animation="border" role="status" size="sm">
                            <span className="visually-hidden">Loading...</span>
                          </Spinner>
                        </div>
                      </div>
                    ) : FBPages.length > 0 ? (
                      <Row className="mt-3">
                        {FBPages.map((item) => (
                          <Col sm={6}>
                            <div className="border rounded p-3 mb-2 w-100">
                              <Stack direction="horizontal" gap={2}>
                                <div className="w-75">
                                  <div className="fw-bold" style={{ fontSize: 14 }}>
                                    {item.name}
                                  </div>
                                  <div className="text-muted" style={{ fontSize: 13 }}>
                                    {item.category}
                                  </div>
                                </div>
                                <div className="ms-auto" />
                                {item.added ? (
                                  <Button variant="danger" disabled={loadingBtnId === item.id} onClick={() => handleUnSubscribeApp(item.id, item.access_token)}>
                                    {loadingBtnId === item.id ? <Spinner animation="border" role="status" size="sm" style={{ marginTop: 2 }}></Spinner> : <FontAwesomeIcon icon={faTrashAlt} size="lg" />}
                                  </Button>
                                ) : (
                                  <Button variant="" className="op-primary-color text-light" disabled={loadingBtnId === item.id} onClick={() => handleSubscribeApp(item.id, item.access_token, item.name, fbUserId)}>
                                    {loadingBtnId === item.id ? <Spinner animation="border" role="status" size="sm" style={{ marginTop: 2 }}></Spinner> : <FontAwesomeIcon icon={faPlus} size="lg" />}
                                  </Button>
                                )}
                              </Stack>
                            </div>
                          </Col>
                        ))}
                      </Row>
                    ) : (
                      <div className="w-100 d-flex flex-column align-items-center justify-content-center mt-3">
                        <Image src={require("../assets/fb_connection.png")} width={180} height={180} />
                        <div className="text-center" style={{ fontSize: 15 }}>
                          It looks like you haven't connected any Facebook pages for setup yet. Please add a page to get started.
                        </div>
                      </div>
                    )}
                  </Form.Group>
                </div>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col sm={12}>
                <div style={{ borderBottom: "1px solid #e0e0e0", padding: "2rem" }}>
                  <h5>Initializing Facebook Login</h5>
                </div>
              </Col>
              <Col sm={12}>
                <div className="d-flex justify-content-center align-items-center mt-3">
                  <div className="rounded-circle shadow d-flex justify-content-center align-items-center" style={{ height: 30, width: 30 }}>
                    <Spinner animation="border" role="status" size="sm">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </div>
                </div>
              </Col>
            </Row>
          )}
        </>
      )}
    </Container>
  );
};

export default SettingFBConnection;
