import React, { useState, useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../lib/css/Project.css";
import { Card, Row, Col, Stack, Container } from "react-bootstrap";
import Loader from "../includes/Loader";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { useKit } from "./CustomerKit";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { useTranslation } from "react-i18next";
import axios from "../api/axios";

const CustomerKitPhase = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const { hostUrl, hostUrlType, company_id } = useKit();
  const { project_id, key } = useParams();
  const [phaseRecord, setPhaseRecord] = useState([]);
  const [projectInfo, setProjectInfo] = useState([]);
  const navigate = useNavigate();
  const [thumbsSwiper, setThumbsSwiper] = useState({});
  const [projectData, setProjectData] = useState([]);
  const init = useRef(false);
  // GET FUNCTION ----------------------------------

  const getProjectDetails = async () => {
    try {
      const response = await axios.get("ws/ws_rea_sales_kit.php", {
        params: {
          task: "SalesKit_project-details",
          project_id: project_id,
          key: key,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        if (data.record.phase_count > 0) {
          data.record.phase_record.forEach((record, idx) => {
            record.phase_id = record.phase_name.replaceAll(" ", "").toLowerCase().trim();
            setThumbsSwiper((prevstate) => ({ ...prevstate, [idx]: null }));
          });
        }
        setProjectData(data.record);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // SET FUNCTION ----------------------------------

  const setPhaseImage = (image) => {
    if (image) {
      return `${hostUrl}/${hostUrlType}/assets/rea_booking/${company_id}/project/${image}`;
    } else {
      return `${hostUrl}/${hostUrlType}/assets/rea_booking/generic.jpg`;
    }
  };

  // OPEN FUNCTION ---------------------------------

  const openPhaseDetails = (record) => {
    setTimeout(() => {
      sessionStorage.setItem("rea_phase_id", record.rea_phase_id);
      navigate(`/rea-sales-kit/${key}/${project_id}/${record.phase_id}`);
    }, 500);
  };

  // USEEFFECT FUNCTION ----------------------------

  useEffect(() => {
    const initData = async () => {
      if (!init.current) {
        try {
          await getProjectDetails();
          setLoading(false);
          init.current = true;
        } catch (error) {
          setLoading(false);
        }
      }
    };
    initData();
  }, []);

  const renderPhaseDetails = () => {
    return (
      phaseRecord.length > 0 &&
      phaseRecord.map((record, index) => (
        <Col xxl={12} className="mb-3" key={index}>
          <Card className="shadow border-0 h-100">
            <Row>
              <Col xxl={7}>
                <Swiper
                  spaceBetween={10}
                  navigation={true}
                  thumbs={thumbsSwiper[index] ? { swiper: thumbsSwiper[index] } : null}
                  modules={[FreeMode, Navigation, Thumbs]}
                  className="mySwiper2"
                  style={{
                    "--swiper-navigation-color": "#fff",
                    "--swiper-pagination-color": "#fff",
                  }}
                >
                  {record.phase_image.split(",").map((data, i) => (
                    <SwiperSlide key={i}>
                      <img src={setPhaseImage(data)} alt="" />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </Col>
              <Col xxl={5} className="py-4">
                <Stack direction="vertical" className="h-100">
                  <h5 className="cursor-pointer" onClick={() => openPhaseDetails(record)}>
                    {record.phase_name}
                  </h5>
                  <p className="mb-0 text-muted">{record.phase_location}</p>
                  <p className="mb-0">{record.phase_desc}</p>
                  <div className="d-flex mt-2">
                    <div className="border rounded p-2 me-2">{record.phase_type}</div>
                    {record.completion_date !== "0000-00-00 00:00:00" && <div className="border rounded p-2">{moment(new Date(record.completion_date)).format("ll")}</div>}
                  </div>
                  <div className="mt-auto">
                    <Swiper onSwiper={(swiper) => setThumbsSwiper((prevSwipers) => ({ ...prevSwipers, [index]: swiper }))} spaceBetween={10} slidesPerView={4} freeMode={true} watchSlidesProgress={true} modules={[FreeMode, Navigation, Thumbs]} className="mySwiper">
                      {record.phase_image.split(",").map((data, i) => (
                        <SwiperSlide key={i}>
                          <img src={setPhaseImage(data)} alt="" />
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                </Stack>
              </Col>
            </Row>
          </Card>
        </Col>
      ))
    );
  };

  return (
    <Container fluid className="m-0 p-0 internal-project">
      {loading ? (
        <Loader />
      ) : (
        <div className="p-4">
          <div>
            <h6>{projectData.project_name}'s phases</h6>
            <p>{projectData.phase_count > 1 ? t("CustomerKitPhase_records", { length: projectData.phase_count }) : projectData.phase_count === 1 ? t("CustomerKitPhase_one_record") : t("CustomerKitPhase_no_record")}</p>
          </div>
          <Row className="op-swiper">
            {projectData.phase_count > 0 &&
              projectData.phase_record.map((record, index) => (
                <Col xxl={12} className="mb-3" key={index}>
                  <Card className="shadow border-0 h-100">
                    <Row>
                      <Col xxl={7} className="cursor-pointer" onClick={() => openPhaseDetails(record)}>
                        <Swiper spaceBetween={10} navigation={false} thumbs={{ swiper: thumbsSwiper[index] }} modules={[FreeMode, Navigation, Thumbs]} className="mySwiper2">
                          {record.phase_image.map((data, i) => (
                            <SwiperSlide key={i}>
                              <img src={setPhaseImage(data)} alt="imagemedia" />
                            </SwiperSlide>
                          ))}
                        </Swiper>
                      </Col>
                      <Col xxl={5} className="py-4" style={{ paddingRight: "2.5rem" }}>
                        <Stack direction="vertical" className="h-100">
                          <h5 className="cursor-pointer" onClick={() => openPhaseDetails(record)}>
                            {record.phase_name}
                          </h5>
                          <p className="mb-0 text-muted">{record.phase_location}</p>
                          <p className="mb-0">{record.phase_desc}</p>
                          <div className="d-flex mt-2">
                            <div className="border rounded p-2 me-2">{record.phase_type}</div>
                            {record.completion_date !== "0000-00-00 00:00:00" && <div className="border rounded p-2">{moment(new Date(record.completion_date)).format("MMMM YYYY")}</div>}
                          </div>
                          <div className="mt-auto">
                            <Swiper onSwiper={(swiper) => setThumbsSwiper((prevSwipers) => ({ ...prevSwipers, [index]: swiper }))} spaceBetween={10} slidesPerView={4} freeMode={true} watchSlidesProgress={true} modules={[FreeMode, Navigation, Thumbs]} className="mySwiper">
                              {record.phase_image.map((data, i) => (
                                <SwiperSlide key={i}>
                                  <img src={setPhaseImage(data)} className="cursor-pointer" alt="" />
                                </SwiperSlide>
                              ))}
                            </Swiper>
                          </div>
                        </Stack>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              ))}
          </Row>
        </div>
      )}
    </Container>
  );
};

export default CustomerKitPhase;
