import { useState, useMemo, useCallback, useEffect, useRef } from "react";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import { FilePond, registerPlugin } from "react-filepond";
import { Container, Row, Col, Form, Button, Card } from "react-bootstrap";
import * as XLSX from "xlsx";
import { AgGridReact } from "ag-grid-react";
import Swal from "sweetalert2/dist/sweetalert2";
import moment from "moment";
import axios from "../../api/axios";
import { setCurrency } from "../../lib/js/Function";
import { useAuth } from "../../auth/AuthContext";
const BulkUnitPrice = () => {
  registerPlugin(FilePondPluginFileValidateType);
  const { session } = useAuth();
  const [gridData, setGridData] = useState([]);
  const [gridRecord, setGridRecord] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [file, setFile] = useState([]);
  const gridRef = useRef();

  const getPreview = async () => {
    try {
      var recordReader = new FileReader();
      recordReader.onload = () => {
        var recordFileData = recordReader.result;
        var recordWorkBook = XLSX.read(recordFileData, { type: "binary" });
        var recordRow = XLSX.utils.sheet_to_row_object_array(recordWorkBook.Sheets["Bulk Unit Price"]);
        var recordData = recordRow;
        setTotalRecords(recordRow.length);

        var headerRows = [];
        var recordWorksheet = recordWorkBook.Sheets["Bulk Unit Price"];

        if (recordWorksheet) {
          var range = XLSX.utils.decode_range(recordWorksheet["!ref"]);
          for (var R = range.s.r; R <= range.e.r; ++R) {
            var row = [];
            for (var C = range.s.c; C <= range.e.c; ++C) {
              var cellAddress = XLSX.utils.encode_cell({
                r: R,
                c: C,
              });
              var cell = recordWorksheet[cellAddress];
              var cellValue = cell ? cell.v : "";
              row.push(cellValue);
            }
            headerRows.push(row);
          }
        }

        if (!recordWorksheet) {
          Swal.fire({
            icon: "warning",
            html: `<h5>You're uploading the wrong template. Please use the provided template to make an update.</h5><div className="reason"><h6>Reason:</h6><p>Your sheet name is wrong. Please rename it to 'Bulk Unit Price'.</p></div>`,
          });
          return;
        }

        var expectedColumns = ["Original Selling Price", "Selling Price", "New Selling Price", "Hash", "Unit Number"];
        var currentColumns = headerRows[0];

        if (currentColumns) {
          var missingColumns = expectedColumns.filter((column) => !currentColumns.includes(column));
          if (missingColumns.length > 0) {
            var alertMessage = `<h5>You're uploading the wrong template. Please use the provided template to make an update.</h5><div className="reason"><h6>Reason:</h6><p>You're missing the following columns in the template:</p><ul>`;
            for (var i = 0; i < missingColumns.length; i++) {
              alertMessage += `<li>${missingColumns[i]}</li>\n`;
            }
            alertMessage += `</ul></div>`;

            Swal.fire({
              icon: "warning",
              html: alertMessage,
            });
            return;
          }
        }

        if (!recordRow.length) {
          Swal.fire({
            icon: "warning",
            html: `<h5>You're uploading the wrong template. Please use the provided template to make an update.</h5><div className="reason"><h6>Reason:</h6><p>There is no data inside the sheet.</p></div>`,
          });
          return;
        }

        for (let i = 0; i < recordData.length; i++) {
          recordData[i].index = i + 1;
        }

        const bulkData = recordData.map(({ "Original Selling Price": original_selling_price = "", "Selling Price": selling_price = "", "New Selling Price": new_selling_price = "", Hash: hash = "", "Unit Number": unit_number = "", index: index }) => ({
          original_selling_price,
          selling_price,
          new_selling_price,
          hash,
          unit_number,
          index,
        }));

        setGridRecord(bulkData);
      };
      recordReader.readAsArrayBuffer(file[0]);
    } catch (error) {
      console.error(error);
    }
  };

  const sendBulkUnitPrice = async () => {
    const formData = new FormData();
    formData.append("task", "BulkUnitPrice");
    formData.append("utoken", session.user_ac_token);
    formData.append("ctoken", session.company_token);
    formData.append("hash", gridData.map((item) => item.hash).join("[-0-]"));
    formData.append("selling_price", gridData.map((item) => item.selling_price).join("[-0-]"));
    formData.append("new_selling_price", gridData.map((item) => item.new_selling_price).join("[-0-]"));
    formData.append("unit_number", gridData.map((item) => item.unit_number).join("[-0-]"));

    try {
      const response = await axios.post("ws/ws_rea_bulk_unit.php", formData);
      const data = response.data;
      if (data.status === 0) {
        Swal.fire({
          icon: "success",
          text: "Successfully updated the unit price",
        });
      } else {
        Swal.fire({
          icon: "error",
          text: "Failed to update the unit price",
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setFile([]);
      setGridRecord([]);
      setGridData([]);
    }
  };

  const containerStyle = useMemo(() => ({ width: "100%", height: "65vh" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const gridColumn = [
    {
      headerCheckboxSelection: true,
      checkboxSelection: true,
      headerName: "Unit Number",
      field: "unit_number",
      flex: 1,
    },
    {
      headerName: "Original Selling Price",
      field: "original_selling_price",
      flex: 1,
      cellRenderer: (params) => {
        return params.value ? setCurrency(params.value) : "-";
      },
    },
    {
      headerName: "Selling Price",
      field: "selling_price",
      flex: 1,
      cellRenderer: (params) => {
        return params.value ? setCurrency(params.value) : "-";
      },
    },
    {
      headerName: "New Selling Price",
      field: "new_selling_price",
      flex: 1,
      cellRenderer: (params) => {
        return params.value ? setCurrency(params.value) : "-";
      },
    },
    {
      headerName: "Hash",
      field: "hash",
      flex: 1,
    },
  ];

  const gridRowId = useCallback(function (params) {
    return params.data.index.toString();
  }, []);

  const gridSelect = useCallback(() => {
    setGridData(gridRef.current.api.getSelectedRows());
  }, []);

  return (
    <Container fluid className="position-relative p-4" style={{ backgroundColor: "#fff", height: "calc(100vh - 56px)" }}>
      <Row>
        <Col sm={12} className="mb-1">
          <FilePond
            allowMultiple={false}
            maxFiles={1}
            name="file"
            credits={false}
            acceptedFileTypes={["application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]}
            instantUpload={false}
            files={file}
            onupdatefiles={(fileItems) => setFile(fileItems.map((fileItem) => fileItem.file))}
            onremovefile={() => {
              setFile([]);
              setGridRecord([]);
            }}
          />
        </Col>
        <Col sm={12}>
          <Card>
            <Card.Header className="d-flex align-items-center justify-content-between">
              <Button variant="" className="op-primary-color text-light" onClick={getPreview} disabled={file.length === 0}>
                Preview
              </Button>

              <Button variant="light" className="border shadow-sm" onClick={sendBulkUnitPrice} disabled={gridData.length === 0}>
                Submit
              </Button>
            </Card.Header>
            <div style={containerStyle}>
              <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                <AgGridReact ref={gridRef} rowSelection="multiple" rowData={gridRecord} columnDefs={gridColumn} getRowId={gridRowId} onSelectionChanged={gridSelect} pagination={true} paginationPageSize={100} paginationPageSizeSelector={false} suppressRowClickSelection={true} animateRows={true} />
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default BulkUnitPrice;
