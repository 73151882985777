import React, { useEffect } from "react";
import { Outlet, NavLink } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Col, Container, FloatingLabel, Form, Nav, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBracketsCurly as faBracketsCurlyActive, faBuildings as faBuildingsActive, faHouse as faHouseActive, faMap as faMapActive, faComments as faCommentsActive, faBrainCircuit as faBrainCircuitActive, faFileImport as faFileImportActive } from "@fortawesome/pro-solid-svg-icons";
import { faBracketsCurly, faBuildings, faHouse, faMap, faComments, faBrainCircuit, faFileImport } from "@fortawesome/pro-light-svg-icons";
import { useState } from "react";
import Swal from "sweetalert2/dist/sweetalert2";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const BookingSettings = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [login, setLogin] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    const loginAuth = sessionStorage.getItem("BookingSettingsAuth");
    if (loginAuth) {
      setLogin(true);
      if (location.pathname === "/admin-settings" || location.pathname === "/admin-settings/") {
        navigate("/admin-settings/waba", { replace: true });
      }
    }
  }, [navigate]);

  const handleSubmit = () => {
    if (email === "sprintadmin" && btoa(password) === "c3ByaW50bmV4aWJsZUBhZG1pbg==") {
      sessionStorage.setItem("BookingSettingsAuth", true);
      setLogin(true);
      navigate("/admin-settings/waba", { replace: true });
    } else {
      setEmail("");
      setPassword("");
      Swal.fire({
        icon: "warning",
        text: "Wrong Username or Password",
      });
    }
  };

  return (
    <Container fluid className="m-0 p-0 d-flex" style={{ height: "calc(100vh - 56px)" }}>
      {login ? (
        <>
          <div className="h-100" style={{ width: "20%" }}>
            <Nav variant="pills" className="h-100  sidebar-tab bg-light py-4 px-3 overflow-auto op-scrollbar" style={{ height: "calc(100vh - 56px)" }}>
              <Nav.Item className="mb-2 w-100">
                <NavLink className="nav-link" to="/admin-settings/waba" activeclassname="active">
                  {({ isActive }) => (
                    <div className="d-flex align-items-center py-1">
                      <span className="me-2 text-center" style={{ width: 25 }}>
                        <FontAwesomeIcon icon={isActive ? faWhatsapp : faWhatsapp} size="xl" className="me-2" />
                      </span>
                      <span>WABA Interactive Template</span>
                    </div>
                  )}
                </NavLink>
              </Nav.Item>

              <Nav.Item className="mb-2 w-100">
                <NavLink className="nav-link" to="/admin-settings/blueprint2" activeclassname="active">
                  {({ isActive }) => (
                    <div className="d-flex align-items-center py-1">
                      <span className="me-2 text-center" style={{ width: 25 }}>
                        <FontAwesomeIcon icon={isActive ? faMapActive : faMap} size="xl" className="me-2" />
                      </span>
                      <span>Blueprint2 Template</span>
                    </div>
                  )}
                </NavLink>
              </Nav.Item>

              <Nav.Item className="mb-2 w-100">
                <NavLink className="nav-link" to="/admin-settings/conversation-flow" activeclassname="active">
                  {({ isActive }) => (
                    <div className="d-flex align-items-center py-1">
                      <span className="me-2 text-center" style={{ width: 25 }}>
                        <FontAwesomeIcon icon={isActive ? faCommentsActive : faComments} size="xl" className="me-2" />
                      </span>
                      <span>Conversation Flow Template</span>
                    </div>
                  )}
                </NavLink>
              </Nav.Item>

              <Nav.Item className="mb-2 w-100">
                <NavLink className="nav-link" to="/admin-settings/fact-sheet" activeclassname="active">
                  {({ isActive }) => (
                    <div className="d-flex align-items-center py-1">
                      <span className="me-2 text-center" style={{ width: 25 }}>
                        <FontAwesomeIcon icon={isActive ? faBrainCircuitActive : faBrainCircuit} size="xl" className="me-2" />
                      </span>
                      <span>AI Fact Sheet</span>
                    </div>
                  )}
                </NavLink>
              </Nav.Item>

              <Nav.Item className="mb-2 w-100">
                <NavLink className="nav-link" to="/admin-settings/project" activeclassname="active">
                  {({ isActive }) => (
                    <div className="d-flex align-items-center py-1">
                      <span className="me-2" style={{ width: 25 }}>
                        <FontAwesomeIcon icon={isActive ? faBuildingsActive : faBuildings} size="xl" />
                      </span>
                      <span>Project Settings</span>
                    </div>
                  )}
                </NavLink>
              </Nav.Item>

              <Nav.Item className="mb-2 w-100">
                <NavLink className="nav-link" to="/admin-settings/phase" activeclassname="active">
                  {({ isActive }) => (
                    <div className="d-flex align-items-center py-1">
                      <span className="me-2" style={{ width: 25 }}>
                        <FontAwesomeIcon icon={isActive ? faBuildingsActive : faBuildings} size="xl" />
                      </span>
                      <span>Phase Settings</span>
                    </div>
                  )}
                </NavLink>
              </Nav.Item>

              <Nav.Item className="mb-2 w-100">
                <NavLink className="nav-link" to="/admin-settings/type" activeclassname="active">
                  {({ isActive }) => (
                    <div className="d-flex align-items-center py-1">
                      <span className="me-2" style={{ width: 25 }}>
                        <FontAwesomeIcon icon={isActive ? faHouseActive : faHouse} size="xl" />
                      </span>
                      <span>Unit Type Settings</span>
                    </div>
                  )}
                </NavLink>
              </Nav.Item>

              <Nav.Item className="mb-2 w-100">
                <NavLink className="nav-link" to="/admin-settings/unit" activeclassname="active">
                  {({ isActive }) => (
                    <div className="d-flex align-items-center py-1">
                      <span className="me-2" style={{ width: 25 }}>
                        <FontAwesomeIcon icon={isActive ? faHouseActive : faHouse} size="xl" />
                      </span>
                      <span>Unit Settings</span>
                    </div>
                  )}
                </NavLink>
              </Nav.Item>

              <Nav.Item className="mb-2 w-100">
                <NavLink className="nav-link" to="/admin-settings/coords" activeclassname="active">
                  {({ isActive }) => (
                    <div className="d-flex align-items-center py-1">
                      <span className="me-2" style={{ width: 25 }}>
                        <FontAwesomeIcon icon={isActive ? faBracketsCurlyActive : faBracketsCurly} size="xl" />
                      </span>
                      <span>Booking Sales Chart</span>
                    </div>
                  )}
                </NavLink>
              </Nav.Item>

              <Nav.Item className="mb-2 w-100">
                <NavLink className="nav-link" to="/admin-settings/modules" activeclassname="active">
                  {({ isActive }) => (
                    <div className="d-flex align-items-center py-1">
                      <span className="me-2" style={{ width: 25 }}>
                        <FontAwesomeIcon icon={isActive ? faBracketsCurlyActive : faBracketsCurly} size="xl" />
                      </span>
                      <span>Booking Modules</span>
                    </div>
                  )}
                </NavLink>
              </Nav.Item>

              <Nav.Item className="mb-2 w-100">
                <NavLink className="nav-link" to="/admin-settings/field-list" activeclassname="active">
                  {({ isActive }) => (
                    <div className="d-flex align-items-center py-1">
                      <span className="me-2" style={{ width: 25 }}>
                        <FontAwesomeIcon icon={isActive ? faBracketsCurlyActive : faBracketsCurly} size="xl" />
                      </span>
                      <span>Booking Field List</span>
                    </div>
                  )}
                </NavLink>
              </Nav.Item>

              <Nav.Item className="mb-2 w-100">
                <NavLink className="nav-link" to="/admin-settings/permission" activeclassname="active">
                  {({ isActive }) => (
                    <div className="d-flex align-items-center py-1">
                      <span className="me-2" style={{ width: 25 }}>
                        <FontAwesomeIcon icon={isActive ? faBracketsCurlyActive : faBracketsCurly} size="xl" />
                      </span>
                      <span>Booking Permission</span>
                    </div>
                  )}
                </NavLink>
              </Nav.Item>

              <Nav.Item className="mb-2 w-100">
                <NavLink className="nav-link" to="/admin-settings/format" activeclassname="active">
                  {({ isActive }) => (
                    <div className="d-flex align-items-center py-1">
                      <span className="me-2" style={{ width: 25 }}>
                        <FontAwesomeIcon icon={isActive ? faBracketsCurlyActive : faBracketsCurly} size="xl" />
                      </span>
                      <span>Booking Format</span>
                    </div>
                  )}
                </NavLink>
              </Nav.Item>

              <Nav.Item className="mb-2 w-100">
                <NavLink className="nav-link" to="/admin-settings/main-format" activeclassname="active">
                  {({ isActive }) => (
                    <div className="d-flex align-items-center py-1">
                      <span className="me-2" style={{ width: 25 }}>
                        <FontAwesomeIcon icon={isActive ? faBracketsCurlyActive : faBracketsCurly} size="xl" />
                      </span>
                      <span>Booking Main Buyer</span>
                    </div>
                  )}
                </NavLink>
              </Nav.Item>

              <Nav.Item className="mb-2 w-100">
                <NavLink className="nav-link" to="/admin-settings/joint-format" activeclassname="active">
                  {({ isActive }) => (
                    <div className="d-flex align-items-center py-1">
                      <span className="me-2" style={{ width: 25 }}>
                        <FontAwesomeIcon icon={isActive ? faBracketsCurlyActive : faBracketsCurly} size="xl" />
                      </span>
                      <span>Booking Joint Buyer</span>
                    </div>
                  )}
                </NavLink>
              </Nav.Item>

              <Nav.Item className="mb-2 w-100">
                <NavLink className="nav-link" to="/admin-settings/unit-format" activeclassname="active">
                  {({ isActive }) => (
                    <div className="d-flex align-items-center py-1">
                      <span className="me-2" style={{ width: 25 }}>
                        <FontAwesomeIcon icon={isActive ? faBracketsCurlyActive : faBracketsCurly} size="xl" />
                      </span>
                      <span>Booking Unit Modules</span>
                    </div>
                  )}
                </NavLink>
              </Nav.Item>

              <Nav.Item className="mb-2 w-100">
                <NavLink className="nav-link" to="/admin-settings/amenities" activeclassname="active">
                  {({ isActive }) => (
                    <div className="d-flex align-items-center py-1">
                      <span className="me-2" style={{ width: 25 }}>
                        <FontAwesomeIcon icon={isActive ? faBracketsCurlyActive : faBracketsCurly} size="xl" />
                      </span>
                      <span>Booking Amenities</span>
                    </div>
                  )}
                </NavLink>
              </Nav.Item>

              <Nav.Item className="mb-2 w-100">
                <NavLink className="nav-link" to="/admin-settings/facilities" activeclassname="active">
                  {({ isActive }) => (
                    <div className="d-flex align-items-center py-1">
                      <span className="me-2" style={{ width: 25 }}>
                        <FontAwesomeIcon icon={isActive ? faBracketsCurlyActive : faBracketsCurly} size="xl" />
                      </span>
                      <span>Booking Facilities</span>
                    </div>
                  )}
                </NavLink>
              </Nav.Item>

              <Nav.Item className="mb-2 w-100">
                <NavLink className="nav-link" to="/admin-settings/contact" activeclassname="active">
                  {({ isActive }) => (
                    <div className="d-flex align-items-center py-1">
                      <span className="me-2" style={{ width: 25 }}>
                        <FontAwesomeIcon icon={isActive ? faBracketsCurlyActive : faBracketsCurly} size="xl" />
                      </span>
                      <span>Booking Contact</span>
                    </div>
                  )}
                </NavLink>
              </Nav.Item>

              <Nav.Item className="mb-2 w-100">
                <NavLink className="nav-link" to="/admin-settings/file" activeclassname="active">
                  {({ isActive }) => (
                    <div className="d-flex align-items-center py-1">
                      <span className="me-2" style={{ width: 25 }}>
                        <FontAwesomeIcon icon={isActive ? faBracketsCurlyActive : faBracketsCurly} size="xl" />
                      </span>
                      <span>Booking File</span>
                    </div>
                  )}
                </NavLink>
              </Nav.Item>

              <Nav.Item className="mb-2 w-100">
                <NavLink className="nav-link" to="/admin-settings/virtual-tour" activeclassname="active">
                  {({ isActive }) => (
                    <div className="d-flex align-items-center py-1">
                      <span className="me-2" style={{ width: 25 }}>
                        <FontAwesomeIcon icon={isActive ? faBracketsCurlyActive : faBracketsCurly} size="xl" />
                      </span>
                      <span>Booking Virtual Tour</span>
                    </div>
                  )}
                </NavLink>
              </Nav.Item>

              <Nav.Item className="mb-2 w-100">
                <NavLink className="nav-link" to="/admin-settings/bulk-unit-price" activeclassname="active">
                  {({ isActive }) => (
                    <div className="d-flex align-items-center py-1">
                      <span className="me-2" style={{ width: 25 }}>
                        <FontAwesomeIcon icon={isActive ? faFileImportActive : faFileImport} size="xl" />
                      </span>
                      <span>Bulk Unit Price</span>
                    </div>
                  )}
                </NavLink>
              </Nav.Item>
            </Nav>
          </div>

          <div className="overflow-auto" style={{ width: "85%" }}>
            <Outlet />
          </div>
        </>
      ) : (
        <div className="d-flex justify-content-center align-items-center w-100">
          <Form className="w-25">
            <label className="h6 text-center mb-3 w-100">Booking Settings Authentication</label>
            <Form.Group className="mb-3">
              <FloatingLabel controlId="floatingInput" label="Username">
                <Form.Control type="text" className="op-text-medium" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Username" required />
              </FloatingLabel>
            </Form.Group>
            <Form.Group className="mb-3">
              <FloatingLabel controlId="floatingInput" label="Password" className="position-relative">
                <Form.Control className="op-text-medium" type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" required />
              </FloatingLabel>
            </Form.Group>
            <Form.Group>
              <Button variant="" className="op-primary-color text-light w-100" onClick={handleSubmit}>
                Login
              </Button>
            </Form.Group>
          </Form>
        </div>
      )}
    </Container>
  );
};

export default BookingSettings;
