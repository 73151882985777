import { useRef, useState, useEffect, createContext, useContext } from "react";
import { useAuth } from "../auth/AuthContext";
import { Outlet, NavLink, useNavigate } from "react-router-dom";
import { Container, Row, Stack, ButtonGroup, ToggleButton, Form, Modal, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBoxCircleCheck, faBox } from "@fortawesome/pro-duotone-svg-icons";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { faBars, faCalendarDay, faEnvelopeCircleCheck, faFlag, faPhone, faQuestion, faUsers, faPhoneVolume, faStopwatch, faEnvelopeOpenText, faCalendarClock, faCircleUser, faNoteSticky, faLink, faDollarCircle, faUser } from "@fortawesome/pro-duotone-svg-icons";
import { Formik } from "formik";
import { Editor } from "@tinymce/tinymce-react";
import { setLatestTime } from "../lib/js/Function";
import * as yup from "yup";
import moment from "moment";
import Swal from "sweetalert2";
import axios from "../api/axios";
import Loader from "../includes/Loader";
import { FormSelect, FormSelectCreatable } from "../includes/FormCustom";
import "../lib/scss/op-activity.scss";
import { useTranslation } from "react-i18next";
import NoteEditor from "../includes/NoteEditor";

const newDate = new Date();
const latestDate = moment(newDate).format("YYYY-MM-DD");

const durationList = [
  { label: "5 Mins", value: "5min" },
  { label: "10 Mins", value: "10min" },
  { label: "15 Mins", value: "15min" },
  { label: "20 Mins", value: "20min" },
  { label: "30 Mins", value: "30min" },
  { label: "35 Mins", value: "35min" },
  { label: "40 Mins", value: "40min" },
  { label: "45 Mins", value: "45min" },
  { label: "50 Mins", value: "50min" },
  { label: "55 Mins", value: "55min" },
  { label: "1 Hour", value: "60min" },
  { label: "1 Hour 30 Mins", value: "90min" },
  { label: "2 Hours", value: "120min" },
  { label: "2 Hours 30 Mins", value: "150min" },
  { label: "3 Hours", value: "180min" },
  { label: "3 Hours 30 Mins", value: "210min" },
  { label: "4 Hours", value: "240min" },
];

const timings = [
  { label: "8:00", value: "8:00" },
  { label: "8:15", value: "8:15" },
  { label: "8:30", value: "8:30" },
  { label: "8:45", value: "8:45" },
  { label: "9:00", value: "9:00" },
  { label: "9:15", value: "9:15" },
  { label: "9:30", value: "9:30" },
  { label: "9:45", value: "9:45" },
  { label: "10:00", value: "10:00" },
  { label: "10:15", value: "10:15" },
  { label: "10:30", value: "10:30" },
  { label: "10:45", value: "10:45" },
  { label: "11:00", value: "11:00" },
  { label: "11:15", value: "11:15" },
  { label: "11:30", value: "11:30" },
  { label: "11:45", value: "11:45" },
  { label: "12:00", value: "12:00" },
  { label: "12:15", value: "12:15" },
  { label: "12:30", value: "12:30" },
  { label: "12:45", value: "12:45" },
  { label: "13:00", value: "13:00" },
  { label: "13:15", value: "13:15" },
  { label: "13:30", value: "13:30" },
  { label: "13:45", value: "13:45" },
  { label: "14:00", value: "14:00" },
  { label: "14:15", value: "14:15" },
  { label: "14:30", value: "14:30" },
  { label: "14:45", value: "14:45" },
  { label: "15:00", value: "15:00" },
  { label: "15:15", value: "15:15" },
  { label: "15:30", value: "15:30" },
  { label: "15:45", value: "15:45" },
  { label: "16:00", value: "16:00" },
  { label: "16:15", value: "16:15" },
  { label: "16:30", value: "16:30" },
  { label: "16:45", value: "16:45" },
  { label: "17:00", value: "17:00" },
  { label: "17:15", value: "17:15" },
  { label: "17:30", value: "17:30" },
  { label: "17:45", value: "17:45" },
  { label: "18:00", value: "18:00" },
  { label: "18:15", value: "18:15" },
  { label: "18:30", value: "18:30" },
  { label: "18:45", value: "18:45" },
  { label: "19:00", value: "19:00" },
  { label: "19:15", value: "19:15" },
  { label: "19:30", value: "19:30" },
  { label: "19:45", value: "19:45" },
  { label: "20:00", value: "20:00" },
  { label: "20:15", value: "20:15" },
  { label: "20:30", value: "20:30" },
  { label: "20:45", value: "20:45" },
  { label: "21:00", value: "21:00" },
  { label: "21:15", value: "21:15" },
  { label: "21:30", value: "21:30" },
  { label: "21:45", value: "21:45" },
  { label: "22:00", value: "22:00" },
];


const ActivityContext = createContext();

const Activity = () => {
  const { t } = useTranslation();
  const { session } = useAuth();
  const navigate = useNavigate();

  const initialized = useRef(false);
  const addActivityRefNotes = useRef(null);

  const [user, setuser] = useState([]);
  const [activityType, setactivityType] = useState([]);
  const [customActivity, setcustomActivity] = useState([]);
  const [activityUser, setActivityUser] = useState([]);
  const [selectedDuration, setSelectedDuration] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedBlockTime, setSelectedBlockTime] = useState(null);

  const [isFindDeal, setIsFindDeal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isAddActivity, setIsAddActivity] = useState(false);
  const [isFindProspect, setIsFindProspect] = useState(false);
  const [isActivityType, setIsActivityType] = useState(0);
  const [updateGrid, setUpdateGrid] = useState(null);

  const [activityUserData, setActivityUserData] = useState({});
  const [activity, setActivity] = useState({
    user: "",
    customActivity: "",
    activity: "",
    status: 0
  });

  const selectTime = [
    { label: t("Activity_full_day"), value: "540" },
    { label: t("Activity_am"), value: "240" },
    { label: t("Activity_pm"), value: "240" },
  ];


  const addActivitySchema = yup.object().shape({
    // activity_title: yup.string().when("activity_mode", {
    //   is: "1",
    //   then: schema => schema.required(t("Activity_activity_title_is_required"))
    // }),
    activity_title: yup.string()
      .when("activity_mode", {
        is: "1",
        then: schema => schema
          .trim()
          .required('Activity title is required')
          .min(1, 'Activity title cannot be empty or whitespace'),
        otherwise: schema => schema.notRequired()
      }),
    // deal_id: yup.string().when("activity_linked", {
    //   is: "deal",
    //   then: schema => schema.required(t("Deal is required")),
    //   otherwise: schema => schema.notRequired()
    // }),
    // prospect_id: yup.string().when("activity_linked", {
    //   is: "person",
    //   then: schema => schema.required(t("Prospect is required")),
    //   otherwise: schema => schema.notRequired()
    // }),
    deal_id: yup.string().when(["activity_mode", "activity_linked"], {
      is: (activity_mode, activity_linked) => activity_mode === "1" && activity_linked === "deal",
      then: schema => schema.required(t("Deal is required")),
      otherwise: schema => schema.notRequired()
    }),
    prospect_id: yup.string().when(["activity_mode", "activity_linked"], {
      is: (activity_mode, activity_linked) => activity_mode === "1" && activity_linked === "person",
      then: schema => schema.required(t("Prospect is required")),
      otherwise: schema => schema.notRequired()
    }),
    activity_title_block: yup.string()
      .when("activity_mode", {
        is: "2",
        then: schema => schema
          .trim()
          .required('Activity title is required')
          .min(1, 'Activity title cannot be empty or whitespace'),
        otherwise: schema => schema.notRequired()
      }),
  });


  const contextData = {
    ctxActivity: activity,
    updateGrid: updateGrid,
  };

  useEffect(() => {
    const recentActivity = sessionStorage.getItem("recentActivity") || "/activity/list";
    navigate(recentActivity, { replace: true });
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // GET FUNCTION -------------------------------------

  const getActivityType = async () => {
    try {
      const response = await axios.get("ws/ws_setting.php", {
        params: {
          task: 4,
        },
      });

      var data = response.data;
      if (data.status === 0) {
        data.record.forEach((record) => {
          if (record.dat_id === "1") {
            record.icon = faPhone;
          } else if (record.dat_id === "2") {
            record.icon = faUsers;
          } else if (record.dat_id === "3") {
            record.icon = faStopwatch;
          } else if (record.dat_id === "4") {
            record.icon = faFlag;
          } else if (record.dat_id === "5") {
            record.icon = faEnvelopeCircleCheck;
          } else {
            record.icon = faQuestion;
          }
        });

        var recordOptions = data.record.map((record) => ({
          dat_id: Number(record.dat_id),
          dat_title: record.dat_title,
          icon: record.icon,
        }));

        recordOptions.unshift({
          dat_id: 0,
          dat_title: "All",
          icon: "",
        });

        setactivityType(recordOptions);
      }
    } catch (error) {
      console.error("Error in getActivityType:", error);
    }
  };

  const getUser = async (loginData) => {
    try {
      const response = await axios.get("ws/ws_user.php", {
        params: {
          task: "4b",
          utoken: loginData.user_ac_token,
          ctoken: loginData.company_token,
        },
      });

      var data = response.data;
      if (data.status === 0) {
        var recordOptions = data.record.map((record) => ({
          label: record.user_name,
          value: record.user_id,
        }));

        recordOptions.unshift({
          label: t("Activity_all_user"),
          value: 0,
        });

        var recordOptions2 = data.record.map((record) => ({
          label: record.user_name,
          value: record.user_id,
        }));

        var findActivityUser = recordOptions2.find((record) => Number(record.value) === Number(loginData.user_id));
        setActivityUserData(findActivityUser);
        setActivityUser(recordOptions2);
        setuser(recordOptions);
      } else {
        setuser([
          {
            label: t("Activity_all_user"),
            value: 0,
          },
        ]);
      }
    } catch (error) {
      setuser([
        {
          label: t("Activity_all_user"),
          value: 0,
        },
      ]);
    }
  };

  const getDeal = (inputValue, callback) => {
    setIsFindDeal(false);
    axios
      .get("ws/ws_deal.php", {
        params: {
          task: 6,
          company: session.company_id,
          uid: session.user_id,
          q: inputValue,
        },
      })
      .then((response) => {
        var data = response.data;
        if (data.status === 0) {
          var dealRecord = data.record
            .filter((record) => {
              return Number(record.deal_id) > 0;
            })
            .map((record) => ({
              label: record.deal_title,
              value: record.deal_id,
            }));

          callback(dealRecord);
          setIsFindDeal(true);
        } else {
          callback([]);
          setIsFindDeal(true);
        }
      });
  };

  const getProspect = (inputValue, callback) => {
    setIsFindProspect(false);
    axios
      .get("ws/ws_prospect.php", {
        params: {
          task: 9,
          company: session.company_id,
          uid: session.user_id,
          q: inputValue,
        },
      })
      .then((response) => {
        var data = response.data;
        if (data.status === 0) {
          var prospectRecord = data.record
            .filter((record) => {
              return Number(record.prospect_id) > 0;
            })
            .map((record) => ({
              label: record.prospect_name,
              value: record.prospect_id,
            }));

          callback(prospectRecord);
          setIsFindProspect(true);
        } else {
          callback([]);
          setIsFindProspect(true);
        }
      });
  };

  const getCustomActivity = async (loginData) => {
    try {
      const response = await axios.get("ws/ws_deal.php", {
        params: {
          task: 41,
          utoken: loginData.user_ac_token,
          ctoken: loginData.company_token,
        },
      });

      var data = response.data;
      if (data.status === 0) {
        var recordOptions = data.record.map((record) => ({
          label: record.ca_label,
          value: record.ca_id,
        }));

        recordOptions.unshift({
          label: t("Activity_all_custom_activity"),
          value: "",
        });

        setcustomActivity(recordOptions);
      } else {
        setcustomActivity([
          {
            label: t("Activity_all_custom_activity"),
            value: "",
          },
        ]);
      }
    } catch (error) {
      setcustomActivity([
        {
          label: t("Activity_all_custom_activity"),
          value: "",
        },
      ]);
    }
  };

  // ONCHANGE FUNCTION ---------------------------------

  const onchangeFilter = (mode, value) => {
    setActivity((prevState) => ({
      ...prevState,
      [mode]: value,
    }));
  };

  // SEND FUNCTION -------------------------------------

  const sendAddActivity = (values) => {
    axios
      .get("ws/ws_deal.php", {
        params: {
          task: 2,
          create_user: session.user_id,
          dated: moment(new Date(values.activity_date)).format("YYYY-MM-DD"),
          timed: selectedTime,
          duration: selectedDuration,
          title: values.activity_title,
          content: values.activity_notes,
          mad: values.mad ? "yes" : "no",
          dat: values.activity_type,
          caid: "",
          assign_user: selectedUser,
          deal: values.activity_linked === "deal" ? values.deal_id : "",
          people: values.activity_linked === "person" ? values.prospect_id : "",
          organization: "",
        },
      })
      .then((response) => {
        var data = response.data;
        window.location.reload();

        if (data.status === 0) {
          Swal.fire({
            icon: 'success',
            title: t("Activity_success"),
            text: t("Activity_activity_added_successfully"),
            timer: 1500
          });
          // window.location.reload();
        }
        setIsAddActivity(false);
        setUpdateGrid((prevKey) => prevKey + 1)
      });
  };

  const blockActivity = async (values) => {
    const url = session.hostUrlType === 'cloud' ? "https://www.nexcrmapis.com/cloud_dev/ws/ws_deal.php" : "https://www.nexcrmapis.com/cloud_staging/ws/ws_deal.php";
    try {
      const response = await axios.get(url, {
        params: {
          task: 2,
          create_user: session.user_id,
          dated: moment(new Date(values.activity_date)).format("DD-MM-YYYY"),
          timed: selectedTime,
          duration: selectedBlockTime,
          title: values.activity_title_block,
          mad: "yes",
          dat: 11,
          assign_user: selectedUser,
          deal: "",
          people: "",
          organization: "",
        },
      });

      const data = response.data;
      if (data.status === 0) {
        // Request was successful, show success alert
        setIsAddActivity(false);
        Swal.fire({
          icon: 'success',
          title: t("Activity_success"),
          text: t("Activity_activity_blocked_successfully"),
          timer: 1500
        });
        window.location.reload();
      } else {
        // Request failed, show error alert
        setIsAddActivity(false);
        Swal.fire({
          icon: 'error',
          title: t("Activity_error"),
          text: t("Activity_failed_to_block_activity"),
          timer: 1500
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: t("Activity_error"),
        text: t("Activity_an_unexpected_error_occured"),
        timer: 1500
      });
    }
  };


  useEffect(() => {
    const initializeData = async () => {
      if (!initialized.current) {
        initialized.current = true;

        try {
          await getActivityType();
          await getUser(session);
          await getCustomActivity(session);
          setTimeout(() => {
            setIsLoading(false);
          }, 500);

        } catch (error) {
          setIsLoading(false);
        }
      }
    };

    initializeData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (activityUserData && activityUserData.value) {
      setSelectedUser(activityUserData.value);
    }

    setSelectedTime(setLatestTime(timings).value);
  }, [activityUserData]); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <Container fluid style={{ padding: 0 }}>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="wrapper">
          <section className="content-header d-flex align-items-center">
            <Stack className="mx-3 w-100" direction="horizontal" gap={3}>
              <ButtonGroup className="activity-tab">
                <NavLink to="/activity/list" className="btn btn-outline" activeclassname="active">
                  <FontAwesomeIcon icon={faBars} size="lg" />
                </NavLink>
                <NavLink to="/activity/calendar" className="btn btn-outline" activeclassname="active">
                  <FontAwesomeIcon icon={faCalendarDay} size="lg" />
                </NavLink>
              </ButtonGroup>

              <button type="button" className="btn op-button op-primary-color text-light" onClick={setIsAddActivity}>
                <FontAwesomeIcon icon={faPlus} className="me-2" />
                {t("Activity_activity")}
              </button>
              <div className="ms-auto"></div>

              {Number(session.role_id) === 1 && <FormSelect options={user} valueDefault={user[0]} onChange={(e) => onchangeFilter("user", e.value)} shadow={true} border={true} width="250px" />}
              <FormSelect options={customActivity} valueDefault={customActivity[0]} shadow={true} border={true} width="250px" onChange={(e) => onchangeFilter("customActivity", e.value)} />

              <ButtonGroup className="activity-tab">
                {activityType.map((record, index) => (
                  <ToggleButton
                    key={index}
                    id={`type-${index}`}
                    type="radio"
                    variant=""
                    name="type"
                    value={record.dat_id}
                    checked={Number(isActivityType) === Number(record.dat_id)}
                    onChange={(e) => {
                      setIsActivityType(e.target.value);
                      onchangeFilter("activity", e.target.value);
                    }}
                  >
                    {record.dat_id === 0 ? "All" : <FontAwesomeIcon icon={record.icon} size="lg" />}
                  </ToggleButton>
                ))}
              </ButtonGroup>
            </Stack>
          </section>
          <ActivityContext.Provider value={contextData}>
            <Outlet />
          </ActivityContext.Provider>
        </div>
      )}

      <Modal show={isAddActivity} onHide={setIsAddActivity} size="lg">
        <Formik
          validationSchema={addActivitySchema}
          onSubmit={(values) => {
            if (values.activity_mode === "1") {
              sendAddActivity(values);
            }
            else if (values.activity_mode === "2") {
              blockActivity(values);
            }
          }
          }
          initialValues={{
            activity_mode: "1",
            activity_title: "",
            activity_title_block: "",
            activity_type: "1",
            activity_date: latestDate,
            activity_time: selectedTime,
            activity_duration: selectedDuration,
            activity_notes: "",
            activity_linked: "deal",
            activity_user: selectedUser,
            deal_id: "",
            prospect_id: "",
            mad: false,
          }}
        >
          {({ handleSubmit, handleChange, setValues, values, touched, errors }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Form.Group>
                  <ButtonGroup className="activity-tab" style={{ width: "100%" }}>
                    <ToggleButton variant="" type="radio" name="activity_mode" id="activity_add" value="1" checked={values.activity_mode === "1"} onChange={() => handleChange("activity_mode")("1")}>
                      <FontAwesomeIcon icon={faBoxCircleCheck} size="lg" className="me-1" />
                      {t("Activity_add_activity")}
                    </ToggleButton>
                    <ToggleButton variant="" type="radio" name="activity_mode" id="activity_block" value="2" checked={values.activity_mode === "2"} onChange={() => handleChange("activity_mode")("2")}>
                      <FontAwesomeIcon icon={faBox} size="lg" className="me-1" />
                      {t("Activity_block_activity")}
                    </ToggleButton>
                  </ButtonGroup>
                </Form.Group>
              </Modal.Header>
              <Modal.Body>
                {values.activity_mode === "1" && (
                  <div>
                    <div className="d-flex mb-3">
                      <div style={{ width: "5%" }}></div>
                      <div style={{ width: "95%" }}>
                        <Form.Group>
                          <Form.Control type="text" placeholder={t("Activity_activity_title")} isInvalid={errors.activity_title && touched.activity_title} onChange={handleChange("activity_title")} />
                          {errors.activity_title && touched.activity_title && <div className="op-error-message">{errors.activity_title}</div>}
                        </Form.Group>
                      </div>
                    </div>

                    <div className="d-flex mb-3">
                      <div style={{ width: "5%" }}></div>
                      <Form.Group>
                        <ButtonGroup className="activity-tab">
                          <ToggleButton variant="" type="radio" name="activity_type" id="activity_type_1" value="1" checked={values.activity_type === "1"} onChange={() => handleChange("activity_type")("1")}>
                            <FontAwesomeIcon icon={faPhoneVolume} className="me-1" />
                            {t("Activity_call")}
                          </ToggleButton>
                          <ToggleButton variant="" type="radio" name="activity_type" id="activity_type_2" value="2" checked={values.activity_type === "2"} onChange={() => handleChange("activity_type")("2")}>
                            <FontAwesomeIcon icon={faUsers} className="me-1" />
                            {t("Activity_meeting")}
                          </ToggleButton>
                          <ToggleButton variant="" type="radio" name="activity_type" id="activity_type_3" value="3" checked={values.activity_type === "3"} onChange={() => handleChange("activity_type")("3")}>
                            <FontAwesomeIcon icon={faStopwatch} className="me-1" />
                            {t("Activity_task")}
                          </ToggleButton>
                          <ToggleButton variant="" type="radio" name="activity_type" id="activity_type_4" value="4" checked={values.activity_type === "4"} onChange={() => handleChange("activity_type")("4")}>
                            <FontAwesomeIcon icon={faFlag} className="me-1" />
                            {t("Activity_deadline")}
                          </ToggleButton>
                          <ToggleButton variant="" type="radio" name="activity_type" id="activity_type_5" value="5" checked={values.activity_type === "5"} onChange={() => handleChange("activity_type")("5")}>
                            <FontAwesomeIcon icon={faEnvelopeOpenText} className="me-1" />
                            {t("Activity_email")}
                          </ToggleButton>
                        </ButtonGroup>
                      </Form.Group>
                    </div>

                    <div className="d-flex mb-3">
                      <div className="d-flex justify-content-center align-items-center" style={{ width: "5%" }}>
                        <FontAwesomeIcon icon={faCalendarClock} size="lg" />
                      </div>
                      <div style={{ width: "95%" }}>
                        <Row>
                          <Form.Group as={Col}>
                            <Form.Control type="date" value={values.activity_date} onChange={handleChange("activity_date")} />
                          </Form.Group>
                          <Form.Group as={Col}>
                            <FormSelect
                              options={timings}
                              valueDefault={setLatestTime(timings)}
                              placeholder={t("Activity_select_time")}
                              onChange={(e) => {
                                setSelectedTime(e.value)
                                handleChange("activity_time", e)
                              }} />
                          </Form.Group>
                          <Form.Group as={Col}>
                            <FormSelect
                              options={durationList}
                              placeholder={t("Activity_select_duration")}
                              // onChange={(e) => handleChange("activity_duration", e)}
                              onChange={(e) => {
                                setSelectedDuration(e.value);
                                handleChange("activity_duration", e);
                              }}
                            />
                          </Form.Group>
                        </Row>
                      </div>
                    </div>

                    <div className="d-flex mb-3">
                      <div className="d-flex justify-content-center align-items-center" style={{ width: "5%" }}>
                        <FontAwesomeIcon icon={faCircleUser} size="lg" />
                      </div>
                      <div style={{ width: "95%" }}>
                        <Form.Group>
                          <FormSelect
                            placeholder={t("Activity_select_user")}
                            // options={activityUser}
                            options={
                              session.role_id == 3
                                ? activityUser.filter(user => user.value === session.user_id.toString())
                                : activityUser
                            }
                            valueDefault={activityUserData}
                            onChange={(e) => {
                              setSelectedUser(e.value)
                              handleChange("activity_user", e)
                            }}
                          />
                        </Form.Group>
                      </div>
                    </div>

                    <div className="d-flex mb-3">
                      <div className="d-flex justify-content-center" style={{ width: "5%" }}>
                        <FontAwesomeIcon icon={faNoteSticky} size="lg" className="mt-2" />
                      </div>
                      <div style={{ width: "95%" }}>
                        <Form.Group className="activity-notes">
                          <NoteEditor value={values.activity_notes} onChangeValue={(value) => handleChange("activity_notes")(value)} />
                        </Form.Group>
                      </div>
                    </div>

                    <div className="d-flex mb-3">
                      <div className="d-flex justify-content-center" style={{ width: "5%" }}>
                        <FontAwesomeIcon icon={faLink} size="lg" />
                      </div>
                      <div style={{ width: "95%" }}>
                        <Form.Group>
                          <Form.Label className="op-text-bigger">{t("Activity_linked_to")}</Form.Label>
                          <Form.Check type="radio" label={t("Activity_deal")} name="activity_linked" id="activity_linked_1" checked={values.activity_linked === "deal"} onChange={() => handleChange("activity_linked")("deal")} />
                          <Form.Check type="radio" label={t("Activity_person")} name="activity_linked" id="activity_linked_2" checked={values.activity_linked === "person"} onChange={() => handleChange("activity_linked")("person")} />
                        </Form.Group>
                      </div>
                    </div>

                    {values.activity_linked === "deal" ? (
                      <div className="d-flex mb-3">
                        <div className="d-flex justify-content-center align-items-center" style={{ width: "5%" }}>
                          <FontAwesomeIcon icon={faDollarCircle} size="lg" />
                        </div>
                        <div style={{ width: "95%" }}>
                          <Form.Group>
                            <FormSelectCreatable
                              placeholder={t("Activity_select_deal")}
                              loadOptions={getDeal}
                              isLoading={isFindDeal}
                              onChange={(info) => {
                                handleChange({
                                  target: { name: "deal_id", value: info.value },
                                });
                              }}
                              isInvalid={errors.deal_id && touched.deal_id}
                            />
                            {errors.deal_id && touched.deal_id && <div className="op-error-message">{errors.deal_id}</div>}
                          </Form.Group>
                        </div>
                      </div>
                    ) : (
                      <div className="d-flex mb-3">
                        <div className="d-flex justify-content-center align-items-center" style={{ width: "5%" }}>
                          <FontAwesomeIcon icon={faUser} size="lg" />
                        </div>
                        <div style={{ width: "95%" }}>
                          <Form.Group>
                            <FormSelectCreatable
                              placeholder={t("Activity_select_person")}
                              loadOptions={getProspect}
                              isLoading={isFindProspect}
                              onChange={(info) => {
                                handleChange({
                                  target: { name: "prospect_id", value: info.value },
                                });
                              }}
                              isInvalid={errors.prospect_id && touched.prospect_id}
                            />
                            {errors.prospect_id && touched.prospect_id && <div className="op-error-message">{errors.prospect_id}</div>}
                          </Form.Group>
                        </div>
                      </div>
                    )}
                  </div>
                )}

                {values.activity_mode === "2" && (
                  <div>
                    <div>
                      <div className="mb-3">
                        <Form.Group>
                          <Form.Control
                            type="text"
                            placeholder={t("Activity_please_enter_activity_title")}
                            value={values.activity_title_block}
                            name="activity_title_block"
                            onChange={handleChange("activity_title_block")}
                            isInvalid={touched.activity_title_block && !!errors.activity_title_block}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.activity_title_block}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>

                      <div className="mb-3">
                        <Row>
                          <Form.Group as={Col}>
                            <Form.Control type="date" value={values.activity_date} onChange={handleChange("activity_date")} />
                          </Form.Group>
                          <Form.Group as={Col}>
                            <FormSelect
                              options={selectTime}
                              placeholder={t("Activity_select_time")}
                              onChange={(e) => {
                                setSelectedBlockTime(e.value);
                                handleChange("activity_time", e);
                              }}
                            />
                          </Form.Group>
                        </Row>
                      </div>

                      <div>
                        <Form.Group>
                          <FormSelect
                            placeholder={t("Activity_select_user")}
                            // options={activityUser}
                            options={
                              session.role_id == 3
                                ? activityUser.filter(user => user.value === session.user_id.toString())
                                : activityUser
                            }
                            valueDefault={activityUserData}
                            onChange={(e) => {
                              setSelectedUser(e.value)
                              handleChange("activity_user", e)
                            }}
                          />
                        </Form.Group>
                      </div>
                    </div>
                  </div>
                )}
              </Modal.Body>
              <Modal.Footer>
                {values.activity_mode === "1" && (
                  <Row>
                    <div className="w-auto">
                      <Form.Check
                        type="switch"
                        name="mad"
                        id="mad"
                        label={t("Activity_mad")}
                        className="d-flex justify-content-center align-items-center me-3"
                        onChange={(e) => {
                          handleChange({
                            target: { name: "mad", value: e.target.checked },
                          });
                        }}
                      />
                    </div>
                    <button type="submit" className="btn op-button op-primary-color text-light shadow w-auto">
                      {t("Activity_submit")}
                    </button>
                  </Row>
                )}

                {values.activity_mode === "2" && (
                  <Row>
                    <button type="submit" className="btn op-button op-primary-color text-light shadow w-auto">
                      {t("Activity_block_activity")}
                    </button>
                  </Row>
                )}
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </Container>
  );
};


export const useActivity = () => {
  return useContext(ActivityContext);
}

export default Activity;
