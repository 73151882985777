import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./components/Login.jsx";
import PrivateRoutes from "./auth/PrivateRoutes.jsx";
import Deal from "./components/Deal.jsx";
import DealList from "./components/DealList.jsx";
import DealDetails from "./components/DealDetails.jsx";
import Prospect from "./components/Prospect.jsx";
import ProspectList from "./components/ProspectList.jsx";
import ProspectDetails from "./components/ProspectDetails.jsx";
import Users from "./components/Users.jsx";
import Activity from "./components/Activity.jsx";
import ActivityList from "./components/ActivityList.jsx";
import ActivityCalendar from "./components/ActivityCalendar.jsx";
import ContactReport from "./components/ReportContact.jsx";
import DealReport from "./components/ReportDeal.jsx";
import { AuthProvider, useAuth } from "./auth/AuthContext.jsx";

import DuplicateLeadReport from "./components/ReportDuplicateLead.jsx";
import ProductivityReport from "./components/ReportProductivity.jsx";
import TransferLeadReport from "./components/ReportTransferLead.jsx";
import ActivityReport from "./components/ReportActivity.jsx";
import CallInsight from "./components/CallInsight.jsx";
import Project from "./components/Project.jsx";
import Phase from "./components/Phase.jsx";
import SalesChart from "./components/SalesChart.jsx";
import SalesChartUnit from "./components/SalesChartUnit.jsx";
import SalesChartLevel from "./components/SalesChartLevel.jsx";
import SalesChartCarpark from "./components/SalesChartCarpark.jsx";
import Booking from "./components/Booking.jsx";
import BookingList from "./components/BookingList.jsx";
import Conversation from "./components/Conversation.jsx";
import BookingForm from "./components/BookingForm.jsx";
import BookingDetails from "./components/BookingDetails.jsx";
import Statistics from "./components/Statistics.jsx";
import BookingDashboard from "./components/BookingDashboard.jsx";
import PhaseDetail from "./components/PhaseDetail.jsx";
import SalesChartUnitDetails from "./components/SalesChartUnitDetails.jsx";
import DealEngagementReport from "./components/ReportDealEngagement.jsx";
import DealPerformanceReport from "./components/ReportDealPerformance.jsx";
import InboundAnalyticReport from "./components/ReportInboundAnalytic.jsx";
import InboundLeadPerformanceReport from "./components/ReportInbLdPer.jsx";
import LeadAcceptanceReport from "./components/ReportLeadAcceptance.jsx";
import LeadRouteReport from "./components/ReportLeadRoute.jsx";
import MarketingAnalyticReport from "./components/ReportMarketingAnalytic.jsx";
import ScheduleDealReport from "./components/ReportScheduleDeal.jsx";
import LeadAgingReport from "./components/LeadAgingReport.jsx";
import AIOutreach from "./components/AIOutreach.jsx";
import Error from "./components/404.jsx";
import Forbidden from "./components/403.jsx";
import CustomerKit from "./components/CustomerKit.jsx";
import CustomerKitProject from "./components/CustomerKitProject.jsx";
import CustomerKitPhase from "./components/CustomerKitPhase.jsx";
import CustomerKitPhaseDetails from "./components/CustomerKitPhaseDetails.jsx";
import CustomerKitSalesChart from "./components/CustomerKitSalesChart.jsx";
import CallAnalytics from "./components/CallAnalytics.jsx";
import Settings from "./components/Settings.jsx";
import MobileSalesChartUnit from "./components/MobileSalesChartUnit.jsx";
import MobileSalesChartLevel from "./components/MobileSalesChartLevel.jsx";
import MobileSalesChartCarpark from "./components/MobileSalesChartCarpark.jsx";
import MobileSalesChartUnit2 from "./components/MobileSalesChartUnit2.jsx";
import MobileSalesChartLevel2 from "./components/MobileSalesChartLevel2.jsx";
import MobileSalesChartCarpark2 from "./components/MobileSalesChartCarpark2.jsx";
import DeviceNotification from "./components/SettingDeviceNot.jsx";
import AvanserCallSetting from "./components/SettingAvanserCall.jsx";
import LostReasonSetting from "./components/SettingLostReason.jsx";
import SourceCategorySetting from "./components/SettingSourceCategory.jsx";
import BUSetting from "./components/SettingBU.jsx";
import FBTokenSetting from "./components/SettingFBToken.jsx";
import DealSourceSetting from "./components/SettingDealSource.jsx";
import MeetupSetting from "./components/SettingMeetup.jsx";
import FBFormSetting from "./components/SettingFBForm.jsx";
import WhatsAppTemplateSetting from "./components/SettingWATemplate.jsx";
import TeamSetting from "./components/SettingTeam.jsx";
import PipelineSetting from "./components/SettingPipeline.jsx";
import EmailTemplateSetting from "./components/SettingEmailTemplate.jsx";
import SmartlinkTemplateSetting from "./components/SettingSmartlinkTemplate.jsx";
import Organization from "./components/Organizations.jsx";
import OrganizationList from "./components/OrganizationList.jsx";
import OrganizationDetails from "./components/OrganizationDetails.jsx";
import NotificationSettings from "./components/NotificationSettings.jsx";
import CustomerKitUnitDetails from "./components/CustomerKitUnitDetails.jsx";
import DealDetailsGL from "./components/DealDetailsGL.jsx";
import ProspectDetailGL from "./components/ProspectDetailsGL.jsx";
import GLTierList from "./components/GLTierList.jsx";
import Gamuda from "./components/Gamuda.jsx";
import Redemption from "./components/Redemption.jsx";
import RedemptionDashboard from "./components/RedemptionDashboard.jsx";
import RedemptionVoucher from "./components/RedemptionVoucher.jsx";
import Approval from "./components/Approval.jsx";
import RedemptionCategory from "./components/RedemptionCategory.jsx";
import RedemptionVendor from "./components/RedemptionVendor.jsx";
import RedemptionEmail from "./components/RedemptionEmail.jsx";
import RedemptionTransactional from "./components/RedemptionTransactional.jsx";
import ProspectListGL from "./components/ProspectListGL.jsx";
import InterestList from "./components/InterestList.jsx";
import CustomField from "./components/CustomField.jsx";
import CustomFieldDeal from "./components/CustomFieldDeal.jsx";
import CustomFieldPerson from "./components/CustomFieldPerson.jsx";
import CustomFieldOrganization from "./components/CustomFieldOrganization.jsx";
import CustomFieldBooking from "./components/CustomFieldBooking.jsx";
import Waba from "./components/SettingWabaList.jsx";
import Blueprint2 from "./components/SettingBlueprint2.jsx";
import ConversationFlow from "./components/SettingConversationFlow.jsx";
import BookingSettings from "./components/booking_settings/BookingSettings.jsx";
import BookingModules from "./components/booking_settings/BookingModules.jsx";
import BookingMap from "./components/booking_settings/BookingMap.jsx";
import ProjectSettings from "./components/booking_settings/ProjectSettings.jsx";
import PhaseSettings from "./components/booking_settings/PhaseSettings.jsx";
import UnitTypeSettings from "./components/booking_settings/UnitTypeSettings.jsx";
import UnitSettings from "./components/booking_settings/UnitSettings.jsx";
import FactSheet from "./components/SettingFactSheet.jsx";
import MarketingForm from "./components/SettingMktgForm.jsx";
import SettingFBConnection from "./components/SettingFBConnection.jsx";
import SettingPermission from "./components/SettingPermission.jsx";
import Survey from "./components/SettingSurvey.jsx";
import SettingLeadScore from "./components/SettingLeadScore.jsx";
import SettingsCampaign from "./components/SettingCampaign.jsx";
import SettingProduct from "./components/SettingProduct.jsx";
import SettingBulkUpdate from "./components/SettingBulkUpdate.jsx";
import SettingUploadLeads from "./components/SettingUploadLeads.jsx";
import CustomerService1 from "./components/CustomerService1.jsx";
import CustomerService2 from "./components/CustomerService2.jsx";
import SettingGetResConn from "./components/SettingGetresConn.jsx";
import SummaryLeads from "./components/SummaryOfLeads.jsx";
import BookngPermission from "./components/booking_settings/BookingPermission.jsx";
import BookingFormat from "./components/booking_settings/BookingFormat.jsx";
import BookingMainFormat from "./components/booking_settings/BookingMainFormat.jsx";
import BookingJointFormat from "./components/booking_settings/BookingJointFormat.jsx";
import BookingUnitModules from "./components/booking_settings/BookingUnitModules.jsx";
import BookingAmenities from "./components/booking_settings/BookingAmenities.jsx";
import BookingFacilities from "./components/booking_settings/BookingFacilities.jsx";
import BookingContact from "./components/booking_settings/BookingContact.jsx";
import BookingFile from "./components/booking_settings/BookingFile.jsx";
import BookingVirtualTour from "./components/booking_settings/BookingVirtualTour.jsx";
import BookingFieldList from "./components/booking_settings/BookingFieldList.jsx";
import GLPlayReport from "./components/GLPlayReport.jsx";
import IncomingLogGL from "./components/IncomingLogGL.jsx";
import OutgoingLogGL from "./components/OutgoingLogGL.jsx";
import Curation from "./components/Curation.jsx";
import CurationList from "./components/CurationList.jsx";
import CurationDetails from "./components/CurationDetails.jsx";
import CurationSegment from "./components/CurationSegment.jsx";
import ChatInstance from "./components/SettingChatInstance.jsx";
import EventRsvp from "./components/EventRSVP.jsx";
import SurveyDashboard from "./components/SurveyDashboard.jsx";
import CampaignDashboard from "./components/CampaignDashboard.jsx";
import ReportDealWon from "./components/ReportDealWon.jsx";
import ReportDealLost from "./components/ReportDealLost.jsx";
import WhatsappCampaign from "./components/WhatsappCampaign.jsx";
import LoginReport from "./components/LoginReport.jsx";
import { PermissionProvider } from "./auth/PermissionContext.jsx";
import ReportSMSCampaign from "./components/ReportSMSCampaign.jsx";
import LeadsAnalytic from "./components/LeadsAnalytic.jsx";
import SRMDashboard from "./components/SRMDashboard.jsx";
import ChatbotConversation from "./components/ChatbotConversation.jsx";
import DealView from "./components/DealView.jsx";
import DealPipeline from "./components/DealPipeline.jsx";
import SettingsWsCampaignTemplate360 from "./components/SettingsWsCampaignTemplate360.jsx";
import UnitPricePreview from "./components/UnitPricePreview.jsx";
import BulkUnitPrice from "./components/booking_settings/BulkUnitPrice.jsx";
import ReportIncomingLogBooking from "./components/ReportIncomingLogBooking.jsx";


const App = () => {
  const [session, setSession] = useState(null);
  const gamuda = [180, 200, 190, 202, 203, 212, 213, 251, 210, 402, 412];

  return (
    <Router>
      <AuthProvider onAuth={(auth) => setSession(auth)}>
        <PermissionProvider>
          <Routes>
            <Route index path="/" element={<Login title="Outperform - Login" />} />
            <Route element={<PrivateRoutes />}>
            <Route path="/deal" element={<Deal />}>
                <Route path="view" element={<DealView />}>
                  <Route path="list" element={<DealList />} />
                  <Route path="pipeline" element={<DealPipeline />} />
                </Route>

                <Route path=":deal_id" element={session && gamuda.includes(Number(session.company_id)) ? <DealDetailsGL /> : <DealDetails />} />
              </Route>

              <Route path="/prospect" element={<Prospect />}>
                <Route index path="list" element={session && gamuda.includes(Number(session.company_id)) ? <ProspectListGL /> : <ProspectList />} />
                <Route path=":prospect_id" element={session && gamuda.includes(Number(session.company_id)) ? <ProspectDetailGL /> : <ProspectDetails />} />
              </Route>

              <Route path="/activity" element={<Activity />}>
                <Route path="list" element={<ActivityList />} title="Activity" />
                <Route path="calendar" element={<ActivityCalendar title="Calendar" />} />
              </Route>

              <Route path="/curation" element={<Curation />}>
                <Route index path="list" element={<CurationList />} />
                <Route path=":curation_id" element={<CurationDetails />} />
              </Route>

              <Route path="/users" element={<Users title="User" />} />
              <Route path="/report-contact" element={<ContactReport title="Contact Report" />} />
              <Route path="/report-deal" element={<DealReport />} title="Deal Report" />
              <Route path="/report-transfer-lead" element={<TransferLeadReport title="Transfer Lead Report" />} />
              <Route path="/report-duplicate-lead" element={<DuplicateLeadReport title="Duplicate Lead Report" />} />
              <Route path="/report-productivity" element={<ProductivityReport title="Productivity Report" />} />
              <Route path="/report-activity" element={<ActivityReport title="Activity Report" />} />
              <Route path="/report-deal-engagement" element={<DealEngagementReport title="Deal Engagement Report" />} />
              <Route path="/report-deal-performance" element={<DealPerformanceReport title="Deal Performance Report" />} />
              <Route path="/report-inbound-analytics" element={<InboundAnalyticReport title="Inbound Analytics Report" />} />
              <Route path="/report-inbound-lead-performance" element={<InboundLeadPerformanceReport title="Inbound Lead Performance" />} />
              <Route path="/report-lead-acceptance" element={<LeadAcceptanceReport title="Lead Acceptance Report" />} />
              <Route path="/report-lead-route" element={<LeadRouteReport title="Lead Routing" />} />
              <Route path="/report-marketing-analytics" element={<MarketingAnalyticReport title="Marketing Analytics" />} />
              <Route path="/report-schedule-deal" element={<ScheduleDealReport title="Schedule Deal Report" />} />
              <Route path="/report-login" element={<LoginReport />} />
              <Route path="/statistics" element={<Statistics title="Statistics" />} />
              <Route path="/lead-aging" element={<LeadAgingReport title="Lead Aging" />} />
              <Route path="/ai-outreach" element={<AIOutreach title="AI Outreach Report" />} />
              <Route path="/call-insight" element={<CallInsight title="Call Insight Dashboard" />} />
              <Route path="/call-analytics" element={<CallAnalytics title="Call Analytics" />} />
              <Route path="/cs1" element={<CustomerService1 />} />
              <Route path="/cs2" element={<CustomerService2 />} />
              <Route path="/report-open-deal" element={<SummaryLeads title="Call Analytics" />} />
              <Route path="/event-rsvp" element={<EventRsvp />} />
              <Route path="/survey-dashboard" element={<SurveyDashboard />} />
              <Route path="/campaign-dashboard" element={<CampaignDashboard />} />
              <Route path="/report-open-deal" element={<SummaryLeads title="Call Analytics" />} />
              <Route path="/report-won-deal" element={<ReportDealWon />} />
              <Route path="/report-lost-deal" element={<ReportDealLost />} />
              <Route path="/report-sms-campaign" element={<ReportSMSCampaign />} />
              <Route path="/report-incoming-log-booking" element={<ReportIncomingLogBooking />} />
              <Route path="/whatsapp-campaign" element={<WhatsappCampaign />} />
              <Route path="/lead-analytic" element={<LeadsAnalytic />} />
              <Route path="/chatbot-conversation" element={<ChatbotConversation />} />

              <Route path="/conversation" element={<Conversation title="Conversation" />}>
                <Route path=":chat_instance_id" element={<Conversation title="Conversation" />} />
              </Route>

              <Route path="/organizations" element={<Organization />}>
                <Route index path="list" element={<OrganizationList title="Organization List" />} />
                <Route path=":organization_id" element={<OrganizationDetails title="Organization Details" />} />
              </Route>

              <Route path="/booking-form/:rea_unit_id/:deal_id/:prospect_id" element={<BookingForm title="Booking Form" />} />
              <Route path="/booking-details/:booking_id/:deal_id" element={<BookingDetails title="Booking Details" />} />
              <Route path="/sales-chart" element={<SalesChart />}>
                <Route index path="unit/:deal_id/:prospect_id" element={<SalesChartUnit title="Sales Kit" />} />
                <Route path="unit/:deal_id/:prospect_id/:rea_project_id/:rea_phase_id" element={<SalesChartUnit title="Sales Kit" />} />
                <Route path="unit-details/:deal_id/:prospect_id/:rea_unit_id" element={<SalesChartUnitDetails title="Unit Details" />} />
                <Route path="level/:deal_id/:prospect_id/:rea_project_id/:rea_phase_id/:level_id" element={<SalesChartLevel title="Level Sales Kit" />} />
                <Route path="carpark/:deal_id/:prospect_id/:rea_project_id/:rea_phase_id/:carpark_id" element={<SalesChartCarpark title="Car Park Sales Kit" />} />
              </Route>

              <Route path="/booking" element={<Booking />}>
                <Route path="list" element={<BookingList />} />
                <Route index path="project/:deal_id/:prospect_id" element={<Project />} />
                <Route path="project/:rea_project_id/:deal_id/:prospect_id" element={<Phase />} />
                <Route path="phase/:rea_project_id/:rea_phase_id/:deal_id/:prospect_id" element={<PhaseDetail />} />
                <Route path="dashboard" element={<BookingDashboard title="Booking Dashboard" />} />
                <Route path="approval" element={<Approval />} />
                <Route path="interest" element={<InterestList />} />
                <Route path="unit-price" element={<UnitPricePreview />} />
              </Route>

              <Route path="admin-settings" element={<BookingSettings />}>
                <Route path="waba" element={<Waba />} />
                <Route path="blueprint2" element={<Blueprint2 />} />
                <Route path="conversation-flow" element={<ConversationFlow />} />
                <Route path="fact-sheet" element={<FactSheet />} />
                <Route index path="project" element={<ProjectSettings />} />
                <Route path="phase" element={<PhaseSettings />} />
                <Route path="type" element={<UnitTypeSettings />} />
                <Route path="unit" element={<UnitSettings />} />
                <Route path="coords" element={<BookingMap />} />
                <Route path="modules" element={<BookingModules />} />
                <Route path="field-list" element={<BookingFieldList />} />
                <Route path="permission" element={<BookngPermission />} />
                <Route path="format" element={<BookingFormat />} />
                <Route path="main-format" element={<BookingMainFormat />} />
                <Route path="joint-format" element={<BookingJointFormat />} />
                <Route path="unit-format" element={<BookingUnitModules />} />
                <Route path="amenities" element={<BookingAmenities />} />
                <Route path="facilities" element={<BookingFacilities />} />
                <Route path="contact" element={<BookingContact />} />
                <Route path="file" element={<BookingFile />} />
                <Route path="virtual-tour" element={<BookingVirtualTour />} />
                <Route path="bulk-unit-price" element={<BulkUnitPrice />} />
              </Route>

              <Route path="/settings" element={<Settings />}>
                <Route path="source-category" element={<SourceCategorySetting title="Source Category Settings" />} />
                <Route path="business-unit" element={<BUSetting title="Business Unit Settings" />} />
                <Route path="avanser-call" element={<AvanserCallSetting title="Avanser Call Settings" />} />
                <Route path="device-notification" element={<DeviceNotification title="Device Notification Settings" />} />
                <Route path="facebook-token" element={<FBTokenSetting title="Facebook Token Settings" />} />
                <Route path="lost-reason" element={<LostReasonSetting title="Lost Reason Settings" />} />
                <Route path="deal-source" element={<DealSourceSetting title="Deal Source Settings" />} />
                <Route path="meetup" element={<MeetupSetting title="Meetup Settings" />} />
                <Route path="fb-form" element={<FBFormSetting title="Facebook Forms Settings" />} />
                <Route path="whatsApp-template" element={<WhatsAppTemplateSetting title="WhatsApp Template Settings" />} />
                <Route path="team" element={<TeamSetting title="Team Settings" />} />
                <Route path="pipeline" element={<PipelineSetting title="Pipeline setting" />} />
                <Route path="email-template" element={<EmailTemplateSetting title="Email Template Setting" />} />
                <Route path="smartlink-template" element={<SmartlinkTemplateSetting title="Smartlink Template Setting" />} />
                <Route path="notification" element={<NotificationSettings title="Notification Setting" />} />
                <Route path="waba" element={<Waba />} />
                <Route path="blueprint2" element={<Blueprint2 />} />
                <Route path="conversation-flow" element={<ConversationFlow />} />
                <Route path="marketing-form" element={<MarketingForm />} />
                <Route path="fb-connection" element={<SettingFBConnection />} />
                <Route path="permission" element={<SettingPermission />} />
                <Route path="survey-settings" element={<Survey />} />
                <Route path="team-settings" element={<TeamSetting />} />
                <Route path="lead-score" element={<SettingLeadScore />} />
                <Route path="campaign-settings" element={<SettingsCampaign />} />
                <Route path="products" element={<SettingProduct />} />
                <Route path="bulk-update" element={<SettingBulkUpdate />} />
                <Route path="upload-leads" element={<SettingUploadLeads />} />
                <Route path="getresponse-connection" element={<SettingGetResConn />} />
                <Route path="chat-instance" element={<ChatInstance />} />
                <Route path="ws-campaign-template-360" element={<SettingsWsCampaignTemplate360 />} />

                <Route path="fields" element={<CustomField />}>
                  <Route path="deal" element={<CustomFieldDeal />} />
                  <Route path="person" element={<CustomFieldPerson />} />
                  <Route path="organization" element={<CustomFieldOrganization />} />
                  <Route path="booking" element={<CustomFieldBooking />} />
                </Route>
              </Route>

              <Route path="/gamuda" element={<Gamuda />}>
                <Route path="report-incoming-log" element={<IncomingLogGL />} />
                <Route path="report-outgoing-log" element={<OutgoingLogGL />} />
                <Route path="report-gl-play" element={<GLPlayReport />} />
                <Route path="list-tier" element={<GLTierList />} />
                <Route path="segment/:curation_id" element={<CurationSegment />} />
                <Route path="srm-dashboard" element={<SRMDashboard />} />
                <Route path="redemption" element={<Redemption />}>
                  <Route index path="dashboard" element={<RedemptionDashboard />} />
                  <Route path="voucher" element={<RedemptionVoucher />} />
                  <Route path="category" element={<RedemptionCategory />} />
                  <Route path="vendor" element={<RedemptionVendor />} />
                  <Route path="email" element={<RedemptionEmail />} />
                  <Route path="transactional" element={<RedemptionTransactional />} />
                </Route>
              </Route>
            </Route>

            <Route path="/rea-sales-kit/:key" element={<CustomerKit />}>
              <Route index path="project" element={<CustomerKitProject />} />
              <Route path=":project_id" element={<CustomerKitPhase />} />
              <Route path=":project_id/:phase_id" element={<CustomerKitPhaseDetails />} />
              <Route path=":project_id/:phase_id/saleschart" element={<CustomerKitSalesChart />} />
              <Route path=":project_id/:phase_id/:unit_number" element={<CustomerKitUnitDetails />} />
            </Route>

            <Route path="/mobile-kit-unit/:rea_project_id/:rea_phase_id/:company_id/:utoken/:ctoken/:role_id/:booking_restricted" element={<MobileSalesChartUnit />} />
            <Route path="/mobile-kit-level/:rea_project_id/:rea_phase_id/:company_id/:level_id/:utoken/:ctoken/:role_id/:booking_restricted" element={<MobileSalesChartLevel />} />
            <Route path="/mobile-kit-carpark/:rea_project_id/:rea_phase_id/:company_id/:carpark_id/:utoken/:ctoken/:role_id/:booking_restricted" element={<MobileSalesChartCarpark />} />

            <Route path="/mobile-unit/:rea_phase_id/:company_id/:utoken/:ctoken/:booking_restricted" element={<MobileSalesChartUnit2 />} />
            <Route path="/mobile-level/:rea_phase_id/:company_id/:level_id/:utoken/:ctoken/:booking_restricted" element={<MobileSalesChartLevel2 />} />
            <Route path="/mobile-carpark/:rea_phase_id/:company_id/:carpark_id/:utoken/:ctoken/:booking_restricted" element={<MobileSalesChartCarpark2 />} />

            <Route path="*" element={<Error />} />
            <Route path="/forbidden" element={<Forbidden />} />
          </Routes>
        </PermissionProvider>
      </AuthProvider>
    </Router>
  );
};

export default App;
