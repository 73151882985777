import React, { useState, useEffect, useRef, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { Button, Card, Col, Container, Form, Row, Stack } from "react-bootstrap";
import { FormSelect } from "../includes/FormCustom";
import { useAuth } from "../auth/AuthContext";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "../api/axios";
import Loader from "../includes/Loader";
import { setCurrency } from "../lib/js/Function";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { faDown } from "@fortawesome/pro-solid-svg-icons";
import { toJpeg } from "html-to-image";
import { io } from "socket.io-client";
import iziToast from "izitoast";
import { useKit } from "./CustomerKit";

const CustomerKitSalesChart = () => {
  const init = useRef(false);
  const { key, project_id, phase_id } = useParams();
  const salesChartRef = useRef();
  const [loading, setLoading] = useState(true);
  const [phaseData, setPhaseData] = useState({});
  const [phaseOption, setPhaseOption] = useState([]);
  const [isSalesChart, setIsSalesChart] = useState(false);
  const [selectedPhase, setSelectedPhase] = useState(null);
  const [salesChart, setSalesChart] = useState("");
  const [unitList, setUnitList] = useState({});
  const navigate = useNavigate();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search).get("search");
  const [searchData, setSearchData] = useState({});
  const imgRef = useRef(null);
  const [svgDimensions, setSvgDimensions] = useState({ width: 0, height: 0 });
  const [salesChartImage, seSalesChartImage] = useState("");
  const { hostUrl, hostUrlType, company_id } = useKit();

  // GET FUNCTION -----------------------------------------

  const getphaseDataing = async (ph_id) => {
    try {
      const response = await axios.get("ws/ws_rea_sales_kit.php", {
        params: {
          task: "SalesKit_saleschart-unit",
          project_id: project_id,
          phase_id: ph_id,
          key: key,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        const selectedRecord = data.phase_record.find((record) => record.value === phase_id);
        setSelectedPhase(selectedRecord);
        setPhaseData(data.record);
        setPhaseOption(data.phase_record);
        setTimeout(() => setUnitLot(data.record.unit_record, data.record), 1000);
        if (searchParams) {
          const decodeSearch = JSON.parse(sessionStorage.getItem("findUnit"));
          setSearchData(decodeSearch);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getSalesChart = async (ph_id) => {
    try {
      const response = await axios.get(`${hostUrl}/${hostUrlType}/assets/rea_booking/api_booking.php`, {
        params: {
          company_id: company_id,
          filename: "booking_map",
        },
      });

      const data = response.data;
      if (data.status === 0 && !Array.isArray(data.record)) {
        if (data.record[ph_id]) {
          return {
            status: true,
            record: data.record[ph_id],
          };
        } else {
          return false;
        }
      } else {
        return {
          status: false,
        };
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getSalesChartImage = async (record) => {
    try {
      const url = record.split(".");
      const urlType = url[url.length - 1];

      const response = await axios.get(`${hostUrl}/${hostUrlType}/php/api_get_file_content.php`, {
        params: {
          filepath: `../assets/rea_booking/${company_id}/project/${record}`,
        },
      });

      const data = response.data;
      if (data && data.content) {
        const base64Mode = `data:image/${urlType};base64,`;
        seSalesChartImage(base64Mode + data.content);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // ONCHANGE FUNCTION ------------------------------------

  const onchangePhase = async (event) => {
    setIsSalesChart(false);
    seSalesChartImage("");
    setSelectedPhase(event);
    await getphaseDataing(event.value);
  };

  // SET FUNCTION -----------------------------------------

  const setUnitLot = async (unit, phase) => {
    try {
      const salesChartClient = await getSalesChart(phase.rea_phase_id);
      if (salesChartClient.status) {
        const clientMap = salesChartClient.record;
        await getSalesChartImage(clientMap.sales_chart);
        setSalesChart("client");
        setUnitList(clientMap);
        setIsSalesChart(true);
      } else {
        const unitRecord = {};
        const processRecord = (record, key) => {
          unitRecord[key] = unitRecord[key] || [];
          unitRecord[key].push(record);
        };

        if (unit && unit.length > 0) {
          unit.forEach((record) => {
            record.unit_status = Number(record.on_hold) === 1 ? "on_hold" : record.unit_status;
            processRecord(record, phase.phase_layout === "landed" ? `${record.unit_title} ${record.unit_type}` : record.unit_level);
          });
        }

        setUnitList(unitRecord);
        setSalesChart(phase.phase_layout);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const setUnitColor = (status) => {
    if (status === "available") {
      return "success";
    } else if (status === "booked") {
      return "danger";
    } else if (status === "reserved") {
      return "danger";
    } else if (status === "sold") {
      return "danger";
    } else if (status === "unavailable") {
      return "danger";
    } else if (status === "on_hold") {
      return "danger";
    } else {
      return "";
    }
  };

  const setUnitColor2 = (status) => {
    if (status === "available") {
      return "rgba(25, 135, 84, 0.5)";
    } else if (status === "booked") {
      return "rgba(220, 53, 69, 0.5)";
    } else if (status === "reserved") {
      return "rgba(220, 53, 69, 0.5)";
    } else if (status === "sold") {
      return "rgba(220, 53, 69, 0.5)";
    } else if (status === "unavailable") {
      return "rgba(220, 53, 69, 0.5)";
    } else if (status === "on_hold") {
      return "rgba(220, 53, 69, 0.5)";
    } else {
      return "";
    }
  };

  const setUnitColor3 = (status) => {
    if (status === "available") {
      return "rgba(25, 135, 84, 0.5)";
    } else if (status === "booked") {
      return "rgba(220, 53, 69, 0.5)";
    } else if (status === "reserved") {
      return "rgba(220, 53, 69, 0.5)";
    } else if (status === "sold") {
      return "rgba(220, 53, 69, 0.5)";
    } else if (status === "unavailable") {
      return "rgba(220, 53, 69, 0.5)";
    } else if (status === "on_hold") {
      return "rgba(220, 53, 69, 0.5)";
    } else {
      return "";
    }
  };

  const setFindScaleRect = (coords, image) => {
    const [x1, y1, x2, y2] = coords.map(Number);
    const scaleX = image.width / image.naturalWidth;
    const scaleY = image.height / image.naturalHeight;
    return [x1 * scaleX, y1 * scaleY, x2 * scaleX, y2 * scaleY];
  };

  const setFindScalePoly = (coords, image) => {
    const scaleX = image.width / image.naturalWidth;
    const scaleY = image.height / image.naturalHeight;
    const formattedCoords = [];
    for (let i = 0; i < coords.length; i += 2) {
      const x = coords[i] * scaleX;
      const y = coords[i + 1] * scaleY;
      formattedCoords.push(`${x}, ${y}`);
    }
    return formattedCoords.join(" ");
  };

  // OPEN FUNCTION ----------------------------------------

  const openUnitDetails = async (unit) => {
    navigate(`/rea-sales-kit/${key}/${project_id}/${phase_id}/${unit.unit_number}`);
  };

  const openLevel = (level) => {
    // if (searchParams) {
    //   navigate(`/sales-chart/level/${deal_id}/${prospect_id}/${selectedPhase.project}/${selectedPhase.value}/${level}?search=true`);
    // } else {
    //   navigate(`/sales-chart/level/${deal_id}/${prospect_id}/${selectedPhase.project}/${selectedPhase.value}/${level}`);
    // }
  };

  const openCarkPark = (carkpark) => {
    // if (searchParams) {
    //   navigate(`/sales-chart/carpark/${deal_id}/${prospect_id}/${selectedPhase.project}/${selectedPhase.value}/${carkpark}?search=true`);
    // } else {
    //   navigate(`/sales-chart/carpark/${deal_id}/${prospect_id}/${selectedPhase.project}/${selectedPhase.value}/${carkpark}`);
    // }
  };

  // RENDER FUNCTION --------------------------------------

  const RenderControl = ({ zoomIn, zoomOut }) => (
    <Stack direction="vertical" gap={2} style={{ position: "absolute", top: 10, right: 10, zIndex: 10 }}>
      <Button variant="light" className="shadow-sm" onClick={() => zoomIn()}>
        <FontAwesomeIcon icon={faPlus} />
      </Button>
      <Button variant="light" className="shadow-sm" onClick={() => zoomOut()}>
        <FontAwesomeIcon icon={faMinus} />
      </Button>
    </Stack>
  );

  const RenderClientUnitRect = useCallback(
    ({ record, image }) => {
      if (!image || !image.current) return null;
      const coords = record.coords.split(",");
      const scaledCoords = setFindScaleRect(coords, image.current);
      const unitData = phaseData.unit_record.length > 0 && phaseData.unit_record.find((data) => Number(data.rea_unit_id) === Number(record.rea_unit_id));

      if (!unitData || unitData.unit_status !== "available" && Number(unitData.on_hold) === 0) return;
      return (
        <>
          <rect key={record.rea_unit_id} width={scaledCoords[2] - scaledCoords[0]} height={scaledCoords[3] - scaledCoords[1]} y={scaledCoords[1]} x={scaledCoords[0]} fill={setUnitColor3(unitData.unit_status)} onClick={() => openUnitDetails(unitData)} />
          {Number(record.rea_unit_id) === Number(searchData.rea_unit_id) && (
            <circle cx={scaledCoords[0]} cy={(scaledCoords[1] + scaledCoords[3]) / 2} r="3" fill="#87E400">
              <animate attributeName="r" values="3;5;3" dur="1s" repeatCount="indefinite" />
            </circle>
          )}
        </>
      );
    },
    [imgRef, phaseData, selectedPhase] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const RenderClientUnitPoly = useCallback(
    ({ record, image }) => {
      if (!image || !image.current) return null;
      const coords = record.coords.split(",");
      const scaledCoords = setFindScalePoly(coords, image.current);
      const circleScaled = setFindScaleRect(coords, image.current);
      const phaseData = phaseData.find((data) => Number(data.rea_phase_id) === Number(selectedPhase.value));
      const unitData = phaseData.unit_record.length > 0 && phaseData.unit_record.find((data) => Number(data.rea_unit_id) === Number(record.rea_unit_id));

      if (!unitData || unitData.unit_status !== "available" && Number(unitData.on_hold) === 0) return null;
      unitData.unit_status = Number(unitData.on_hold) === 1 ? "on_hold" : unitData.unit_status;

      return (
        <>
          <polygon key={record.rea_unit_id} points={scaledCoords} fill={setUnitColor3(unitData.unit_status)} onClick={() => openUnitDetails(unitData)} />
          {Number(record.rea_unit_id) === Number(searchData.rea_unit_id) && (
            <circle cx={(circleScaled[0] + circleScaled[2]) / 2} cy={(circleScaled[1] + circleScaled[3]) / 2} r="3" fill="#87E400">
              <animate attributeName="r" values="3;5;3" dur="1s" repeatCount="indefinite" />
            </circle>
          )}
        </>
      );
    },
    [imgRef, phaseData, selectedPhase] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const RenderClientLevelRect = useCallback(
    ({ record, image }) => {
      if (!image || !image.current) return null;
      const coords = record.coords.split(",");
      const scaledCoords = setFindScaleRect(coords, image.current);

      return (
        <>
          <rect key={record.level_title} width={scaledCoords[2] - scaledCoords[0]} height={scaledCoords[3] - scaledCoords[1]} y={scaledCoords[1]} x={scaledCoords[0]} fill="rgba(255,255,255, 0)" onClick={() => openLevel(record.level_title)} />

          {record.level_title === searchData.level_id && (
            <circle cx={scaledCoords[0]} cy={(scaledCoords[1] + scaledCoords[3]) / 2} r="3" fill="#87E400">
              <animate attributeName="r" values="3;5;3" dur="1s" repeatCount="indefinite" />
            </circle>
          )}
        </>
      );
    },
    [imgRef, phaseData, selectedPhase] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const RenderClientLevelPoly = useCallback(
    ({ record, image }) => {
      if (!image || !image.current) return null;

      const coords = record.coords.split(",");
      const scaledCoords = setFindScalePoly(coords, image.current);
      const circleScaled = setFindScaleRect(coords, image.current);

      return (
        <>
          <polygon key={record.level_id} points={scaledCoords} fill="rgba(255,255,255, 0)" onClick={() => openLevel(record.level_title)} />
          {record.level_title === searchData.level_id && (
            <circle cx={(circleScaled[0] + circleScaled[2]) / 2} cy={(circleScaled[1] + circleScaled[3]) / 2} r="3" fill="#87E400">
              <animate attributeName="r" values="3;5;3" dur="1s" repeatCount="indefinite" />
            </circle>
          )}
        </>
      );
    },
    [imgRef, phaseData, selectedPhase] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const RenderClientCarparkRect = useCallback(
    ({ record, image }) => {
      if (!image || !image.current) return null;
      const coords = record.coords.split(",");
      const scaledCoords = setFindScaleRect(coords, image.current);

      return (
        <>
          <rect key={record.carpark_id} width={scaledCoords[2] - scaledCoords[0]} height={scaledCoords[3] - scaledCoords[1]} y={scaledCoords[1]} x={scaledCoords[0]} fill="rgba(255,255,255, 0)" onClick={() => openCarkPark(record.carpark_title)} />

          {record.carpark_title === searchData.carpark_id && (
            <circle cx={scaledCoords[0]} cy={(scaledCoords[1] + scaledCoords[3]) / 2} r="3" fill="#87E400">
              <animate attributeName="r" values="3;5;3" dur="1s" repeatCount="indefinite" />
            </circle>
          )}
        </>
      );
    },
    [imgRef, phaseData, selectedPhase] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const RenderClientCarparkPoly = useCallback(
    ({ record, image }) => {
      if (!image || !image.current) return null;
      const coords = record.coords.split(",");
      const scaledCoords = setFindScalePoly(coords, image.current);
      const circleScaled = setFindScaleRect(coords, image.current);

      return (
        <>
          <polygon key={record.carpark_id} points={scaledCoords} fill="rgba(255,255,255, 0)" onClick={() => openCarkPark(record.carpark_title)} />
          {record.carpark_title === searchData.carpark_id && (
            <circle cx={(circleScaled[0] + circleScaled[2]) / 2} cy={(circleScaled[1] + circleScaled[3]) / 2} r="3" fill="#87E400">
              <animate attributeName="r" values="3;5;3" dur="1s" repeatCount="indefinite" />
            </circle>
          )}
        </>
      );
    },
    [imgRef, phaseData, selectedPhase] // eslint-disable-line react-hooks/exhaustive-deps
  );

  // USEEFFECT --------------------------------------------

  useEffect(() => {
    const initData = async () => {
      if (!init.current) {
        try {
          await getphaseDataing(phase_id);
          init.current = true;
          setTimeout(() => {
            setLoading(false);
          }, 600);
        } catch (error) {
          setLoading(false);
        }
      }
    };
    initData();
  }, [phase_id]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container fluid className="m-0 p-0" style={{ height: "calc(100vh - 56px)", overflow: "auto" }}>
      {loading ? (
        <Loader />
      ) : (
        <div className="p-4">
          <Row>
            <Col xxl={12} className="mb-4">
              <Stack direction="horizontal" gap={1}>
                <Form.Group>
                  <FormSelect options={phaseOption} valueDefault={selectedPhase} onChange={onchangePhase} width="250px" border={false} shadow={true} />
                </Form.Group>

                <Stack direction="horizontal" gap={3} className="ms-auto">
                  <div className="d-flex align-items-center">
                    <FontAwesomeIcon icon={faCircle} style={{ color: setUnitColor2("available") }} className="me-1" /> Available
                  </div>
                </Stack>
              </Stack>
            </Col>
            <Col xxl={12}>
              <div className="w-100">
                {salesChart === "landed" && (
                  <Container fluid className="m-0 p-0">
                    {Object.entries(unitList).map(([key, value]) => (
                      <div className="mb-3" key={key}>
                        <Card className="border-0 shadow-sm py-2 text-center" index={key}>
                          {key}
                        </Card>
                        <Row>
                          {value.map((record, index) => (
                            <Col xxl={3} className="mt-3 cursor-pointer" key={index} onClick={() => openUnitDetails(record)}>
                              <Card bg={setUnitColor(record.unit_status)} className="border-0 shadow-sm text-light">
                                <Card.Body>
                                  <Card.Title>{record.unit_number}</Card.Title>
                                  <Card.Text className="op-text-medium m-0">{setCurrency(record.unit_selling_price)}</Card.Text>
                                  <Card.Text className="op-text-medium m-0">
                                    {record.unit_title} {record.unit_type}
                                  </Card.Text>
                                </Card.Body>
                              </Card>
                            </Col>
                          ))}
                        </Row>
                      </div>
                    ))}
                  </Container>
                )}

                {salesChart === "condo" && (
                  <Container fluid className="m-0 p-0">
                    <div style={{ overflow: "auto", width: "90vw" }}>
                      <table className="table" style={{ verticalAlign: "middle" }}>
                        <tbody>
                          {[...Object.entries(unitList).reverse()].map(([key, value]) => (
                            <tr key={key}>
                              <th style={{ backgroundColor: "#e5e6e8" }}>
                                <div style={{ width: "100px" }} className="text-center">
                                  Level {Number(key) + 1}
                                </div>
                              </th>
                              <th style={{ backgroundColor: "#eeeeee" }}>
                                <div style={{ width: "100px" }} className="text-center">
                                  Unit No.
                                </div>
                              </th>
                              {value.map((record, index) => (
                                <td key={index} className="cursor-pointer" style={{ backgroundColor: setUnitColor2(record.unit_status), border: "1px solid #fff" }} onClick={() => openUnitDetails(record)}>
                                  <div className="p-2 text-light" style={{ width: "250px" }}>
                                    <div className="op-text-bigger fw-bold mb-1">{record.unit_number}</div>
                                    <div className="op-text-medium">{record.unit_selling_price}</div>
                                    <div className="op-text-medium">{`${record.unit_type} - ${record.unit_builtup_area}`}</div>
                                  </div>
                                </td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </Container>
                )}

                {salesChart === "client" && (
                  <>
                    <TransformWrapper initialScale={1} initialPositionX={200} wheel={{ disabled: true }}>
                      {(utils) => (
                        <Container fluid className="position-relative m-0 p-0">
                          <RenderControl {...utils} />
                          <TransformComponent wrapperStyle={{ width: "100%", height: "75vh", backgroundColor: "#999" }}>
                            <div ref={salesChartRef} className="position-relative d-inline-block" style={{ width: 1000 }}>
                              {salesChartImage && (
                                <img
                                  ref={imgRef}
                                  src={salesChartImage}
                                  width={"100%"}
                                  onLoad={() => {
                                    const { width, height } = imgRef.current;
                                    setSvgDimensions({ width, height });
                                  }}
                                  alt="saleschart"
                                />
                              )}

                              {isSalesChart ? (
                                <svg width={svgDimensions.width} height={svgDimensions.height} style={{ position: "absolute", top: 0, left: 0, zIndex: 1 }}>
                                  {unitList.unit_coords.map((record, index) => (record.shape === "rect" ? <RenderClientUnitRect key={index} record={record} image={imgRef} /> : record.shape === "poly" ? <RenderClientUnitPoly key={index} record={record} image={imgRef} /> : null))}
                                  {unitList.level_availibility === 1 &&
                                    unitList.level.map((record, index) => (record.shape === "rect" ? <RenderClientLevelRect key={index} record={record} image={imgRef} /> : record.shape === "poly" ? <RenderClientLevelPoly key={index} record={record} image={imgRef} /> : null))}
                                  {unitList.carpark_availability === 1 &&
                                    unitList.carpark_level.map((record, index) => (record.shape === "rect" ? <RenderClientCarparkRect key={index} record={record} image={imgRef} /> : record.shape === "poly" ? <RenderClientCarparkPoly key={index} record={record} image={imgRef} /> : null))}
                                </svg>
                              ) : (
                                <Loader />
                              )}
                            </div>
                          </TransformComponent>
                        </Container>
                      )}
                    </TransformWrapper>
                  </>
                )}
              </div>
            </Col>
          </Row>
        </div>
      )}
    </Container>
  );
};

export default CustomerKitSalesChart;
