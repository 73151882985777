import { faBars, faChartSimple, faFilterList, faGear } from "@fortawesome/pro-duotone-svg-icons";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import { Button, ButtonGroup, Col, Container, Dropdown, Modal, OverlayTrigger, Row, Stack, Tooltip, Form, Spinner } from "react-bootstrap";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useDeal } from "../auth/DealContext";
import { FormCustomField, FormDate, FormSelect, FormSelectCreatable } from "../includes/FormCustom";
import { useAuth } from "../auth/AuthContext";
import { useEffect, useState } from "react";
import { setCurrency } from "../lib/js/Function";
import * as formik from "formik";
import * as yup from "yup";
import axios from "../api/axios";
import Swal from "sweetalert2/dist/sweetalert2";
import moment from "moment";
import { usePermission } from "../auth/PermissionContext";
import AddDealDuplicate from "./gamuda/AddDealDuplicate";

const DealView = () => {
  const { Formik } = formik;
  const { dealCount, ísPipeline, pipelineData, pipeline, stageDefaultData, rowCount, isRowSelected, onchangePipeline, setIsFilter, RenderBulkActionToggle, RenderBulkActionMenu, setRefresh, setLoading, setDealCache } = useDeal();
  const renderTooltip = (props, text) => <Tooltip {...props}>{text}</Tooltip>;
  const location = useLocation();
  const navigate = useNavigate();
  const { session } = useAuth();
  const { pipeData, pipeStats, pipeRecord, isPipe, onchangePipe, pipeUser, isPipeUser, onchangePipeUser } = useDeal();
  const [modalAddDeal, setModalAddDeal] = useState(false);
  const [dealSource, setDealSource] = useState([]);
  const [isFindProspect, setIsFindProspect] = useState(false);
  const [customFieldDeal, setCustomFieldDeal] = useState([]);
  const [customFieldPerson, setCustomFieldPerson] = useState([]);
  const [addDealDisabledBtn, setAddDealDisabledBtn] = useState(false);
  const [addDeal, setAddDeal] = useState({
    deal_title: "",
    deal_source: "",
    deal_value: "",
    expected_close_date: "",
    prospect_name: "",
    prospect_id: "",
    contact1: "",
    contact_id: "",
    contact1_disabled: false,
    organization_title: "",
    organization_backup: "",
    organization_id: "",
    organization_disabled: false,
    organization_new: true,
    cf_deals: [],
    cf_person: [],
  });

  const [isDupContact, setisDupContact] = useState(false);
  const [dupContactData, setDupContactData] = useState({});
  const [organizationShow, setOrganizationShow] = useState(true);
  const [dealSourceShow, setDealSourceShow] = useState(true);
  const [dealValueShow, setDealValueShow] = useState(true);
  const [expectedCloseDateShow, setExpectedCloseDateShow] = useState(true);

  const newDealSchema = yup.object().shape({
    deal_title: yup.string().required(t("DealList_error_message")),
    prospect_name: yup.string().required(t("DealList_error_message")),
    prospect_id: yup.string().required(t("DealList_error_message")),
    contact1: yup.string().required(t("DealList_error_message")),
  });

  const setDouble = (text) => {
    if (text) {
      const result = text.replace(/,/g, "");
      return result;
    }

    return 0;
  };

  const setPipelineViewRecord = (stats) => {
    if (Number(stats.status) === 0) {
      if (Number(stats.total_deal_count) > 1) {
        return `${setCurrency(setDouble(stats.grand_deal_value), session.default_lang)} &bull; ${t("DealList_record_many", { count: stats.total_deal_count })}`;
      } else if (Number(stats.total_deal_count) === 1) {
        return `${setCurrency(setDouble(stats.grand_deal_value), session.default_lang)} &bull;  ${t("DealList_record_one", { count: stats.total_deal_count })}`;
      }
    }

    return t("DealList_record_none");
  };

  const getProspect = (inputValue, callback) => {
    setIsFindProspect(false);
    axios
      .get("/ws/ws_prospect.php", {
        params: {
          task: 9,
          company: session.company_id,
          uid: session.user_id,
          q: inputValue,
        },
      })
      .then((response) => {
        var data = response.data;
        if (data.status === 0) {
          var prospectRecord = data.record
            .filter((record) => {
              return Number(record.prospect_id) > 0;
            })
            .map((record) => ({
              ...record,
              label: record.prospect_name,
              value: record.prospect_id,
              customLabel: "",
              contact1: record.contact1 ? record.contact1 : record.contact2 ? record.contact3 : record.contact3 ? record.contact3 : record.contact4 ? record.contact4 : record.contact5 ? record.contact5 : "",
              contact_id: record.contact_id,
            }));

          var newProspectRecord = data.record.find((record) => Number(record.prospect_id) === 0);
          if (newProspectRecord) {
            prospectRecord.unshift({
              label: inputValue,
              value: 0,
              customLabel: newProspectRecord.msg,
              contact1: "",
              contact_id: "",
            });
          }

          callback(prospectRecord);
          setIsFindProspect(true);
        } else {
          var options = [{ label: inputValue, value: 0, customLabel: data.error[0].msg }];
          callback(options);
          setIsFindProspect(true);
        }
      });
  };

  const getCustomField = async (pipelineId) => {
    try {
      const response = await axios.get("ws/ws_custom_field.php", {
        params: {
          task: "getCFForAddDeal",
          area: "",
          cfpipe: pipelineId,
          company: session.company_id,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          v_add: "yes",
        },
      });

      const data = response.data;
      if (data.status === 0) {
        var cfDeal = data.record.filter((record) => {
          return record.cf_area === "deal";
        });

        var cfPerson = data.record.filter((record) => {
          return record.cf_area === "person";
        });

        setCustomFieldDeal(cfDeal);
        setCustomFieldPerson(cfPerson);

        setAddDeal((prevState) => ({
          ...prevState,
          cf_deals: cfDeal.map((record) => ({
            name: record.cf_id,
            fd_values: record.ctf_title === "Checkbox" ? [] : "",
          })),
          cf_person: cfPerson.map((record) => ({
            name: record.cf_id,
            fd_values: record.ctf_title === "Checkbox" ? [] : "",
          })),
        }));
      } else {
        setCustomFieldDeal([]);
        setCustomFieldPerson([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getDealSource = () => {
    axios
      .get("/ws/ws_source.php", {
        params: {
          task: 2,
          company: session.company_id,
        },
      })
      .then((response) => {
        var data = response.data;

        if (data.status === 0) {
          const dealSourceRecord = data.record.map((record) => ({
            label: record.ds_title,
            value: record.ds_id,
          }));

          setDealSource(dealSourceRecord);
        }
      });
  };

  const getDealField = async () => {
    try {
      const response = await axios.get("ws/ws_setting.php", {
        params: {
          task: 8,
          company: session.company_id,
        },
      });

      const data = response.data;

      if (data.record && data.record.length > 0) {

        const organizationField = data.record.find((record) => record.core_title === "organization_title");
        const dealSourceField = data.record.find((record) => record.core_title === "ds_title");
        const dealValueField = data.record.find((record) => record.core_title === "deal_value");
        const expectedCloseDateField = data.record.find((record) => record.core_title === "deal_expected_close_date");

        if (organizationField) {
          setOrganizationShow(organizationField.setting_field_status === "yes");
        }

        if (dealSourceField) {
          setDealSourceShow(dealSourceField.setting_field_status === "yes");
        }

        if (dealValueField) {
          setDealValueShow(dealValueField.setting_field_status === "yes");
        }

        if (expectedCloseDateField) {
          setExpectedCloseDateShow(expectedCloseDateField.setting_field_status === "yes");
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const gamuda = [180, 200, 190, 202, 203, 212, 213, 251, 210, 402, 412];

  const sendCreateOrganization = async (values) => {
    try {
      const response = await axios.get("ws/ws_organization.php", {
        params: {
          task: 4,
          company: session.company_id,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          q: values.organization_title,
        },
      });

      const data = response.data;
      if (Number(data.status) === 0) {
        return data.record[0].organization_id;
      }

      return 0;
    } catch (error) {
      console.error(error);
    }
  }

  const sendCreateDeal = async (values) => {
    setAddDealDisabledBtn(true);
    try {

      var organization_id = values.organization_new && values.organization_title === "" ? 0 : values.organization_id;
      if (organization_id === 0) {
        organization_id = await sendCreateOrganization(values);
      }

      if (values.contact1_disabled === false) {
        const response = await axios.get("ws/ws_prospect.php", {
          params: {
            task: "getMobileExists",
            mobile: values.contact1,
            company: session.company_id,
            type: "",
            people: "",
            user: session.user_id,
          },
        });
    
        const data = response.data;
        if (Number(data.status) === 0) {
          if (gamuda.includes(Number(session.company_id))) {
            sendCreateDealGamuda(values, organization_id);
          } else {
            sendCreateDealGlobal(values, organization_id);
          }
        } else {
          if (gamuda.includes(Number(session.company_id))) {
            setDupContactData(values);
            setisDupContact(true);
          } else {
            setAddDealDisabledBtn(false);
            Swal.fire({
              icon: "error",
              title: "Failed",
              text: "Contact number entered already exists in the system.",
            });
          }
        }
      } else {
        if (gamuda.includes(Number(session.company_id))) {
          sendCreateDealGamuda(values, organization_id);
        } else {
          sendCreateDealGlobal(values, organization_id);
        }
      }


    } catch (error) {
      console.error(error);
    }
  };

  const sendCreateDealGlobal = async (values, organization_id) => {
    try {
      var customField = values.cf_deals.concat(values.cf_person);

      var cf_id = customField.map((record) => {
        return record.name;
      });

      var fd_values = customField.map((record) => {
        if (Array.isArray(record.fd_values)) {
          return record.fd_values.join("|");
        } else {
          return record.fd_values;
        }
      });

      var pipeid = pipelineData.value === "All" ? 0 : pipelineData.value;

      if (location.pathname === "/deal/view/pipeline") {
        pipeid = pipeData.value === "All" ? 0 : pipeData.value;
      } else {
        pipeid = pipelineData.value === "All" ? 0 : pipelineData.value;
      }

      const response = await axios.get("ws/ws_deal.php", {
        params: {
          task: "addDealWithCfv3",
          title: values.deal_title,
          value: values.deal_value,
          source: values.deal_source,
          pipeid: pipeid,
          close_date: values.expected_close_date ? moment(values.expected_close_date).format("YYYY-MM-DD") : "",
          prospect: values.prospect_id,
          prospect_name: values.prospect_name,
          organization: organization_id,
          organization_name: values.organization_title,
          contact: values.contact1,
          contact_id: values.contact_id,
          user: session.user_id,
          cs: stageDefaultData,
          cfid_arr: cf_id.join("[-0-]"),
          cfval_arr: fd_values.join("[-0-]"),
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          company: session.company_id,
        },
      });

      const data = response.data;
      if (Number(data.status) === 0) {
        setDealCache(Math.random());
        setModalAddDeal(false);
        setRefresh(Math.random());
        Swal.fire({
          icon: "success",
          text: t("DealList_success_deal_created"),
          timer: 1500,
        });
      } else {
        setModalAddDeal(false);
        Swal.fire({
          icon: "error",
          text: t("DealDetails_error_api_message"),
          timer: 1500,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendCreateDealGamuda = async (values, organization_id) => {
    try {
      var customField = values.cf_deals.concat(values.cf_person);

      var cf_id = customField.map((record) => {
        return record.name;
      });

      var fd_values = customField.map((record) => {
        if (Array.isArray(record.fd_values)) {
          return record.fd_values.join("|");
        } else {
          return record.fd_values;
        }
      });

      const response = await axios.get("ws/ws_deal.php", {
        params: {
          task: "addDealWithCfv3",
          title: values.deal_title,
          value: values.deal_value,
          source: values.deal_source,
          pipeid: pipelineData.value === "All" ? 0 : pipelineData.value,
          close_date: values.expected_close_date ? moment(values.expected_close_date).format("YYYY-MM-DD") : "",
          prospect: values.prospect_id,
          prospect_name: values.prospect_name,
          organization: organization_id,
          organization_name: values.organization_title,
          contact: values.contact1,
          contact_id: values.contact_id,
          user: session.user_id,
          cs: stageDefaultData,
          cfid_arr: cf_id.join("[-0-]"),
          cfval_arr: fd_values.join("[-0-]"),
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          company: session.company_id,
        },
      });

      const data = response.data;
      if (Number(data.status) === 0) {
        setDealCache(Math.random());
        setModalAddDeal(false);
        setRefresh(Math.random());
        Swal.fire({
          icon: "success",
          title: t("DealList_successful"),
          text: t("DealList_success_deal_created"),
          timer: 1500,
        });
      } else {
        setModalAddDeal(false);
        Swal.fire({
          icon: "error",
          text: t("DealDetails_error_api_message"),
          timer: 1500,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getDealField();
    getDealSource();
    if (location.pathname === "/deal/view" || location.pathname === "/deal/view/") {
      if (Number(session.open_deal_list) === 1) {
        getCustomField(pipelineData.value);
        navigate("/deal/view/list");
      } else {
        getCustomField(pipeData.value);
        navigate("/deal/view/pipeline");
      }
    } else if (location.pathname === "/deal/view/pipeline") {
      setLoading(true);
    } else {
      if (Number(session.open_deal_list) === 1) {
        getCustomField(pipelineData.value);
      } else {
        getCustomField(pipeData.value);
      }
    }
  }, [location.pathname]);

  return (
    <Container fluid className="position-relative p-0">
      <div className="bg-light d-flex align-items-center w-100 px-4" style={{ borderTop: "1px solid #ddd", height: 70 }}>
        <Stack className="w-100 h-100" direction="horizontal" gap={2}>
          <ButtonGroup className="activity-tab">
            <NavLink to="/deal/view/list" className="btn btn-outline" activeclassname="active">
              <FontAwesomeIcon icon={faBars} size="lg" />
            </NavLink>
            <NavLink to="/deal/view/pipeline" className="btn btn-outline" activeclassname="active">
              <FontAwesomeIcon icon={faChartSimple} size="lg" rotation={180} />
            </NavLink>
          </ButtonGroup>
          <button
            type="button"
            className="btn op-button op-primary-color text-light shadow-sm"
            onClick={() => {
              setAddDealDisabledBtn(false);
              setModalAddDeal(true);
            }}
          >
            <FontAwesomeIcon icon={faPlus} className="me-2" /> {t("DealList_deal")}
          </button>

          {location.pathname === "/deal/view/list" ? (
            <>
              {isRowSelected && (
                <>
                  <Dropdown>
                    <Dropdown.Toggle as={RenderBulkActionToggle}></Dropdown.Toggle>
                    <Dropdown.Menu className="shadow-sm border-none animate slideIn" align="start" size={50} as={RenderBulkActionMenu} />
                  </Dropdown>
                  <h6 className="op-text-medium mt-2 me-2">{t("DealList_row_selected", { count: rowCount })}</h6>
                </>
              )}

              <h6 className="ms-auto op-text-medium mt-2 me-2"> {dealCount === 1 ? t("DealList_record_one", { count: dealCount }) : dealCount > 1 ? t("DealList_record_many", { count: dealCount }) : t("DealList_record_none")}</h6>
              {ísPipeline ? <FormSelect options={pipeline} valueDefault={pipelineData} onChange={onchangePipeline} shadow={true} border={true} placeholder="Select Pipeline" width={250} /> : null}
              <OverlayTrigger placement="bottom" overlay={(props) => renderTooltip(props, "Filters")}>
                <button className="btn btn-light shadow-sm border" onClick={setIsFilter}>
                  <FontAwesomeIcon icon={faFilterList} size="lg" />
                </button>
              </OverlayTrigger>
            </>
          ) : (
            <>
              <div className="ms-auto" />
              <div className="ms-auto op-text-medium mt-2 me-2" dangerouslySetInnerHTML={{ __html: setPipelineViewRecord(pipeStats) }} />
              {isPipe && <FormSelect options={pipeRecord} valueDefault={pipeData} onChange={onchangePipe} shadow={true} border={true} placeholder="Select Pipeline" width={250} />}
              {isPipeUser && <FormSelect options={pipeUser} valueDefault={pipeUser[0]} onChange={onchangePipeUser} shadow={true} border={true} placeholder="Select User" width={250} />}
              {Number(session.role_id) === 1 && (
                <OverlayTrigger placement="bottom" overlay={(props) => renderTooltip(props, "Pipeline Settings")}>
                  <Button variant="light" className="border shadow-sm" onClick={() => navigate("/settings/pipeline")}>
                    <FontAwesomeIcon icon={faGear} size="xl" />
                  </Button>
                </OverlayTrigger>
              )}
            </>
          )}
        </Stack>
      </div>
      <div style={{ height: "calc(100vh - 126px)" }}>
        <Outlet />
      </div>

      <Modal show={modalAddDeal} onHide={setModalAddDeal} size="lg">
        <Formik validationSchema={newDealSchema} onSubmit={sendCreateDeal} initialValues={addDeal}>
          {({ handleSubmit, handleChange, setFieldValue, values, touched, errors }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title as={"h6"}>{t("DealList_add_deal")}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Col className="w-50 px-4 py-3">
                    <Form.Group className="mb-3">
                      <Form.Label>{t("DeaList_contact_person")}</Form.Label>
                      <FormSelectCreatable
                        name="prospect_id"
                        loadOptions={getProspect}
                        isLoading={isFindProspect}
                        isInvalid={errors.prospect_id && touched.prospect_id}
                        onChange={(info) => {
                          handleChange({
                            target: { name: "prospect_name", value: info.label },
                          });
                          handleChange({
                            target: { name: "prospect_id", value: info.value },
                          });

                          if (Number(info.value) > 0) {
                            handleChange({ target: { name: "contact1", value: info.contact1 } });
                            handleChange({ target: { name: "contact_id", value: info.contact_id } });

                            if (info.contact1) {
                              handleChange({ target: { name: "contact1_disabled", value: true } });
                            } else {
                              handleChange({ target: { name: "contact1_disabled", value: false } });
                            }

                            if (info.organization_id) {
                              handleChange({ target: { name: "organization_title", value: info.organization_title } });
                              handleChange({ target: { name: "organization_id", value: info.organization_id } });
                              handleChange({ target: { name: "organization_disabled", value: true } });
                              handleChange({ target: { name: "organization_backup", value: info.organization_title } });

                              if (organizationShow) {
                                handleChange({ target: { name: "organization_new", value: false } });
                              } else {
                                handleChange({ target: { name: "organization_new", value: true } });
                              }
                            } else {
                              handleChange({ target: { name: "organization_title", value: "" } });
                              handleChange({ target: { name: "organization_id", value: "" } });
                              handleChange({ target: { name: "organization_disabled", value: false } });
                              handleChange({ target: { name: "organization_backup", value: "" } });
                              
                              if (organizationShow) {
                                handleChange({ target: { name: "organization_new", value: false } });
                              } else {
                                handleChange({ target: { name: "organization_new", value: true } });
                              }
                            }
                          } else {
                            handleChange({ target: { name: "contact1", value: "" } });
                            handleChange({ target: { name: "contact_id", value: "" } });
                            handleChange({ target: { name: "contact1_disabled", value: false } });
                            handleChange({ target: { name: "organization_title", value: "" } });
                            handleChange({ target: { name: "organization_id", value: "" } });
                            handleChange({ target: { name: "organization_disabled", value: false } });
                            handleChange({ target: { name: "organization_new", value: true } });
                            handleChange({ target: { name: "organization_backup", value: "" } });
                          }
                        }}
                      />
                      {errors.prospect_id && touched.prospect_id && <div className="op-error-message">{errors.prospect_id}</div>}
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>{t("DealList_deal_title")}</Form.Label>
                      <Form.Control name="deal_title" type="text" isInvalid={errors.deal_title && touched.deal_title} onChange={handleChange("deal_title")} />
                      {errors.deal_title && touched.deal_title && <div className="op-error-message">{errors.deal_title}</div>}
                    </Form.Group>

                    {dealSourceShow && (
                      <Form.Group className="mb-3">
                        <Form.Label>{t("DealList_deal_source")}</Form.Label>
                        <FormSelect name="deal_source" options={dealSource} isSearchable={true} placeholder="" shadow={false} onChange={(info) => handleChange("deal_source")(info.value)} />
                      </Form.Group>
                    )}

                    {dealValueShow && (
                      <Form.Group className="mb-3">
                        <Form.Label>{t("DealList_deal_value")}</Form.Label>
                        <Form.Control name="deal_value" type="text" onChange={(e) => handleChange("deal_value")(e.target.value)} />
                      </Form.Group>
                    )}

                    {expectedCloseDateShow && (
                      <Form.Group className="mb-3">
                        <Form.Label>{t("DealList_expected_close_date")}</Form.Label>
                        <FormDate name="expected_close_date" onChange={(date) => handleChange("expected_close_date")(date)} />
                      </Form.Group>
                    )}

                    {customFieldDeal.map((record, index) => (
                      <FormCustomField
                        key={record.cf_id}
                        id={record.cf_id}
                        label={record.cf_label}
                        options={record.cf_value}
                        type={record.ctf_title}
                        name={record.cf_id}
                        placeholder=""
                        firstOptions={false}
                        valueDefault={false}
                        isClearable={true}
                        value={values.cf_deals[index].fd_values}
                        onChange={(info) => {
                          const updatedCustomFields = [...values.cf_deals];
                          if (record.ctf_title === "Select") {
                            updatedCustomFields[index].fd_values = info.value;
                          } else if (record.ctf_title === "Radio") {
                            const { value, checked } = info.target;
                            updatedCustomFields[index].fd_values = checked ? value : "";
                          } else if (record.ctf_title === "Checkbox") {
                            const { value, checked } = info.target;
                            if (checked) {
                              updatedCustomFields[index].fd_values.push(value);
                            } else {
                              const indexToRemove = updatedCustomFields[index].fd_values.indexOf(value);
                              if (indexToRemove !== -1) {
                                updatedCustomFields[index].fd_values.splice(indexToRemove, 1);
                              }
                            }
                          } else if (record.ctf_title === "Date") {
                            updatedCustomFields[index].fd_values = info;
                          } else {
                            updatedCustomFields[index].fd_values = info.target.value;
                          }

                          setFieldValue("cf_deals", updatedCustomFields);
                        }}
                      />
                    ))}
                  </Col>
                  <Col className="w-50 px-4 py-3">
                    <div className="op horizontal-start divider">{t("DealList_person")}</div>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("DealList_contact_number")}</Form.Label>
                      <Form.Control name="contact1" value={values.contact1} disabled={values.contact1_disabled} type="text" isInvalid={errors.contact1 && touched.contact1} onChange={handleChange("contact1")} />
                      {errors.contact1 && touched.contact1 && <div className="op-error-message">{errors.contact1}</div>}
                    </Form.Group>

                    {organizationShow && (
                      <Form.Group className="mb-3">
                        <Form.Label>Organization Name</Form.Label>
                        <Form.Control
                          name="organization_title"
                          value={values.organization_title}
                          disabled={values.organization_disabled}
                          type="text"
                          onDoubleClick={() => setFieldValue("organization_disabled", false)}
                          onChange={(e) => {
                            if (e.target.value === values.organization_backup) {
                              setFieldValue("organization_new", false);
                            } else {
                              setFieldValue("organization_new", true);
                            }
                            setFieldValue("organization_id", "");
                            setFieldValue("organization_title", e.target.value);
                          }}
                        />
                        {errors.organization_id && touched.organization_id && <div className="op-error-message">{errors.organization_id}</div>}
                      </Form.Group>
                    )}

                    {customFieldPerson.map((record, index) => (
                      <FormCustomField
                        key={record.cf_id}
                        id={record.cf_id}
                        label={record.cf_label}
                        options={record.cf_value}
                        type={record.ctf_title}
                        name={record.cf_id}
                        placeholder=""
                        firstOptions={false}
                        valueDefault={false}
                        isClearable={true}
                        value={values.cf_person[index].fd_values}
                        onChange={(info) => {
                          const updatedCustomFields = [...values.cf_person];
                          if (record.ctf_title === "Select") {
                            updatedCustomFields[index].fd_values = info.value;
                          } else if (record.ctf_title === "Radio") {
                            const { value, checked } = info.target;
                            updatedCustomFields[index].fd_values = checked ? value : "";
                          } else if (record.ctf_title === "Checkbox") {
                            const { value, checked } = info.target;
                            if (checked) {
                              updatedCustomFields[index].fd_values.push(value);
                            } else {
                              const indexToRemove = updatedCustomFields[index].fd_values.indexOf(value);
                              if (indexToRemove !== -1) {
                                updatedCustomFields[index].fd_values.splice(indexToRemove, 1);
                              }
                            }
                          } else if (record.ctf_title === "Date") {
                            updatedCustomFields[index].fd_values = info;
                          } else {
                            updatedCustomFields[index].fd_values = info.target.value;
                          }

                          setFieldValue("cf_person", updatedCustomFields);
                        }}
                      />
                    ))}
                  </Col>
                  <div className="op vertical fluid divider"></div>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <button type="submit" className="btn op-button op-primary-color text-light shadow" disabled={addDealDisabledBtn}>
                  {addDealDisabledBtn ? <Spinner animation="border" size="sm" /> : t("DealList_submit")}
                </button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>

      {isDupContact && (
        <AddDealDuplicate
          isOpen={setisDupContact}
          dataSet={dupContactData}
          onSuccess={() => {
            setRefresh(Math.random());
            setModalAddDeal(false);
          }}
          stageDefaultData={stageDefaultData}
          pipelineData={pipelineData}
        />
      )}
    </Container>
  );
};

export default DealView;
