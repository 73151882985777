import { useState, useRef, useEffect, useCallback, forwardRef, useMemo } from "react";
import { useParams } from "react-router-dom";
import FormSelect from "../includes/FormSelect";
import { Container, Row, Col, Stack, Dropdown, Card, Nav, Accordion, useAccordionButton, CloseButton, Form, ToggleButton, ButtonGroup, Modal, Spinner, Button, Image } from "react-bootstrap";
import axios from "../api/axios";
import { useAuth } from "../auth/AuthContext";
import Swal, { swal } from "sweetalert2/dist/sweetalert2";
import Loader from "../includes/Loader";
import * as formik from "formik";
import * as yup from "yup";
import "../lib/scss/op-pros-details-gl.scss";
import { AgGridReact } from "ag-grid-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faEllipsis, faPlus, faStar, faChevronDown, faTriangleExclamation, faCircleXmark, faBrowser } from "@fortawesome/pro-solid-svg-icons";
import { faCircle as faCircleLight, faShieldCheck } from "@fortawesome/pro-light-svg-icons";
import {
  faBriefcase,
  faChartBar,
  faChartLine,
  faChartLineDown,
  faChartLineUp,
  faEnvelopeOpenText,
  faFlag,
  faHandshake,
  faPenToSquare,
  faPhoneVolume,
  faRightLeft,
  faStopwatch,
  faUser,
  faUsers,
  faSave,
  faCalendarClock,
  faCircleUser,
  faNoteSticky,
  faPaperclipVertical,
  faEnvelope,
  faCalendarDay,
  faBarsStaggered,
  faBuilding,
  faShieldExclamation,
  faHouseCircleCheck,
  faBallot,
  faUserTie,
  faPhone,
  faListCheck,
  faEnvelopeCircleCheck,
  faPhoneArrowDownLeft,
  faPhoneArrowUpRight,
  faLink,
  faLocationCheck,
  faCircleMinus,
  faPeopleArrows,
  faCircleArrowRight,
  faBoxArchive,
  faLockKeyholeOpen,
  faEnvelopeOpen,
  faFile,
  faFileImage,
  faEnvelopesBulk,
  faFax,
  faHouse,
} from "@fortawesome/pro-duotone-svg-icons";
import { faSquareWhatsapp, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import BuyLot from "./gamuda/BuyLot";
import EditContactDetail from "./gamuda/EditContactDetail";
import EditContactDetailVN from "./gamuda/EditContactDetailVN";
import ContactPref from "./gamuda/ContactPreference";
import EditCustomerProfile from "./gamuda/EditCustomerProfile";
import EditCustomerProfileVN from "./gamuda/EditCustomerProfileVN";
import LotDetails from "./gamuda/LotDetails";
import LotDetailsVN from "./gamuda/LotDetailsVN";
import LinkedContact from "./gamuda/LinkedContact";
import ActivityTimeline from "./gamuda/ActivityTimeline";
import AddContributor from "./gamuda/AddContributor";
import Referral from "./gamuda/Referral";
import { setCurrency, setDateTimeStr, setDateTimeStrVN } from "../lib/js/Function";
import { useTranslation } from "react-i18next";
// import { faChevronLeft, faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import ReactHtmlParser from "html-react-parser";

const ProspectDetailGL = () => {
  const { prospect_id } = useParams();
  const init = useRef(false);
  const { session, logoutUser } = useAuth();
  const { Formik } = formik;
  const { t } = useTranslation();
  const vietnam = [402, 412];

  const [isLoading, setIsLoading] = useState(true);
  const [prosData, setProsData] = useState([]);
  const [projOwner, setProjOwner] = useState([]);
  const [isProsVN, setIsProsVN] = useState([]);
  const [isVN, setIsVN] = useState(false);
  const [isVN2, setIsVN2] = useState(false);
  const [userList, setUserList] = useState([]);
  const [cfPerson, setCfPerson] = useState([]);

  const [isProsTab, setIsProsTab] = useState("contact");

  const [contactType, setContactType] = useState("");
  const [tier, setTier] = useState("");
  const [TOV, setTOV] = useState("");
  const [isContactDetail, setIsContactDetail] = useState(false);
  const [isCustProf, setIsCustProf] = useState(false);
  const [isContributor, setIsContributor] = useState(false);

  // const [dsList, setDsList] = useState([]);

  const [prosDealRowData, setProsDealRowData] = useState([]);
  const [isProsDeal, setIsProsDeal] = useState(false);
  const [prosDealRecFound, setProsDealRecFound] = useState(0);

  const [casesRowData, setCasesRowData] = useState([]);
  const [isCases, setIsCases] = useState(false);
  const [casesRecFound, setCasesRecFound] = useState(0);

  const [edmRowData, setEdmRowData] = useState([]);
  const [isEdm, setIsEdm] = useState(false);
  const [edmRecFound, setEdmRecFound] = useState(0);

  const [loyaltyRowData, setLoyaltyRowData] = useState([]);
  const [isLoyalty, setIsLoyalty] = useState(false);
  const [loyaltyRecFound, setLoyaltyRecFound] = useState(0);

  const [auHisRowData, setAuHisRowData] = useState([]);
  const [isAuHis, setIsAuHis] = useState(false);
  const [auHisRecFound, setAuHisRecFound] = useState(0);

  const [glPlayRowData, setGlPlayRowData] = useState([]);
  const [isGlPlay, setIsGlPlay] = useState(false);
  const [glPlayRecFound, setGlPlayRecFound] = useState(0);

  const [glPlayPurItmRowData, setGlPlayPurItmRowData] = useState([]);
  const [isGlPlayPurItm, setIsGlPlayPurItm] = useState(false);
  const [isOpenGlPlayPurItm, setIsOpenGlPlayPurItm] = useState(false);

  const [intrdcrList, setIntrdcrList] = useState([]);

  const [isDisableFd, setIsDisableFd] = useState(false);

  const [isTransferOwner, setIsTransferOwner] = useState(false);

  const [debtorID, setDebtorID] = useState("");

  const prosDealGridRef = useRef();
  const containerStyleLeads = useMemo(() => ({ width: "100%", height: isProsDeal ? "70vh" : "70vh", paddingTop: 10 }), [isProsDeal]);
  const gridStyleLeads = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const reportColDef = useMemo(() => {
    return {
      sortable: true,
      unSortIcon: true,
      filter: false,
    };
  }, []);

  const [leadsColumn] = useState([
    {
      headerName: t("ProspectDetGL_LeadsGrid_HeaderName1"),
      field: "deal_title",
      resizable: true,
      flex: 1,
      minWidth: 150,
      comparator: (valueA, valueB) => {
        return valueA.localeCompare(valueB);
      },
      valueGetter: (params) => {
        if (params.data) {
          return params.data.deal_title;
        }
      },
      cellRenderer: (params) => {
        if (params.data) {
          return (
            <div
              className="clickable"
              onClick={() => {
                window.open(session.origin + "/deal/" + params.data.deal_id);
              }}
            >
              {params.data.deal_title}
            </div>
          );
        }
      },
    },
    {
      headerName: t("ProspectDetGL_LeadsGrid_HeaderName10"),
      field: "",
      resizable: true,
      width: 120,
    },
    {
      headerName: t("ProspectDetGL_LeadsGrid_HeaderName2"),
      field: "deal_archieve",
      resizable: true,
      width: 120,
      comparator: (valueA, valueB) => {
        return valueA.localeCompare(valueB);
      },
      valueGetter: (params) => {
        if (params.data) {
          if (params.data.deal_archieve) {
            return params.data.deal_archieve;
          } else {
            return "Open";
          }
        }
      },
    },
    {
      headerName: t("ProspectDetGL_LeadsGrid_HeaderName3"),
      field: "company_title",
      resizable: true,
      width: 110,
    },
    {
      headerName: t("ProspectDetGL_LeadsGrid_HeaderName4"),
      field: "cs_title",
      resizable: true,
      width: 120,
    },
    {
      headerName: t("ProspectDetGL_LeadsGrid_HeaderName5"),
      field: "pipeline_title",
      resizable: true,
      width: 150,
    },
    {
      headerName: t("ProspectDetGL_LeadsGrid_HeaderName6"),
      field: "source_category_title",
      resizable: true,
      width: 160,
    },
    {
      headerName: "Source",
      field: "ds_title",
      resizable: true,
      width: 150,
    },
    {
      headerName: t("ProspectDetGL_LeadsGrid_HeaderName7"),
      field: "user_name",
      resizable: true,
      width: 150,
    },
    {
      headerName: t("ProspectDetGL_LeadsGrid_HeaderName8"),
      field: "deal_date_time_create",
      resizable: true,
      width: 150,
      comparator: (date1, date2) => {
        const dateObj1 = new Date(date1);
        const dateObj2 = new Date(date2);

        if (dateObj1 < dateObj2) return -1;
        if (dateObj1 > dateObj2) return 1;
        return 0;
      },
      valueGetter: (params) => {
        if (params.data) {
          var date = new Date(params.data.deal_date_time_create);
          var showDate = "";

          if (vietnam.includes(Number(session.company_id))) {
            showDate = setDateTimeStrVN(date);
          } else {
            showDate = setDateTimeStr(date);
          }

          return showDate;
        }
      },
    },
    {
      headerName: t("ProspectDetGL_LeadsGrid_HeaderName9"),
      resizable: true,
      width: 150,
      comparator: (date1, date2) => {
        const dateObj1 = new Date(date1);
        const dateObj2 = new Date(date2);

        if (dateObj1 < dateObj2) return -1;
        if (dateObj1 > dateObj2) return 1;
        return 0;
      },
      valueGetter: (params) => {
        if (params.data) {
          if (params.data.deal_archieve === "") {
            return vietnam.includes(Number(session.company_id)) ? setDateTimeStrVN(params.data.deal_date_time_update) : setDateTimeStr(params.data.deal_date_time_update);
          } else if (params.data.deal_archieve === "won") {
            return vietnam.includes(Number(session.company_id)) ? setDateTimeStrVN(params.data.deal_archieve_won_log) : setDateTimeStr(params.data.deal_archieve_won_log);
          } else if (params.data.deal_archieve === "lost") {
            return vietnam.includes(Number(session.company_id)) ? setDateTimeStrVN(params.data.deal_archieve_lost_log) : setDateTimeStr(params.data.deal_archieve_lost_log);
          } else if (params.data.deal_archieve === "deleted") {
            return vietnam.includes(Number(session.company_id)) ? setDateTimeStrVN(params.data.deal_archieve_deleted_log) : setDateTimeStr(params.data.deal_archieve_deleted_log);
          }
        }
      },
    },
  ]);

  const getProsDeal = () => {
    axios
      .get("ws/ws_prospect.php", {
        params: {
          task: "getProspectDeals",
          prospect: prospect_id,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      })
      .then((res) => {
        let data = res.data;

        if (data.record_open) {
          data.record_open.sort((a, b) => new Date(b.deal_date_time_create) - new Date(a.deal_date_time_create));

          setProsDealRowData(data.record_open);
          setIsProsDeal(true);
          setProsDealRecFound(data.record_open.length);
        } else {
          setProsDealRowData([]);
          setIsProsDeal(false);
          setProsDealRecFound(0);
        }
      })
      .catch((error) => {
        setProsDealRowData([]);
        setIsProsDeal(false);
        setProsDealRecFound(0);
      });
  };

  const casesGridRef = useRef();
  const containerStyleCases = useMemo(() => ({ width: "100%", height: isCases ? "70vh" : "70vh", paddingTop: 10 }), [isCases]);
  const gridStyleCases = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const [casesColumn] = useState([
    {
      headerName: t("ProspectDetGL_CasesGrid_HeaderName1"),
      resizable: true,
      width: 150,
    },
    {
      headerName: t("ProspectDetGL_CasesGrid_HeaderName2"),
      resizable: true,
      flex: 1,
      minWidth: 180,
    },
    {
      headerName: t("ProspectDetGL_CasesGrid_HeaderName3"),
      resizable: true,
      width: 150,
    },
    {
      headerName: t("ProspectDetGL_CasesGrid_HeaderName4"),
      resizable: true,
      width: 150,
    },
    {
      headerName: t("ProspectDetGL_CasesGrid_HeaderName5"),
      resizable: true,
      width: 150,
    },
    {
      headerName: t("ProspectDetGL_CasesGrid_HeaderName6"),
      resizable: true,
      width: 180,
    },
    {
      headerName: t("ProspectDetGL_CasesGrid_HeaderName7"),
      resizable: true,
      width: 150,
    },
    {
      headerName: t("ProspectDetGL_CasesGrid_HeaderName8"),
      resizable: true,
      width: 150,
    },
  ]);

  const getCases = () => {
    setCasesRecFound(0);
    setCasesRowData([]);
    setIsCases(false);
  };

  const edmGridRef = useRef();
  const containerStyleEdm = useMemo(() => ({ width: "100%", height: isEdm ? "70vh" : "70vh", paddingTop: 10 }), [isEdm]);
  const gridStyleEdm = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const [edmColumn] = useState([
    {
      headerName: t("ProspectDetGL_EdmGrid_HeaderName1"),
      resizable: true,
      flex: 1,
      minWidth: 250,
      comparator: (date1, date2) => {
        const dateObj1 = new Date(date1);
        const dateObj2 = new Date(date2);

        if (dateObj1 < dateObj2) return -1;
        if (dateObj1 > dateObj2) return 1;
        return 0;
      },
      valueGetter: (params) => {
        if (params.data) {
          if (params.data.event_date_time) {
            return vietnam.includes(Number(session.company_id)) ? setDateTimeStrVN(params.data.event_date_time) : setDateTimeStr(params.data.event_date_time);
          }
        }
      },
    },
    {
      headerName: t("ProspectDetGL_EdmGrid_HeaderName2"),
      field: "email_template_name",
      resizable: true,
      flex: 1,
      minWidth: 250,
    },
    {
      headerName: t("ProspectDetGL_EdmGrid_HeaderName3"),
      field: "event_type",
      resizable: true,
      flex: 1,
      minWidth: 250,
    },
  ]);

  const getEdm = () => {
    var email = "";

    if (prosData.email1) {
      email = prosData.email1;
    } else if (prosData.email2) {
      email = prosData.email2;
    } else if (prosData.email3) {
      email = prosData.email3;
    }

    axios
      .get("ext/glRedemption/api_edm.php", {
        params: {
          task: "getProspectEDM",
          email: email,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      })
      .then((res) => {
        let data = res.data;

        if (data.status === 0) {
          setEdmRecFound(data.record.length);
          setEdmRowData(data.record);
          setIsEdm(true);
        } else {
          setEdmRecFound(0);
          setEdmRowData([]);
          setIsEdm(false);
        }
      })
      .catch((error) => {
        setEdmRecFound(0);
        setEdmRowData([]);
        setIsEdm(false);
      });
  };

  const loyaltyGridRef = useRef();
  const containerStyleLoyalty = useMemo(() => ({ width: "100%", height: isLoyalty ? "70vh" : "70vh", paddingTop: 10 }), [isLoyalty]);
  const gridStyleLoyalty = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const [loyaltyColumn] = useState([
    {
      headerName: t("ProspectDetGL_LoyaltyGrid_HeaderName1"),
      field: "loyaltyCategory",
      resizable: true,
      width: 180,
    },
    {
      headerName: t("ProspectDetGL_LoyaltyGrid_HeaderName2"),
      field: "accumPurchaseValue",
      resizable: true,
      minWidth: 220,
      cellRenderer: (params) => {
        if (params.data) {
          return vietnam.includes(Number(session.company_id)) ? new Intl.NumberFormat("en-US").format(params.data.accumPurchaseValue) + " VND" : setCurrency(params.data.accumPurchaseValue);
        }
      },
    },
    {
      headerName: t("ProspectDetGL_LoyaltyGrid_HeaderName3"),
      resizable: true,
      flex: 1,
      minWidth: 200,
      comparator: (valueA, valueB) => {
        var num1 = Number(valueA);
        var num2 = Number(valueB);

        return num2 - num1;
      },
      valueGetter: (params) => {
        if (params.data) {
          if (params.data.loyaltyCardNoAlt) {
            return params.data.loyaltyCardNoAlt;
          } else if (params.data.loyaltyCardNo) {
            return params.data.loyaltyCardNo;
          }
        }
      },
    },
    {
      headerName: t("ProspectDetGL_LoyaltyGrid_HeaderName4"),
      resizable: true,
      width: 200,
      comparator: (date1, date2) => {
        const dateObj1 = new Date(date1);
        const dateObj2 = new Date(date2);

        if (dateObj1 < dateObj2) return -1;
        if (dateObj1 > dateObj2) return 1;
        return 0;
      },
      valueGetter: (params) => {
        if (params.data) {
          if (params.data.changeDate) {
            return vietnam.includes(Number(session.company_id)) ? setDateTimeStrVN(params.data.changeDate) : setDateTimeStr(params.data.changeDate);
          }
        }
      },
    },
    {
      headerName: t("ProspectDetGL_LoyaltyGrid_HeaderName5"),
      field: "mobileAppRegisterStatus",
      resizable: true,
      width: 200,
      cellRenderer: (params) => {
        if (params.data) {
          if (params.data.mobileAppRegisterStatus === "notActive") {
            return "No";
          } else if (params.data.mobileAppRegisterStatus === "Active") {
            return "Yes";
          } else {
            return params.data.mobileAppRegisterStatus === "True" || params.data.mobileAppRegisterStatus === "true" ? "Yes" : "No";
          }
        }
      },
    },
    {
      headerName: t("ProspectDetGL_LoyaltyGrid_HeaderName6"),
      resizable: true,
      width: 250,
      comparator: (date1, date2) => {
        const dateObj1 = new Date(date1);
        const dateObj2 = new Date(date2);

        if (dateObj1 < dateObj2) return -1;
        if (dateObj1 > dateObj2) return 1;
        return 0;
      },
      valueGetter: (params) => {
        if (params.data) {
          if (params.data.mobileAppRegisterDate) {
            if (!isNaN(Date.parse(params.data.mobileAppRegisterDate)) === true) {
              return vietnam.includes(Number(session.company_id)) ? setDateTimeStrVN(params.data.mobileAppRegisterDate) : setDateTimeStr(params.data.mobileAppRegisterDate);
            } else {
              return "NA";
            }
          }
        }
      },
    },
  ]);

  const getLoyalty = () => {
    var api_url = "";

    if (vietnam.includes(Number(session.company_id)) && session.hostUrlType === "cloud_staging") {
      api_url = "ext/gamuda/api_rr_loyalty_VN.php";
    } else {
      if (projOwner.length === 0) {
        api_url = "ext/gamuda/api_rr_loyalty.php";
      } else {
        if (vietnam.includes(Number(projOwner[0].sub_prospect_company_id)) && session.hostUrlType === "cloud_staging") {
          api_url = "ext/gamuda/api_rr_loyalty_VN.php";
        } else {
          api_url = "ext/gamuda/api_rr_loyalty.php";
        }
      }
    }

    axios
      .get(api_url, {
        params: {
          prospect_id: prospect_id,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      })
      .then((res) => {
        let data = res.data;

        if (data.status == 0) {
          setLoyaltyRecFound(1);
          setLoyaltyRowData([data.record]);
          setIsLoyalty(true);

          setTier(data.record.loyaltyCategory);
          setTOV(data.record.accumPurchaseValue);
        } else {
          setLoyaltyRecFound(0);
          setLoyaltyRowData([
            {
              loyaltyCategory: "Acquaint",
              accumPurchaseValue: "0",
            },
          ]);
          setIsLoyalty(false);

          setTier("Acquaint");
          setTOV(0);
        }
      })
      .catch((error) => {
        setLoyaltyRecFound(0);
        setLoyaltyRowData([
          {
            loyaltyCategory: "Acquaint",
            accumPurchaseValue: "0",
          },
        ]);
        setIsLoyalty(false);

        setTier("Acquaint");
        setTOV(0);
      });
  };

  const auHisGridRef = useRef();
  const containerStyleAuHis = useMemo(() => ({ width: "100%", height: isAuHis ? "70vh" : "70vh", paddingTop: 10 }), [isAuHis]);
  const gridStyleAuHis = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const [auHisColumn] = useState([
    {
      headerName: t("ProspectDetGL_AuditHistory_HeaderName1"),
      resizable: true,
      width: 250,
      autoHeight: true,
      wrapText: true,
      comparator: (date1, date2) => {
        const dateObj1 = new Date(date1);
        const dateObj2 = new Date(date2);

        if (dateObj1 < dateObj2) return -1;
        if (dateObj1 > dateObj2) return 1;
        return 0;
      },
      valueGetter: (params) => {
        if (params.data) {
          if (params.data.log_timestamp) {
            return vietnam.includes(Number(session.company_id)) ? setDateTimeStrVN(params.data.log_timestamp) : setDateTimeStr(params.data.log_timestamp);
          }
        }
      },
    },
    {
      headerName: t("ProspectDetGL_AuditHistory_HeaderName2"),
      field: "username",
      resizable: true,
      autoHeight: true,
      wrapText: true,
      width: 250,
    },
    {
      headerName: t("ProspectDetGL_AuditHistory_HeaderName3"),
      field: "event",
      resizable: true,
      autoHeight: true,
      wrapText: true,
      width: 250,
    },
    {
      headerName: t("ProspectDetGL_AuditHistory_HeaderName4"),
      resizable: true,
      autoHeight: true,
      wrapText: true,
      flex: 1,
      cellRenderer: (params) => {
        if (params.data) {
          if (params.data.log_new_data[0]) {
            var log = params.data.log_new_data[0].label;
            var log2 = [];

            Object.keys(log).forEach((key) => {
              if (key) {
                if (params.data.log_new_data[0].label[key]) {
                  log2.push(params.data.log_new_data[0].label[key]);
                }
              }
            });

            return (
              <div>
                {log2.map((data, index) => (
                  <div key={index}>{data.replaceAll("_", " ")}</div>
                ))}
              </div>
            );
          }
        }
      },
    },
    {
      headerName: t("ProspectDetGL_AuditHistory_HeaderName5"),
      resizable: true,
      autoHeight: true,
      wrapText: true,
      flex: 1,
      cellRenderer: (params) => {
        if (params.data) {
          if (params.data.log_old_data[0]) {
            var log = params.data.log_old_data[0].value;
            var log2 = [];

            Object.keys(log).forEach((key) => {
              if (key) {
                if (params.data.log_old_data[0].value[key]) {
                  log2.push(params.data.log_old_data[0].value[key]);
                }
              }
            });

            return (
              <div>
                {log2.map((data, index) => (
                  <div key={index}>{decodeURIComponent(data)}</div>
                ))}
              </div>
            );
          }
        }
      },
    },
    {
      headerName: t("ProspectDetGL_AuditHistory_HeaderName6"),
      resizable: true,
      autoHeight: true,
      wrapText: true,
      flex: 1,
      cellRenderer: (params) => {
        if (params.data) {
          if (params.data.log_new_data[0]) {
            var log = params.data.log_new_data[0].value;
            var log2 = [];

            Object.keys(log).forEach((key) => {
              if (key) {
                if (params.data.log_new_data[0].value[key]) {
                  log2.push(params.data.log_new_data[0].value[key]);
                }
              }
            });

            return (
              <div>
                {log2.map((data, index) => (
                  <div key={index}>{decodeURIComponent(data)}</div>
                ))}
              </div>
            );
          }
        }
      },
    },
  ]);

  const getAuHis = () => {
    axios
      .get("ws/ws_auditHistory.php", {
        params: {
          task: "view",
          prospect_id: prospect_id,
          hq_prospect_id: prosData.hq_prospect_id,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      })
      .then((res) => {
        let data = res.data;

        if (data.record) {
          setAuHisRecFound(data.record.length);
          setAuHisRowData(data.record);
          setIsAuHis(true);
        } else {
          setAuHisRecFound(0);
          setAuHisRowData([]);
          setIsAuHis(true);
        }
      })
      .catch((error) => {
        setAuHisRecFound(0);
        setAuHisRowData([]);
        setIsAuHis(true);
      });
  };

  const glPlayGridRef = useRef();
  const containerStyleGlPlay = useMemo(() => ({ width: "100%", height: isGlPlay ? "70vh" : "70vh", paddingTop: 10 }), [isGlPlay]);
  const gridStyleGlPlay = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const [glPlayColumn] = useState([
    {
      headerName: t("ProspectDetGL_GLPlay_HeaderName1"),
      resizable: true,
      width: 200,
      comparator: (valueA, valueB) => {
        var num1 = Number(valueA);
        var num2 = Number(valueB);

        return num2 - num1;
      },
      valueGetter: (params) => {
        if (params.data) {
          if (params.data.OrderDate) {
            return vietnam.includes(Number(session.company_id)) ? setDateTimeStrVN(params.data.OrderDate) : setDateTimeStr(params.data.OrderDate);
          }
        }
      },
    },
    {
      headerName: t("ProspectDetGL_GLPlay_HeaderName2"),
      resizable: true,
      width: "200",
      cellRenderer: (params) => {
        if (params.data) {
          return (
            <button
              className="btn btn-secondary"
              onClick={() => {
                handleGLPurItem(params.data.OrderDetails);
              }}
            >
              Show Item
            </button>
          );
        }
      },
    },
    {
      headerName: t("ProspectDetGL_GLPlay_HeaderName3"),
      field: "OrderID",
      resizable: true,
      width: 250,
      flex: 1,
    },
    {
      headerName: t("ProspectDetGL_GLPlay_HeaderName4"),
      field: "OrderPurchaserName",
      resizable: true,
      flex: 1,
    },
    {
      headerName: t("ProspectDetGL_GLPlay_HeaderName5"),
      field: "OrderAmount",
      resizable: true,
      width: 250,
      comparator: (valueA, valueB) => {
        var num1 = Number(valueA);
        var num2 = Number(valueB);

        return num2 - num1;
      },
      valueGetter: (params) => {
        return params.data.OrderAmount;
      },
      cellRenderer: (params) => {
        if (params.data) {
          return vietnam.includes(Number(session.company_id)) ? new Intl.NumberFormat("en-US").format(params.data.OrderAmount) + " VND" : setCurrency(params.data.OrderAmount);
        }
      },
    },
  ]);

  const getGlPlay = () => {
    axios
      .get("ext/glPlay/api_get_play_orders", {
        params: {
          outperform_prospect_id: prospect_id,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      })
      .then((res) => {
        let data = res.data;

        if (data.status == 0) {
          setGlPlayRecFound(data.GLPlayOrder.length);
          setGlPlayRowData(data.GLPlayOrder);
          setIsGlPlay(true);
        } else {
          setGlPlayRecFound(0);
          setGlPlayRowData([]);
          setIsGlPlay(false);
        }
      })
      .catch((error) => {
        setGlPlayRecFound(0);
        setGlPlayRowData([]);
        setIsGlPlay(false);
      });
  };

  const glPlayPurItmGridRef = useRef();
  const containerStyleGlPlayPurItm = useMemo(() => ({ width: "100%", height: isGlPlayPurItm ? "50vh" : "50vh", paddingTop: 10, paddingBottom: 10 }), [isGlPlayPurItm]);
  const gridStyleGlPlayPurItm = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const [glPlayPurItmCol, setGlPlayPurItmCol] = useState([
    {
      headerName: "Product Quantity",
      field: "ProductQty",
      resizable: true,
      width: "160",
    },
    {
      headerName: "Product SKU",
      field: "ProductSKU",
      resizable: true,
      width: "180",
    },
    {
      headerName: "Product Title",
      field: "ProductTitle",
      resizable: true,
      width: "180",
    },
    {
      headerName: "Product Amount",
      field: "ItemAmount",
      resizable: true,
      width: "180",
      cellRenderer: (params) => {
        if (params.data) {
          return vietnam.includes(Number(session.company_id)) ? new Intl.NumberFormat("en-US").format(params.data.ItemAmount) + " VND" : setCurrency(params.data.ItemAmount);
        }
      },
    },
    {
      headerName: "Product Attraction",
      field: "Attraction",
      resizable: true,
      width: "180",
    },
  ]);

  const handleGLPurItem = (data) => {
    if (data.length > 0 && data) {
      setGlPlayPurItmRowData(data);
      setIsGlPlayPurItm(true);
      setIsOpenGlPlayPurItm(true);
    } else {
      setGlPlayPurItmRowData([]);
      setIsGlPlayPurItm(false);
      setIsOpenGlPlayPurItm(true);
    }
  };

  const getAllUser = () => {
    axios
      .get("ws/ws_user.php", {
        params: {
          task: "4b",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      })
      .then((res) => {
        let data = res.data;

        if (data.status === 0) {
          var userData = data.record.map((data) => {
            return { label: `${data.user_name} (${data.role_title})`, value: data.user_id, role_id: data.role_id };
          });

          setUserList(userData);
        } else {
          setUserList([]);
        }
      });
  };

  const getProspectDetails = () => {
    axios
      .get("ws/ws_prospect_view.php", {
        params: {
          task: "viewProspectDetailsV2",
          prospect: prospect_id,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      })
      .then((res) => {
        let data = res.data;

        if (data.status === 0) {
          const template = ["5000", "5001", "5002", "5003", "5004", "2850", "5005", "5006", "5007", "5008", "5009", "5010", "5011", "5012", "5013", "5014", "5015", "5016", "5017"];

          data.record.prospect_name = ReactHtmlParser(data.record.prospect_name);

          const sortByObject = template.reduce((obj, item, index) => {
            return {
              ...obj,
              [item]: index,
            };
          }, {});

          var cf_person = [];

          data.record.custom_field_data.map((data) => {
            if (template.includes(data.cf_id)) {
              cf_person.push(data);
            }

            if (data.cf_id === "5002") {
              data.cf_label = data.cf_label + " (Newest)";
            }

            if (data.cf_id === "5003") {
              data.cf_label = data.cf_label + " (Older)";
            }

            if (Number(data.cf_id) === 5011) {
              data.fd_value = data.fd_value === "True" ? "Yes" : data.fd_value === "False" ? "No" : data.fd_value;
            }
          });

          cf_person.sort((a, b) => sortByObject[a.cf_id] - sortByObject[b.cf_id]);

          var compulsoryCFIDs = ["5007", "5008", "2681", "5009", "5001", "5014", "5013", "5000", "5010", "5015", "5002", "5005", "5011"];

          cf_person.map((data) => {
            if (compulsoryCFIDs.includes(data.cf_id)) {
              data.cf_label = data.cf_label + " *";
            }
          });

          setCfPerson(cf_person);
          setProsData(data.record);
          setIsLoading(false);
          getDebtorID(data.record);
        } else {
          Swal.fire({
            icon: "error",
            title: "Failed",
          }).then((result) => {
            logoutUser();
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: error.message,
        }).then((result) => {
          logoutUser();
        });
      });
  };

  const getPurchaseLot = () => {
    var api_url = "";

    if (vietnam.includes(Number(session.company_id))) {
      api_url = "ext/gamuda/api_getPurchaseLots_VN.php";
    } else {
      if (projOwner.length === 0) {
        api_url = "ext/gamuda/api_getPurchaseLots.php";
      } else {
        if (vietnam.includes(Number(projOwner[0].sub_prospect_company_id))) {
          api_url = "ext/gamuda/api_getPurchaseLots_VN.php";
        } else {
          api_url = "ext/gamuda/api_getPurchaseLots.php";
        }
      }
    }

    axios
      .get(api_url, {
        params: {
          prospect_id: prospect_id,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      })
      .then((res) => {
        let data = res.data;

        if (data.record && data.record.length > 0) {
          var role = [];
          var status = [];
          var debtorID = "";

          data.record.map((rec) => {
            debtorID = rec.debtorID;
            role.push(rec.role);
            status.push(rec.status);
          });

          if (status.includes("Signed")) {
            if (role.includes("mainBuyer") || role.includes("jointBuyer")) {
              setContactType("Purchaser");
            } else if (role.includes("Tenant") || role.includes("Family") || role.includes("Agent")) {
              setContactType("Prospect");
            } else {
              setContactType("Sub Purchaser");
            }
          } else if (status.includes("Booked")) {
            setContactType("Purchaser");
          } else {
            setContactType("Prospect");
          }

          // if (status.includes('Signed') || status.includes('Booked') || status.includes('Cancelled')) {
          //     setIsDisableFd(true);
          // }else{
          //     setIsDisableFd(false);
          // }
        } else {
          setContactType("Prospect");
          // setIsDisableFd(false);
        }
      })
      .catch((error) => {
        setContactType("Prospect");
        // setIsDisableFd(false);
      });
  };

  const getDebtorID = (prosData) => {
    axios
      .get("ext/gamuda/api_tasks.php", {
        params: {
          task: "getDebtorIDbyProspect",
          prospect_id: prosData.hq_prospect_id && Number(prosData.hq_prospect_id) !== 0 ? prosData.hq_prospect_id : prosData.prospect_id,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      })
      .then((res) => {
        let data = res.data;

        if (Number(data.status) === 0) {
          if (Number(data.debtorID) !== 0) {
            setIsDisableFd(true);
            setDebtorID(data.debtorID);
          } else {
            setIsDisableFd(false);
            setDebtorID("");
          }
        } else {
          setIsDisableFd(false);
          setDebtorID("");
        }
      })
      .catch((error) => {
        console.log(error);
        setIsDisableFd(false);
        setDebtorID("");
      });
  };

  // const getAllDS = () => {

  //     axios.get('ws/ws_source.php', {
  //         params: {
  //             task: '2',
  //             disp: 'show',
  //             company: session.company_id
  //         }
  //     })
  //     .then(res => {
  //         let data = res.data;

  //         if (data.status == 0) {

  //             data.record.sort((a,b) => a.ds_title.localeCompare(b.ds_title));

  //             var dsData = data.record.map((rec) => {
  //                 return {label: rec.ds_title, value: rec.ds_id};
  //             });

  //             setDsList(dsData);
  //         }else{
  //             setDsList([]);
  //         }
  //     })
  //     .catch(error => {
  //         setDsList([]);
  //     });

  // }

  const getIntroducer = () => {
    axios
      .get("ws/ws_prospect_introducer.php", {
        params: {
          task: "GetProspectIntroducer",
          prospect_id: prospect_id,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      })
      .then((res) => {
        let data = res.data;

        if (data.status_introducer == 0) {
          setIntrdcrList(data.record_introducer);
        } else {
          setIntrdcrList([]);
        }
      })
      .catch((error) => {
        setIntrdcrList([]);
      });
  };

  const getOwnerProject = () => {
    axios
      .get("ws/ws_prospect.php", {
        params: {
          task: "getProspectOwnership",
          prospect: prospect_id,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      })
      .then((res) => {
        let data = res.data;

        if (data.status == 0) {
          var owner = [];

          data.record.map((rec) => {
            if (rec.sub_prospect_id == prospect_id) {
              owner.push(rec);
            }
          });

          if (vietnam.includes(Number(session.company_id))) {
            setIsVN2(true);
          } else {
            if (vietnam.includes(Number(data.record[0].sub_prospect_company_id))) {
              setIsVN2(true);
            } else {
              setIsVN2(false);
            }
          }

          setProjOwner(owner);
          setIsProsVN(data.record);
          getPurchaseLot(data.record);
          // getLoyalty(data.record);
        } else {
          setIsVN2(false);
          setProjOwner([]);
          setIsProsVN([]);
          getPurchaseLot([]);
          // getLoyalty([]);
        }
      })
      .catch((error) => {
        setIsVN2(false);
        setProjOwner([]);
        setIsProsVN([]);
        getPurchaseLot([]);
      });
  };

  // useEffect(() => {
  //     if (!init.current) {
  //         init.current = true;
  //         getProspectDetails();
  //         getOwnerProject();
  //         // getPurchaseLot();
  //         getLoyalty();
  //         getAllUser();
  //         // getAllDS();
  //         getIntroducer();
  //         // getDebtorID();
  //     }
  // },[]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!init.current) {
      init.current = true;
      getProspectDetails();
      getOwnerProject();
      // getPurchaseLot();
      getLoyalty();
      getAllUser();
      // getAllDS();
      getIntroducer();
      // getDebtorID();
    }
  }, [prospect_id, init.current]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (init.current) {
      setIsLoading(true);
      init.current = false;
    }
  }, [prospect_id]);

  const RenderAccordionButton = ({ children, eventKey }) => {
    const [isOpen, setIsOpen] = useState(false);
    const decoratedOnClick = useAccordionButton(eventKey, () => setIsOpen(!isOpen));
    return (
      <button onClick={decoratedOnClick} className="accordion-button">
        <FontAwesomeIcon icon={faChevronDown} className={`icon-transition ${isOpen ? "rotate-up" : "rotate-down"} me-2`} />
        <div className="mt-1">{children}</div>
      </button>
    );
  };

  const transferOwnershipShema = yup.object().shape({
    user_id: yup.string().required("This field is required"),
  });

  const sendTransferOwner = (values) => {
    axios
      .get("ws/ws_prospect.php", {
        params: {
          task: "transferProspectOwnership",
          user: values.user_id,
          prospect: prospect_id,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      })
      .then((res) => {
        let data = res.data;

        if (data.status == 0) {
          sendTransferOwner2(values);
          getOwnerProject();
        } else {
          Swal.fire({
            icon: "error",
            title: t("ProspectDetGL_Alert_Failed_Title"),
            text: t("ProspectDetGL_Alert_Failed_Text"),
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: error.message,
        });
      });
  };

  const sendTransferOwner2 = (values) => {
    axios
      .get("ws/ws_system.php", {
        params: {
          task: "5",
          action: "1",
          type: "deal",
          type_id: prospect_id,
          sender: session.user_id,
          sender_type: session.role_id,
          receiver: values.user_id,
          receiver_type: values.role_id,
          company: session.company_id,
          prefix: "transfer",
        },
      })
      .then((res) => {
        let data = res.data;
        if (data) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Successfully transfer ownership",
            timer: 1500,
          }).then((result) => {
            getProspectDetails();
            getOwnerProject();
            setIsTransferOwner(false);
          });
        } else {
          Swal.fire({
            icon: "error",
            title: t("ProspectDetGL_Alert_Failed_Title"),
            text: t("ProspectDetGL_Alert_Failed_Text"),
          }).then((result) => {
            getProspectDetails();
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: error.message,
        });
      });
  };

  const RenderTransferOwnership = forwardRef(({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
    return (
      <div ref={ref} style={{ ...style, width: 300, padding: 0, border: "none", top: 40 }} className={`${className} animate slideIn`} aria-labelledby={labeledBy}>
        <Card>
          <Formik
            validationSchema={transferOwnershipShema}
            onSubmit={sendTransferOwner}
            initialValues={{
              user_id: "",
              role_id: "",
              action: "1",
            }}
          >
            {({ handleSubmit, handleChange, errors, touched, setFieldValue }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Card.Header className="d-flex justify-content-between align-items-center m-2">
                  {t("ProspectDetGL_TransferOwner_Header")}
                  <Dropdown.Item as={CloseButton} onClick={() => setIsTransferOwner(false)} />
                </Card.Header>
                <Card.Body>
                  <Form.Group>
                    <FormSelect
                      placeholder={t("ProspectDetGL_TransferOwner_Body_Placeholder_SelectUser")}
                      options={session.company_id == 251 ? userList.filter((opt) => !prosData.prospect_user_id.includes(opt.value)) : userList.filter((opt) => !projOwner[0].sub_prospect_owner_id.includes(opt.value))}
                      isSearchable={true}
                      isClearable={true}
                      isInvalid={errors.user_id && touched.user_id}
                      onChange={(e) => {
                        setFieldValue("user_id", e ? e.value : "");
                        setFieldValue("role_id", e ? e.role_id : "");
                      }}
                    />
                    {errors.user_id && touched.user_id && <div className="op-error-message">{errors.user_id}</div>}
                  </Form.Group>
                </Card.Body>
                <Card.Footer className="d-flex flex-row-reverse">
                  <button type="submit" className="btn op-button op-primary-color text-light">
                    {t("ProspectDetGL_TransferOwner_Footer_Save")}
                  </button>
                </Card.Footer>
              </Form>
            )}
          </Formik>
        </Card>
      </div>
    );
  });

  const RenderOtherTab = ({ tabName }) => {
    switch (tabName) {
      case "lotDet":
        return (
          <Container fluid style={{ height: "calc(100vh - 206px)", overflow: "auto" }}>
            {vietnam.includes(Number(session.company_id)) ? (
              <LotDetailsVN prospectID={prospect_id} />
            ) : isProsVN.length === 0 ? (
              <LotDetails prospectID={prospect_id} />
            ) : vietnam.includes(Number(isProsVN[0].sub_prospect_company_id)) ? (
              <LotDetailsVN prospectID={prospect_id} />
            ) : (
              <LotDetails prospectID={prospect_id} />
            )}
          </Container>
        );

      case "linkedContact":
        return (
          <Container fluid style={{ height: "calc(100vh - 206px)", overflow: "auto" }}>
            <LinkedContact prospectID={prospect_id} />
          </Container>
        );

      case "leads":
        return (
          <Container fluid className="p-4" style={{ height: "calc(100vh - 206px)", overflow: "auto" }}>
            <Stack direction="horizontal" gap={1} className="d-flex justify-content-center align-items-center pe-2">
              <div className="ms-auto">{prosDealRecFound == 1 ? t("ProspectDetGL_LeadsGrid_Record", { count: prosDealRecFound }) : prosDealRecFound > 1 ? t("ProspectDetGL_LeadsGrid_Record", { count: prosDealRecFound }) : t("ProspectDetGL_LeadsGrid_NoRecord")}</div>
            </Stack>

            <div style={containerStyleLeads}>
              <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyleLeads }}>
                <AgGridReact ref={prosDealGridRef} columnDefs={leadsColumn} rowData={prosDealRowData} defaultColDef={reportColDef} rowHeight={70} pagination={false} />
              </div>
            </div>
          </Container>
        );

      case "cases":
        return (
          <Container fluid className="p-4" style={{ height: "calc(100vh - 206px)", overflow: "auto" }}>
            <Stack direction="horizontal" gap={1} className="d-flex justify-content-center align-items-center pe-2">
              <div className="ms-auto">{casesRecFound == 1 ? t("ProspectDetGL_Grid_Record", { count: casesRecFound }) : casesRecFound > 1 ? t("ProspectDetGL_Grid_Record", { count: casesRecFound }) : t("ProspectDetGL_Grid_NoRecord")}</div>
            </Stack>

            <div style={containerStyleCases}>
              <div className="ag-theme-quartz ag-op" style={{ ...gridStyleCases }}>
                <AgGridReact ref={casesGridRef} columnDefs={casesColumn} rowData={casesRowData} defaultColDef={reportColDef} rowHeight={70} pagination={false} />
              </div>
            </div>
          </Container>
        );

      case "edm":
        return (
          <Container fluid className="p-4" style={{ height: "calc(100vh - 206px)", overflow: "auto" }}>
            <Stack direction="horizontal" gap={1} className="d-flex justify-content-center align-items-center pe-2">
              <div className="ms-auto">{edmRecFound === 1 ? t("ProspectDetGL_Grid_Record", { count: edmRecFound }) : edmRecFound > 1 ? t("ProspectDetGL_Grid_Record", { count: casesRecFound }) : t("ProspectDetGL_Grid_NoRecord")}</div>
            </Stack>

            <div style={containerStyleEdm}>
              <div className="ag-theme-quartz ag-op" style={{ ...gridStyleEdm }}>
                <AgGridReact ref={edmGridRef} columnDefs={edmColumn} rowData={edmRowData} defaultColDef={reportColDef} rowHeight={70} pagination={false} />
              </div>
            </div>
          </Container>
        );

      case "loyaltiProg":
        return (
          <Container fluid className="p-4" style={{ height: "calc(100vh - 206px)", overflow: "auto" }}>
            <Stack direction="horizontal" gap={1} className="d-flex justify-content-center align-items-center pe-2">
              <div className="ms-auto">{loyaltyRecFound == 1 ? t("ProspectDetGL_Grid_Record", { count: loyaltyRecFound }) : loyaltyRecFound > 1 ? t("ProspectDetGL_Grid_Record", { count: loyaltyRecFound }) : t("ProspectDetGL_Grid_NoRecord")}</div>
            </Stack>

            <div style={containerStyleLoyalty}>
              <div className="ag-theme-quartz ag-op" style={{ ...gridStyleLoyalty }}>
                <AgGridReact ref={loyaltyGridRef} columnDefs={loyaltyColumn} rowData={loyaltyRowData} defaultColDef={reportColDef} rowHeight={70} pagination={false} />
              </div>
            </div>
          </Container>
        );

      case "contactPref":
        return (
          // <ContactPref prospectID={prospect_id}/>
          <Container fluid className="p-4" style={{ height: "calc(100vh - 206px)", overflow: "auto" }}>
            <ContactPref prospectID={prospect_id} prospectEmail={prosData.email1} />
          </Container>
        );

      case "auHis":
        return (
          <Container fluid className="p-4" style={{ height: "calc(100vh - 206px)", overflow: "auto" }}>
            {isAuHis && (
              <Stack direction="horizontal" gap={1} className="d-flex justify-content-center align-items-center pe-2">
                <div className="ms-auto">{auHisRecFound == 1 ? t("ProspectDetGL_Grid_Record", { count: auHisRecFound }) : auHisRecFound > 1 ? t("ProspectDetGL_Grid_Record", { count: auHisRecFound }) : t("ProspectDetGL_Grid_NoRecord")}</div>
              </Stack>
            )}
            <div style={containerStyleAuHis}>
              <div className="ag-theme-quartz ag-op" style={{ ...gridStyleAuHis }}>
                <AgGridReact ref={auHisGridRef} columnDefs={auHisColumn} rowData={auHisRowData} defaultColDef={reportColDef} rowHeight={80} pagination={false} />
              </div>
            </div>
          </Container>
        );

      case "glPlay":
        return (
          <Container fluid className="p-4" style={{ height: "calc(100vh - 206px)", overflow: "auto" }}>
            {isGlPlay && (
              <Stack direction="horizontal" gap={1} className="d-flex justify-content-center align-items-center pe-2">
                <div className="ms-auto">{glPlayRecFound == 1 ? `${glPlayRecFound} record found.` : glPlayRecFound > 0 ? `${glPlayRecFound} records found.` : `No record found.`}</div>
              </Stack>
            )}
            <div style={containerStyleGlPlay}>
              <div className="ag-theme-quartz ag-op" style={{ ...gridStyleGlPlay }}>
                <AgGridReact ref={glPlayGridRef} columnDefs={glPlayColumn} rowData={glPlayRowData} defaultColDef={reportColDef} rowHeight={100} pagination={false} />
              </div>
            </div>
          </Container>
        );

      case "referralTab":
        return (
          <Container fluid className="p-4" style={{ height: "calc(100vh - 206px)", overflow: "auto" }}>
            <Referral prospectID={prospect_id} />
          </Container>
        );

      default:
        return null;
    }
  };

  const contactNumFormat = (prefix, number) => {
    if (prefix == 60 && number) {
      return `+${prefix}${number.substring(1)}`;
    } else if (prefix != 60 && prefix && number) {
      return `+${prefix}${number}`;
    } else if (prefix == "" && number) {
      return number;
    } else if (number) {
      return number;
    } else {
      return "-";
    }
  };

  const deleteContr = (data) => {
    var prospectID = "";
    var prospectIDHq = prospect_id;

    if (prosData.hq_prospect_id && prosData.hq_prospect_id != 0) {
      if (session.company_id == 251) {
        prospectID = prosData.hq_prospect_id;
      } else {
        prospectID = prosData.prospect_id;
      }
    } else {
      prospectID = prosData.prospect_id;
    }

    Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: `${data.user_name} will be removed from contributor`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDeleteContr(data, prospectID, prospectIDHq);
      }
    });
  };

  const handleDeleteContr = (data, id, hqID) => {
    axios
      .get("ws/ws_prospect.php", {
        params: {
          task: "delContributor",
          prospectid: id,
          userid: data.user_id,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      })
      .then((res) => {
        let data = res.data;

        if (data.status == 0) {
          getProspectDetails();
        } else {
          Swal.fire({
            icon: "error",
            title: "Failed",
            text: "Please try again or contact your IT Support",
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: error.message,
        });
      });

    // if (session.company_id != 251) {

    //     axios.get('ws/ws_prospect.php',{
    //         params:{
    //             task: "delContributor",
    //             prospectid: hqID,
    //             userid: data.user_id,
    //             utoken: session.user_ac_token,
    //             ctoken: session.company_token
    //         }
    //     })
    //     .then(res => {
    //         let data = res.data;

    //         if (data.status == 0) {
    //             getProspectDetails();
    //         }else{
    //             Swal.fire({
    //                 icon: "error",
    //                 title: "Failed",
    //                 text: "Please try again or contact your IT Support"
    //             });
    //         }
    //     })
    //     .catch(error => {
    //         Swal.fire({
    //             icon: "error",
    //             title: error.message
    //         });
    //     });
    // }
  };

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="pros-dets">
          <div className="section-header-prospect">
            <div className="py-2 px-4">
              <Stack direction="horizontal" gap={3}>
                <div style={{ textTransform: "uppercase" }}>{prosData.prospect_name}</div>
                <div>
                  <FontAwesomeIcon icon={faShieldCheck} size="2xl" style={{ color: debtorID ? "#008000" : "#808080" }} />
                </div>
                <div>
                  <Stack direction="horizontal" gap={2}>
                    {/* <Image 
                                                src={`${session.hostUrl}/${session.hostUrlType}/assets/account_avatar/genericavatar.jpg`} 
                                                roundedCircle
                                                width={43}
                                                height={43}
                                            /> */}
                    <div className="avatar me-2">
                      <div className="avatar-img" style={{ width: 40, height: 40, backgroundColor: "#eee" }}>
                        <div className="avatar-txt text-uppercase ">{session.company_id == 251 ? prosData.owner_name.split("")[0] : projOwner[0] ? projOwner[0].sub_prospect_owner_name.split("")[0] : ""}</div>
                      </div>
                    </div>
                    <div>
                      <div>{session.company_id == 251 ? prosData.owner_name : projOwner[0] ? projOwner[0].sub_prospect_owner_name : ""}</div>
                      <div>{t("ProspectDetGL_Owner")}</div>
                    </div>
                    {session.role_id == 1 && (
                      <Dropdown show={isTransferOwner}>
                        <Dropdown.Toggle className="btn btn-light" onClick={() => setIsTransferOwner(!isTransferOwner)} />
                        <Dropdown.Menu as={RenderTransferOwnership} />
                      </Dropdown>
                    )}
                  </Stack>
                </div>
                <div>
                  {t("ProspectDetGL_ContactType")} : {contactType}
                </div>
                <div>
                  {t("ProspectDetGL_Tier")} : {tier}
                </div>
                <div>
                  {t("ProspectDetGL_TotalAcc")} : {vietnam.includes(Number(session.company_id)) ? new Intl.NumberFormat("en-US").format(TOV) + " VND" : setCurrency(TOV)}
                </div>

                {/* <BuyLot
                                        prospectID={prospect_id}
                                        prosData={prosData}
                                    /> */}
              </Stack>
            </div>
            <div className="py-2 px-2">
              <div style={{ paddingLeft: "10px", paddingRight: "10px", paddingBottom: "10px" }}>
                <Form.Group>
                  <ButtonGroup className="activity-tab" style={{ width: "100%" }}>
                    <ToggleButton variant="" className="d-flex justify-content-center align-items-center" type="radio" name="pros_tab" value={"contact"} checked={isProsTab === "contact"} onClick={() => setIsProsTab("contact")}>
                      {t("ProspectDetGL_Tab_Contact")}
                    </ToggleButton>
                    <ToggleButton variant="" className="d-flex justify-content-center align-items-center" type="radio" name="pros_tab" value={"lotDet"} checked={isProsTab === "lotDet"} onClick={() => setIsProsTab("lotDet")}>
                      {t("ProspectDetGL_Tab_LotDetails")}
                    </ToggleButton>
                    {session.company_id == 251 && (
                      <ToggleButton variant="" className="d-flex justify-content-center align-items-center" type="radio" name="pros_tab" value={"linkedContact"} checked={isProsTab === "linkedContact"} onClick={() => setIsProsTab("linkedContact")}>
                        {t("ProspectDetGL_Tab_LinkedContact")}
                      </ToggleButton>
                    )}
                    <ToggleButton
                      variant=""
                      className="d-flex justify-content-center align-items-center"
                      type="radio"
                      name="pros_tab"
                      value={"leads"}
                      checked={isProsTab === "leads"}
                      onClick={() => {
                        setIsProsTab("leads");
                        getProsDeal();
                      }}
                    >
                      {t("ProspectDetGL_Tab_CampaignLeads")}
                    </ToggleButton>
                    <ToggleButton
                      variant=""
                      className="d-flex justify-content-center align-items-center"
                      type="radio"
                      name="pros_tab"
                      value={"cases"}
                      checked={isProsTab === "cases"}
                      onClick={() => {
                        setIsProsTab("cases");
                        getCases();
                      }}
                    >
                      {t("ProspectDetGL_Tab_Cases")}
                    </ToggleButton>
                    <ToggleButton
                      variant=""
                      className="d-flex justify-content-center align-items-center"
                      type="radio"
                      name="pros_tab"
                      value={"edm"}
                      checked={isProsTab === "edm"}
                      onClick={() => {
                        setIsProsTab("edm");
                        getEdm();
                      }}
                    >
                      {t("ProspectDetGL_Tab_EDM")}
                    </ToggleButton>
                    <ToggleButton
                      variant=""
                      className="d-flex justify-content-center align-items-center"
                      type="radio"
                      name="pros_tab"
                      value={"loyaltiProg"}
                      checked={isProsTab === "loyaltiProg"}
                      onClick={() => {
                        setIsProsTab("loyaltiProg");
                        getLoyalty();
                      }}
                    >
                      {t("ProspectDetGL_Tab_LoyaltyProgram")}
                    </ToggleButton>
                    <ToggleButton variant="" className="d-flex justify-content-center align-items-center" type="radio" name="pros_tab" value={"contactPref"} checked={isProsTab === "contactPref"} onClick={() => setIsProsTab("contactPref")}>
                      {t("ProspectDetGL_Tab_ContactPreference")}
                    </ToggleButton>
                    <ToggleButton
                      variant=""
                      className="d-flex justify-content-center align-items-center"
                      type="radio"
                      name="pros_tab"
                      value={"auHis"}
                      checked={isProsTab === "auHis"}
                      onClick={() => {
                        setIsProsTab("auHis");
                        getAuHis();
                      }}
                    >
                      {t("ProspectDetGL_Tab_AuditHistory")}
                    </ToggleButton>
                    <ToggleButton
                      variant=""
                      className="d-flex justify-content-center align-items-center"
                      type="radio"
                      name="pros_tab"
                      value={"glPlay"}
                      checked={isProsTab === "glPlay"}
                      onClick={() => {
                        setIsProsTab("glPlay");
                        getGlPlay();
                      }}
                    >
                      {t("ProspectDetGL_Tab_GLPlay")}
                    </ToggleButton>
                    {!vietnam.includes(Number(session.company_id)) && (
                      <ToggleButton
                        variant=""
                        className="d-flex justify-content-center align-items-center"
                        type="radio"
                        name="pros_tab"
                        value={"referralTab"}
                        checked={isProsTab === "referralTab"}
                        onClick={() => {
                          setIsProsTab("referralTab");
                        }}
                      >
                        Referral
                      </ToggleButton>
                    )}
                  </ButtonGroup>
                </Form.Group>
              </div>
            </div>
          </div>
          <div className="section-body">
            {isProsTab === "contact" && (
              <div className="section-sidebar">
                <Accordion defaultActiveKey={["0", "1", "2", "3", "4"]} alwaysOpen>
                  <div className="sidebar-accordion">
                    <div className="d-flex justify-content-between align-items-center">
                      <RenderAccordionButton eventKey="0">{t("ProspectDetGL_Sidebar_CustomerProfile")}</RenderAccordionButton>
                      <div className="btn-group shadow-sm">
                        <button
                          type="button"
                          className="btn btn-light border"
                          onClick={() => {
                            setIsCustProf(true);
                            vietnam.includes(Number(session.company_id)) ? setIsVN(true) : isProsVN.length === 0 ? setIsVN(false) : vietnam.includes(Number(isProsVN[0].sub_prospect_company_id)) ? setIsVN(true) : setIsVN(false);
                          }}
                        >
                          <FontAwesomeIcon icon={faPenToSquare} />
                        </button>
                      </div>
                    </div>

                    <Accordion.Collapse eventKey="0">
                      <div className="accordion-body">
                        <div className="d-flex p-2 w-100">
                          <div className="d-flex w-75">
                            <div className="me-2 text-center" style={{ width: 15 }}>
                              <FontAwesomeIcon icon={faUser} size="lg" />
                            </div>
                            <div>{t("ProspectDetGL_Sidebar_CustomerProfile_FullName")} *</div>
                          </div>
                          <span className="w-50 text-break text-end" style={{ textTransform: "uppercase" }}>
                            {prosData.prospect_name ? prosData.prospect_name : "-"}
                          </span>
                        </div>
                        {cfPerson.map((data, index) => (
                          <div className="d-flex p-2 w-100" key={index}>
                            <div className="d-flex w-75">
                              <div className="me-2 text-center" style={{ width: 15 }}>
                                <FontAwesomeIcon icon={faBallot} size="lg" />
                              </div>
                              <div>{data.cf_id == 5014 && isVN2 ? "Monthly Income" : data.cf_label}</div>
                            </div>
                            {data.fd_value ? <span className="w-50 text-break text-end">{data.cf_id == 5001 && data.fd_value == "NRIC No." && isVN2 ? "Vietnam NRIC No." : data.fd_value}</span> : <span className="w-50 text-break text-end">-</span>}
                          </div>
                        ))}
                      </div>
                    </Accordion.Collapse>
                  </div>

                  <div className="sidebar-accordion">
                    <div className="d-flex justify-content-between align-items-center">
                      <RenderAccordionButton eventKey="1">{t("ProspectDetGL_Sidebar_ContactDetails")}</RenderAccordionButton>
                      <div className="btn-group shadow-sm">
                        <button
                          type="button"
                          className="btn btn-light border"
                          onClick={() => {
                            setIsContactDetail(true);
                            vietnam.includes(Number(session.company_id)) ? setIsVN(true) : isProsVN.length === 0 ? setIsVN(false) : vietnam.includes(Number(isProsVN[0].sub_prospect_company_id)) ? setIsVN(true) : setIsVN(false);
                          }}
                        >
                          <FontAwesomeIcon icon={faPenToSquare} />
                        </button>
                      </div>
                    </div>

                    <Accordion.Collapse eventKey="1">
                      <div className="accordion-body">
                        <div className="d-flex p-2 w-100">
                          <div className="d-flex w-75">
                            <div className="me-2 text-center" style={{ width: 15 }}>
                              <FontAwesomeIcon icon={faUser} size="lg" />
                            </div>
                            <div>{t("ProspectDetGL_Sidebar_ContactDetails_ContactNumber1")} *</div>
                          </div>
                          <span className="w-50 text-break text-end">{contactNumFormat(prosData.contact1_prefix, prosData.contact1)}</span>
                        </div>

                        <div className="d-flex p-2 w-100">
                          <div className="d-flex w-75">
                            <div className="me-2 text-center" style={{ width: 15 }}>
                              <FontAwesomeIcon icon={faUser} size="lg" />
                            </div>
                            <div>{t("ProspectDetGL_Sidebar_ContactDetails_ContactNumber2")}</div>
                          </div>
                          <span className="w-50 text-break text-end">{contactNumFormat(prosData.contact2_prefix, prosData.contact2)}</span>
                        </div>

                        <div className="d-flex p-2 w-100">
                          <div className="d-flex w-75">
                            <div className="me-2 text-center" style={{ width: 15 }}>
                              <FontAwesomeIcon icon={faUser} size="lg" />
                            </div>
                            <div>{t("ProspectDetGL_Sidebar_ContactDetails_ContactNumber3")}</div>
                          </div>
                          <span className="w-50 text-break text-end">{contactNumFormat(prosData.contact3_prefix, prosData.contact3)}</span>
                        </div>

                        <div className="d-flex p-2 w-100">
                          <div className="d-flex w-75">
                            <div className="me-2 text-center" style={{ width: 15 }}>
                              <FontAwesomeIcon icon={faUser} size="lg" />
                            </div>
                            <div>{t("ProspectDetGL_Sidebar_ContactDetails_ContactNumber4")}</div>
                          </div>
                          <span className="w-50 text-break text-end">{contactNumFormat(prosData.contact4_prefix, prosData.contact4)}</span>
                        </div>

                        <div className="d-flex p-2 w-100">
                          <div className="d-flex w-75">
                            <div className="me-2 text-center" style={{ width: 15 }}>
                              <FontAwesomeIcon icon={faEnvelope} size="lg" />
                            </div>
                            <div>{t("ProspectDetGL_Sidebar_ContactDetails_Email1")} *</div>
                          </div>
                          <span className="w-50 text-break text-end">{prosData.email1 ? prosData.email1 : "-"}</span>
                        </div>

                        <div className="d-flex p-2 w-100">
                          <div className="d-flex w-75">
                            <div className="me-2 text-center" style={{ width: 15 }}>
                              <FontAwesomeIcon icon={faEnvelope} size="lg" />
                            </div>
                            <div>{t("ProspectDetGL_Sidebar_ContactDetails_Email2")}</div>
                          </div>
                          <span className="w-50 text-break text-end">{prosData.email2 ? prosData.email2 : "-"}</span>
                        </div>

                        <div className="d-flex p-2 w-100">
                          <div className="d-flex w-75">
                            <div className="me-2 text-center" style={{ width: 15 }}>
                              <FontAwesomeIcon icon={faHouse} size="lg" />
                            </div>
                            <div>{t("ProspectDetGL_Sidebar_ContactDetails_AddressType")}</div>
                          </div>
                          <span className="w-50 text-break text-end">{prosData.address_type ? prosData.address_type : "-"}</span>
                        </div>

                        <div className="d-flex p-2 w-100">
                          <div className="d-flex w-75">
                            <div className="me-2 text-center" style={{ width: 15 }}>
                              <FontAwesomeIcon icon={faHouse} size="lg" />
                            </div>
                            <div>{t("ProspectDetGL_Sidebar_ContactDetails_Address1")} *</div>
                          </div>
                          <span className="w-50 text-break text-end" style={{ textTransform: "uppercase" }}>
                            {prosData.address_line1 ? prosData.address_line1 : "-"}
                          </span>
                        </div>

                        <div className="d-flex p-2 w-100">
                          <div className="d-flex w-75">
                            <div className="me-2 text-center" style={{ width: 15 }}>
                              <FontAwesomeIcon icon={faHouse} size="lg" />
                            </div>
                            <div>{t("ProspectDetGL_Sidebar_ContactDetails_Address2")} *</div>
                          </div>
                          <span className="w-50 text-break text-end" style={{ textTransform: "uppercase" }}>
                            {prosData.address_line2 ? prosData.address_line2 : "-"}
                          </span>
                        </div>

                        <div className="d-flex p-2 w-100">
                          <div className="d-flex w-75">
                            <div className="me-2 text-center" style={{ width: 15 }}>
                              <FontAwesomeIcon icon={faHouse} size="lg" />
                            </div>
                            <div>{t("ProspectDetGL_Sidebar_ContactDetails_Address3")}</div>
                          </div>
                          <span className="w-50 text-break text-end" style={{ textTransform: "uppercase" }}>
                            {prosData.address_line3 ? prosData.address_line3 : "-"}
                          </span>
                        </div>

                        <div className="d-flex p-2 w-100">
                          <div className="d-flex w-75">
                            <div className="me-2 text-center" style={{ width: 15 }}>
                              <FontAwesomeIcon icon={faHouse} size="lg" />
                            </div>
                            <div>{t("ProspectDetGL_Sidebar_ContactDetails_Postcode")} *</div>
                          </div>
                          <span className="w-50 text-break text-end">{prosData.postal_code ? prosData.postal_code : "-"}</span>
                        </div>

                        <div className="d-flex p-2 w-100">
                          <div className="d-flex w-75">
                            <div className="me-2 text-center" style={{ width: 15 }}>
                              <FontAwesomeIcon icon={faHouse} size="lg" />
                            </div>
                            <div>{t("ProspectDetGL_Sidebar_ContactDetails_Country")} *</div>
                          </div>
                          <span className="w-50 text-break text-end" style={{ textTransform: "uppercase" }}>
                            {prosData.country_title ? prosData.country_title : "-"}
                          </span>
                        </div>

                        <div className="d-flex p-2 w-100">
                          <div className="d-flex w-75">
                            <div className="me-2 text-center" style={{ width: 15 }}>
                              <FontAwesomeIcon icon={faHouse} size="lg" />
                            </div>
                            <div>{vietnam.includes(Number(session.company_id)) ? `${t("ProspectDetGL_Sidebar_ContactDetails_Province")} *` : "State *"}</div>
                          </div>
                          <span className="w-50 text-break text-end" style={{ textTransform: "uppercase" }}>
                            {prosData.state_title ? prosData.state_title : "-"}
                          </span>
                        </div>

                        <div className="d-flex p-2 w-100">
                          <div className="d-flex w-75">
                            <div className="me-2 text-center" style={{ width: 15 }}>
                              <FontAwesomeIcon icon={faHouse} size="lg" />
                            </div>
                            <div>{vietnam.includes(Number(session.company_id)) ? `${t("ProspectDetGL_Sidebar_ContactDetails_District")} *` : "Town/City *"}</div>
                          </div>
                          <span className="w-50 text-break text-end" style={{ textTransform: "uppercase" }}>
                            {prosData.city_title ? prosData.city_title : "-"}
                          </span>
                        </div>
                      </div>
                    </Accordion.Collapse>
                  </div>

                  <div className="sidebar-accordion">
                    <div className="d-flex justify-content-between align-items-center">
                      <RenderAccordionButton eventKey="2">{t("ProspectDetGL_Sidebar_Contributor")}</RenderAccordionButton>
                      <div className="btn-group shadow-sm">
                        {session.role_id == 1 && (
                          <button type="button" className="btn btn-light border" onClick={setIsContributor}>
                            <FontAwesomeIcon icon={faPlus} />
                          </button>
                        )}
                      </div>
                    </div>

                    <Accordion.Collapse eventKey="2">
                      <div className="accordion-body">
                        {prosData.contributor_count > 0 ? (
                          prosData.contributor.map((data, index) => (
                            <Stack direction="horizontal" key={index} gap={2} className="mb-3 align-items-center px-2">
                              <FontAwesomeIcon icon={faUserTie} size="lg" />
                              <span>{data.user_name}</span>
                              {session.role_id == 1 && (
                                <FontAwesomeIcon
                                  icon={faCircleXmark}
                                  className="ms-auto cursor-pointer"
                                  onClick={() => {
                                    deleteContr(data);
                                  }}
                                />
                              )}
                            </Stack>
                          ))
                        ) : (
                          <div className="px-2">{t("ProspectDetGL_Sidebar_Contributor_None")}</div>
                        )}
                      </div>
                    </Accordion.Collapse>
                  </div>

                  <div className="sidebar-accordion">
                    <div className="d-flex justify-content-between align-items-center">
                      <RenderAccordionButton eventKey="3">{t("ProspectDetGL_Sidebar_Referral")}</RenderAccordionButton>
                      <div className="btn-group shadow-sm">
                        {/* <button 
                                                    type="button" 
                                                    className="btn btn-light border"
                                                >
                                                    <FontAwesomeIcon icon={faPlus} />
                                                </button> */}
                      </div>
                    </div>

                    <Accordion.Collapse eventKey="3">
                      <div className="accordion-body">
                        {intrdcrList.length > 0 ? (
                          intrdcrList.map((data, index) => (
                            <Stack direction="horizontal" className="mb-3 px-2" key={index}>
                              <FontAwesomeIcon icon={faUser} size="lg" className="me-2" />
                              <div>{data.introducer_name}</div>
                            </Stack>
                          ))
                        ) : (
                          <div className="px-2">{t("ProspectDetGL_Sidebar_Referral_None")}</div>
                        )}
                      </div>
                    </Accordion.Collapse>
                  </div>

                  <div className="sidebar-accordion">
                    <div className="d-flex justify-content-between align-items-center">
                      <RenderAccordionButton eventKey="4">{t("ProspectDetGL_Sidebar_Token")}</RenderAccordionButton>
                    </div>

                    <Accordion.Collapse eventKey="4">
                      <div className="accordion-body">
                        {Number(session.company_id) === 251 && (
                          <div className="d-flex p-2 w-100">
                            <div className="d-flex w-75">
                              <div className="me-2 text-center" style={{ width: 15 }}>
                                <FontAwesomeIcon icon={faUser} size="lg" />
                              </div>
                              <div>Debtor ID</div>
                            </div>
                            <span className="w-50 text-break text-end">{debtorID}</span>
                          </div>
                        )}
                        <div className="d-flex p-2 w-100">
                          <div className="d-flex w-75">
                            <div className="me-2 text-center" style={{ width: 15 }}>
                              <FontAwesomeIcon icon={faUser} size="lg" />
                            </div>
                            <div>CRM ID</div>
                          </div>
                          <span className="w-50 text-break text-end">{prosData.CRM_ID ? prosData.CRM_ID : "-"}</span>
                        </div>
                        <div className="d-flex p-2 w-100">
                          <div className="d-flex w-75">
                            <div className="me-2 text-center" style={{ width: 15 }}>
                              <FontAwesomeIcon icon={faUser} size="lg" />
                            </div>
                            <div>Outperform ID</div>
                          </div>
                          <span className="w-50 text-break text-end">{prosData.prospect_token ? prosData.prospect_token : "-"}</span>
                        </div>
                      </div>
                    </Accordion.Collapse>
                  </div>
                </Accordion>
              </div>
            )}
            {isProsTab === "contact" && (
              <div className="section-content">
                <ActivityTimeline prospectID={prospect_id} />
              </div>
            )}
            <RenderOtherTab tabName={isProsTab} />
          </div>

          {isContactDetail && !isVN && (
            <EditContactDetail
              prospectID={prospect_id}
              closeModal={setIsContactDetail}
              onSuccess={() => {
                getProspectDetails();
                setIsContactDetail(false);
              }}
            />
          )}

          {isContactDetail && isVN && (
            <EditContactDetailVN
              prospectID={prospect_id}
              closeModal={setIsContactDetail}
              onSuccess={() => {
                getProspectDetails();
                setIsContactDetail(false);
              }}
            />
          )}

          {isCustProf && !isVN && (
            <EditCustomerProfile
              prospectID={prospect_id}
              closeModal={setIsCustProf}
              onSuccess={() => {
                getProspectDetails();
                setIsCustProf(false);
              }}
              isDisableFd={isDisableFd}
            />
          )}

          {isCustProf && isVN && (
            <EditCustomerProfileVN
              prospectID={prospect_id}
              closeModal={setIsCustProf}
              onSuccess={() => {
                getProspectDetails();
                setIsCustProf(false);
              }}
            />
          )}

          {isContributor && (
            <AddContributor
              prospectID={prospect_id}
              prospectData={prosData}
              contributorData={prosData.contributor}
              closeModal={setIsContributor}
              onSuccess={() => {
                getProspectDetails();
                setIsContributor(false);
              }}
            />
          )}

          <Modal show={isOpenGlPlayPurItm} onHide={setIsOpenGlPlayPurItm} size="lg">
            <Modal.Header closeButton>
              <Modal.Title as={"h6"}>Item Purchased</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div style={containerStyleGlPlayPurItm}>
                <div className="ag-theme-quartz ag-op" style={{ ...gridStyleGlPlayPurItm }}>
                  <AgGridReact ref={glPlayPurItmGridRef} columnDefs={glPlayPurItmCol} rowData={glPlayPurItmRowData} defaultColDef={reportColDef} rowHeight={70} pagination={false} />
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      )}
    </div>
  );
};

export default ProspectDetailGL;
