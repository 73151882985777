import { useState, useEffect, useCallback, useRef, useMemo, forwardRef } from "react";
import { Button, Col, Container, Dropdown, Form, Row, Stack } from "react-bootstrap";
import axios from "../api/axios";
import { useAuth } from "../auth/AuthContext";
import moment from "moment";
import { FormSelect } from "../includes/FormCustom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faMagnifyingGlass } from "@fortawesome/pro-light-svg-icons";
import { AgGridReact } from "ag-grid-react";
import Loader from "../includes/Loader";
import Tippy from "@tippyjs/react";

const ReportIncomingLogBooking = () => {
  const { session } = useAuth();
  const init = useRef(true);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(moment().subtract(1, "months").format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [gridRecord, setGridRecord] = useState([]);
  const [recordFound, setRecordFound] = useState(0);

  // GET FUNCTION ================================

  const getIncomingLog = async () => {
    try {
      const response = await axios.get("ext/mrcb/mrcb_incoming_log.php", {
        params: {
          ds: startDate,
          de: endDate,
        },
      });

      const data = response.data;
      if (data) {
        setRecordFound(data.totalRecord);
        setGridRecord(data.record);
      } else {
        setRecordFound(0);
        setGridRecord([]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // RENDER FUNCTION ================================

  const RenderBuyerName = (params) => {
    const tippyRef = useRef();
    const [visible, setVisible] = useState(false);
    const show = () => setVisible(true);
    const hide = () => setVisible(false);

    const handleWheel = (event) => {
      if (visible) {
        event.preventDefault(); // Prevent default scrolling behavior when the popup is open
      }
    };

    useEffect(() => {
      if (visible) {
        window.addEventListener("wheel", handleWheel, { passive: false });
      } else {
        window.removeEventListener("wheel", handleWheel);
      }
      return () => {
        window.removeEventListener("wheel", handleWheel);
      };
    }, [visible]);

    const dropDownContent = (
      <Dropdown.Menu show style={{ left: "-155px", top: "-15px", zIndex: 0 }}>
        <Dropdown.Header>Open</Dropdown.Header>
        {Number(params.data.booking_id) > 0 && <Dropdown.Item onClick={() => onClickHandler("booking")}>Booking</Dropdown.Item>}
        {Number(params.data.deal_id) > 0 && <Dropdown.Item onClick={() => onClickHandler("deal")}>Deal</Dropdown.Item>}
        {Number(params.data.prospect_id) > 0 && <Dropdown.Item onClick={() => onClickHandler("prospect")}>Prospect</Dropdown.Item>}
      </Dropdown.Menu>
    );

    const onClickHandler = (option) => {
      hide();

      if (option === "booking") {
        window.open(`${session.origin}/booking/${params.data.booking_id}/${params.data.deal_id}`, "_blank");
      } else if (option === "deal") {
        window.open(`${session.origin}/deal/${params.data.deal_id}`, "_blank");
      } else if (option === "prospect") {
        window.open(`${session.origin}/prospect/${params.data.prospect_id}`, "_blank");
      }
    };

    if (params.data !== undefined) {
      return (
        <Tippy ref={tippyRef} content={dropDownContent} visible={visible} onClickOutside={hide} allowHTML={true} arrow={false} appendTo={document.body} interactive={true} placement="left" style={{ zIndex: 1 }}>
          <Button variant="light" className="border" onClick={visible ? hide : show}>
            <FontAwesomeIcon icon={faChevronRight} />
          </Button>
        </Tippy>
      );
    }
  };

  const CustomToggle = forwardRef(({ children, onClick }, ref) => (
    <div
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </div>
  ));

  // GRID FUNCTION ================================

  const gridRef = useRef(null);
  const containerStyle = useMemo(() => ({ width: "100%", height: "80vh" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const gridColumn = [
    {
      headerName: "No",
      field: "",
      maxWidth: 80,
      cellClass: "center",
      headerClass: "center",
      cellRenderer: (params) => {
        if (params) {
          return params.rowIndex + 1;
        } else {
          return <img src="https://www.ag-grid.com/example-assets/loading.gif" alt="loader" />;
        }
      },
    },
    {
      headerName: "Date time triggered",
      field: "date_time_triggered",
      cellClass: "center",
      headerClass: "center",
      cellRenderer: (params) => {
        if (params.data) {
          return moment(params.data.date_time_triggered).format("ll");
        } else {
          return "";
        }
      },
    },
    {
      headerName: "Buyer Name",
      field: "buyer_name",
      filter: true,
    },
    {
      headerName: "Payload",
      flex: 1,
      cellRenderer: (params) => {
        if (params.data) {
          return (
            <pre className="my-2" style={{ whiteSpace: "pre-wrap", fontSize: 13 }}>
              {JSON.stringify(params.data.payload, null, 2)}
            </pre>
          );
        }
      },
    },
    {
      headerName: "Response",
      field: "",
      flex: 1,
      cellRenderer: (params) => {
        if (params.data) {
          return <pre style={{ whiteSpace: "pre-wrap", fontSize: 13 }}>{JSON.stringify(params.data.response, null, 2)}</pre>;
        }
      },
    },

    {
      headerName: "",
      field: "",
      cellClass: "center position-relative",
      headerClass: "center",
      maxWidth: 100,
      pinned: "right",
      cellRenderer: RenderBuyerName,
    },
  ];

  const gridColDef = useMemo(() => {
    return {
      sortable: false,
      filter: false,
      resizable: true,
      wrapText: true,
      autoHeight: true,
    };
  }, []);

  const gridRowId = useCallback(function (params) {
    return params.data.ifca_booking_id.toString();
  }, []);

  useEffect(() => {
    if (init.current) {
      init.current = false;
      getIncomingLog();
    }
  }, [getIncomingLog, init.current]);

  return (
    <Container fluid className="p-4">
      {loading ? (
        <Loader />
      ) : (
        <Row>
          <Col sm={12} className="mb-3">
            <Stack direction="horizontal" gap={2}>
              <div className="ms-auto" />
              <p className="mb-0">{recordFound > 1 ? `${recordFound} records` : recordFound === 1 ? "1 record" : "No record"}</p>

              <Form.Control type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} style={{ width: 250 }} />
              <Form.Control type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} style={{ width: 250 }} />
              <Button variant="" className="op-primary-color text-light" onClick={getIncomingLog}>
                <FontAwesomeIcon icon={faMagnifyingGlass} />
              </Button>
            </Stack>
          </Col>
          <Col sm={12}>
            <div style={containerStyle}>
              <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                <AgGridReact rowHeight={70} pagination={false} animateRows={true} ref={gridRef} defaultColDef={gridColDef} rowData={gridRecord} columnDefs={gridColumn} getRowId={gridRowId} />
              </div>
            </div>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default ReportIncomingLogBooking;
