import React, { useEffect } from "react";
import { Outlet, NavLink } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import { Container, Nav } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGrid2 as faGrid2Active, faHouseCircleCheck as faHouseCircleCheckActive, faHouseBuilding as faHouseBuildingActive, faBallotCheck as faBallotCheckActive, faHouseChimneyHeart as faHouseChimneyHeartActive } from "@fortawesome/pro-solid-svg-icons";
import { faBallotCheck, faGrid2, faHouseBuilding, faHouseChimneyHeart, faHouseCircleCheck } from "@fortawesome/pro-light-svg-icons";
import { useAuth } from "../auth/AuthContext";

const Booking = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { session } = useAuth();
  useEffect(() => {
    if (location.pathname === "/booking" || location.pathname === "/booking/") {
      navigate("/booking/project/0/0", { replace: true });
    }
  }, [navigate]);

  return (
    <Container fluid className="m-0 p-0 d-flex" style={{ height: "calc(100vh - 56px)" }}>
      <div className="h-100 overflow-auto op-scrollbar" style={{ width: "20%" }}>
        <Nav variant="pills" className="h-100 flex-column sidebar-tab bg-light py-4 px-3">
          {Number(session.booking_viewer_access) === 0 && (
            <Nav.Item className="mb-2">
              <NavLink className="nav-link" to="/booking/dashboard" activeclassname="active">
                {({ isActive }) => (
                  <div className="d-flex align-items-center py-1">
                    <span className="me-2" style={{ width: 25 }}>
                      <FontAwesomeIcon icon={isActive ? faGrid2Active : faGrid2} size="xl" />
                    </span>
                    <span>Dashboard</span>
                  </div>
                )}
              </NavLink>
            </Nav.Item>
          )}

          {Number(session.booking_viewer_access) === 0 && (
            <Nav.Item className="mb-2">
              <NavLink className="nav-link" to="/booking/list" activeclassname="active">
                {({ isActive }) => (
                  <div className="d-flex align-items-center py-1">
                    <span className="me-2" style={{ width: 25 }}>
                      <FontAwesomeIcon icon={isActive ? faHouseCircleCheckActive : faHouseCircleCheck} size="xl" className="me-2" />
                    </span>
                    <span>Booking</span>
                  </div>
                )}
              </NavLink>
            </Nav.Item>
          )}

          <Nav.Item className="mb-2">
            <NavLink className="nav-link" to="/booking/project/0/0" activeclassname="active">
              {({ isActive }) => (
                <div className="d-flex align-items-center py-1">
                  <span className="me-2" style={{ width: 25 }}>
                    <FontAwesomeIcon icon={isActive ? faHouseBuildingActive : faHouseBuilding} size="xl" className="me-2" />
                  </span>
                  <span>Project Information</span>
                </div>
              )}
            </NavLink>
          </Nav.Item>

          {Number(session.booking_viewer_access) === 0 && Number(session.role_id) === 1 && (
            <Nav.Item className="mb-2">
              <NavLink className="nav-link" to="/booking/approval" activeclassname="active">
                {({ isActive }) => (
                  <div className="d-flex align-items-center py-1">
                    <span className="me-2" style={{ width: 25 }}>
                      <FontAwesomeIcon icon={isActive ? faBallotCheckActive : faBallotCheck} size="xl" className="me-2" />
                    </span>
                    <span>Approval</span>
                  </div>
                )}
              </NavLink>
            </Nav.Item>
          )}

          {Number(session.booking_viewer_access) === 0 && (
            <Nav.Item className="mb-2">
              <NavLink className="nav-link" to="/booking/interest" activeclassname="active">
                {({ isActive }) => (
                  <div className="d-flex align-items-center py-1">
                    <span className="me-2" style={{ width: 25 }}>
                      <FontAwesomeIcon icon={isActive ? faHouseChimneyHeartActive : faHouseChimneyHeart} size="xl" className="me-2" />
                    </span>
                    <span>Interest</span>
                  </div>
                )}
              </NavLink>
            </Nav.Item>
          )}

          <Nav.Item className="mb-2">
            <NavLink className="nav-link" to="/booking/unit-price" activeclassname="active">
              {({ isActive }) => (
                <div className="d-flex align-items-center py-1">
                  <span className="me-2" style={{ width: 25 }}>
                    {isActive ? (
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                        <path
                          fill="#fff"
                          d="M.1 255.4c0-9 3-17 10-24L266.4 8c7-7 15-8 22-8s15 2 21 7L522 193.9c-8.5-1.3-17.2-1.9-26-1.9c-91.8 0-167.2 70.3-175.3 160c-.2 0-.5 0-.7 0l-64 0c-17.7 0-32 14.3-32 32l0 64 0 24c0 22.1-17.9 40-40 40l-24 0-31.9 0c-1.5 0-3-.1-4.5-.2c-1.2 .1-2.4 .2-3.6 .2l-16 0c-22.1 0-40-17.9-40-40l0-112c0-.9 0-1.9 .1-2.8l0-69.7-32 0c-18 0-32-14-32-32.1zM352 368c0-79.5 64.5-144 144-144s144 64.5 144 144s-64.5 144-144 144s-144-64.5-144-144zm0 101.2c11.7 16.6 26.2 31.1 42.8 42.8l-2.8 0c-22.1 0-40-17.9-40-40l0-2.8zM416 512l36.7 0c-1.4 .1-2.8 .1-4.2 .1L416 512zm24.1-171c1 16 11.7 25.3 21.6 30.7c8.8 4.7 19.7 7.8 28.6 10.3c.6 .2 1.2 .3 1.8 .5c10.3 2.9 17.9 5.2 23.2 8.3c4.5 2.7 4.7 4.2 4.7 5.6c.1 2.4-.5 3.7-1 4.5c-.6 1-1.8 2.1-4 3.3c-4.7 2.5-11.8 3.8-18.5 3.6c-9.5-.3-18.5-3.1-29.9-6.8c-1.9-.6-3.8-1.2-5.8-1.8c-8.4-2.6-17.4 2.1-20 10.5s2.1 17.4 10.5 20c1.6 .5 3.3 1 5 1.6c7.1 2.3 15.1 4.9 23.7 6.6l0 11.4c0 8.8 7.2 16 16 16s16-7.2 16-16l0-10.8c6.2-1.1 12.5-3.1 18.3-6.2c12.1-6.5 22.3-18.7 21.7-36.9c-.5-16.2-10.3-26.3-20.5-32.3c-9.4-5.6-21.2-8.9-30.5-11.5l-.2 0c-10.4-2.9-18.3-5.2-23.9-8.2c-4.8-2.6-4.8-4-4.8-4.5c-.1-2 .3-3 .8-3.6c.6-.9 1.8-2.1 4.2-3.4c5.1-2.7 12.5-4.1 18.7-4c8.2 .1 17.1 1.8 26.4 4.1c8.6 2.1 17.3-3.1 19.4-11.7s-3.1-17.3-11.7-19.4c-5.6-1.4-11.6-2.7-17.9-3.7l0-9.4c0-8.8-7.2-16-16-16s-16 7.2-16 16l0 9.5c-6.1 1.2-12.3 3.2-18 6.3c-11.8 6.3-23 18.4-21.8 37.2zm12.6 171l22.2 0c-.9 .1-1.9 .1-2.9 .1l-16 0c-1.1 0-2.2 0-3.3-.1z"
                        />
                      </svg>
                    ) : (
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                        <path d="M4 266.6C-1.9 260-1.2 249.8 5.4 244L277.4 4c6.1-5.3 15.2-5.3 21.2 0L384 79.4 384 56c0-13.3 10.7-24 24-24l80 0c13.3 0 24 10.7 24 24l0 136.3 .5 .5c-5.4-.5-11-.8-16.5-.8c-10.2 0-20.1 .9-29.8 2.5L288 37.3 96 206.7 96 432c0 26.5 21.5 48 48 48l216.3 0c10 12.1 21.6 22.9 34.5 32L144 512c-44.2 0-80-35.8-80-80l0-197L26.6 268C20 273.9 9.9 273.2 4 266.6zM352 368c0-79.5 64.5-144 144-144s144 64.5 144 144s-64.5 144-144 144s-144-64.5-144-144zm32 0c0 61.9 50.1 112 112 112s112-50.1 112-112s-50.1-112-112-112s-112 50.1-112 112zM416 64l0 43.6 64 56.5L480 64l-64 0zm24.1 277c-1.2-18.8 10-30.9 21.8-37.2c5.7-3.1 12-5.1 18-6.3l0-9.5c0-8.8 7.2-16 16-16s16 7.2 16 16l0 9.4c6.3 .9 12.3 2.3 17.9 3.7c8.6 2.1 13.8 10.8 11.7 19.4s-10.8 13.8-19.4 11.7c-9.3-2.3-18.2-4-26.4-4.1c-6.2-.1-13.6 1.3-18.7 4c-2.4 1.3-3.6 2.5-4.2 3.4c-.4 .7-.9 1.6-.8 3.6c0 .5 0 1.9 4.8 4.5c5.6 3 13.5 5.2 23.9 8.2l.2 0c9.3 2.6 21.1 6 30.5 11.5c10.2 6 20 16.1 20.5 32.3c.5 18.2-9.7 30.4-21.7 36.9c-5.8 3.1-12.1 5.1-18.3 6.2l0 10.8c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-11.4c-8.6-1.7-16.7-4.3-23.7-6.6c-1.7-.6-3.4-1.1-5-1.6c-8.4-2.6-13.1-11.6-10.5-20s11.6-13.1 20-10.5c2 .6 3.9 1.2 5.8 1.8c11.5 3.6 20.4 6.5 29.9 6.8c6.7 .2 13.8-1.1 18.5-3.6c2.2-1.2 3.4-2.4 4-3.3c.5-.8 1.1-2.1 1-4.5c0-1.4-.2-3-4.7-5.6c-5.3-3.1-12.9-5.4-23.2-8.3c-.6-.2-1.2-.3-1.8-.5c-8.9-2.5-19.8-5.6-28.6-10.3c-9.9-5.3-20.6-14.7-21.6-30.7z" />
                      </svg>
                    )}
                  </span>
                  <span>Unit Price</span>
                </div>
              )}
            </NavLink>
          </Nav.Item>
        </Nav>
      </div>

      <div className="overflow-auto" style={{ width: "85%" }}>
        <Outlet />
      </div>
    </Container>
  );
};

export default Booking;
