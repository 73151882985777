import { faArrowDown, faArrowUp, faInfo, faMagnifyingGlass, faMinus } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect, useRef, useCallback, useMemo, forwardRef } from "react";
import { Button, Col, Container, Dropdown, Form, ListGroup, Offcanvas, Row, Spinner, Stack } from "react-bootstrap";
import { FormSelect } from "../includes/FormCustom";
import axios from "../api/axios";
import { useAuth } from "../auth/AuthContext";
import Loader from "../includes/Loader";
import { AgGridReact } from "ag-grid-react";
import { setCurrency } from "../lib/js/Function";
import moment from "moment";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

const UnitPricePreview = () => {
  const init = useRef(true);
  const { session } = useAuth();
  const [loading, setLoading] = useState(true);
  const [projectList, setProjectList] = useState([]);
  const [projectData, setProjectData] = useState({});
  const [phaseList, setPhaseList] = useState([]);
  const [phaseData, setPhaseData] = useState({});
  const [phaseShow, setPhaseShow] = useState(true);
  const [unitList, setUnitList] = useState([]);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [isUnitInfo, setIsUnitInfo] = useState(false);
  const [unitInfo, setUnitInfo] = useState({});
  const [unitPriceList, setUnitPriceList] = useState([]);

  // GET FUNCTION ===================================

  const getProjectList = async () => {
    try {
      const response = await axios(`ws/ws_rea_bulk_unit.php`, {
        params: {
          task: "GetProjectPhaseList",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      setProjectList(data.status === 0 ? data.record : []);
      setProjectData(data.status === 0 ? data.record[0] : { label: "", value: 0 });
      setPhaseList(data.status === 0 ? data.record[0].phase_record : []);
      setPhaseData(data.status === 0 ? data.record[0].phase_record[0] : { label: "", value: 0 });
      await getUnitList(data.status === 0 ? data.record[0].phase_record[0].value : 0);
    } catch (error) {
      console.error(error);
    }
  };

  const getUnitList = async (phaseid) => {
    try {
      const response = await axios(`ws/ws_rea_bulk_unit.php`, {
        params: {
          task: "GetUnitList",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          rea_phase_id: phaseid,
        },
      });

      const data = response.data;
      setUnitList(data.status === 0 ? data.record : []);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      setLoading2(false);
    }
  };

  const getDownloadUnit = async (phaseid, downloadType) => {
    setLoadingBtn(true);
    try {
      const response = await axios(`ws/ws_rea_bulk_unit.php`, {
        params: {
          task: "DownloadUnit",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          rea_phase_id: phaseid,
          download_type: downloadType,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        window.open(`${session.hostUrlApi}/${session.hostUrlApiType}/ws/booking_files/${data.filename}`, "_blank");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingBtn(false);
    }
  };

  const getUnitPriceList = async (rea_unit_id) => {
    try {
      const response = await axios(`ws/ws_rea_bulk_unit.php`, {
        params: {
          task: "UnitPriceList",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          rea_unit_id: rea_unit_id,
        },
      });

      const data = response.data;
      setUnitPriceList(data.status === 0 ? data.record : []);
    } catch (error) {
      console.error(error);
    }
  };

  // ONCHANGE FUNCTION ===================================

  const onChangeProject = (e) => {
    setPhaseShow(false);
    setProjectData(e);
    setPhaseList(e.phase_record);
    setPhaseData(e.phase_record[0]);
    setTimeout(() => setPhaseShow(true), 100);
  };

  // SET FUNCTION ===================================

  const setStatusColor = (status) => {
    if (status === "available") {
      return "success";
    } else if (status === "booked") {
      return "warning";
    } else if (status === "reserved") {
      return "primary";
    } else if (status === "sold") {
      return "danger";
    } else if (status === "unavailable") {
      return "secondary";
    } else if (status === "on_hold") {
      return "orange";
    } else {
      return "";
    }
  };

  const setUnitStatusLabel = (status) => {
    if (status === "available") {
      return "Available";
    } else if (status === "booked") {
      return "Booked";
    } else if (status === "reserved") {
      return "Reserved";
    } else if (status === "sold") {
      return "Sold";
    } else if (status === "unavailable") {
      return "Unavailable";
    } else if (status === "on_hold") {
      return "On Hold";
    } else {
      return "";
    }
  };

  // RENDER FUNCTION ===================================

  const RenderDownloadButton = forwardRef(({ children, onClick }, ref) => (
    <Button
      ref={ref}
      variant=""
      className="op-primary-color text-light"
      disabled={loadingBtn}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {loadingBtn ? <Spinner animation="border" size="sm" /> : children}
    </Button>
  ));

  // GRID SETUP ===================================

  const gridRef = useRef(null);
  const containerStyle = useMemo(() => ({ width: "100%", height: "80vh" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const gridColumn = [
    {
      headerName: "No",
      field: "index",
      maxWidth: 70,
      headerClass: "center",
      cellClass: "center",
      cellRenderer: (params) => {
        if (params) {
          return params.rowIndex + 1;
        }
        return null;
      },
    },
    {
      headerName: "Unit Number",
      field: "unit_number",
      filter: true,
      onCellClicked: (params) => {
        if (params.data) {
          window.open(`${session.origin}/sales-chart/unit-details/0/0/${params.data.rea_unit_id}`, "_blank");
        }
      },
    },

    {
      headerName: "Unit Status",
      field: "unit_status",
      cellRenderer: (params) => {
        if (params.data) {
          return (
            <Button variant={setStatusColor(params.data.unit_status)} style={{ pointerEvents: "none" }}>
              {setUnitStatusLabel(params.data.unit_status)}
            </Button>
          );
        }
        return null;
      },
    },
    {
      headerName: "Original Selling Price",
      field: "unit_selling_price",
      flex: 1,
      cellRenderer: (params) => {
        if (params.value) {
          return setCurrency(params.value);
        }
        return null;
      },
    },
    {
      headerName: "Current Selling Price",
      field: "new_selling_price",
      cellRenderer: (params) => {
        if (params.value) {
          return setCurrency(params.value);
        }
        return setCurrency(params.data.unit_selling_price);
      },
    },
    {
      headerName: "Before Selling Price",
      field: "before_selling_price",
      cellRenderer: (params) => {
        if (params.value) {
          return setCurrency(params.value);
        }
        return null;
      },
    },
    {
      headerName: "Last Update",
      field: "date_time_created",
      cellRenderer: (params) => {
        if (params.value) {
          return moment(params.value).format("lll");
        }
        return null;
      },
    },
    {
      headerName: "",
      field: "",
      maxWidth: 100,
      headerClass: "center",
      cellClass: "center",
      pinned: "right",
      cellRenderer: (params) => {
        if (params.data) {
          let status = "normal";
          if (Number(params.data.before_selling_price) > Number(params.data.new_selling_price)) {
            status = "down";
          } else if (Number(params.data.before_selling_price) < Number(params.data.new_selling_price)) {
            status = "up";
          }

          return (
            <div className="d-flex justify-content-center">
              <Button variant={status === "down" ? "danger" : status === "up" ? "success" : "secondary"} className="me-2" style={{ pointerEvents: "none" }}>
                <FontAwesomeIcon icon={status === "down" ? faArrowDown : status === "up" ? faArrowUp : faMinus} />
              </Button>

              <Button
                variant=""
                className="border shadow-sm"
                onClick={async () => {
                  await getUnitPriceList(params.data.rea_unit_id);
                  setIsUnitInfo(true);
                  setUnitInfo(params.data);
                }}
              >
                <FontAwesomeIcon icon={faInfo} />
              </Button>
            </div>
          );
        }
      },
    },
  ];

  const gridRowId = useCallback(function (params) {
    return params.data.rea_unit_id.toString();
  }, []);

  useEffect(() => {
    if (init.current) {
      init.current = false;
      getProjectList();
    }
  }, []);

  return (
    <Container fluid className="p-4 position-relative" style={{ height: "calc(100vh - 56px)" }}>
      {loading ? (
        <Loader />
      ) : (
        <Row>
          <Col sm={12} className="mb-3">
            <Stack direction="horizontal" gap={2}>
              <Dropdown>
                <Dropdown.Toggle as={RenderDownloadButton}>Download List</Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Header style={{ fontSize: "13px" }}>Choose below option</Dropdown.Header>
                  <Dropdown.Item onClick={() => getDownloadUnit(phaseData.value, "all")}>Download All</Dropdown.Item>
                  <Dropdown.Item onClick={() => getDownloadUnit(phaseData.value, "available")}>Download Available Only</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <div className="ms-auto" />
              <FormSelect options={projectList} valueDefault={projectData} onChange={onChangeProject} width="250px" />
              {phaseShow && <FormSelect options={phaseList} valueDefault={phaseData} onChange={setPhaseData} width="250px" />}
              <Button
                variant=""
                className="op-primary-color text-light"
                onClick={() => {
                  setLoading2(true);
                  getUnitList(phaseData.value);
                }}
              >
                <FontAwesomeIcon icon={faMagnifyingGlass} />
              </Button>
            </Stack>
          </Col>
          {loading2 ? (
            <Col sm={12}>
              <div className="d-flex justify-content-center align-items-center w-100">
                <div className="rounded-circle shadow d-flex justify-content-center align-items-center" style={{ height: 30, width: 30 }}>
                  <Spinner animation="border" role="status" size="sm">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
              </div>
            </Col>
          ) : (
            <Col sm={12}>
              <div style={containerStyle}>
                <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                  <AgGridReact ref={gridRef} rowSelection="multiple" rowData={unitList} rowHeight={70} columnDefs={gridColumn} getRowId={gridRowId} pagination={true} paginationPageSize={100} paginationPageSizeSelector={false} suppressRowClickSelection={true} animateRows={true} />
                </div>
              </div>
            </Col>
          )}
        </Row>
      )}

      <Offcanvas show={isUnitInfo} placement="end" onHide={() => setIsUnitInfo(false)}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{unitInfo.unit_number}'s Price History</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="border p-3 rounded">
            <Form.Group className="w-100">
              <Form.Label className="w-100 text-center">
                <b>Current Selling Price</b>
              </Form.Label>
              <Form.Label className="w-100 text-center mb-0" style={{ fontSize: "17px", color: "#198754" }}>
                <b>{unitInfo.new_selling_price ? setCurrency(unitInfo.new_selling_price) : setCurrency(unitInfo.unit_selling_price)}</b>
              </Form.Label>
              {unitInfo.new_selling_price && (
                <Form.Label className="w-100 text-center" style={{ textDecoration: "line-through", color: "#999" }}>
                  {setCurrency(unitInfo.unit_selling_price)}
                </Form.Label>
              )}
            </Form.Group>
          </div>
          <ListGroup className="mt-3">
            <ListGroup.Item className="d-flex align-items-center">
              <div className="d-flex align-items-center w-100">
                <div style={{ width: "13%" }}>
                  <div className={`d-flex align-items-center justify-content-center me-2 text-light bg-secondary`} style={{ width: 30, height: 30, borderRadius: 50 }}>
                    <FontAwesomeIcon icon={faArrowRight} />
                  </div>
                </div>
                <div style={{ width: "87%" }}>Starting price from {setCurrency(unitInfo.unit_selling_price)}</div>
              </div>
            </ListGroup.Item>
            {unitPriceList.map((item, index) => (
              <ListGroup.Item key={index} className="mt-2 border">
                <div className="d-flex align-items-center w-100">
                  <div style={{ width: "13%" }}>
                    <div
                      className={`d-flex align-items-center justify-content-center me-2 text-light ${Number(item.new_selling_price) > Number(unitInfo.unit_selling_price) ? "bg-success" : Number(item.new_selling_price) === Number(unitInfo.unit_selling_price) ? "bg-secondary" : "bg-danger"}`}
                      style={{ width: 30, height: 30, borderRadius: 50 }}
                    >
                      <FontAwesomeIcon icon={Number(item.new_selling_price) > Number(unitInfo.unit_selling_price) ? faArrowUp : Number(item.new_selling_price) === Number(unitInfo.unit_selling_price) ? faArrowRight : faArrowDown} />
                    </div>
                  </div>
                  <div style={{ width: "87%" }}>Price change to {setCurrency(item.new_selling_price)} from {setCurrency(item.before_selling_price)} on {moment(item.date_time_created).format("lll")}</div>
                </div>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Offcanvas.Body>
      </Offcanvas>
    </Container>
  );
};

export default UnitPricePreview;
