import React, { useState } from "react";
import SearchInput from "./SearchInput";
import SearchDropdown from "./SearchDropdown";
const SearchBar = () => {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchBackup, setSearchBackup] = useState("");
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [category, setCategory] = useState("all");

  const handleSearch = (searchValue) => {
    setSearchTerm(searchValue);
  };

  const handleSearchClose = () => {
    setIsSearchOpen(false);
  };

  return (
    <React.Fragment>
      <div className="op-search-container">
        <div className="op-search-wrapper">
          <SearchInput
            searchRef={isSearchOpen}
            handleSearch={handleSearch}
            handleSearchShow={setIsSearchOpen}
            handleSearchClose={handleSearchClose}
            loading={loading}
          />
        </div>
        {/* <SearchDropdown /> */}
        {isSearchOpen && <SearchDropdown options={options} setOptions={setOptions} category={category} setCategory={setCategory} searchBackup={searchBackup} setSearchBackup={setSearchBackup} onSearch={searchTerm} onLoading={(value) => setLoading(value)} handleSearchClose={(value) => setIsSearchOpen(value)} />}
      </div>
      
      
    </React.Fragment>
  );
};

export default SearchBar;
